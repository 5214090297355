/* LoginForm.jsx

Form to join 
Parent: pages/public/Join.jsx
Children:  form components in -  ./formCompnents

*/

import React from 'react'
import { chitBurgandyDull, lightGrey } from '../styles/colors';
import { Navigate, useNavigate } from 'react-router-dom'

import { FormProvider, useForm, Controller } from "react-hook-form";

import { useDispatch } from 'react-redux';
import { changeLoadingStatus } from '../app/redux/statusRedux/statusSlice'

import { resetUserPassword } from '../app/firebase/firebase.utils';

import { yupResolver } from '@hookform/resolvers/yup';
import { SchemaOf, string, object, array } from 'yup';
import { StyledInput } from './formComponents/StyledInput'

import { styled, createTheme } from '@mui/material/styles'
import Button from '@mui/material/Button';
// import FirebaseAuthService from '../app/firebase/FirebaseAuthService';
// import { updateLastVisit } from '../app/firebase/FirebaseFirestoreService';

const theme = createTheme(); // allows use of mui theme in styled component


// ===================================================

// --- Yup setup ---

const defaultValues = {
  email: "",
};

//  -- Input requirements for user for each component (if any)

const formSchema = object({
  email: string().required('email is required').email('not an email'),
});

// ===================================================

function PasswordResetForm() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const methods = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(formSchema)
  });

  const { handleSubmit, reset, control } = methods;

  const cancelReset = () => {
    navigate('/login')
  }

  const submitForm = async (data) => {
    console.log('[Responsive_Form]...data ', data.email)

    resetUserPassword(data.email)


    navigate('/login')
    reset(defaultValues)

  };


  // --- Actual Form ---------------------------------------------
  return (
    <>
      <TitleWrapper> Reset Password</TitleWrapper>
      <FormProvider {...methods}>
        <FormWrapper onSubmit={handleSubmit(submitForm)}>


          {/* ------Input Component  -------------------------- */}

          <FormComponentWrapper>
            <ComponentName>
              Enter email
            </ComponentName>

            <ComponentWrapper>
              <StyledInput name="email" control={control} label="Email" type="text" />

            </ComponentWrapper>
          </FormComponentWrapper>






          {/* ------Submit ---------- -------------------------- */}
          <ButtonWrapper>

            <StyledButton type="submit" variant="contained" color="primary">
              Reset Password
            </StyledButton>
            <StyledButton onClick={() => cancelReset()}>

              cancel
            </StyledButton>
          </ButtonWrapper>
        </FormWrapper>

      </FormProvider >




    </>
  )// end return
}

export default PasswordResetForm





// ----------------------
const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  backgroundColor: '#fad3b6',

  height: '100vh',


})

const TitleWrapper = styled('h3')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '50%'





})

const FormWrapper = styled('form')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '60%',



  [theme.breakpoints.down('sm')]: {
    width: '100%',


  },

})
const FormComponentWrapper = styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '100%',
  margin: '.5rem',
  // overflowY: 'hidden',

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const ComponentName = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  color: 'darkGrey',
  // overflowY: 'hidden',

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const ComponentWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
  // overflowY: 'hidden',

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const ButtonWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',

  margin: '.75rem',
  // overflowY: 'hidden',

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const StyledButton = styled(Button)({
  backgroundColor: 'white',
  border: '1px solid #E6E7E8',
  color: chitBurgandyDull,
  margin: '0 8px',
  padding: ' .5rem 1rem',
  // height: '1.5rem',
  fontSize: '.8rem',
  '&:hover': {
    backgroundColor: lightGrey
  }

})