import React from 'react'
import { chitRedDark, chitBurgandy, backgroundBlue } from '../../../../styles/colors'


import { styled, createTheme } from "@mui/material/styles"
const theme = createTheme(); // allows use of mui theme in styled component




export const Picture11 = () => {
    return (
        <>
            <Header>What ChitGit keeps track of : </Header>
            <ul>
                <Bullets>Your interactions with others</Bullets>
                <Bullets>Obligations, IOU's </Bullets>
                <Bullets> Promises made by you or to you </Bullets>
                <Bullets> Disputes you're involved in </Bullets>
                <Bullets> Ideas you've had</Bullets>
                <Bullets> Accomplishments in your life</Bullets>
            </ul>
        </>
    )
}


export const Narration11 = () => {
    return (
        <NarWrapper>
            <NarHeader>
                How does ChitGit work?
            </NarHeader>

            <NarDetail>
                <div>
                    Enter an event, interaction, or thought into ChitGit and forget about it.   You can now recall a single item... or, view the entire history of events related to a topic, a pursuit, or your interactions with a person or group.  
                </div>

            </NarDetail>

        </NarWrapper>
    )
}


// -----------------------------------------------------------------

const NarWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    width: '70%',

    [theme.breakpoints.down('sm')]: {
        width: '100%',

    }

})
const NarHeader = styled('div')({
    display: 'flex',
    justifyContent: 'flex-start',
    // padding: '0 auto 0 auto',
    color: chitBurgandy,
    // marginTop: '2rem',
    fontSize: '1rem',
    fontWeight: 'bold',
    // // letterSpacing: '.1rem',
    textAlign: 'center',
    width: '80%',


    [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
        fontSize: '.9rem',
        width: '100%',
    },

    [theme.breakpoints.down('xs')]: {
        // width: '65%',
        // fontSize: '1.2rem',
    }

})



const NarDetail = styled('div')({


    display: 'flex',
    flexDirection: 'column',
    // padding: '0 auto 0 auto',
    color: 'black',
    marginTop: '1rem',
    fontSize: '.8rem',
    textAlign: 'left',
    width: '80%',

    '& div': {
        padding: '0',
        margin: '3px'
    },

    '& span': {
        fontWeight: 'bold'
    },

    '& em': {
        color: chitRedDark,
        fontStyle: 'normal',
        fontWeight: 'bold'
    },
    [theme.breakpoints.down('sm')]: {

        textAlign: 'left',
        width: '80%',
    },
    [theme.breakpoints.down('xs')]: {
        width: '80%',

    }

})

// ----Slide 1 ------------------



const PictureStyle = styled('div')({

    display: 'flex',
    jutifyContent: 'center',
    alignItems: 'center',

    width: '60%',
    height: '15rem',



    [theme.breakpoints.down('sm')]: {
        width: '80%',
        jutifyContent: 'flex-start',
        height: '7rem',
        marginBottom: '1rem'
    }
})

const CoinEnds = styled('div')({

    display: 'flex',
    jutifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'flex-end',
    flexDirection: 'column',
    width: '9rem',
    height: '9rem',
    fontSize: '.9rem',
    textAlign: 'center',
    color: 'black',

    [theme.breakpoints.down('sm')]: {

        height: '7rem',
        width: '7rem',
        fontSize: '.75rem',
    }

})

const CoinMiddle = styled('div')({

    display: 'flex',
    jutifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',

    width: '9rem',
    height: '9rem',
    fontSize: '.9rem',
    textAlign: 'center',
    color: 'black',

    [theme.breakpoints.down('sm')]: {

        height: '7rem',
        width: '7rem',
        fontSize: '.75rem',
    }

})

const CoinStyle = styled('img')({
    display: 'block',
    margin: 'auto',
    width: '5rem',
    height: '5rem',

    [theme.breakpoints.down('sm')]: {

        width: '4rem',
        height: '4rem',
    }

})

const Bullets = styled('li')({
    fontSize: '.9rem',
    color: chitBurgandy,
    [theme.breakpoints.down('md')]: {

        fontSize: '.85rem',
    }

})

const Header = styled('div')({

    display: 'block',
    jutifyContent: 'flex-end',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',

    textAlign: 'center',


    [theme.breakpoints.down('sm')]: {


    }

})