
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { Scrollbars } from 'react-custom-scrollbars';

import { chitBlueDull,  mediumGrey, veryLightGrey, lightGrey, chitBurgandyDull, mediumLightGrey, chitLightBlueDull } from '../styles/colors';

// ################ TEMP ######################
import cuid from 'cuid'  // #### for sample site only ####

import { FormProvider, useForm, Controller } from "react-hook-form";

import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import { UTCtoDate, UTCtoISO } from '../app/helpers/dateHelper';
// ---Retrieve all needed collections from Redux store -------

import { changeLoadingStatus, selectStatus, closeTopicalSectionForm } from '../app/redux/statusRedux/statusSlice';

import { 
  selectTopicalSections,
  updateEditedTopicalSection, 
  selectTopicalSectionFromArray
 } from '../app/redux/topicalRedux/topicalSectionsSlice';

import { Editor } from './formComponents/JournalEditor'

import { StyledJournalDatePicker } from './formComponents/StyledJournalDatePicker'
import { updateDocumentFromFirestore } from '../app/firebase/fireStore.utils';

import { ChronicleInput } from './formComponents/ChronicleInput'

// --- MUI imports ---------

import InfoIcon from '@mui/icons-material/Info';

import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import { styled, createTheme } from '@mui/material/styles'
 

const theme = createTheme(); // allows use of mui theme in styled component

// ========================================================
export default function EditTopicalSectionForm(props) {
  
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const status = useSelector(selectStatus)
  const uid = status.auth.loginId
  const topicalSectionId = status.view.topical.sectionId

  let match = useParams()
  let urlId = match.id
 
  // --- open and closes form in section
 

  // --- dialog box open and close
  const [open, setOpen] = React.useState(false);
  
  const handleClickAway = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

// --- popover for search 
const [anchorEl, setAnchorEl] = React.useState(null);

const handleClick = (event) => {
  setAnchorEl(event.currentTarget);
};

const handleClosePopover = () => {
  setAnchorEl(null);
};

  // --- close / cancel form 
  const cancelEditForm = () => {

    dispatch(closeTopicalSectionForm())
    // navigate(`/sample/topical`)
  }

const openPopover = Boolean(anchorEl);
const id = openPopover ? 'simple-popover' : undefined;

  // (1) ---Retrieve all needed collections from Redux store -------

  let retrievedTopicalSections = useSelector(selectTopicalSections)
  const [allTopicalSections, setAllTopicalSections ] = useState(retrievedTopicalSections)
  useEffect(()=>{
    setAllTopicalSections(retrievedTopicalSections)
  },[retrievedTopicalSections])



    // --- set up defaultValues

    let defaultValues,   sectionId, detail, title,   dateTime,  topicalSection, topicalDate

    topicalSectionId  === 'new' ? sectionId = cuid()  : sectionId =  topicalSectionId  

    topicalSection =  selectTopicalSectionFromArray(allTopicalSections, topicalSectionId)


    
    title = topicalSection.title
    detail = topicalSection.detail
    let defaultTopicalDate = topicalSection.topicalDate
    topicalDate = new Date(defaultTopicalDate) 
    let today = Date.now()
    let ISODate = UTCtoDate(today)
    dateTime = new Date(ISODate).toISOString()


    defaultValues = {
      detail: detail,
      title: title,
 
      lastEdit:  dateTime,  // used in last Edit (auto)
      topicalDate: topicalDate  // Bob's login time Mar 14 - used in input field  
  
    };



  // ===========  FORM  ===============================================
  const formSchema = object({

    // journalContent: string().required('Your journal needs some content'),


  });

  const methods = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(formSchema)
  });

  const { handleSubmit, reset, control, setValue, onChange, watch, ref } = methods;

  const submitForm = async (data) => {
    console.log('[ Topical SECTION FORM FORM FORM ] data ', data);

       
    // - replace the <p>s with <div>s in Quill editor to make it appear better
    let noPtagDetail = data.detail.replaceAll('<p>', '<div>')
    let cleanDetail = noPtagDetail.replaceAll('</p>', '</div>')

    let formattedlastEdit= JSON.stringify(data.lastEdit)
    let defaultTopicalDate= JSON.stringify(data.topicalDate)
    let ISOTopicalDate = new Date (data.topicalDate).toISOString()
    try{ 


      let cleanedData = {
        detail: cleanDetail,
        title: data.title,
        lastEdit: dateTime,
        topicalDate: ISOTopicalDate,
        topic: urlId,
      }

      await updateDocumentFromFirestore(uid, 'topicalSections', topicalSectionId, cleanedData)

      let cleanedReduxData = {
        id: topicalSectionId,
        detail: cleanDetail,
        title: data.title,
        lastEdit: dateTime,
        topicalDate: ISOTopicalDate,
        topic: urlId,
      }

      dispatch(updateEditedTopicalSection(cleanedReduxData))
      cancelEditForm()

    } catch (error) {
      alert(error)
      cancelEditForm()
    }




 


  }// end submitForm

  // --- Main Return ------------------------------

return (
 
    <Wrapper>



    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">

      </DialogTitle>
      <DialogContent>
        <div>
          Your edited topical section section has not been saved. <br/> Either save the edits,  or cancel. 
        </div>
      </DialogContent>
      <DialogActions>


        <StyledButton
          form="submit-form"
          variant="contained"
          color="primary"

          onClick={handleClose}
        >
          Got it
        </StyledButton>

      </DialogActions>
    </Dialog>

 
      <FormProvider {...methods}>

        <ClickAwayListener
          onClickAway={handleClickAway}
          mouseEvent="onMouseDown"
          touchEvent="onTouchStart"
        >


          <FormWrapper id="submit-form" onSubmit={handleSubmit(submitForm)} >


            <MainWrapper>


            <ButtonWrapper>

                <StyledButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{ textTransform: 'none' }}
                >
                  Save and Close

                </StyledButton>

            <StyledButton onClick={() => cancelEditForm()}> Cancel</StyledButton>
            </ButtonWrapper>

              
              <DateContainer>
        <CalendarTodayIcon style={{
                color: '#A7A7A8',
                fontSize: '1.2rem',
                marginLeft: '5px'
              }}
              />
          <DateWrapper>


            <Controller

                name="topicalDate"
                control={control}


                render={({ field }) => (
                  <StyledJournalDatePicker {...field} ref={null} />
                )} 
              />

          </DateWrapper>

        </DateContainer>



        <OuterContentWrapper>

          <TitleWrapper>

          <ChronicleInput
              name={"title"}
              control={control}
              label={"newPerson"}
              defaultValue= {''}
              placeholder = ' create a headline'
               
               
            />


              </TitleWrapper>


              <ContentWrapper>

{/* Quill here */}

<Controller

name="detail"
control={control}
initialNote={'hi quill description'}

render={({ field }) => (
  <Editor
    {...field}
    ref={null}
    IniitalValue={defaultValues.detail} />
)}

/>


              </ContentWrapper>


  </OuterContentWrapper>



            </MainWrapper>
 

          </FormWrapper>
        </ClickAwayListener>
      </FormProvider>

 

    </Wrapper>

)
}



// ==== Styles ===========================================


const Wrapper = styled(Paper)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  // zIndex: '95',
border: '2px solid orange',
  width: '100%',
  height: '100%',
  // overflow: 'auto',

 

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',
    // backgroundColor: 'red'
  },

})

//  --- Buttons Wrapper  ---------------------------------

const ButtonWrapper= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  margin: '.25rem 0 6px  0',
  padding: '3px 0',

  backgroundColor: mediumLightGrey,
  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const StyledButton= styled(Button)({
  backgroundColor: 'white',
  border: '1px solid #E6E7E8',
  color: chitBurgandyDull,
  margin: '0 8px',
  padding: ' 0 1rem',
  height: '1.5rem',
  fontSize: '.8rem',
  textTransform: 'none',
  '&:hover' :{
    backgroundColor: lightGrey
  }

})






 




const FormWrapper = styled('form')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '98%',
  height: '100%',
  margin: '5px 0',
  padding: '4px',
  backgroundColor: 'white',



  [theme.breakpoints.down('sm')]: {
    width: '100%',
  

  },

})

const MainWrapper= styled('div')({

  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  // backgroundColor: 'green',
  width: '98%',
  height: '100%',
  marginBottom: '6px',
  paddingBottom: '6px',
 
  [theme.breakpoints.down('sm')] : {
    // width: '100%'
  },


})


const DateContainer= styled('div')({

  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
 width: '98%',

 padding: '2px 8px',
  margin: '0 0 3px o',
  backgroundColor: veryLightGrey,
 


  width: '100%',
  margin: '3px 0',
 
  height: '2rem',
  [theme.breakpoints.down('sm')] : {
    // width: '100%'
  },


})

const DateWrapper= styled('div')({

  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  // backgroundColor: 'green',
  width: '35%',
  padding: '2px 6px',

  fontSize: '.8rem',
  height: '.8rem',

  '& span': {
    color: mediumGrey,
    marginLeft: '6px',
     
  },

  [theme.breakpoints.down('sm')] : {
    width: '100%'
  },


})


const OuterContentWrapper= styled('div')({

  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',

  width: '100%',
  

  [theme.breakpoints.down('sm')] : {
    // width: '100%'
  },


})

// ---- Content Wrapper -------------------------------------

const ContentWrapper= styled('div')({

  display: 'flex',
  position: 'relative',
  flexDirection: 'column',

  width: '100%',
  // marginTop:'6px',
  margin: 'auto',
  
  
  
  [theme.breakpoints.down('sm')] : {
    // width: '100%'
  },


})


const TitleWrapper= styled('div')({

  display: 'flex',
 
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  fontSize: '.75rem',
  width: '100%',
  margin : '5px 0 8px 0',
  padding: '2px 8px',
  
   

 

  [theme.breakpoints.down('sm')] : {
    // width: '100%'
  },


})

const TimesWrapper= styled('div')({

  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',

  width: '100%',
  padding: '2px 6px',

  fontSize: '.65rem',
  color: mediumGrey,

  [theme.breakpoints.down('sm')] : {
    // width: '100%'
  },


})

const HeadlineWrapper= styled('div')({

  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  color: chitBlueDull,
  width: '99%',
  padding: '6px 0',

  [theme.breakpoints.down('sm')] : {
    // width: '100%'
  },


})





const Content= styled('div')({
  flexGrow: 1,
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  fontSize: '.85rem',
  width: '70%',

   
  // borderLeft: '1px solid #E6E7E8',
  backgroundColor: 'white',
   
  [theme.breakpoints.down('sm')] : {
    // width: '100%'
  },


})



// ##############################################

const FormComponentWrapper= styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '100%',
  margin: '.5rem',

 
  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const ComponentName= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  color: 'darkGrey',


  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const ComponentWrapper= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',

 
  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})
 
const InfoIconWrapper= styled(InfoIcon)({

  color: mediumLightGrey,
  fontSize : '1.6rem',
  
  '&:hover' : {
    backgroundColor: chitLightBlueDull,
    borderRadius: '50px',
    cursor: 'pointer'
  },

})



  




 