
import React from 'react';

import { chitYellowMedium } from '../../../styles/colors';

import EditIcon from '../components/Edit_icon'
import DeleteIcon from '../components/Delete_icon'

//  ---- Material Ui ------------------

import Paper from '@mui/material/Paper'
 
 

import { styled, createTheme  } from "@mui/material/styles"
const theme = createTheme(); // allows use of mui theme in styled component




// ======================================================

export default function TopicalNote(props) {
  const {noteContent, noteDate, noteKeywordArray, peopleArray, id} = props.data
  return (
    <Wrapper>
 <IconWrapper>
<DeleteIcon id = {id} source = 'notes'/>
</IconWrapper>
      <QuillDiv  dangerouslySetInnerHTML={{__html: noteContent}}/>


    </Wrapper>
  );
}


 // -----------------------------------------------------------------
 const Wrapper = styled(Paper)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '6px 6px',

  flexWrap: 'wrap',
backgroundColor: chitYellowMedium,
   
width: '15rem',
  fontSize: '.85rem',
  height: '15rem',
 


  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },
})

const QuillDiv= styled('div')({
  width: '15rem',
  fontSize: '14px',
  overflowY: 'auto',
  overflowX:'hidden',
  
   '& p' :{
    lineHeight: '.7'
   },
  
  '& .ql-size-small':{
    fontSize: '12px'
  },
  
  '& .ql-size-large' :{
    fontSize: '18px'
  }
  
  })
  const IconWrapper= styled('div')({

    display: 'flex',
    position: 'relative',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    // backgroundColor: 'green',
    // width: '50%',
  
    width: '100%',
    [theme.breakpoints.down('sm')] : {
      // width: '100%'
    },
  
  
  })