/* function TimeLock_icon-------------
     icon to control edit disable for 
     either a log section or topical section
 
------------------------------------*/

import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

 
import{mediumLightGrey, chitOrange, lightGrey, chitBurgandyDull, } from '../../../styles/colors'
// import{changeLastSpotlightDisplayed,  openModal, closeModal} from '../../../../app/redux/statusRedux/sam_action_Status'

// --- import selectors
import { selectTopicalSections } from '../../../app/redux/topicalRedux/topicalSectionsSlice'
import { updateEditedLogTimeLock } from '../../../app/redux/logRedux/logsSlice'
import { selectLogs } from '../../../app/redux/logRedux/logsSlice'

import { selectStatus } from '../../../app/redux/statusRedux/statusSlice'


import { changeLoadingStatus } from '../../../app/redux/statusRedux/statusSlice'

import { ISOtoTraditional } from '../../../app/helpers/dateHelper'
import { updateDocumentFromFirestore } from '../../../app/firebase/fireStore.utils'



import { updateEditedTopicalSectionTimeLock } from '../../../app/redux/topicalRedux/topicalSectionsSlice'

// Material UI --------------------
 
import Tooltip from '@mui/material/Tooltip';
import LockClockIcon from '@mui/icons-material/LockClock';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button'


import { styled, createTheme} from "@mui/material/styles"
import {withStyles} from '@mui/styles'
const theme = createTheme(); // allows use of mui theme in styled component



// =========  Main Function        =======================

function TimeLock_icon(props) {

  const {id, dbCollection }= props
  const dispatch = useDispatch()

  const topicalSections = useSelector(selectTopicalSections)
  const [allTopicalSections, setAllTopicalSections] = useState(topicalSections)

  useEffect(()=>{
    setAllTopicalSections(topicalSections)

  }, [topicalSections])



  const logs = useSelector(selectLogs)
  const [allLogs, setAllLogs] = useState(logs)

  useEffect(()=>{
    setAllLogs(logs)

  }, [logs])








  const status = useSelector(selectStatus)

  //-- get user login id for Firestore update
  const uid = status.auth.loginId

    // console.log('[ TimeLock_icon ] uid', uid);

  // --- Popup Dialog functions -----

  const [open, setOpen] = useState(false)

  const handleClick = (id, source) => {
    setOpen(true);

  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  // --- retrieve log or topical section object based on props.id

  let timeLockStatus, lastEdit
if(id)
  if(dbCollection === 'topicalSections'){
  let topicalObject = allTopicalSections.find(topical => topical.id === id)

  timeLockStatus = topicalObject.timeLock
  lastEdit = topicalObject.lastEdit

}

if(dbCollection === 'logs'){
  let logObject = allLogs.find(log => log.id === id)

  timeLockStatus = logObject.timeLock
  lastEdit = logObject.lastEdit

}
  
let styledLastEdit = ISOtoTraditional(lastEdit)
  // console.log('[ TimeLock_icon ] timeLockStatus ', timeLockStatus);
  // console.log('[ TimeLock_icon ] lastEdit ', lastEdit);

// -- functions for popupMessage -----------------

  let updateDialog = ()=>{

    return(
      // <div> 
      //   update timelock 
      // </div>

      <> 
      <DialogTitle id="alert-dialog-title">

      </DialogTitle>
      <DialogContent>
        <div>
          {/* {sourceMessage} */}
          <div>
          Time locking this section will prevent 
          any future editing.  </div>
          <div>
          Do you still wish to time lock this section?
          </div>
          
        </div>
      </DialogContent>
      <DialogActions>
        <StyledButton

          variant="contained"
          color="primary"
          onClick={() => handleUpdate(id, dbCollection)}
        >
          Yes - lock this topical section
        </StyledButton>

        <StyledButton
          form="submit-form"
          variant="contained"
          color="primary"

          onClick={handleDialogClose}
        >
          No - Cancel
        </StyledButton>

      </DialogActions>
      </>


    )
  }

  
  let timeLockedDialog = () => {

    return (
      // <div> 
      //   <div> this section timelocked</div>
      //   <div> lastEdit : {lastEdit} </div>
      // </div>

      <>
        <DialogTitle id="alert-dialog-title">

        </DialogTitle>
        <DialogContent>
          <div>
            {/* {sourceMessage} */}

            <div> this section timelocked</div>
            <div> last edited on  {styledLastEdit} </div>

          </div>
        </DialogContent>
        <DialogActions>


          <StyledButton
            form="submit-form"
            variant="contained"
            color="primary"

            onClick={handleDialogClose}
          >
            Got it
          </StyledButton>

        </DialogActions>
      </>


    )
  }
 
  const handleUpdate = async (id, dbCollection)=>  {

    console.log('[ TimeLock_icon ] Update this Puppy ', id);

    let formData = {
      timeLock: 'yes'

    }

    let reduxData = {
      id : id,
      timeLock: 'yes'

    }
    
    try {

      dispatch(changeLoadingStatus(true))
      await updateDocumentFromFirestore(uid, dbCollection, id, formData)

      if (dbCollection === 'topicalSections') {
        dispatch(updateEditedTopicalSectionTimeLock(reduxData))


      }

      if (dbCollection === 'logs') {
        dispatch(updateEditedLogTimeLock(reduxData))

      }
      dispatch(changeLoadingStatus(false))
    } catch (error) {
      alert(error.message)
      dispatch(changeLoadingStatus(false))


    } // end catch


  }

// ==== Main Return =======================================

  return (
    <>

      <Dialog
        open={open}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >

        
        {!timeLockStatus  && 

        updateDialog()
        }

        {timeLockStatus === 'yes' && 

        timeLockedDialog()
        }

      </Dialog>

    {!timeLockStatus  && 

      <LightTooltip   title = 'Time lock'  arrow> 

        <OrangeIcon
          onClick={()=> handleClick(id)}
        />

      </LightTooltip  >
 
    } 

    {timeLockStatus === 'yes' && 
    
      <LightTooltip   title = 'Time lock'  arrow> 

        <RedIcon
          onClick={()=> handleClick(id)}
        />

      </LightTooltip  >
 
    } 



    </>
  )
}


export default TimeLock_icon

// -----------------------------------------------------------------

 
 

const OrangeIcon= styled(LockClockIcon)({
  backgroundColor: 'white',
  borderRadius: '5px',
  fontSize: '.99rem',
  // color: chitOrange,
  color: chitOrange,
  margin: '0 .5rem .3rem .5rem',
  cursor: 'pointer',
  


  '&:hover': {
    color: mediumLightGrey
    // backgroundColor: mediumLightGrey
  },


  [theme.breakpoints.down('sm')] : {
    // height: '1.25rem',
    // backgroundColor: 'red'
  },
})

const RedIcon= styled(LockClockIcon)({
  backgroundColor: 'white',
  borderRadius: '5px',
  fontSize: '.99rem',
  // color: chitOrange,
  color: 'red',
  margin: '0 .5rem .3rem .5rem',
  cursor: 'pointer',



  [theme.breakpoints.down('sm')] : {
    // height: '1.25rem',
    // backgroundColor: 'red'
  },
})



const LightTooltip = withStyles({
  tooltip: {
    color: "grey",
    backgroundColor: "white",
    boxShadow: '2px 3px 3px black',
    border: '1px solid grey',
  }
})(Tooltip);


const StyledButton= styled(Button)({
  backgroundColor: 'white',
  border: '1px solid #E6E7E8',
  color: chitBurgandyDull,
  margin: '0 1.5rem 0 6px',
 
  height: '1.5rem',
  fontSize: '.8rem',
  '&:hover' :{
    backgroundColor: lightGrey
  }

})
