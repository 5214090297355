import React from 'react';

import {useParams} from 'react-router-dom'

import {backgroundBlue, chitLightPink, chitOrange, } from '../../styles/colors'

import OverviewFeatureSlides from './slides/featureSlides/FeatureSlides_overview'

import TwoPartyFeatureSlides from './slides/featureSlides/FeatureSlides_twoParty'

import PersonalFeatureSlides from './slides/featureSlides/FeatureSlides_personal'

import LogFeatureSlides from './slides/featureSlides/FeatureSlides_logs'


import FeatureNav from '../navComponents/publicNav/Feature_nav'

import { styled, createTheme  } from "@mui/material/styles"
const theme = createTheme(); // allows use of mui theme in styled component

 const Features = () => {

  let match = useParams()

  let view 

  if(!match.id){view = 'overview'}else{view = match.id}
  console.log('[Features  page] route id is :  ', match.id)


  return (
    <BodyWrapper> 

   
        <HeadWrapper>
          <Header> Features and Tools</Header>
          <FeatureNav />

          <NavWrapper>

           


          </NavWrapper>

        </HeadWrapper>
        {/* <HeaderSpacer/> */}

        <Content>

          {!view &&
            <OverviewFeatureSlides />
          }


          {view === 'overview' &&
            <OverviewFeatureSlides />
          }

          {view === 'twoParty' &&
            <TwoPartyFeatureSlides />

          }


          {view === 'logs' &&
            <LogFeatureSlides />

          }




          {view === 'personal' &&
            <PersonalFeatureSlides />
          }



        




        </Content>
        

</BodyWrapper>
 
  );
}

export default Features

// -----------------------------------------------------------------

const BodyWrapper= styled('div')({
  display: 'block',
  

  overflowY: 'hidden',
  overflowX: 'hidden',
  fontFamily: 'Lato, sans-serif',
  backgroundColor: backgroundBlue ,
    // backgroundColor: 'white' ,
  width: '100%',

  [theme.breakpoints.down('xs')] : {
                              
    alignItems: 'center',                           
    width: '100%',
    padding: '0',
   
 
 }


// backgroundColor: testColors.testGreen

})





const HeadWrapper= styled('div')({

  
  position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  
    fontSize: '1.2rem',
    width: '100%',
    
    marginTop: '1.5rem',
    color: 'white',

    backgroundCOlor: backgroundBlue,
  backgroundImage: 'linear-gradient(#04102F, #096E99)',
 
  
  
  
    borderBottom: '1px solid #CFD0D1',
  
    [theme.breakpoints.down('sm')] : {
      fontSize: '1.1rem',
      height: '5rem',
      marginTop: 0,
    }
  
  
  
  // backgroundColor: testColors.testGreen
  
  })

  const Header= styled('div')({

      display: 'flex',
      
      justifyContent: 'center',
      alignItems: 'flex-start',

      width: '100%',

      
      // paddingTop: '1rem',
      // paddingBottom: '1.5rem',
      // backgroundColor: 'blue' ,
      // overflow: 'hidden',
      
     
    
      [theme.breakpoints.down('xs')] : {
        overflow: 'auto',
      },

  
    
    })



const NavWrapper= styled('div')({

  
  // position: 'absolute',
    display: 'block',
  
    justifyContent: 'center',
    position: 'relative',
    width: '80%',

    // backgroundColor: 'red' ,
    // paddingBottom: '.5rem',

      
    
    
  
 
    [theme.breakpoints.down('sm')] : {
      width: '100%',
    }
  
  

  })
  
 
 
const Content= styled('div')({

  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',

  width: '100%',
  height: 'calc(100vh - 2.5rem - 2.5rem - 2rem - 3.25rem )',
 
 
  // paddingTop: '1rem',
  // paddingBottom: '1rem',

 
 



  [theme.breakpoints.down('sm')] : {
    width: '100%',
    height: 'calc(100vh - 2.5rem - 2.5rem - 2rem - 4.5rem )',
  }



// backgroundColor: testColors.testGreen

})
 
 

