/* Header_nav.jsx
   contains child slideshow components: 
      SpotlightSlides
      ChitSlides
      etc. etc

    parent: src/app/App.js

*/


import React from "react"
import { useDispatch } from 'react-redux'
import { NavLink, useLocation } from 'react-router-dom'
import { getPage } from '../../../app/helpers/locationHelper'

// import{setPage} from '../../../app/redux/actions/X_landingActions'
import { openModal } from "../../../app/redux/statusRedux/statusSlice"

import { veryLightGrey } from '../../../styles/colors'

import Button from '@mui/material/Button'


import { styled, createTheme } from "@mui/material/styles"
const theme = createTheme(); // allows use of mui theme in styled component

//--------------------------------------------------- 




// ================================================

export default function HeaderNav(props) {
  const dispatch = useDispatch()

  let location = useLocation()
  let page = getPage(location)



  const openForm = () => {

    //  define which Form to open in Modal by passing
    //  dbCollection to Modal depending on pageView in browser URL 
    // ------------------------------------------------------------

    // props.openModal(dbCollection, id)

    dispatch(openModal(
      {
        modalParams: {
          modalType: 'form',
          dbCollection: '',
          id: '',
          login: 'login'
        }
      }

    ))

  }// end openForm

  return (

    <Container>

      <Wrapper>

        <StyledLink to="/" >

          {page !== 'home' &&
            <NavButton
              id='home'
            // onClick = {handlePageChange}

            >Home  </NavButton>
          }

          {page === 'home' &&
            <NavButtonDisabled disabled
              id='home'
            // onClick = {handlePageChange}

            >Home </NavButtonDisabled>
          }

        </StyledLink>

        <StyledLink to="/features" >

          {page !== 'features' &&
            <NavButton
              id='features'
            // onClick = {handlePageChange}

            >Features</NavButton>
          }

          {page === 'features' &&
            <NavButtonDisabled disabled
              id='features'
            // onClick = {handlePageChange}

            >Features </NavButtonDisabled>
          }


        </StyledLink>

        <StyledLink to="/sample" >

          {page !== 'sample' &&
            <NavButton
              id='sample'
            // onClick = {handlePageChange}

            >Sample </NavButton>
          }

          {page === 'sample' &&
            <NavButtonDisabled disabled
              id='sample'
            // onClick = {handlePageChange}

            >Sample  </NavButtonDisabled>
          }


        </StyledLink>






        <NavButton
          id='login'
          onClick={() => openForm(page)}

        >Login </NavButton>



      </Wrapper>


    </Container>
  );
}



// ----------------------------

const Container = styled('div')({


  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',



})



const Wrapper = styled('div')({


  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  width: '100%',
  [theme.breakpoints.down('sm')]: {



  }

})


const NavButton = styled(Button)({

  // border: 'none',
  color: 'white',
  textTransform: 'none',
  fontWeight: '300',
  paddingRight: '10px',
  paddingLeft: '10px',

  '& :hover': {
    backgroundColor: '#2D259C',
    boxShadow: 'none'

  },
  [theme.breakpoints.down('sm')]: {
    // fontWeight: 'bold',
    fontSize: '.75rem',
    padding: '1px',

  },



  [theme.breakpoints.down('xs')]: {
    fontWeight: 'bold',
    fontSize: '.75rem',
    padding: '1px',

  },

})

const NavButtonDisabled = styled(Button)({



  textTransform: 'none',

  // backgroundColor: shadowBlue,
  borderBottom: '1px solid #8293B8',
  borderRadius: '0',
  color: 'white',
  fontWeight: '300',
  marginRight: '8px',
  padding: '0 10px',

  '&:disabled ': {
    color: '#8293B8'
  },

  '& :hover': {
    backgroundColor: veryLightGrey,
  },

  [theme.breakpoints.down('sm')]: {
    fontWeight: 'bold',
    fontSize: '.75rem',
    padding: '1px',

  },
  [theme.breakpoints.down('xs')]: {
    fontWeight: 'Bold',
    fontSize: '.75rem',
    padding: '1px',

  }

})



const StyledLink = styled(NavLink)({

  textDecoration: 'none',

})


