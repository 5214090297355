import React from 'react';
 

 import ContactForm from '../../forms/ContactForm';

export default function ContactPublic(props) {
  return (
    <>
   
      <ContactForm/>
    </>
  );
}

 
 