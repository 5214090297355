
 
import {initializeApp} from 'firebase/app'
import 'firebase/firestore'

import { 
      getAuth, 
      signInWithPopup, 
      GoogleAuthProvider,
      createUserWithEmailAndPassword,
      signInWithEmailAndPassword,
      onAuthStateChanged,
      signout,
      signOut,
      sendPasswordResetEmail

    }from 'firebase/auth'

import {
  getFirestore,
  doc, 
  getDoc,
  setDoc
} from 'firebase/firestore'



// --- config  --------------------------

const firebaseConfig = {
  apiKey: "AIzaSyBmIUvkBUzAgCWOBfEU9GiS8VofgvBiPtw",
  authDomain: "chitgit-e6101.firebaseapp.com",
  projectId: "chitgit-e6101",
  storageBucket: "chitgit-e6101.appspot.com",
  messagingSenderId: "28796176981",
  appId: "1:28796176981:web:564d837de89136cc9822dc",
  measurementId: "G-Q4KQKM1SR0"
}


// --- initialize firebase  --------------------------

 initializeApp(firebaseConfig)




// --- Google Sign in  --------------------------

const provider = new GoogleAuthProvider()

provider.setCustomParameters({
  prompt: 'select_account'
})

export const auth = getAuth()
export const signInWithGooglePopup = () => signInWithPopup(auth, provider)


// --- Add signed in user to FireSTORE users collection ------------

const db = getFirestore()

export const createUserDocumentFromAuth = async (
  userAuth, 
  additionalInformation = {}
  ) => {
 
  if (!userAuth) return

  const userDocRef = doc(db, 'users', userAuth.uid)


  const userSnapshot = await getDoc(userDocRef)




  // if user data does NOT exist

  if (!userSnapshot.exists()) {


    const { displayName, email } = userAuth
    const createdAt = new Date()

    try {

      await setDoc(userDocRef, {
        displayName,
        email,
        createdAt,
        ...additionalInformation
      })


      const peopleDocRef = doc(db, `data/${userAuth.uid}/people/unknown`)
      await setDoc(peopleDocRef, {
        name: 'unknown',
        type: 'person'
      })

      const categoryDocRef = doc(db, `data/${userAuth.uid}/categories/Accomplishments`)
      await setDoc(categoryDocRef, {
        category: 'Accomplishments',
         
      })

      const topicsDocRef = doc(db, `data/${userAuth.uid}/topics/junkyard`)
      await setDoc(topicsDocRef, {
        topic: 'Junkyard',
         
      })

    } catch (error) {


    }


  }// end try catch

  return userDocRef


} // end createUserDocumentFromAUth


export const createAuthUserWithEmailAndPassword = async (email, password) => {

  if (!email || !password) return

  return await createUserWithEmailAndPassword(auth, email, password)
} 


export const signInAuthUserWithEmailAndPassword = async (email, password) => {

  if (!email || !password) return

  return await signInWithEmailAndPassword(auth, email, password)
} 

export const onAuthStateChangeListener = (callback) => {
  onAuthStateChanged(auth, callback)
}

export const signOutUser = ()=> signOut(auth)


export const resetUserPassword = async (email) => {
 
  try {
    await sendPasswordResetEmail(auth, email)
    alert('An email has been sent to you to reset your password')
  } catch (error) {
    alert(error.message)
  }
  
} 

// ---  --------------------------

 

// transfer auth object into Firebase

// export const createUserProfileDocument = async (userAuth, additionalData) =>{

//   // check if auth does Not exist - don't do anything
//   if(!userAuth){ return}
//   console.log('{firebase.utils] userAuth: ', userAuth)
//   // if there is a userAuth object

//   const userRef = firestore.doc(`users/${userAuth.uid}`)
//   const snapShot = await userRef.get()

//   if(!snapShot.exists) {
//     const {displayName, email} = userAuth
//     const createdAt = new Date()

//     try {
//       await userRef.set({
//         displayName,
//         email,
//         createdAt,
//         ...additionalData
//       })

     

//     } catch(error){
//       console.log('[firebase.utils] error in createUserProfileDocument: ' , error.message)
//     }

//     return userRef

//   }

//   console.log('{firebase.utils] userAuth: ', snapShot)


// }


// firebase.initializeApp(config)

// export const auth = firebase.auth()
// export const firestore = firebase.firestore()

// const provider = new firebase.auth.GoogleAuthProvider()


// // ----- Google (specific) sign in methods ----------------------

// provider.setCustomParameters({prompt: 'select_account'})
// export const signInWithGoogle = ()=> auth.signInWithPopup(provider)

// export default firebase
 