import { createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
// import InitialStore from '../store/sharedChit_initialStore'



export const sharedChitsSlice = createSlice({
  name: 'sharedChitSlice',
   
  initialState: [],

  // initialState: {
 
  //     id: '' ,
  //     sharedChitLink: '',
  //     chitId: '',
  //     chitType: '',
  //     chitColor: '',
  //     senderName: '', 
  //     receiverName: '',
  //     deedPerformedBy: '',
  //     chitDate: '',
  //     sharedDate: '',
  //     sharedTitle: '',
  //     sharedMessage: ''

    
  // }, // end Initial State

  reducers: {
 
    
    addAllSharedChitsFromFirestore: (state = [], action) => {
    
      let firestoreData = action.payload
    
      // Clear the state array
      state = [];
    
      // Add the Firestore data to the state array
      state = [...firestoreData];
    
      return state
    },// end addAllSharedChitsFromFirestore

    addSharedChitToStore: (state, action) => {

      let sharedChit = action.payload

      state.push(sharedChit)

                   
    },

    resetSharedChits: (state, action) => {
      let initialState = []
      return initialState
    },// end resetSharedChits





    updateSharedChit: (state, action) => {

      let data = action.payload
      let twoPartyChitId = action.payload.id
  
      
      const { otherPartyCollection, chitType, chitColor,  chitValue, chitBurden,chitDate, person, group, deedPerformedBy, workRelated, description, keyWordArray, otherPartyId} = data
  
  
      let twoPartyIndex = state.findIndex(index => index.id === twoPartyChitId)
      
  
  
      state[twoPartyIndex].id  = twoPartyChitId
      state[twoPartyIndex].chitType  = chitType
      state[twoPartyIndex].chitColor  = chitColor
      state[twoPartyIndex].chitBurden  = chitBurden
      state[twoPartyIndex].chitValue  = chitValue
      state[twoPartyIndex].chitDate  = chitDate
      state[twoPartyIndex].deedPerformedBy  = deedPerformedBy
      state[twoPartyIndex].otherPartyId  = otherPartyId
      state[twoPartyIndex].otherPartyCollection  = otherPartyCollection
      state[twoPartyIndex].workRelated  = workRelated
      state[twoPartyIndex].description  = description
  
      
     
  
  
    }, // end updateEditedtwoParty
  }, //end reducers

}) // end slice statusSlice 


// --- Export actions ---------------------------------------------

export const { 
  addAllSharedChitsFromFirestore,
  addSharedChitToStore,
  updateSharedChit,
  resetSharedChits

  } = sharedChitsSlice.actions



// --- Export selectors ------------------------------------------

export const selectSharedChits = state => state.sharedChits //Sample site



 






// --- default Export reducers -----------------------------------

export default sharedChitsSlice.reducer