import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { darkGrey, chitBurgandy } from '../../../styles/colors';

import PersonalChitViewNav from '../../navComponents/privateNav/PersonalChit_View_nav';

import EditIcon from '../components/Edit_icon'
import DeleteIcon from '../components/DeleteMany_icon'

import { selectCategories } from '../../../app/redux/categoryRedux/categorySlice';

import { selectStatus } from '../../../app/redux/statusRedux/statusSlice';

import QuickPersonalChitForm from '../../../forms/QuickPersonalChitForm';

import { openModal } from '../../../app/redux/statusRedux/statusSlice';
//  ---- Material Ui ------------------

import Paper from '@mui/material/Paper'
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button'

// ================================================
import { styled, createTheme } from "@mui/material/styles"
const theme = createTheme(); // allows use of mui theme in styled component



// ================================================
export default function PersonalChitHeader(props) {


  const match = useParams()
  const dispatch = useDispatch()
  const matchId = match.id
  let status = useSelector(selectStatus)
  let statusId = status.view.personal.id

  const allCategories = useSelector(selectCategories)

  console.log('[ PersonalChit Header ] allCategories ', allCategories);
  console.log('[ PersonalChit Header ] statusId ', statusId);

  let displayName

  if (statusId === 'milestones') { displayName = 'Milestones' }
  else if (statusId === 'workChits') { displayName = 'WorkChits' }
  else if (!statusId) { displayName = 'choose a category or create a new chit' }
  else {
    let categoryObject = allCategories.find((category) => category.id === statusId)
    displayName = categoryObject.category

  }


  const openForm = () => {

    //  define which Form to open in Modal by passing
    //  dbCollection to Modal depending on pageView in browser URL 
    // ------------------------------------------------------------

    // props.openModal(dbCollection, id)

    dispatch(openModal(
      {
        modalParams: {
          modalType: 'form',
          dbCollection: 'personalChits',

        }
      }

    ))

  }// end openForm

  return (
    <Wrapper>

      {statusId &&
        <TitleContainer>
          <TitleWrapper>
            <TitleLabel> Category : </TitleLabel>
            <Title>
              {displayName}
            </Title>
          </TitleWrapper>

          {statusId && statusId !== 'workChits' && statusId !== 'milestones' && statusId !== 'accomplishments' &&
            <IconWrapper>

              {statusId !== 'Accomplishments' &&
                <>
                  <DeleteIcon id={statusId} source='personalChits' />

                  <EditIcon id={statusId} dbCollection='categories' />

                </>
              }
            </IconWrapper>
          }




        </TitleContainer>
      }

      {!statusId &&
        <MessageTitleWrapper>


          {displayName}

        </MessageTitleWrapper>

      }
      <BottomWrapper>
        <ButtonWrapper>

          <FormButton startIcon={<AddIcon />}
            onClick={() => openForm()}
          > add Chit</FormButton>

        </ButtonWrapper>
        <ViewNavWrapper>
          <PersonalChitViewNav />
        </ViewNavWrapper>
        <Spacer />

      </BottomWrapper>

    </Wrapper>




  )
}// end func TopicalDetail

// -----------------------------------------------------------------

const Wrapper = styled(Paper)({
  display: 'flex',
  flexDirection: 'column',
  // justifyContent: 'flex-start',
  // alignItems: 'center',
  margin: '1rem  0 .5rem 0',
  paddingBottom: '.5rem',


  width: '90%',



  [theme.breakpoints.down('md')]: {
    margin: '1rem  0 .5rem 0',
    width: '100%',
  },
})

const TitleContainer = styled('div')({
  display: 'flex',

  justifyContent: 'space-between',
  alignItems: 'center',

  width: '100%',

  // marginBottom: '.5rem',




  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',
    // backgroundColor: 'red'
  },
})

const TitleWrapper = styled('div')({
  display: 'flex',

  justifyContent: 'flex-start',
  alignItems: 'center',

  width: '98%',
  padding: '0 .5rem',
  // marginBottom: '.5rem',

  fontSize: '1rem',

  '&.backgroundCompleted': {

    color: 'white',

  },


  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',
    // backgroundColor: 'red'
  },
})

const MessageTitleWrapper = styled('div')({
  display: 'flex',

  justifyContent: 'center',
  alignItems: 'center',




  width: '98%',
  padding: '0 .5rem',
  // marginBottom: '.5rem',

  fontSize: '1rem',

  '&.backgroundCompleted': {

    color: 'white',


  },


  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',
    // backgroundColor: 'red'
  },
})

const TitleLabel = styled('div')({
  display: 'flex',

  justifyContent: 'flex-start',
  alignItems: 'center',

  margin: '0 8px ',


  flexWrap: 'wrap',

  fontSize: '.9rem',
  color: darkGrey,
  fontStyle: 'italic',

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',
    // backgroundColor: 'red'
  },
})

const Title = styled('div')({
  display: 'flex',

  justifyContent: 'flex-start',
  alignItems: 'center',



  color: chitBurgandy,
  flexWrap: 'wrap',

  fontSize: '1.2rem',



  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',
    // backgroundColor: 'red'
  },
})

const BottomWrapper = styled('div')({
  display: 'flex',

  justifyContent: 'space-between',
  alignItems: 'center',
  width: 'calc(100% - 3rem)',
  padding: '0 1rem',

  [theme.breakpoints.down('sm')]: {
    width: 'calc(100% - 12px)',
    padding: 0,
  },
})

const ButtonWrapper = styled('div')({
  display: 'flex',

  justifyContent: 'flex-start',
  alignItems: 'center',


  [theme.breakpoints.down('md')]: {
    backgroundColor: 'yellow'

  },
})

const FormButton = styled(Button)({



  display: 'flex',
  textTransform: 'none',


  color: 'white',
  backgroundColor: '#727376',
  fontWeight: 'normal',
  fontSize: '.85rem',
  padding: '0 1.5rem',
  width: '9.5rem',
  height: '1.2rem',
  margin: '0 1rem',

  [theme.breakpoints.down('md')]: {
    fontSize: '.75rem',
    padding: '0 .5rem',
    width: '7.5rem',
    margin: '0 0',
  },

  '&:hover': {
    // backgroundColor: chitBlueDull,
    textDecoration: 'none',
    border: '1px solid #A8BEED',
    color: '#3B30CC'

  }


})

const IconWrapper = styled('div')({
  display: 'flex',

  justifyContent: 'flex-end',
  alignItems: 'center',




  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',
    // backgroundColor: 'red'
  },
})

const ViewNavWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  // backgroundColor: 'green',

  position: 'relative',
  top: 0,



  height: '2rem',
  color: 'white',



  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },


})

const Spacer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  // backgroundColor: 'green',



  [theme.breakpoints.down('sm')]: {
    display: 'none',

  },


})

