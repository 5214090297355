/* app/App.js in chitgit

Main Funcitional Wrapper for entire site.
contains:
   Routing for:
      Landing
      Features
      Sample Site
      Protected - Entry way to user apps
   
    Also contains theming for @MUI

note: upon load, App determines if logged in 
     -- if yes
      1) dispatch user Id to Redux store
      2) redirect to Main Page
     -- if no - show Landing Page
*/




import React, { Fragment, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, Navigate } from 'react-router-dom'

// ###########################################

import { onAuthStateChangeListener, createUserDocumentFromAuth } from './firebase/firebase.utils.js';

import { getUserDataFromFirestore } from './firebase/fireStore.utils.js';


// ###########################################

import { selectStatus, selectLoadingStatus, updateAuth } from './redux/statusRedux/statusSlice.jsx';

import { ThemeProvider } from '@mui/styles';
import CssBaseline from '@mui/material/CssBaseline'

import theme from '../styles/Theme'

import Loading from '../common_components/Loading';
import Landing from '../pages/public/Landing'

import Sample from '../pages/public/Sample.jsx';
import SampleMain from '../pages/public/sampleSite/Main_s.jsx';



import Home from '../pages/private/Home'
import Main from '../pages/private/Main'



// --------------------------------------------------

const App = () => {
  const dispatch = useDispatch()
  const status = useSelector(selectStatus)


  let statusUid = status.auth.loginId


  const [user, setUser] = useState(null)
  useEffect(() => {
    setUser(statusUid)
  }, [statusUid])

  let loadingStatus = useSelector(selectLoadingStatus)


  useEffect(() => {

    const unsubscribe = onAuthStateChangeListener(async (user) => {
      if (user) {
        await createUserDocumentFromAuth(user)
        const userName = await getUserDataFromFirestore(user.uid)


        dispatch(updateAuth({
          loginId: user.uid,
          displayName: userName.displayName,
          email: userName.email
        }))
      }


    })

    return unsubscribe

  }, [])



  // ---return -----------------------------------------
  return (
    <CssBaseline>
      <ThemeProvider theme={theme} >

        {loadingStatus && <Loading />}

        <Routes>

          {/* ---  logged in ---- */}

          {statusUid &&
            <>

              <Route path="/" element={<Navigate replace to="main/home" />} />

              <Route path='main/home' element={<Home />} />


              <Route path="/sharedChit/:sharedChitId" element={<Home />} />
              <Route path="/sharedChit" element={<Home />} />
              <Route path="/features" element={<Home />} />
              <Route path="/sample" element={<Home />} />
              <Route path="/login" element={<Home />} />
              <Route path="/join" element={<Home />} />
              <Route path='main/home' element={<Home />} />

              <Route path='/main/:pageView/:id' element={<Main />} />
              <Route path='/main/:pageView' element={<Main />} />
              <Route path='/main' element={<Main />} />

            </>
          }



          <Route path='/sample/:pageView/:id' element={<SampleMain />} />
          <Route path='/sample/:pageView' element={<SampleMain />} />
          <Route path='/sample' element={<Sample />} />

          <Route path='/:pageView/:id' element={<Landing />} />
          <Route path='/:pageView' element={<Landing />} />
          <Route path='/' element={<Landing />} />




        </Routes>




      </ThemeProvider>
    </CssBaseline>

  )
}


export default App;
