/*---- File - Main_s.jsx
    Main page of site

     Main chooses views from URL 
     Two params from the URL in specific... xxx/pageView/detailId

     It also holds the empty side panel,  and
     the empty content wrapper

    Contains children: 
       Header_main
       New - button to launch modal with new Spotlight form
       Modal (hidden on load)
       Side panel Nav children - becomes hidden on phones 
           chitsNav, 
           spotlightNav, 
           etc.
       Colored Header with Info Icon
       Main Component Wrapper children
           reports,  
           chits, 
           spotlights, etc

      parent- Sample - src/pages/public/sample
*/



import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom'

import { chitDarkLavendar, chitOrangeMedium, veryLightGrey, chitLightGreen, chitBright, chitLightPink, chitDullYellow, lightGrey, backgroundBlue } from '../../styles/colors';

import { capitalizeFirstLetter } from '../../app/helpers/commonHelpers';
// -----------------
import MainHeader from '../navComponents/privateNav/Main_header';

import New from './components/New'
import InfoIcon from './components/Info'

import Logs from './logs/Logs'
import PersonalChits from './personalChits/PersonalChits'
import TwoPartyChits from './twoPartyChits/TwoPartyChits'
import Topicals from './topicals/Topicals'
import ContactForm from '../../forms/ContactForm';
import LogNav from '../navComponents/privateNav/sideBarNav/LogNav'
import PersonalChitNav from '../navComponents/privateNav/sideBarNav/PersonalNav'
import TwoPartyChitNav from '../navComponents/privateNav/sideBarNav/TwoPartyNav'
import TopicalNav from '../navComponents/privateNav/sideBarNav/TopicalNav'

import ReceivedSharedChitUser from './sharedChits/ReceivedSharedChitUser';

import { selectStatus, changeSidePanelView } from '../../app/redux/statusRedux/statusSlice';
import Modal from '../../common_components/modal/ModalComponent';
// --- MUI 
import Button from '@mui/material/Button'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import ClickAwayListener from '@mui/material/ClickAwayListener';

import { styled, createTheme } from "@mui/material/styles"
const theme = createTheme(); // allows use of mui theme in styled component

// ======================================

const Main = (props) => {
  let match = useParams()
  const dispatch = useDispatch()

  const status = useSelector(selectStatus)
  let sidePanelView = status.sidePanel.view
  // const modalShow = status.modal.modalDisplayed
  let page, formattedPage
  page = match.pageView

  console.log('[ MAIN ] page ', page);

  switch (page) {

    case 'personalChits':
      formattedPage = 'Personal Chits'
      break

    case 'twoPartyChits':
      formattedPage = 'Two Party Chits'
      break

    case 'workChits':
      formattedPage = 'Work Chits'
      break


    default: formattedPage = capitalizeFirstLetter(page)
  }



  /*
     --- each page (chits, logs etc) has a different header color
     --- headerColor logic determines which color is displayed
     --- based on page variable in URL
  */
  const headerColor = (page) => {
    let background

    if (page === 'personal') {
      background = { backgroundColor: chitLightGreen, fontColor: 'white' }
    }
    if (page === 'twoPartyChits') {
      background = { backgroundColor: chitDarkLavendar, fontColor: 'white' }
    }
    if (page === 'personalChits') {
      background = { backgroundColor: chitLightGreen, fontColor: 'black' }
    }

    if (page === 'logs') {
      background =
        { backgroundColor: chitLightPink, fontColor: 'black' }
    }



    if (page === 'topicals') {
      background =
        { backgroundColor: chitOrangeMedium, fontColor: 'black' }
    }


    if (page === 'contact') {
      background =
        { backgroundColor: 'white', fontColor: 'white' }
    }




    return background
  }

  // ----side Panel logic ----- 


  const [viewSidePanel, setViewSidePanel] = useState(sidePanelView);

  useEffect(() => {
    setViewSidePanel(sidePanelView)

  }, [sidePanelView])


  const handleSidePanelChange = (evt) => {
    evt.persist()
    const newSidePanelView = (viewSidePanel === 'show') ? 'hide' : 'show'
    setViewSidePanel(newSidePanelView)

    dispatch(changeSidePanelView(newSidePanelView))

  }

  const [open, setOpen] = React.useState(false);

  const handleClickAway = () => {
    setViewSidePanel('hide')
  };


  // --- Main Return -----------------------------------------

  return (
    <BodyWrapper>

      <Modal />

      <MainHeader />
      {/* <TopSpacer /> */}


      <MainWrapper>

        <HeaderWrapper style={{
          backgroundColor: headerColor(page).backgroundColor,
          color: headerColor(page).fontColor
        }}>
          <Header> {formattedPage}   </Header>
          <InfoIconWrapper>


            <InfoIcon

            // onClick={() => openModal('help', page)} 
            />
          </InfoIconWrapper>
        </HeaderWrapper>
        <MainContentWrapper>


          {/* #########   SIDE PANEL  ############################### */}
          <ClickAwayListener
            onClickAway={handleClickAway}
            mouseEvent="onMouseDown"
            touchEvent="onTouchStart"
          >
            <SidePanelWrapper className={viewSidePanel}>


              <Handle onClick={handleSidePanelChange}>

                {viewSidePanel === 'hide' &&
                  <KeyboardArrowRightIcon style={{ color: 'white' }} />
                }

                {viewSidePanel === 'show' &&
                  <KeyboardArrowLeftIcon style={{ color: 'white' }} />
                }
              </Handle>




              {/* {page === 'twoParty' &&  <TwoPartyNav />  }
            {page === 'personal' &&  <PersonalNav />  }
            {page === 'work' &&  <WorkNav  />  }
            {page === 'notes' &&  <NoteNav/>  }
            {page === 'logs' &&  <LogNav/>  }

            */}

              <New />


              {page === 'logs' && <LogNav />}

              {page === 'topicals' && <TopicalNav />}


              {page === 'personalChits' && <PersonalChitNav />}
              {page === 'twoPartyChits' && <TwoPartyChitNav />}

            </SidePanelWrapper>

          </ClickAwayListener>

          <ContentArea>

            {page === 'twoPartyChits' &&
              <TwoPartyChits />
            }



            {page === 'personalChits' &&
              <PersonalChits />
            }


            {page === 'logs' &&
              <Logs />
            }
            {page === 'topicals' &&
              <Topicals />
            }
            {page === 'contact' &&
              <ContactBodyWrapper>
                <ContactForm />
              </ContactBodyWrapper>
            }


          </ContentArea>

        </MainContentWrapper>

      </MainWrapper>

    </BodyWrapper>


  ) // end return
} // --- end func Main_s


export default Main

// ---Styles ---------------------------------------
const BodyWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',

  // backgroundColor: bodyBlue,
  height: '100vh',
  width: '100vw',
  backgroundColor: veryLightGrey,
  // backgroundImage: 'linear-gradient(to  bottom, #3B30CC, #1B1625, #040952)',
  overflow: 'hidden',


})

const ContactBodyWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',

  // backgroundColor: bodyBlue,
  height: '100%',
  width: '100%',
  // backgroundColor: backgroundBlue,
  // backgroundImage: 'linear-gradient(to  bottom, #3B30CC, #1B1625, #040952)',
  overflow: 'hidden',


})




const MainWrapper = styled('div')({

  display: 'block',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  margin: '0 auto',
  width: '100%',
  overflow: 'hidden',

  height: 'calc(100% - 3.4rem)',
  backgroundColor: veryLightGrey,


  [theme.breakpoints.down('md')]: {
    width: '100%'
  },

})
// ----- Content Area here -------------



const MainContentWrapper = styled('div')({

  display: 'block',
  height: 'calc(100% - 2rem)',
  position: 'relative',

  // paddingTop: '3rem',





  [theme.breakpoints.down('sm')]: {
    // paddingTop: '1.5rem',
    paddingLeft: '0',
    // height: '90%',

  },

  [theme.breakpoints.down('xs')]: {

    // paddingLeft: '3rem',

  }

})



const ContentArea = styled('div')({

  display: 'flex',
  marginLeft: '15rem',
  height: '100%',
  overflow: 'auto',
  position: 'relative',

  // paddingTop: '.5rem',



  [theme.breakpoints.down('md')]: {
    // display: 'block'
    marginLeft: '2rem',
  },

  [theme.breakpoints.down('xs')]: {
    marginLeft: '0',

  }

})


// ---- Side Panel CSS

const SidePanelWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  position: 'absolute',

  backgroundColor: 'white',
  width: '15rem',
  height: '100%',
  paddingTop: '3px',

  paddingBottom: '2rem',
  zIndex: '31',


  [theme.breakpoints.down('md')]: {

    left: '3rem',
    '&.hide': {
      left: '-15rem',
      borderRight: '4px solid #CFD0D1'
    },

    '&.show': {
      left: '0rem',
      borderRight: '2px solid #CFD0D1'
    },

  },



  [theme.breakpoints.down('md')]: {
    marginLeft: '0',
    // display: 'none'

    '&.hide': {
      left: '-15rem'
    },

    '&.show': {
      left: '0rem'
    },
  },

})

const Handle = styled('div')({

  display: 'none',
  position: 'absolute',
  width: '1.5rem',
  height: '3rem',
  borderRadius: '0 5px 5px 0',


  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    right: '-1.5rem',
    top: '0',



    background: 'grey'
  },


})

// --- Side Panel Header



const HeaderWrapper = styled('div')({

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '28px',
  width: '100%',
  backgroundColor: 'white',
  borderRadius: '0 0 15px 15px',
  margin: '0 auto 3px auto',

  [theme.breakpoints.down('sm')]: {
    borderRadius: 0
  }


})

const Header = styled('div')({

  display: 'block',
  fontSize: '1.1rem',

  // color: chitRedDark




})


const InfoIconWrapper = styled('div')({



  position: 'absolute',
  top: '2px',
  right: '24px',

  '&:hover': {
    color: lightGrey,
    cursor: 'pointer'
  },

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',
    // backgroundColor: 'red'
  },
})

