// Sample Store - keywords
// Samp Spotlights


const SampleTopics =  [


  { 
    id: 'topic_1',
    topic: 'Wakeboard boot',
  },

  { 
    id: 'topic_2',
    topic: 'Vlog',
  },
 
  { 
    id: 'topic_3',
    topic: 'Diet',
  },

 
 
 
 
 ] // end spotlights  ============================================
 
 
 
 
 
 
 
 export default SampleTopics