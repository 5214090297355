/*---- File - filename.jsx 
   What file does

   View Logic in LogForm read me ...
           src\readMe\LogForm_info.md


   Contains children: 
       input components
       src\forms\formComponents\ChronicleSelectMui.jsx
   parent: New 
*/




import React, {useState, useEffect}  from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useNavigate, useParams } from 'react-router-dom'
import { chitBlueDull, chitBurgandy, chitBurgandyDull, darkGrey, lightGrey, mediumGrey, veryLightGrey } from '../styles/colors'



// --- Firebase imports ---------
import cuid from 'cuid'  // #### for sample site only ####

// --- React-hook-form imports ---------

import { FormProvider, useForm, Controller } from "react-hook-form";
 
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object  } from 'yup';
import * as Yup from 'yup';

// --- Redux slices imports ---------------------------------
import { changeLoadingStatus, updateStatusView } from '../app/redux/statusRedux/statusSlice'
import {
  closeLogSectionForm, 
  closeNewLogForm, 
  selectStatus, 
  updateTwoPartyViewData


} from '../app/redux/statusRedux/statusSlice'


import { addReceivedChitToStore } from '../app/redux/receivedRedux/receivedChitsSlice'
import { 
  addDocumentToFirestore,
  getDocumentFromFirestore,
  updateDocumentFromFirestore
} from '../app/firebase/fireStore.utils';
 
// --- imports to create options for selectors



import { selectPeople, addPersonToStore } from '../app/redux/peopleRedux/peopleSlice'
import { selectGroups, addGroupToStore } from '../app/redux/groupRedux/groupSlice'
 

// --- Form component imports ---------


import { StyledChitSelectMuiCreatable } from '../forms/formComponents/StyledChitSelectMuiCreatable'

import { ChitRadio } from '../forms/formComponents/ChitRadio'

import { descendSorter, stripWhiteSpace } from '../app/helpers/commonHelpers'
import { closeModal } from '../app/redux/statusRedux/statusSlice'
// --- MUI imports ---------

import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

import { styled, createTheme} from '@mui/material/styles'
import {withStyles} from '@mui/styles'


const theme = createTheme(); // allows use of mui theme in styled component



// ---functions --------------------------------------------

/**
chitColor: "gold"
​​
chitDate: "2022-01-09T12:20:16.000Z"
​​
chitType: "promise"
​​
deedPerformedBy: "otherParty"
​​
receiverName: "Jerry G"
​​
senderId: "g6q3v1JmoZTWEJg1shBMD9BeZVN2"
​​
senderName: "shelby brous"
​​
sharedDate: "2022-09-08T13:04:00.546Z"
​​
sharedMessage: "<div>Jerry promised to help me build our deck. And he promised to get Billy to help.</div>"
​​
sharedTitle: "a shared Chit Title from Jerry"





 */


 


// ==============================================================
// ==== MAIN FUNCTION ===========================================

export default function ReceivedChitForm(props) {

  
  const dispatch = useDispatch()
  const navigate = useNavigate()
  let reduxStatusData = props.modalParams.data

  const { chitColor, chitDate, chitType, deedPerformedBy, receiverName, senderId, senderName, sharedDate, sharedMessage, sharedTitle } = reduxStatusData
 

  const status = useSelector(selectStatus)
  const allPeople = useSelector(selectPeople)
  console.log('[ ReceivedChitForm ] allPeople ', allPeople);
 
  const uid = status.auth.loginId





  const formSchema = object({
    person: string().when(["otherPartyType" ], {
      is: (otherPartyType) => otherPartyType === 'person' ,
      then: string().required('You must choose a person')
      .nullable()
    })
    .nullable()


    });


// -- create Options for  people select ----- 
  let peopleObjectArray = []
  let peopleOptionsArray = []

 
  
  let sortedFilteredPeople = descendSorter(allPeople, 'name')



  sortedFilteredPeople.map((person, index) => {
    peopleOptionsArray.push(person.name)


      return peopleOptionsArray
    }

  ) //end map

  console.log('[ ReceivedChitForm ] people ', peopleOptionsArray);


 
 
 

// ----create default paramters if note exists ---------------------

let defaultValues
  defaultValues = {

   
    person: '',


  };

  // --- close / cancel form 
  const cancelNewForm = () => {

    dispatch(closeModal())
    // navigate(`/sample/twoPartyChits/allChits`)

    
  }
// ===========  FORM  ==================================================

  const methods = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(formSchema)
  });
  const { handleSubmit, reset, control, setValue, onChange, watch, ref , formState: { errors } } = methods;

  const submitForm = async (data) => {

 

    console.log('[ReceivedChitForm]...data ', data)

 
    try {

  

/*

1. retrieve person_name from input form
2. check to see if person_name is new or existing
3. if new 
    a.  add Person to Firestore people collection
    b.  get new person id
    c.  update redux store people

    if existing
    a. get id of person from redux store

4. create new Firestore receivedChit object
     reduxStatusData + person id

5. create new receivedChit document in Firestore receivedChits collection
6. return new receivedChitId
7. create new receivedChit Redux object - with new receivedChit id
8. add new receivedChit to redux store


*/
let receiverId
let cleanedPerson = stripWhiteSpace(data.person)

console.log('[ ReceivedChitForm ] cleanedPerson ',  cleanedPerson);

let personObject = allPeople.find((searchPerson) => searchPerson.name === cleanedPerson)

// console.log('[ ReceivedChitForm ] cleanedPersonObject  id',  personObject.id);

let chitValue, chitBurden

if(chitType !== 'promise'){
  if(chitColor === 'gold'){
    chitValue = 40
    chitBurden = 40
  }
  if(chitColor === 'silver'){
    chitValue = 25
    chitBurden = 25
  }
  if(chitColor === 'copper'){
    chitValue = 10
    chitBurden = 10
  }

  if(chitColor === 'awChit'){
    chitValue = 40
    chitBurden = 40
  }

}else{

  chitValue = 0
  chitBurden = 0
}


// ###########################################################
// ###########################################################

// if no person object - person does not exist
/* 
   3a, 3b -  add person to Firestore 
   3c     - add person to redux 



*/


if(!personObject) {

  console.log('[ where ] no Person Object ');

  let personObject = {
    type: 'person',
    name: cleanedPerson
  }

  let newPersonId = await addDocumentToFirestore(uid, 'people', personObject)
  receiverId = newPersonId
  dispatch(addPersonToStore({
    id: newPersonId,
    name: cleanedPerson,

  }
  )) // end dispatach addPersonToStore

  // convert chitColor to values for chitBurden and chitValue

  let receivedChitFirestoreObject = {

    chitColor: chitColor,
    chitValue: chitValue,
    chitBurden: chitBurden,
    chitDate: chitDate,
    chitType: chitType,
    deedPerformedBy: deedPerformedBy,
    receiverName: receiverName,
    senderId: newPersonId,
    senderName: senderName,
    sharedDate: sharedDate,
    sharedMessage: sharedMessage,
    sharedTitle: sharedTitle

  } // end receivedChitFirestoreObject

  let newReceivedChitId  = await addDocumentToFirestore(uid, 'receivedChits', receivedChitFirestoreObject)


  let receivedChitReduxObject = {
    id:  newReceivedChitId,
    chitColor: chitColor,
    chitValue: chitValue,
    chitBurden: chitBurden,
    chitDate: chitDate,
    chitType: chitType,
    deedPerformedBy: deedPerformedBy,
    receiverName: receiverName,
    senderId: newPersonId,
    senderName: senderName,
    sharedDate: sharedDate,
    sharedMessage: sharedMessage,
    sharedTitle: sharedTitle

  } // end receivedChitFirestoreObject

  dispatch(addReceivedChitToStore(receivedChitReduxObject))

} //- end if  !personObject


if(personObject){


// if person object - person exists - get id 
/* 
  5., 6. - add receivedChit to Firestore
  7, 8   - add receivedChit to Redux

*/ 
receiverId = personObject.id
  let receivedChitFirestoreObject = {

    chitColor: chitColor,
    chitDate: chitDate,
    chitValue: chitValue,
    chitBurden: chitBurden,
    chitType: chitType,
    deedPerformedBy: deedPerformedBy,
    receiverName: receiverName,
    senderId: personObject.id,
    senderName: senderName,
    sharedDate: sharedDate,
    sharedMessage: sharedMessage,
    sharedTitle: sharedTitle

  } // end receivedChitFirestoreObject

  let newReceivedChitId  = await addDocumentToFirestore(uid, 'receivedChits', receivedChitFirestoreObject)


  let receivedChitReduxObject = {
    id:  newReceivedChitId,
    chitColor: chitColor,
    chitValue: chitValue,
    chitBurden: chitBurden,
    chitDate: chitDate,
    chitType: chitType,
    deedPerformedBy: deedPerformedBy,
    receiverName: receiverName,
    senderId: personObject.id,
    senderName: senderName,
    sharedDate: sharedDate,
    sharedMessage: sharedMessage,
    sharedTitle: sharedTitle

  } // end receivedChitFirestoreObject

  dispatch(addReceivedChitToStore(receivedChitReduxObject))

}


dispatch(updateStatusView({
  pageType: 'twoParty',
  pageView: 'ledger',
  type: 'twoPartyChits',
  id: receiverId
}))
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
console.log('[ ReceivedChitFOrm ] receiverId ', receiverId);

      changeLoadingStatus(false)
      dispatch(closeModal())
      navigate(`/main/twoPartyChits/${receiverId}`)
      // navigate(`/main/twoPartyChits`)



    } catch (error) {
      alert(error.message)
      dispatch(changeLoadingStatus(false))

      reset(defaultValues)

    } // end catch
  } // end async submit

   

 
 

  // ==== return - Form JSX  ======================================

  return (
    <Wrapper>

      <FormProvider {...methods}>
        <FormWrapper id="submit-form" onSubmit={handleSubmit(submitForm)} >

          <MainWrapper>

            <FormComponentWrapper>
              <ComponentName>
                Add chit received from <Color>{senderName} </Color> to your ledger
              </ComponentName>

              <ComponentWrapper>

              <Instructions>Select a contact, or type in a new contact</Instructions>
                      <StyledChitSelectMuiCreatable
                        name={'person'}
                        control={control}
                        options={peopleOptionsArray}
                        // defaultValue = {{ value: 'ge423', label: 'home'}}
                        defaultValue={defaultValues.person}
                        placeholder=''


                      />
                      {errors.person && <ErrorMessage>{errors.person.message} </ErrorMessage>}


              </ComponentWrapper>
            </FormComponentWrapper>






          </MainWrapper>
      




          {/* ------Submit ---------- -------------------------- */}
          <BottomWrapper>
            <StyledButton

              variant="contained"
              color="primary"
              style={{
                textTransform: 'none',

              }}
              onClick={() => cancelNewForm()}

            >
              Cancel
            </StyledButton>

            <ButtonWrapper>
              <StyledButton
                type="submit"
                variant="contained"
                color="primary"
                style={{ textTransform: 'none' }}
              >
                Next
              </StyledButton>

            </ButtonWrapper>


          </BottomWrapper>
        </FormWrapper>

      </FormProvider>

    </Wrapper>
  );
}

Yup.addMethod(Yup.string, 'customValidator', function() {
  return this.test({
    name: 'name',
    message: 'Input is not valid aaaaa',
    test: (score) => score !== 'red'


})
});
// ==== Styles ===========================================


const Wrapper = styled(Paper)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  // zIndex: '95',

  width: '100%',
  // height: '100%',
  overflow: 'auto',
// border: '2px solid #F285B5',
 

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',
    // backgroundColor: 'red'
  },

})


const FormWrapper = styled('form')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '98%',
  height: '100%',
  margin: '5px 0',
  padding: '4px',
  backgroundColor: 'white',



  [theme.breakpoints.down('sm')]: {
    width: '100%',
    height: '50%',
 
  },

})

const MainWrapper= styled('div')({

  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '98%',
  height: '100%',
  marginBottom: '6px',
  paddingBottom: '6px',
 
  // backgroundColor: 'green',


  [theme.breakpoints.down('sm')] : {
    // width: '100%'
  },


})







// ##############################################

const FormComponentWrapper= styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '90%',
  // margin: '.25rem',
 
// backgroundColor: 'yellow',

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const FormComponentWrapperIndent= styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '90%',
  marginLeft: '2rem',

// backgroundColor: 'yellow',

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const FormComponentWrapperDoubleIndent= styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '90%',
  marginLeft: '3rem',

// backgroundColor: 'yellow',

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const ComponentName= styled('div')({

  width: '100%',
  color: darkGrey,
  fontSize: '.9rem',


  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const ComponentWrapper= styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '100%',
  marginTop: '1.2rem',
  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const NewInputContainer= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '13rem',
  marginLeft: '9px',
 
  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const NewMetaContainer= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '20rem',
  marginLeft: '9px',
  border: '1px solid grey',
  broderRadius: '5px',
 
  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const NewSelectContainer= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '15.5rem',
 
 
  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})


const RadiotWrapper= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '30%',
 
 
  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const SelectWrapper= styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '80%',
 
 
  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})







const CreateNewWrapper= styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
 
 
 
  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const NewWrapper= styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '80%',
 
 
 
  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})











const ErrorMessage= styled('div')({
  fontSize: '.8rem',
  color: 'red',
 
  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})
// #######################################


//  --- Buttons -----------
const BottomWrapper= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  width: '95%',
  margin: '.75rem',

  
  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const ButtonWrapper= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
   

  
  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const StyledButton= styled(Button)({
  backgroundColor: 'white',
  border: '1px solid #E6E7E8',
  color: chitBurgandyDull,
  margin: '0 8px',
  width: '5rem',
  height: '1.5rem',
  fontSize: '.8rem',
  '&:hover' :{
    backgroundColor: lightGrey
  }

})


const EditNameWrapper= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
   
  color: darkGrey,
  fontSize: '.9rem',
  marginLeft: '12px',

  '& span' :{
    color: chitBurgandy,
    marginRight: '.5rem',
    fontSize: '.85rem'
  },

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const Instructions= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  marginLeft: '.7rem',
  color: mediumGrey,
  fontSize: '.7rem',


 

})



const Color= styled('span')({
  color: chitBurgandy


 

})
// -----------------------------------






