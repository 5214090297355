//rootReducer.js

/*

DATA BASE - Data Loading --------------

Initial Store Data from database gets loaded with
homeActions (features/main/home/homeActions.js) in
componentDidMount.  The Home.jsx file is the destination
after successful login.


*/

import { combineReducers } from 'redux';



import statusReducer from '../statusRedux/sam_statusSlice'


import logReducer from '../logRedux/sam_logsSlice'
import logHolderReducer from '../logHolderRedux/sam_logHolderSlice'



import noteReducer from '../noteRedux/sam_notesSlice'
import categoryReducer from '../categoryRedux/sam_categorySlice'


import groupReducer from '../groupRedux/sam_groupSlice'
import peopleReducer from '../peopleRedux/sam_peopleSlice'

// import topicReducer from '../topicRedux/sam_topicSlice'
import personalChitReducer from '../personalChitRedux/sam_personalChitSlice';
import twoPartyChitReducer from '../twoPartyChitRedux/sam_twoPartyChitSlice';


import topics from '../topicalRedux/sam_topicsSlice'
import topicalSections from '../topicalRedux/sam_topicalSectionsSlice'

const sampleRootReducer = combineReducers({
  // data: homeReducer,
    // private : mainReducer,
    // public: landingReducer,
    status : statusReducer,
    personalChits: personalChitReducer,
    twoPartyChits: twoPartyChitReducer,
   
    categories: categoryReducer,

    logs: logReducer,
    logHolders: logHolderReducer,
    topics: topics,
    topicalSections: topicalSections,
   
    notes: noteReducer,

    people: peopleReducer,
    groups: groupReducer,

 


  // crud : crudReducer
  

})

export default sampleRootReducer