
// *** Sample Chrons Selectors ----------

import { createSelector } from 'reselect'

// Get all personalChit sections --------------------

import { createSlice } from '@reduxjs/toolkit'



export const personalChitSlice = createSlice({
  name: 'personalChitsSample',
  initialState: [],

  reducers: {

    addAllPersonalChitsFromFirestore: (state = [], action) => {
    
      let firestoreData = action.payload
    
      // Clear the state array
      state = [];
    
      // Add the Firestore data to the state array
      state = [...firestoreData];
    
      return state
    },// end addAllPersonalChitsFromFirestore


    addPersonalChitToStore: (state, action) => {

      let personalChit = action.payload

      state.push(personalChit)
    },


    resetPersonalChits: (state, action) => {
      let initialState = []
      return initialState
    },// end resetPersonalChits



    updateEditedPersonalChit: (state, action) => {

 
    let data = action.payload
    let personalChitId =data.id

        console.log('[ updateEditedPersonalChit ] data ', data);

            console.log('[ updateEditedPersonalChit ] personalChitId ', personalChitId);

    const {chitType, chitColor, category, workRelated, detail, chitDate } = data



    let personalIndex  = state.findIndex(index => index.id === personalChitId)

    state[personalIndex].chitType  = chitType
    state[personalIndex].chitColor  = chitColor
    state[personalIndex].category  = category
    state[personalIndex].workRelated  = workRelated
    state[personalIndex].detail  = detail
    state[personalIndex].chitDate  = chitDate
   

 



  }, // end updateEditedpersonalChit

  deletePersonalChit: (state, action) => {

    console.log('[ deletePersonalChit ] payload ', action.payload);
        let personalChitId = action.payload.id
    
        return state.filter(item => item.id !== personalChitId)
     
    }, // end deletePersonalChit

},




}) // end slice personalChitsSlice 


// --- Export actions ---------------------------------------------

export const { 
  addAllPersonalChitsFromFirestore,
  addPersonalChitToStore, 
  updateEditedPersonalChit,
  addPersonalChitHolder ,
  deletePersonalChit,
  resetPersonalChits

} = personalChitSlice.actions



// --- Export selectors ------------------------------------------

export const selectAllPersonalChits = state => state.personalChits




// --- default Export reducers -----------------------------------

export default personalChitSlice.reducer