
// *** Sample Chrons Selectors ----------

import { createSelector } from 'reselect'

// Get all logs sections --------------------

import { createSlice } from '@reduxjs/toolkit'


export const logsSlice = createSlice({
  name: 'logsSample',
  initialState: [],

  reducers: {



    addAllLogsFromFirestore: (state = [], action) => {
    
  let firestoreData = action.payload

  // Clear the state array
  state = [];

  // Add the Firestore data to the state array
  state = [...firestoreData];

  return state
},// end addAllLogsFromFirestore

    addLogToStore: (state, action) => {

      let log = action.payload

      state.push(log)
    },


    resetLogs: (state, action) => {
      let initialState = []
      return initialState
    },// end resetLogs




  updateEditedLog: (state, action) => {
    console.log('[ sam_logSlice ] payload ', action.payload);

    let logId = action.payload.id
 
    
  
    let newLogMeta = action.payload.meta
    let newLogPeopleArray = action.payload.peopleArray
    let newLogLastEdit = action.payload.lastEdit
    let newLogLogDate = action.payload.logDate

    let newLogDetail = action.payload.detail
   
    
    
    

    let logIndex = state.findIndex(index => index.id === logId)

    console.log('[ sam_logSlice ] state[logIndex] ', state[logIndex]);

    
    state[logIndex].detail = newLogDetail
  
  state[logIndex].meta = newLogMeta
  state[logIndex].peopleArray = newLogPeopleArray
  state[logIndex].lastEdit = newLogLastEdit
  state[logIndex].logDate = newLogLogDate


  }, // end updateEditedLogs

  updateEditedLogTimeLock: (state, action) => {

    let id = action.payload.id
    let logIndex = state.findIndex(index => index.id === id)

    let timeLock = action.payload.timeLock




    console.log('[ logSectionsSlice ] xxxxxx logSectionId ', timeLock);


    state[logIndex].timeLock = timeLock




  }, // end updateEditedLogTimeLock





  deleteLogSection: (state, action) => {

    console.log('[ deleteLogSection ] payload ', action.payload);
    let logId = action.payload.id

    return state.filter(item => item.id !== logId)
 
}, // end deleteLogSection


}

}) // end slice firstSlice 

 


// --- Export actions ---------------------------------------------

export const { 
  addAllLogsFromFirestore,
  addLogToStore, 
  updateEditedLog,
  updateEditedLogTimeLock,
  deleteLogSection,
  resetLogs 

} = logsSlice.actions



// --- Export selectors ------------------------------------------

export const selectLogs = state => state.logs

export const selectLogFromArray = (logArray, id) => {
 
  let log = logArray.find(log => log.id === id) 
 
  return log
}


// --- default Export reducers -----------------------------------

export default logsSlice.reducer