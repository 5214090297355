/* function TwoPartyMain (props) -------------------
    1. if no person id in URl - display message - choose a person
    2. if person id - 
       get the view (karmic or ledger) from status
       

  parent: ../TwoPartyChits.jsx
------------------------------------*/

import React from 'react'
import { useSelector} from 'react-redux'
import { useParams  } from 'react-router-dom'
 

import {chitRedDark, veryLightGrey} from '../../../styles/colors'

import { selectStatus } from '../../../app/redux/statusRedux/statusSlice';

import TwoPartyChitHeader from './TwoPartyChitHeader';
import TwoPartyLedger from './TwoPartyLedger';
import New from '../components/New_Circle';
 import KarmicView from './KarmicView';
 import KarmicHeader from './KarmicHeader';

 import Paper from '@mui/material/Paper'


import { styled, createTheme} from "@mui/material/styles"

const theme = createTheme(); // allows use of mui theme in styled component

// -----------------------------------------------------------------


export default function TwoPartyMain(props) {

  let match = useParams()

  let chitView = useSelector(selectStatus).view.twoParty.display 
  let personId = match.id

  return (
    <Wrapper>

    {!personId && 
      <Message> 
        
        <div> Choose a person or group </div>
        <div>or</div>
       <div>Create a new chit

       <br/>
              <New/>
       </div>

      </Message>
    }
 {personId && 
 <>
      {chitView === 'ledger' &&
        <TwoPartyChitHeader />
      }

      {chitView === 'karmic' &&
        <KarmicHeader />
      }
 
      {chitView === 'ledger' && <TwoPartyLedger />}
      {chitView === 'karmic' && <KarmicView />}
      </>
    }
    </Wrapper>
  );
}



// ---------------------------


const Wrapper= styled('div')({

 
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
//   backgroundColor: veryLightGrey,
  width: '100%',
  height: '100%',
  overflowY: 'auto',

//   [theme.breakpoints.down('sm')] : {
//     // width: '100%'
//   },

})

const Message = styled(Paper)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'black',
  fontSize: '1.1rem',
  marginTop: '2rem',
  width: '70%',
  padding: '2rem',

  [theme.breakpoints.down('sm')] : {
    // height: '1.25rem',
    // backgroundColor: 'red'
  },

})
