/* MainPage_NavButtons_s.jsx
  Large Navigation Buttons to Sample Site apps
       

    parent: src/pages/public/Sample.jsx

*/


import React from 'react'
 
import {useSelector} from 'react-redux'
import {NavLink, useParams } from 'react-router-dom'


import{highlightGrey } from '../../../../styles/colors'

import { selectStatus } from '../../../../app/redux/statusRedux/sam_statusSlice'


import Button from '@mui/material/Button'



import { styled, createTheme  } from "@mui/material/styles"
const theme = createTheme(); // allows use of mui theme in styled component


// ========================================================

export default function MainPage_NavButtons_s(props) {
  const status = useSelector(selectStatus)
  const twoPartyId = status.view.twoPartyChit.id
  const twoPartyLink = `/sample/twoPartyChits/${twoPartyId}`
  
  
  const personalId = status.view.personalChit.id
  const personalLink = `/sample/personalChits/${personalId}`
  
  const topicalsId = status.view.topical.id
  const topicalLink = `/sample/topicals/${topicalsId}`
  
  const logsId = status.view.log.id
  const logLink = `/sample/logs/${logsId}`

    return (
      <Wrapper>
  
    
        <StyledLink to={twoPartyLink}>
          <NavButton> Two Party Chits </NavButton>
        </StyledLink>
  
        <StyledLink to= {logLink} >
          <NavButton> Logs </NavButton>
        </StyledLink>
  
        <StyledLink to= {topicalLink} >
          <NavButton> Topicals </NavButton>
        </StyledLink>
  
        <StyledLink to={personalLink}>
          <NavButton> Personal Chits </NavButton>
        </StyledLink>
  
      </Wrapper>
    )
}
 
// -----------------------------------------------------------------

const NavButton= styled(Button)({
  
   
  
  display: 'flex',
  textTransform: 'none',
  fontFamily: 'sans-serif',
 
  color: 'white',
  border: '1px solid white',
  borderRadius: '10px',
  padding: '.3rem .8rem',
  
  fontSize: '1rem',
  height: '3rem',
  minWidth: '7.5rem',
  margin: '0 1rem',
  '&:hover' : {
    
    textDecoration: 'none',
    border: '1px solid #A8BEED' ,
    color: '#A8BEED'
  },


  [theme.breakpoints.down('md')] : {
    display: 'flex',
     fontSize: '.7rem',
  height: '4rem',
  minWidth: '5.5rem',
 padding: '0 4px',
 margin: '0 .5rem',
  },
  


})


const StyledLink= styled(NavLink)({

  textDecoration: 'none',

})

const Wrapper = styled('div')({
 
  display: 'flex',
  width: '100%',
  justifyContent: 'space-around',

    [theme.breakpoints.down('xs')] : {
       
 
      
      
    }
   

})
