/* function First(props) -------------------

    maps the First sections for a specified First


  Children: ./FirstSection
 
    parent: ./FirstMain
------------------------------------*/


import React , {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useParams} from 'react-router-dom'

import { lightGrey, chitBurgandy } from '../../../styles/colors';

import { selectFirsts } from '../../../app/redux/firstRedux/firstSlice';
import { selectStatus, openFirstForm } from '../../../app/redux/statusRedux/statusSlice';
import FirstSection from './FirstSection'

import { sortFirstsByDateAscending, sortFirstsByDateDescending, firstFilter   } from '../../../app/helpers/chronicleHelpers';

import SliderComponent from '../components/SliderComponent';

//  ---- Material Ui ------------------
import Paper from '@mui/material/Paper'
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button'
import { styled, createTheme  } from "@mui/material/styles"
const theme = createTheme(); // allows use of mui theme in styled component

// ================================================

export default function First(props) {

  let match = useParams()
  let id = match.id // get URL view location
  const dispatch = useDispatch()
  const allFirstsArray = useSelector(selectFirsts)
  const status = useSelector(selectStatus)

  const [allFirsts, setAllFirsts] = useState(allFirstsArray)

  useEffect(()=>{

    setAllFirsts(allFirstsArray)

  },[allFirstsArray])


  const displayStatus = status.view.firstContact.sectionId

  var firstsArray = [...allFirsts]; // mutable copy of allFirstsArray

  const [arrayOrder, setArrayOrder] = useState(false)

  let sortedFirstsByDate  
  if(arrayOrder=== true){
   sortedFirstsByDate =sortFirstsByDateAscending(firstsArray)
  }

  if(arrayOrder=== false){
   sortedFirstsByDate= sortFirstsByDateDescending(firstsArray)
   }
  let filteredFirsts = firstFilter(sortedFirstsByDate, id)



  const firstRows = () =>

  filteredFirsts.map((row, index) => {

    return (
      <FirstSection
        id={row.id}
        key={row.id}
        data={row}

      />
    )
  }
  ) //end map

  const handleClick = ()=>{

  
   console.log('[ First HEADER] open new form ');
    dispatch(openFirstForm('new'))
    
  }

  const handleSwitchState = (newState) => {
   newState === false? setArrayOrder(true): setArrayOrder(false)
   // 
 }

// =========================================================

return (
  <Wrapper>
          <FilterWrapper>

<SliderComponent
handleSwitchState={handleSwitchState} //gets new state from child switch
leftLabel='latest first'
rightLabel='oldest first'
/>
</FilterWrapper>

    
    {filteredFirsts.length > 0 && 
    <> 
   {firstRows()}
   </>
  }
    {filteredFirsts.length === 0 && displayStatus !== 'new' &&
      <FirstsMessageWrapper>
        <MessageWrapper>
          You have no logs for this party yet .
          

        </MessageWrapper>
         

          <ButtonWrapper>

            <FormButton
              startIcon={<AddIcon />}
              onClick={() => handleClick()}
            >
              add Section
            </FormButton>

          </ButtonWrapper>

      


      </FirstsMessageWrapper>

  }

  </Wrapper>
)
}


 
//--- STYLES begin --------------------------

const Wrapper= styled('div')({

  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  // backgroundColor: 'green',
  width: '100%',
  height: '100%',

overflow: 'auto',
  [theme.breakpoints.down('sm')] : {
    // width: '100%'
  },


})

const FirstsMessageWrapper= styled(Paper)({

  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  // backgroundColor: 'green',
  width: '98%',
  height: '8rem',
  marginTop: '2rem',
  backgroundColor: lightGrey,
 
 
  [theme.breakpoints.down('sm')] : {
    // width: '100%'
  },


})

const MessageWrapper= styled('div')({

  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'enter',
  alignItems: 'center',
  color: chitBurgandy,
  marginBottom: '1rem',
  [theme.breakpoints.down('sm')] : {
    // width: '100%'
  },


})



const ButtonWrapper = styled('div')({
  display: 'flex',
  
  justifyContent: 'flex-end',
  alignItems: 'center',


[theme.breakpoints.down('sm')] : {
    // height: '1.25rem',
    // backgroundColor: 'red'
  },
})

const FormButton = styled(Button)({

 

  display: 'flex',
  textTransform: 'none',
  
 
  color: 'white',
  backgroundColor:  '#727376',
  fontWeight: 'normal',
  fontSize: '.85rem',
  padding: '0 1.5rem',
  width: '9.5rem',
  height: '1.2rem',
// margin: '0 1rem',
  
  '&:hover' : {
    // backgroundColor: chitBlueDull,
    textDecoration: 'none',
    border: '1px solid #A8BEED' ,
    color: '#3B30CC'

  }


})

const FilterWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  
// backgroundColor: 'yellow',
  width: '90%',
  padding: '0 12px',
  // height: '3rem',
  margin: '.5rem  0 .5rem 0',
 
  // height: '90%',

  
  // overflowY: 'hidden',

  [theme.breakpoints.down('sm')] : {
    // height: '1.25rem',

  },

})