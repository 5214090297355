/* function PersonalChits(props) -------------------
  Chooses Plan display options
  a) if no PersonalChits --  message 1
  b) if PersonalChits but no detailId  in route --  message 2
  c) if  PersonalChits AND detailId  in route--  show Plan page

------------------------------------*/

import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch} from 'react-redux'
 
import { useParams, useNavigate } from 'react-router-dom'
import {veryLightGrey, lightGrey, chitBurgandy} from '../../../styles/colors'

import {selectAllPersonalChits} from  '../../../app/redux/personalChitRedux/personalChitSlice'
import PersonalMain from './PersonalMain'
import New from '../components/New_Circle'

import { selectStatus } from '../../../app/redux/statusRedux/statusSlice'
import { openModal } from '../../../app/redux/statusRedux/statusSlice'
// -------Material UI 
import Button from '@mui/material/Button'; 
import InfoIcon from '@mui/icons-material/Info';

import { styled, createTheme, withStyles  } from "@mui/material/styles"
const theme = createTheme(); // allows use of mui theme in styled component






// ====================================
 
// ===================================
export default function PersonalChits(props) {
  const dispatch = useDispatch()
  // let personalChitsArray = []
  let personalChitsArray = useSelector(selectAllPersonalChits)
   
  const openForm = ()=>{

    //  define which Form to open in Modal by passing
   

      
    // props.openModal(dbCollection, id)
  
    dispatch(openModal(
      { modalParams: {
            modalType: 'form',
            dbCollection: 'personalChits',
            id: ''
        }
      }
  
    ))
  
  }// end openForm

  return (
    <Wrapper>



      {personalChitsArray.length === 0 &&
              <> 
        

              <NoneMessage>
                
                <ButtonWrapper> 
                <StyledButton 
                 onClick={() => openForm()}
                >Create your first personal chit</StyledButton>
                </ButtonWrapper>
              </NoneMessage>
      
              <NoneDescription>
                <Header>Use personal chits if you want to : </Header>
                <StyledUl>
                  <StyledLi> record your accomplishments</StyledLi>
                  <StyledLi> motivate yourself
                   </StyledLi>
                  
                
                </StyledUl>
                <KeyFeature> Key Feature - Milestones</KeyFeature>
      <Info> 
        <InfoIconWrapper/>
        <div> click info icon above for details on how to use  </div>
      </Info>
                
              </NoneDescription>
      
      </>

      }




      {personalChitsArray.length > 0 &&

        <PersonalMain />

      }




    </Wrapper>
  )
}


// -----------------------------------------------------------------
const Wrapper= styled('div')({

  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  backgroundColor: veryLightGrey,
  width: '100%',
  height: '100%',
 

  [theme.breakpoints.down('sm')] : {
    // width: '100%'
  },

})

const NoneMessage= styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '1.5rem 0',
  width: '80%',
  height: '8rem',
  backgroundColor: 'white',
  marginTop: '2rem',
 

  [theme.breakpoints.down('sm')] : {
    width: '95%'
   
  },
  
})

const NoneDescription= styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '1.5rem 0',
  width: '80%',
  maxHeight: '20rem',
  backgroundColor: 'white',
  marginTop: '2rem',
 


  [theme.breakpoints.down('sm')] : {
    width: '95%',
    padding: '1.5rem 0',
  },
  
})

const Header= styled('div')({
  
fontStyle: 'italic',

 
  [theme.breakpoints.down('sm')] : {
    // height: '1.25rem',
    fontSize: '.9rem',
  },
  
})
const ButtonWrapper= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '60%',
  margin: '.75rem',
 
  
  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const StyledButton= styled(Button)({
  backgroundColor: 'white',
  border: '1px solid #F58634',
  color: chitBurgandy,
  margin: '0 8px',
  padding: ' 0 1rem',
  height: '1.5rem',
  fontSize: '.75rem',
  '&:hover' :{
    backgroundColor: veryLightGrey
  },
  [theme.breakpoints.down('md')] : {
    // height: '1.25rem',
    fontSize: '.7rem',
  },

  [theme.breakpoints.down('sm')] : {
    // height: '1.25rem',
    fontSize: '.55rem',
  },

})


const Small= styled('span')({

  fontSize : '.7rem',
  
 

})
const StyledUl= styled('ul')({
  
  marginLeft: '2rem'

})

const StyledLi= styled('li')({

  fontSize : '.9rem',
  lineHeight: '1rem',
  marginBottom: '10px',
  
 
  [theme.breakpoints.down('sm')] : {
    // height: '1.25rem',
    fontSize: '.8rem',
  },
 

})

const KeyFeature= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '80%',
  margin: '.75rem',
 fontSize: '.8rem',
  color: chitBurgandy,
  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const Info= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '80%',
  margin: '.75rem',
 fontSize: '.7rem',
  
  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const InfoIconWrapper= styled(InfoIcon)({

  color: 'grey',
  fontSize : '1.6rem',
  
 

})