/* Header_landing.jsx
   contains child  component: 
   
       Header_nav -  navComponents/publicNav/Header_nav

    parent: Landing- src/pages/public/Landing.jsx

*/

import React from 'react'
import { useLocation} from 'react-router-dom'
import { useDispatch} from 'react-redux'
import{backgroundBlue, veryLightGrey} from '../../../styles/colors'
 
 
import AppBar from '@mui/material/AppBar';
import { getPage } from '../../../app/helpers/locationHelper';

import { openModal } from '../../../app/redux/statusRedux/statusSlice';
// import HeaderLogin from './Header_login'

import HeaderNav from '../../navComponents/publicNav/Header_nav'
import Button from '@mui/material/Button'
import { styled, createTheme  } from "@mui/material/styles"
const theme = createTheme(); // allows use of mui theme in styled component



// -------------------------

const Header_landing = (props) => {
  let location = useLocation()
  let page = getPage(location)
  const dispatch = useDispatch()
 

  //  let handlePageChange = props.handlePageChange

  const openForm = ()=>{

    //  define which Form to open in Modal by passing
    //  dbCollection to Modal depending on pageView in browser URL 
    // ------------------------------------------------------------
 
    // props.openModal(dbCollection, id)
    
    dispatch(openModal(
      { modalParams: {
            modalType: 'form',
            dbCollection: '',
            id: '',
            login: 'login'
        }
      }
  
    ))
  
  }// end openForm
 
  return (

    <HeaderWrapper position="fixed" elevation={0}>
      <Header>
       

        <NavBox>
          <HeaderNav
            // handlePageChange={handlePageChange}


          />
        </NavBox>

       
          {/* <LoginBox onClick={() => openForm(page)}>
          <StyledLogIn>
            Login
          </StyledLogIn>
          </LoginBox> */}
       



      </Header>
      <AppBarSpacer />

    </HeaderWrapper>



  )
}


export default Header_landing

// -----------------------------------------------------------------

const HeaderWrapper = styled(AppBar)({
  height: '2.5rem', 
  backgroundColor: backgroundBlue,
  border: 'none',
 
  width: '100vw',

})

const Header= styled('div')({
 
  height: '2.5rem', 
  marginBottom: '1rem',
 width: '100vw',
  display: 'flex',
 
  justifyContent: 'space-between',
  alignItems: 'center',

  [theme.breakpoints.down('sm')] : {
    height: '3rem',
    justifyContent: 'space-around',
   
  }
 

})

const AppBarSpacer= styled('div')({

  height: '2.5rem', 
  display: 'block',


  [theme.breakpoints.down('sm')] : {
     
 
  }

})

 
const NavBox= styled('div')({
 
  height: '100%', 
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
 
  [theme.breakpoints.down('sm')] : {
    justifyContent: 'space-around',
    width: '100%',
  

  }

})

const LoginBox= styled('div')({
backgroundColor: 'green',
position: 'absolute',
  height: '100%', 
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  // margin: '0 8rem',
 
width: '20%',
  [theme.breakpoints.down('sm')] : {
    margin: '0 1rem',


  }

})



 

const StyledDonateButton= styled(Button)({

 
  top: '4px',
  left: '1.2rem',
  display: 'block',
  textTransform: 'none',
  
  border: '1px solid white' ,
  color: 'white',
  fontWeight: 'normal',
  fontSize: '.8rem',
  padding: '1px 1.4rem',
  
  '&:hover' : {
     // backgroundColor: chitSkyBlue,
     textDecoration: 'none',
     border: '1px solid #A8BEED' ,
     color: '#A8BEED'
  },
     
  [theme.breakpoints.down('sm')] : {
     
    fontSize: '.65rem',
    padding: '0 1rem',
    left: '8px'
  },


})
 
const StyledLogIn= styled(Button)({


  
  textTransform: 'none',
 
  // backgroundColor: shadowBlue,
 
  borderRadius: '0',
  color: 'white',
  fontWeight: '300',
  
  padding: '0 10px',

  '&:disabled ' : {
    color: '#8293B8'
  },

  '& :hover': {
    backgroundColor: veryLightGrey,
  },
   
  [theme.breakpoints.down('sm')] : {
     

    
  },
  [theme.breakpoints.down('xs')] : {

  }

})