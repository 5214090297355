/* DeleteMany_icon

  Displays the garbage icon used on header for all ChitGit apps.
  
  Each header is specific - either a category, person, group,
  or topic.  When the garbage icon is clicked ... first a warning box pops up with two choices.
     1.  delete the chits or sections only for the header.
     2.  delete the person, category, etc + all corresponding children.

     Thus there are two complete sets of code for each app:
       a.  one where the code deletes all children
       b.  one where the code deletes the parent + children
 
     TwoPartyChits and Logs act together since they both are specific to people or groups.  So deleting the party will delete both all logs, and all chits.

     TwoPartyChits are more complex because they can either be chits created by the user, or received from someone who sent them a chit ... and was subsequently saved in the user's repo.


*/




import React from 'react';

import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { mediumLightGrey, chitOrange, lightGrey, chitBurgandyDull, } from '../../../styles/colors'

// --- redux imports

import { deleteTopicalSection } from '../../../app/redux/topicalRedux/topicalSectionsSlice';


import { deleteTwoPartyChit } from '../../../app/redux/twoPartyChitRedux/twoPartyChitsSlice';


import { deleteReceivedChit, selectReceivedChits } from '../../../app/redux/receivedRedux/receivedChitsSlice';

import { deletePersonalChit } from '../../../app/redux/personalChitRedux/personalChitSlice';

import { deleteDocumentFromFirestore } from '../../../app/firebase/fireStore.utils';

import { selectStatus, updateStatusView } from '../../../app/redux/statusRedux/statusSlice';
import { selectPeople, deletePerson } from '../../../app/redux/peopleRedux/peopleSlice';
import { selectGroups, deleteGroup } from '../../../app/redux/groupRedux/groupSlice';

import { selectLogHolders, deleteLogHolder } from '../../../app/redux/logHolderRedux/logHolderSlice';

import { selectLogs, deleteLogSection } from '../../../app/redux/logRedux/logsSlice';

import { selectTopics, deleteTopic } from '../../../app/redux/topicalRedux/topicsSlice';
import { selectTopicalSections } from '../../../app/redux/topicalRedux/topicalSectionsSlice';

import { selectCategories, deleteCategory } from '../../../app/redux/categoryRedux/categorySlice';
import { selectAllTwoPartyChits } from '../../../app/redux/twoPartyChitRedux/twoPartyChitsSlice';

import { selectAllPersonalChits } from '../../../app/redux/personalChitRedux/personalChitSlice';

// --- helper imports



// Material UI --------------------

import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button'



import { styled, createTheme } from "@mui/material/styles"
import { withStyles } from '@mui/styles'
const theme = createTheme(); // allows use of mui theme in styled component


export default function DeleteMany_icon(props) {
  console.log('[ DeleteMany_icon ] props ', props);
  const { source, id } = props
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const status = useSelector(selectStatus)

  const uid = status.auth.loginId

  const allPeople = useSelector(selectPeople)
  const allGroups = useSelector(selectGroups)

  const allLogHolders = useSelector(selectLogHolders)
  const allLogs = useSelector(selectLogs)

  const allTopics = useSelector(selectTopics)
  const allTopicalSections = useSelector(selectTopicalSections)

  const allCategories = useSelector(selectCategories)

  const allTwoPartyChits = useSelector(selectAllTwoPartyChits)
  const allPersonalChits = useSelector(selectAllPersonalChits)
  const allReceivedChits = useSelector(selectReceivedChits)

  let sourceMessage, sourceId


  if (source === 'logs') {

    sourceId = status.view.log.id
    console.log('[ DeleteMany_icon ] log sourceId ', sourceId);

    sourceMessage = `note - If choose "delete person or group", all logs and chits for that party will also be permanently deleted.`
  }

  if (source === 'topicals') {


    sourceId = status.view.topical.id
    console.log('[ DeleteMany_icon ] topical sourceId ', sourceId);

    sourceMessage = `note - If you delete this topic, all descendent sections will also be permanently deleted.`

  }

  if (source === 'personalChits') {

    sourceId = status.view.personal.id
    console.log('[ DeleteMany_icon ] personal sourceId ', sourceId);

    sourceMessage = `note - If you delete this cateogry, all chits for this cateogry will also be permanently deleted.`

  }

  if (source === 'twoPartyChits') {

    sourceId = status.view.twoParty.id
    console.log('[ DeleteMany_icon ] twoParty sourceId ', sourceId);

    sourceMessage = `note - If you delete this party, all chits and logs for this party will also be permanently deleted.`

  }
  console.log('[ DeleteMany_icon OUTSIDE ***]  sourceId ', sourceId);
  console.log('[ DeleteMany_icon OUTSIDE ***] sourceMessage ', sourceMessage);
  // --- Popup Dialog functions
  const [open, setOpen] = React.useState(false)

  const handleClick = (id, source) => {
    console.log('[ DeleteMany_icon  handleClick id ', id)
    console.log('[ DeleteMany_icon  handleClick  aource', source)
    setOpen(true);

  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleDeleteLogs = async (id, source) => {

    // alert('delete logs')

    /*
          1. filter sections for id - make array of logSection id's
              delete / map all sections for id
                -firestore
                -redux
          2. delete log holder for id
              -firestore
             -redux
             */


    // 1. filter sections for id - make array of logSection id's

    let logArrayToBeDeleted = allLogs.filter(log => log.otherPartyId === id);

    console.log('[ DeleteMany_icon logArrayToBeDeleted   ***] logArrayToBeDeleted ', logArrayToBeDeleted);


    // 2a. delete / map all sections for id


    let deleteLog = async (sectionId) => await deleteDocumentFromFirestore(uid, 'logs', sectionId)

    if (logArrayToBeDeleted.length > 0) {


      logArrayToBeDeleted.forEach(element => {

        // console.log('[ DeleteMany_icon logItem id to be deleted   ***]  ',  element.id);

        deleteLog(element.id)

        let logToBeDeleted = {
          id: element.id,
          source: 'logs',
        }

        dispatch(deleteLogSection(logToBeDeleted))

      });

    }

    // 2c. delete logHolder for id

    await deleteDocumentFromFirestore(uid, 'logHolders', id)
    let logHolderToBeDeleted = {
      id: id,
      source: 'logHolders',
    }

    dispatch(deleteLogHolder(logHolderToBeDeleted))


    dispatch(updateStatusView({ pageType: 'log', display: 'logs', id: '' }))



  } // -- end func handleDeleteLogs




  const handleDeleteParty = async (id, source) => {

    // alert('delete person, chits and logs')

    /*
      1. determine if id is a group or a person
      2. a. filter sections for id - make array of logSection id's
          b. delete / map all sections for id
            -firestore
            -redux
      3. delete log holder for id
          -firestore
         -redux
      4. filter chits for id - make array of chit id's
         if chits exist then
           -delete / map chits for id
              -firestore
              -redux

      5. filter chits for id - make array of chit id's
         if chits exist then
           -delete / map chits for id
              -firestore
              -redux

      6. delete logHolder for id
         -firestore
         -redux

      7. delete person or group
    */



    //  1. determine if id is a group or a person

    let personObject = allPeople.find(element => element.id === id)
    let groupObject = allGroups.find(element => element.id === id)

    let otherPartyType
    personObject ? otherPartyType = 'people' : otherPartyType = 'groups'

    // 2a. filter sections for id - make array of logSection id's


    let logArrayToBeDeleted = allLogs.filter(log => log.otherPartyId === id);



    // 2b. delete / map all sections for id


    let deleteLog = async (sectionId) => await deleteDocumentFromFirestore(uid, 'logs', sectionId)

    if (logArrayToBeDeleted.length > 0) {


      logArrayToBeDeleted.forEach(element => {

        // console.log('[ DeleteMany_icon logItem id to be deleted   ***]  ',  element.id);

        deleteLog(element.id)

        let logToBeDeleted = {
          id: element.id,
          source: 'logs',
        }

        dispatch(deleteLogSection(logToBeDeleted))

      });

    }

    // 3. delete logHolder for id

    await deleteDocumentFromFirestore(uid, 'logHolders', id)
    let logHolderToBeDeleted = {
      id: id,
      source: 'logHolders',
    }

    dispatch(deleteLogHolder(logHolderToBeDeleted))


    dispatch(updateStatusView({ pageType: 'log', display: 'logs', id: '' }))
    dispatch(updateStatusView({ pageType: 'twoParty', display: 'ledger', id: '' }))


    // 4. filter chits for id - make array of chit id's
    let twoPartyChitArrayToBeDeleted = allTwoPartyChits.filter(chit => chit.otherPartyId === id)

    let deleteChit = async (chitId) => await deleteDocumentFromFirestore(uid, 'twoPartyChits', chitId)



    if (twoPartyChitArrayToBeDeleted.length > 0) {

      twoPartyChitArrayToBeDeleted.forEach(element => {

        // console.log('[ DeleteMany_icon logItem id to be deleted   ***]  ',  element.id);

        deleteChit(element.id)

        let chitToBeDeleted = {
          id: element.id,
          source: 'twoPartyChits',
        }

        dispatch(deleteTwoPartyChit(chitToBeDeleted))


      });


    }



    // 4. filter chits for id - make array of chit id's
    let receivedChitArrayToBeDeleted = allReceivedChits.filter(chit => chit.senderId === id)

    console.log('[ @@@@ deleteReceivedChhit] receivedChitArrayToBeDeleted ', receivedChitArrayToBeDeleted);




    let deleteReceivedChitFromFirestore = async (chitId) => await deleteDocumentFromFirestore(uid, 'receivedChits', chitId)
    console.log('[ DeleteMany_icon receivedChitArrayToBeDeleted     ***]  ', receivedChitArrayToBeDeleted);

    if (receivedChitArrayToBeDeleted.length > 0) {

      receivedChitArrayToBeDeleted.forEach(element => {


        deleteReceivedChitFromFirestore(element.id)

        let receivedChitToBeDeleted = {
          id: element.id,
          source: 'receivedChits',
        }

        dispatch(deleteReceivedChit(receivedChitToBeDeleted))


      });


    }




    // 6. delete person or group

    console.log('[ DELETE MANY ] personObject id ', personObject);

    if (personObject) {
      await deleteDocumentFromFirestore(uid, 'people', id)
      let personToBeDeleted = {
        id: id,
        source: 'people',
      }
      // console.log('[ DELETE MANY ] personObject id ', id);
      dispatch(deletePerson(personToBeDeleted))


    }//end if personObject


    if (groupObject) {
      await deleteDocumentFromFirestore(uid, 'groups', id)
      let groupToBeDeleted = {
        id: id,
        source: 'people',
      }

      dispatch(deleteGroup(groupToBeDeleted))
    }
    if (source === 'twoPartyChits') {
      navigate(`/main/twoPartyChits`)
    }

    if (source === 'logs') {
      navigate(`/main/logs`)
    }



  } // ---  end handleDeleteParty





  const handleDeleteAllChits = async (id, source) => {

    // alert('delete chits')

    /*
          1. filter sections for id - make array of logSection id's
              delete / map all sections for id
                -firestore
                -redux
          2. delete log holder for id
              -firestore
             -redux
             */


    // 4. filter 2 party chits for id - make array of 2 partychit id's
    let twoPartyChitArrayToBeDeleted = allTwoPartyChits.filter(chit => chit.otherPartyId === id)

    let deleteChit = async (chitId) => await deleteDocumentFromFirestore(uid, 'twoPartyChits', chitId)

    if (twoPartyChitArrayToBeDeleted.length > 0) {

      twoPartyChitArrayToBeDeleted.forEach(element => {

        // console.log('[ DeleteMany_icon logItem id to be deleted   ***]  ',  element.id);

        deleteChit(element.id)

        let chitToBeDeleted = {
          id: element.id,
          source: 'twoPartyChits',
        }

        dispatch(deleteTwoPartyChit(chitToBeDeleted))


      });


    }


    // 4b. filter received chits for id - make array of received chit id's
    let receivedChitArrayToBeDeleted = allReceivedChits.filter(chit => chit.senderId === id)

    console.log('[ @@@@ deleteReceivedChhit] receivedChitArrayToBeDeleted ', receivedChitArrayToBeDeleted);




    let deleteReceivedChitFromFirebase = async (chitId) => await deleteDocumentFromFirestore(uid, 'receivedChits', chitId)
    console.log('[ DeleteMany_icon receivedChitArrayToBeDeleted     ***]  ', receivedChitArrayToBeDeleted);

    if (receivedChitArrayToBeDeleted.length > 0) {

      receivedChitArrayToBeDeleted.forEach(element => {


        deleteReceivedChitFromFirebase(element.id)

        let receivedChitToBeDeleted = {
          id: element.id,
          source: 'receivedChits',
        }

        dispatch(deleteReceivedChit(receivedChitToBeDeleted))


      });


    }
    navigate(`/main/twoPartyChits`)

  } // -- end func handleDeleteAllChits





























  const handleDeleteCategories = async (id, source) => {

    /*
      1. filter sections for id - make array of personalChit id's
          delete / map all sections for id
            -firestore
            -redux
      2. delete log holder for id
          -firestore
         -redux
    */

    console.log('[ Delete_icon - handleDeleteCategories ] id ', id);

    let personalChitArrayToBeDeleted = allPersonalChits.filter(chit => chit.category === id);

    console.log('[ DeleteMany_icon personalChitArrayToBeDeleted   ***] personalChitArrayToBeDeleted ', personalChitArrayToBeDeleted);

    let deleteChit = async (sectionId) => await deleteDocumentFromFirestore(uid, 'personalChits', id)


    if (personalChitArrayToBeDeleted.length > 0) {


      personalChitArrayToBeDeleted.forEach(element => {

        // console.log('[ DeleteMany_icon logItem id to be deleted   ***]  ',  element.id);

        deleteChit(element.id)

        let chitToBeDeleted = {
          id: element.id,
          source: 'personalChits',
        }

        dispatch(deletePersonalChit(chitToBeDeleted))

      });

    } // ---end personalChitArrayToBeDeleted

    // 2c. delete category for id


    console.log('[ DELETE_Many ] id to be deleted ', id);



    await deleteDocumentFromFirestore(uid, 'categories', id)
    let categoryToBeDeleted = {
      id: id,
      source: 'categories',
    }




    dispatch(updateStatusView({ pageType: 'personal', display: 'ledger', id: '' }))

    dispatch(deleteCategory(categoryToBeDeleted))

    navigate(`/main/personalChits`)

  }// --- end handleDeleteCategories

  //  ############################################################
  //  ############################################################

  const handleDeleteTopicals = async (id, source) => {



    /*
     1. filter sections for id - make array of personalChit id's
         delete / map all sections for id
           -firestore
           -redux
     2. delete log holder for id
         -firestore
        -redux
   */

    console.log('[ Delete_icon - handleDeleteTopicals ] id ', id);

    let topicalSectionsArrayToBeDeleted = allTopicalSections.filter(chit => chit.category === id);

    console.log('[ DeleteMany_icon topicalSectionsArrayToBeDeleted   ***] topicalSectionsArrayToBeDeleted ', topicalSectionsArrayToBeDeleted);

    let deleteTopicalSection = async (sectionId) => await deleteDocumentFromFirestore(uid, 'topicalSections', id)


    if (topicalSectionsArrayToBeDeleted.length > 0) {


      topicalSectionsArrayToBeDeleted.forEach(element => {

        // console.log('[ DeleteMany_icon logItem id to be deleted   ***]  ',  element.id);

        deleteTopicalSection(element.id)

        let topicalSectionToBeDeleted = {
          id: element.id,
          source: 'topicalSections',
        }

        dispatch(deleteTopicalSection(topicalSectionToBeDeleted))

      });

    } // ---end topicalSectionsArrayToBeDeleted

    // 2c. delete logHolder for id

    await deleteDocumentFromFirestore(uid, 'topics', id)
    let topicToBeDeleted = {
      id: id,
      source: 'topics',
    }
    navigate(`/main/topicals`)
    dispatch(updateStatusView({ pageType: 'topical', display: 'all', id: '', sectionId: '' }))

    dispatch(deleteTopic(topicToBeDeleted))










  }// --- end handleDeleteTopicals

  //  ############################################################
  //  ############################################################

  // --- main return Delete_icon -----------------------------

  return (
    <>

      {source === 'personalChits' &&
        <>
          <LightTooltip title='Delete' arrow>
            <Icon


              onClick={() => handleClick(id, source)}

            />
          </LightTooltip  >


          <Dialog
            open={open}
            onClose={handleDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">

            </DialogTitle>
            <DialogContent>
              <div>
                {sourceMessage}
              </div>
            </DialogContent>
            <StyledDialogActions>
              <StyledButton

                variant="contained"
                color="primary"
                onClick={() => handleDeleteCategories(id, source)}
              >
                Yes - delete category
              </StyledButton>

              <StyledButton
                form="submit-form"
                variant="contained"
                color="primary"

                onClick={handleDialogClose}
              >
                Cancel
              </StyledButton>

            </StyledDialogActions>
          </Dialog>
        </>
      }
      {source === 'topicals' &&
        <>
          <LightTooltip title='Delete' arrow>
            <Icon


              onClick={() => handleClick(id, source)}

            />
          </LightTooltip  >


          <Dialog
            open={open}
            onClose={handleDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">

            </DialogTitle>
            <DialogContent>
              <div>
                {sourceMessage}
              </div>
            </DialogContent>
            <StyledDialogActions>
              <StyledButton

                variant="contained"
                color="primary"
                onClick={() => handleDeleteTopicals(id, source)}
              >
                Yes - delete topical
              </StyledButton>

              <StyledButton
                form="submit-form"
                variant="contained"
                color="primary"

                onClick={handleDialogClose}
              >
                Cancel
              </StyledButton>

            </StyledDialogActions>
          </Dialog>
        </>
      }

      {source === 'logs' &&
        <>
          <LightTooltip title='Delete' arrow>
            <Icon


              onClick={() => handleClick(id, source)}

            />
          </LightTooltip  >


          <Dialog
            open={open}
            onClose={handleDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">

            </DialogTitle>
            <DialogContent>
              <div>
                {sourceMessage}
              </div>
            </DialogContent>
            <StyledDialogActions>
              <StyledButton

                variant="contained"
                color="primary"
                onClick={() => handleDeleteLogs(id, source)}
              >
                Delete Logs Only
              </StyledButton>
              <StyledButton

                variant="contained"
                color="primary"
                onClick={() => handleDeleteParty(id, source)}

              >
                Delete Person or Group
              </StyledButton>
              <StyledButton
                form="submit-form"
                variant="contained"
                color="primary"

                onClick={handleDialogClose}
              >
                Cancel
              </StyledButton>

            </StyledDialogActions>
          </Dialog>
        </>
      }



      {source === 'twoPartyChits' &&
        <>
          <LightTooltip title='Delete' arrow>
            <Icon


              onClick={() => handleClick(id, source)}

            />
          </LightTooltip  >


          <Dialog
            open={open}
            onClose={handleDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">

            </DialogTitle>
            <DialogContent>
              <div>
                {sourceMessage}
              </div>
            </DialogContent>
            <StyledDialogActions>
              <StyledButton

                variant="contained"
                color="primary"
                onClick={() => handleDeleteAllChits(id, source)}
              >
                Delete Chits Only
              </StyledButton>
              <StyledButton

                variant="contained"
                color="primary"
                onClick={() => handleDeleteParty(id, source)}

              >
                Delete Person or Group
              </StyledButton>
              <StyledButton
                form="submit-form"
                variant="contained"
                color="primary"

                onClick={handleDialogClose}
              >
                Cancel
              </StyledButton>

            </StyledDialogActions>
          </Dialog>
        </>
      }




    </>
  )
}


// ----------------------------

const StyledDialogActions = styled(DialogActions)({
  flexDirection: 'row',
  display: 'flex',
  justifyContent: 'space-around',


  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'space-around',
    height: '10rem'

  },


})

const Icon = styled(DeleteIcon)({
  backgroundColor: 'white',
  borderRadius: '5px',
  fontSize: '.95rem',
  color: chitOrange,
  margin: '0 .5rem .3rem .5rem',
  cursor: 'pointer',



  '&:hover': {
    color: mediumLightGrey
    // backgroundColor: mediumLightGrey
  },


  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',
    // backgroundColor: 'red'
  },
})

const StyledButton = styled(Button)({
  backgroundColor: 'white',
  border: '1px solid #E6E7E8',
  color: chitBurgandyDull,
  margin: '0 1.5rem 0 6px',
  textTransform: 'none',
  height: '1.5rem',

  fontSize: '.8rem',
  '&:hover': {
    backgroundColor: lightGrey
  }

})


const LightTooltip = withStyles({
  tooltip: {
    color: "grey",
    backgroundColor: "white",
    boxShadow: '2px 3px 3px black',
    border: '1px solid grey',
  }
})(Tooltip);
