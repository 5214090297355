/* function TwoPartyLedger (props) -------------------
 
  children: ./TwoPartyLedgerRow
  parent: ./TwoPartyMain
------------------------------------*/

import React, { useEffect, useState } from 'react'
import { useSelector} from 'react-redux'
import { useParams } from 'react-router-dom'


import TwoPartyLedgerRow from './TwoPartyLedgerRow';
import ReceivedChitLedgerRow from './ReceivedChitLedgerRow'
import { selectAllTwoPartyChits } from '../../../app/redux/twoPartyChitRedux/twoPartyChitsSlice';

import { selectReceivedChits } from '../../../app/redux/receivedRedux/receivedChitsSlice';

import { sortChitsByDate,  twoPartyChitFilter  } from '../../../app/helpers/chitHelpers';




 
import { styled, createTheme} from "@mui/material/styles"
// import {withStyles} from '@mui/styles'
const theme = createTheme(); // allows use of mui theme in styled component

// ============================

export default function TwoPartyLedger(props) {

  let match = useParams()
  let id  


  match.id ? id = match.id: id = 'allChits'

  let allTwoPartyChitsArray = useSelector(selectAllTwoPartyChits) //immutable
  let allReceivedChitsArray = useSelector(selectReceivedChits) //immutable



  let twoPartyObject
  let twoPartyChitsObjectArray = []
  
  allTwoPartyChitsArray.map((twoPartyChit, index)=> {

    twoPartyObject = {
      collection: 'twoPartyChits',
      chitId: twoPartyChit.id,
      otherPartyId: twoPartyChit.otherPartyId,
      chitDate: twoPartyChit.chitDate,
      data: twoPartyChit
    }

    twoPartyChitsObjectArray.push(twoPartyObject)


    return twoPartyChitsObjectArray
  })

  
  let receivedObject
  let receivedChitsObjectArray = []
  
  allReceivedChitsArray.map((receivedChit, index)=> {

    receivedObject = {
      collection: 'receivedChits',
      chitId: receivedChit.id,
      otherPartyId: receivedChit.senderId,
      chitDate: receivedChit.chitDate,
      data: receivedChit
    }

    receivedChitsObjectArray.push(receivedObject)


    return receivedChitsObjectArray
  })

  let allChitsObjectArray = [ ...twoPartyChitsObjectArray , ...receivedChitsObjectArray]

  let mutableAllChitsObjectsArray = [...allChitsObjectArray]
  let filteredChits = twoPartyChitFilter(mutableAllChitsObjectsArray, id)
  let sortedAllChitsObjects = sortChitsByDate(filteredChits)

  


 

  // console.log('[ TWO PARTY LEDGER ] what is the id', id);



  const ledgerRows = () =>
 
    sortedAllChitsObjects.map((row, index) => {
      let displayedRow

      let twoPartyRow = (row)=>{

        // console.log('[ TWO PARTY LEDGER ] TwoParty row.chitId ', row.chitId)
        return(
          <TwoPartyLedgerRow
          id={row.chitId}
          key={row.chitId}
          data={row.data}

        />
        )

      }

      if (row.collection === 'twoPartyChits') {
        displayedRow = twoPartyRow(row)
      }


      let receivedRow = (row)=>{

        // console.log('[ TWO PARTY LEDGER ] ---row Received row --- ', row);
        // console.log('[ TWO PARTY LEDGER ] ---row Received row.otherPartyId --- ', row.chitId)


        return(
          <ReceivedChitLedgerRow
          id={row.chitId}
          key={row.chitId}
          data={row.data}

        />
        )

      }

      if (row.collection === 'receivedChits') {

        displayedRow = receivedRow(row)
      }

      // console.log('[ TWO PARTY LEDGER ] ---displayedRow --- ', displayedRow)
      return displayedRow
    }
  ) //end map

  return (
    <Wrapper>
      {ledgerRows()}  
    </Wrapper>
  );
}

// -------------------------------------




const Wrapper= styled('div')({

  // backgroundColor: 'green' ,
  
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: 'calc(100% - 16px)' ,
  //   height: '100%',
  
  
  //   [theme.breakpoints.down('sm')] : {
  //     // width: '100%'
  //   },
  
  })
  
  

 