/*---- File - filename.jsx 
   What file does

   View Logic in LogForm read me ...
           src\readMe\LogForm_info.md


   Contains children: 
       input components
       src\forms\formComponents\ChronicleSelectMui.jsx
   parent: New 
*/


import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'


import { chitBlueDull, darkGrey, lightGrey, chitBurgandyDull, veryLightGrey, chitBurgandy } from '../styles/colors'

import {

  checkIfWordExists,
  cleanOptions,
  optionDescendSorter,
  isArrayDifferent,
  doesArrayIncludeItem,
  doesArrayOfObjectsIncludeItem,
  descendSorter, stripWhiteSpace

} from '../app/helpers/commonHelpers'



// --- Firebase imports ---------
import cuid from 'cuid'  // #### for sample site only ####
import {
  addDocumentToFirestore,
  getDocumentFromFirestore
} from '../app/firebase/fireStore.utils';

// --- React-hook-form imports ---------

import { FormProvider, useForm, Controller } from "react-hook-form";

import { yupResolver } from '@hookform/resolvers/yup';
import { string, object } from 'yup';
import * as Yup from 'yup';

// --- Redux slices imports ---------------------------------

import { selectStatus, changeLoadingStatus, updateStatusView } from '../app/redux/statusRedux/statusSlice'

import { closeModal } from '../app/redux/statusRedux/statusSlice'

import { selectTopics, addTopicToStore } from '../app/redux/topicalRedux/topicsSlice'
// --- Form component imports ---------

import { ChronicleInput } from './formComponents/ChronicleInput'
import { ChronicleSelectMui } from './formComponents/ChronicleSelectMui'

import { ChronicleRadio } from './formComponents/ChronicleRadio'


// --- MUI imports ---------

import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { styled, createTheme } from '@mui/material/styles'


const theme = createTheme(); // allows use of mui theme in styled component


// ==============================================================
// ==== MAIN FUNCTION ===========================================

function NewTopicForm(props) {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const status = useSelector(selectStatus)
  const allTopics = useSelector(selectTopics)
  const uid = status.auth.loginId
  //--- Dialog box functions ----

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    reset()
  };
  // --- form Schema tests   ------------------------------

  // --- does newTopic already exist in topic collection

  const doesTopicExist = (inputValue) => {

    // to compare input value to existing values - clean both
    // so comparing "apples" to "apples"

    let topicsArray = []
    allTopics.map((topic, index) => {

      let cleanTopic = stripWhiteSpace(topic.topic).toLowerCase()

      topicsArray.push(cleanTopic)

      return topicsArray

    }) //end map

    let cleanInputValue = stripWhiteSpace(inputValue).toLowerCase()

    let topicExists = doesArrayIncludeItem(cleanInputValue, topicsArray)
    // returns true if exists ... schema test requires false to proceed
    // so return the opposite of person exists
    return topicExists


  }// end doeTopicExist

  const formSchema = object({

    topic: string().required('Your journal needs some content')


  })// -- end formSchema --------------


  // ----create default paramters if note exists ---------------------

  let defaultValues


  // ##### Sample only  ###########
  // logSectionId === 'new' ? sectionId = cuid()  : sectionId =  logSectionId  


  defaultValues = {

    topic: '',



  };


  // --- close / cancel form 
  const cancelNewForm = () => {

    dispatch(closeModal())
    navigate(`/main/topicals`)
  }




  // ===========  FORM  ==================================================

  const methods = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(formSchema)
  });
  const { handleSubmit, reset, control, setValue, onChange, watch, ref, formState: { errors } } = methods;

  const submitForm = async (data) => {

    const { topic } = data



    console.log('[topic Form ] data : ', data)

    /* --- check to see if user added topic alreacy exists or not
          if yes - open dialog box and message
          if no - proceed to try catch
    */
    let topicExists = doesTopicExist(topic)

    if (topicExists) { handleClickOpen() }
    if (!topicExists) {


      try {


        const newTopicId = await addDocumentToFirestore(uid, 'topics', data)

        console.log('[ NewTopicForm ] newTopicId  is : ', newTopicId);

        const newTopicData = await getDocumentFromFirestore(uid, 'topics', newTopicId)

        let topicData = newTopicData.data()
        let cleanedTopicData = stripWhiteSpace(topicData.topic)
        let newTopicObject = {
          id: newTopicId,

          topic: cleanedTopicData

        }

        console.log('[ NewTopicForm ] newTopicObject  is : ', newTopicObject);

        dispatch(addTopicToStore(newTopicObject))

        dispatch(updateStatusView({ id: newTopicId, pageType: 'topical', displayType: 'all' }))

        dispatch(closeModal())
        navigate(`/main/topicals/${newTopicId}`)

      } catch (error) {
        alert(error)
      }



    }

  }//end if topic exists


  // ------ main return ---------------------
  return (
    <Wrapper>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">

        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            The topic already exists.  Use existent topic or create a different topic name.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <StyledButton

            variant="contained"
            color="primary"

            onClick={() => handleClose()}
          >
            Got it
          </StyledButton>



        </DialogActions>
      </Dialog>

      <HeaderWrapper> Add new topc </HeaderWrapper>

      <FormProvider {...methods}>
        <FormWrapper id="submit-form" onSubmit={handleSubmit(submitForm)} >

          <MainWrapper>

            <FormComponentWrapper>
              <ComponentName>
                Create a new topic for your topicals
              </ComponentName>



              <ChronicleInput
                name={"topic"}
                control={control}
                label={"topic"}
                defaultValue={''}
                placeholder=' Add new topic'


              />
            </FormComponentWrapper>


          </MainWrapper>
          {/* ------Submit ---------- -------------------------- */}
          <ButtonWrapper>


            <StyledButton

              variant="contained"
              color="primary"
              style={{ textTransform: 'none' }}
              onClick={() => cancelNewForm()}
            >
              Cancel
            </StyledButton>

            <StyledButton
              type="submit"
              variant="contained"
              color="primary"
              style={{ textTransform: 'none' }}
            >
              Submit
            </StyledButton>
          </ButtonWrapper>
        </FormWrapper>

      </FormProvider>

    </Wrapper>

  )// end main return



} //--- end main function

export default NewTopicForm


// ==== Styles ===========================================


const Wrapper = styled(Paper)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  // zIndex: '95',

  width: '100%',
  height: '100%',

  marginTop: '2rem',
  backgroundColor: veryLightGrey,

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',
    // backgroundColor: 'red'
  },

})


const FormWrapper = styled('form')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '98%',
  height: '100%',
  margin: '5px 0',
  padding: '4px',
  backgroundColor: 'white',



  [theme.breakpoints.down('sm')]: {
    width: '100%',


  },

})

const MainWrapper = styled('div')({

  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '98%',
  height: '100%',
  marginBottom: '6px',
  paddingBottom: '6px',

  // backgroundColor: 'green',


  [theme.breakpoints.down('sm')]: {
    // width: '100%'
  },


})

// ##############################################

const FormComponentWrapper = styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '90%',
  // margin: '.25rem',

  // backgroundColor: 'yellow',

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})


const ComponentName = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  color: darkGrey,
  fontSize: '.9rem',


  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})



const ErrorMessage = styled('div')({
  fontSize: '.8rem',
  color: 'red',

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})
// #######################################


//  --- Buttons -----------
const ButtonWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '60%',
  margin: '.75rem',


  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})



const StyledButton = styled(Button)({
  backgroundColor: 'white',
  border: '1px solid #E6E7E8',
  color: chitBurgandyDull,
  margin: '0 8px',
  width: '5rem',
  height: '1.5rem',
  fontSize: '.8rem',
  '&:hover': {
    backgroundColor: lightGrey
  }

})


const HeaderWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  margin: '.75rem',
  fontSize: '.8rem',
  color: chitBurgandy,



  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

// -----------------------------------






