/* MainPage_NavButtons_s.jsx
  Large Navigation Buttons to Sample Site apps
       

    parent: src/pages/public/Sample.jsx

*/


import React from 'react'

import { useSelector } from 'react-redux'
import { NavLink, useParams } from 'react-router-dom'


import { highlightGrey } from '../../../styles/colors'

import { selectStatus } from '../../../app/redux/statusRedux/statusSlice'


import Button from '@mui/material/Button'


import { styled, createTheme } from "@mui/material/styles"
const theme = createTheme(); // allows use of mui theme in styled component

function MainPage_bigLinks(props) {

  return (

    <Wrapper>


      <StyledLink to="/main/twoPartyChits" >
        <NavButton> Two Party <br /> Chits </NavButton>
      </StyledLink>

      <StyledLink to="/main/logs" >
        <NavButton> Logs </NavButton>
      </StyledLink>

      <StyledLink to="/main/topicals" >
        <NavButton> Topicals</NavButton>
      </StyledLink>

      <StyledLink to="/main/personalChits" >
        <NavButton> Personal   <br /> Chits </NavButton>
      </StyledLink>


    </Wrapper>


  )

}

export default MainPage_bigLinks

// -------------------------

// -----------------------------------------------------------------

const NavButton = styled(Button)({



  display: 'flex',
  textTransform: 'none',
  fontFamily: 'sans-serif',

  color: 'white',
  border: '1px solid white',
  borderRadius: '10px',
  padding: '.5rem .8rem',

  fontSize: '.9rem',

  width: '7.5rem',
  height: '4.5rem',
  margin: '0 1rem',
  '&:hover': {

    textDecoration: 'none',
    border: '1px solid #A8BEED',
    color: '#A8BEED'
  },



  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    fontSize: '.7rem',
    height: '2.5rem',
    width: '6rem',
    margin: '0 4px',
    padding: '2px 4px'

  },



})


const StyledLink = styled(NavLink)({

  textDecoration: 'none',

})

const Wrapper = styled('div')({

  display: 'flex',
  width: '100%',
  justifyContent: 'space-around',

  [theme.breakpoints.down('xs')]: {




  }


})
