/* Features_nav.jsx
   - navigation to Features slideshows   

   contains child slideshow components: 
      SpotlightSlides
      ChitSlides
      etc. etc

    parent: Features - src/pages/public/Features

*/


import React from "react"


import { NavLink, } from 'react-router-dom'
import { useParams } from 'react-router-dom'

import { connect } from 'react-redux'

import { backgroundBlue, veryLightGrey } from '../../../styles/colors'


import Button from '@mui/material/Button'

import { styled, createTheme } from "@mui/material/styles"
const theme = createTheme(); // allows use of mui theme in styled component





// ================================================

function FeatureNav(props) {
  let match = useParams()
  // let {page} = props

  // let location = useLocation()
  // let page = getPage(location)
  // console.log('[Features Nav ] - page location : ', page)
  let view
  let featureId = match.id




  if (!featureId) { view = 'chits' } else { view = match.id }
  console.log('[Features Nav page] I  is:  ', view)

  return (



    <Container>

      {!view &&
        <StyledLink to="/features/overview" >
          <NavButtonDisabled disabled
            id='overview'
          // onClick = {handlePageChange}

          >Overview </NavButtonDisabled>

        </StyledLink>

      }

      <StyledLink to="/features/overview" >

        {view !== 'overview' &&
          <NavButton
            id='overview'
          // onClick = {handlePageChange}

          >Overview </NavButton>
        }

        {view === 'overview' &&
          <NavButtonDisabled disabled
            id='overview'
          // onClick = {handlePageChange}

          >Overview </NavButtonDisabled>
        }


      </StyledLink>

      <StyledLink to="/features/twoParty" >

        {view !== 'twoParty' &&
          <NavButton
            id='twoParty'
          // onClick = {handlePageChange}

          >2 Party Chits </NavButton>
        }

        {view === 'twoParty' &&
          <NavButtonDisabled disabled
            id='twoParty'
          // onClick = {handlePageChange}

          >2 Party Chits </NavButtonDisabled>
        }


      </StyledLink>



      <StyledLink to="/features/logs" >

        {view !== 'logs' &&
          <NavButton
            id='logs'
          // onClick = {handlePageChange}

          >Logs and Topicals</NavButton>
        }

        {view === 'logs' &&
          <NavButtonDisabled disabled
            id='logs'
          // onClick = {handlePageChange}

          >Logs and Topicals </NavButtonDisabled>
        }


      </StyledLink>




      <StyledLink to="/features/personal" >

        {view !== 'personal' &&
          <NavButton
            id='personal'
          // onClick = {handlePageChange}

          >Personal Chits </NavButton>
        }

        {view === 'personal' &&
          <NavButtonDisabled disabled
            id='personal'
          // onClick = {handlePageChange}

          >Personal Chits </NavButtonDisabled>
        }


      </StyledLink>


    </Container>
  );
}

export default FeatureNav
// -----------------------------------------------------------------
const Container = styled('div')({

  // position: 'absolute',
  display: 'flex',

  justifyContent: 'space-around',

  width: '70%',






  [theme.breakpoints.down('sm')]: {
    width: '100%',
  }


})

const NavButton = styled(Button)({

  // border: 'none',
  color: 'white',
  textTransform: 'none',
  fontWeight: '400',

  padding: '0 10px 0 10px',

  '& :hover': {
    backgroundColor: veryLightGrey,
    backgroundShadow: 'none'

  },
  [theme.breakpoints.down('sm')]: {
    fontWeight: 'bold',
    fontSize: '.65rem',
    padding: '1px',

  },



  [theme.breakpoints.down('xs')]: {
    fontWeight: 'bold',
    fontSize: '.75rem',
    padding: '1px',

  },

})

const NavButtonDisabled = styled(Button)({



  textTransform: 'none',

  // backgroundColor: shadowBlue,

  borderRadius: '0',
  color: 'white',
  fontWeight: '400',
  marginRight: '8px',
  padding: '0 10px',

  '&:disabled ': {
    color: '#8293B8'
  },

  '& :hover': {
    backgroundColor: 'veryLightGrey',
  },

  [theme.breakpoints.down('sm')]: {
    fontWeight: 'bold',
    fontSize: '.85rem',
    padding: '1px',

  },
  [theme.breakpoints.down('xs')]: {
    fontWeight: 'Bold',
    fontSize: '.75rem',
    padding: '1px',

  }

})



const StyledLink = styled(NavLink)({

  textDecoration: 'none',

})

