//rootReducer.js

/*

DATA BASE - Data Loading --------------

Initial Store Data from database gets loaded with
homeActions (features/main/home/homeActions.js) in
componentDidMount.  The Home.jsx file is the destination
after successful login.


*/

import { combineReducers } from 'redux';



import statusReducer from '../statusRedux/statusSlice'
import peopleReducer from '../peopleRedux/peopleSlice'
import categoriesReducer from '../categoryRedux/categorySlice'
import groupsReducer from '../groupRedux/groupSlice'
import logHoldersReducer from '../logHolderRedux/logHolderSlice'
import logsReducer from '../logRedux/logsSlice'
import firstsReducer from '../firstRedux/firstSlice'
import notesReducer from '../noteRedux/notesSlice'
import personalChitsReducer from '../personalChitRedux/personalChitSlice'
import topicsReducer from '../topicalRedux/topicsSlice'
import topicalSectionsReducer from '../topicalRedux/topicalSectionsSlice'
import twoPartyChitsReducer from '../twoPartyChitRedux/twoPartyChitsSlice'
import sharedChitsReducer from '../sharedRedux/sharedChitsSlice';
import receivedChitsReducer from '../receivedRedux/receivedChitsSlice';
import sampleReducer from './sampleRootReducer'

const rootReducer = combineReducers({

  // async: asyncReducer,
  sample: sampleReducer,
    status:  statusReducer,
    people: peopleReducer,
    categories: categoriesReducer,
    groups: groupsReducer,
    logHolders: logHoldersReducer,
    logs: logsReducer,
    notes: notesReducer,
    personalChits: personalChitsReducer,
    topicalSections: topicalSectionsReducer,
    topics: topicsReducer,
    twoPartyChits: twoPartyChitsReducer,
    firstContacts: firstsReducer,
    sharedChits: sharedChitsReducer,
    receivedChits: receivedChitsReducer
 
    

  

})

export default rootReducer