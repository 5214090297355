/* Landing_slides - pitch deck
   wrapper for individual slides:
      - each slide consists of 2 parts : narration and picture
      - each slide is formatted to swap from left to right and back again

   children slides:
    {Picture1, Narration1} from '../slides/homeSlides/Description_one'
    import {Picture2, Narration2} from '../slides/homeSlides/Description_two'
    etc. etc
   parent: /src/public/Landing
*/

import React from 'react'
import { Link } from 'react-router-dom'

import { openModal } from '../../../app/redux/statusRedux/statusSlice'
import { useDispatch } from 'react-redux'

import { Picture1, Narration1 } from '../slides/homeSlides/Description_one'
import { Picture2, Narration2 } from '../slides/homeSlides/Description_two'
import { Picture3, Narration3 } from '../slides/homeSlides/Description_three'
import { Picture4, Narration4 } from '../slides/homeSlides/Description_four'
import { Picture5, Narration5 } from '../slides/homeSlides/Description_five'
import { Picture6, Narration6 } from '../slides/homeSlides/Description_six'
import { Picture7, Narration7 } from '../slides/homeSlides/Description_seven'
import { Picture8, Narration8 } from '../slides/homeSlides/Description_eight'
import { Picture9, Narration9 } from '../slides/homeSlides/Description_nine'
import { Picture10, Narration10 } from '../slides/homeSlides/Description_ten'
import { Picture11, Narration11 } from '../slides/homeSlides/Description_eleven'
import { JoinButtonSlides, FeatureButtonSlides } from './Nav_buttons'


import { styled, createTheme } from "@mui/material/styles"
import { backgroundBlue, lightGrey } from '../../../styles/colors'
const theme = createTheme(); // allows use of mui theme in styled component




// ==========================================================


const Landing_slides = (props) => {
  const dispatch = useDispatch()

  const handlePageChange = (evt) => {
    evt.persist()
    console.log('I BE CLICKED in TP :: ', evt.currentTarget.id)
    props.setPage(evt.currentTarget.id) //XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX

  }

  const openJoinForm = () => {

    //  define which Form to open in Modal by passing
    //  dbCollection to Modal depending on pageView in browser URL 
    // ------------------------------------------------------------
    console.log('[ Header Landing ] openJoinForm clicked ');
    // props.openModal(dbCollection, id)

    dispatch(openModal(
      {
        modalParams: {
          modalType: 'form',
          dbCollection: '',
          id: '',
          login: 'join'
        }
      }

    ))

  }// end openJoinForm

  // let page = props.page.public.page

  return (
    <SlideContent>


      <SlideWrapper>
        <Narration>
          <Narration7 />
        </Narration>

        <Picture>
          <Picture7 />
        </Picture>

      </SlideWrapper>

      <SlideWrapper>
        <Narration>
          <Narration10 />
        </Narration>

        <Picture>
          <Picture10 />
        </Picture>

      </SlideWrapper>


      <SlideWrapper>

        <Narration>
          <Narration2 />
        </Narration>
        <Picture>
          <Picture2 />
        </Picture>


      </SlideWrapper>


      <SlideWrapper>

        <Narration>
          <Narration3 />
        </Narration>

        <Picture>
          <Picture3 />
        </Picture>


      </SlideWrapper>


      <SlideWrapper>

        <Narration>
          <Narration4 />
        </Narration>

        <Picture>
          <Picture4 />
        </Picture>


      </SlideWrapper>

      <SlideWrapper>

        <Narration>
          <Narration8 />
        </Narration>

        <Picture>
          <Picture8 />
        </Picture>


      </SlideWrapper>





      <SlideWrapper>

        <Narration>
          <Narration5 />
        </Narration>

        <Picture>
          <Picture5 />
        </Picture>


      </SlideWrapper>


      <SlideWrapper>

        <Narration>
          <Narration9 />
        </Narration>

        <Picture>
          <Picture9 />
        </Picture>



      </SlideWrapper>

      <SlideWrapper>

        <Narration>
          <Narration6 />
        </Narration>

        <Picture>
          <Picture6 />
        </Picture>



      </SlideWrapper>

      <SlideWrapper>

        <Narration>
          <Narration11 />
        </Narration>

        <Picture>
          <Picture11 />
        </Picture>



      </SlideWrapper>

      <ButtonWrapper>

        <StyledMessage to="/features" id='features' onClick={handlePageChange}>
          Use the links above to view featues or a sample
        </StyledMessage>

        <JoinWrapper onClick={() => openJoinForm()}> Join </JoinWrapper>
        {/* <JoinButtonSlides onClick = {()=>openJoinForm()}/> */}


      </ButtonWrapper>

    </SlideContent>


  )
}

export default Landing_slides


// -----------------------------------------------------------------



const SlideContent = styled('div')({

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

  width: '100vw',
  minHeight: '15rem',
  // marginBottom: '5rem',
  background: 'white',
  borderBottom: '1px solid #E6E7E8',



  [theme.breakpoints.down('sm')]: {

  },

  [theme.breakpoints.down('sm')]: {

  },

  [theme.breakpoints.down('xs')]: {


  }


})

const SlideWrapper = styled('div')({

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  width: '100vw',
  minHeight: '15rem',
  // marginBottom: '5rem',
  padding: '2.5rem 0',
  borderBottom: '1px solid #E6E7E8',
  maxWidth: '65rem',

  // overflow: 'hidden',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    padding: '.5rem 0',
    width: '96vw',

  }
  // backgroundColor: 'green'

})

const SlideWrapperReverse = styled('div')({

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'row-reverse',

  width: '100vw',
  // height: '60vh',
  minHeight: '15rem',
  overflow: 'hidden',
  backgroundColor: 'white',
  padding: '2.5rem 0',
  borderBottom: '1px solid #E6E7E8',
  maxWidth: '65rem',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',

    padding: '1.5rem 0',
    width: '96vw',

  }
})

const Narration = styled('div')({

  display: 'flex',
  flexDirection: 'column',
  jutifyContent: 'center',
  alignItems: 'center',
  width: '50%',
  height: '100%',

  [theme.breakpoints.down('sm')]: {
    width: '100%',
    marginBottom: '1.5rem',
  }
})

const Picture = styled('div')({

  display: 'flex',
  flexDirection: 'column',
  jutifyContent: 'center',
  alignItems: 'center',
  width: '50%',

  // height: '100%',
  [theme.breakpoints.down('sm')]: {
    width: '80%',
    jutifyContent: 'flex-start',
  }

})

const ButtonWrapper = styled('div')({

  display: 'flex',
  flexDirection: 'column',
  jutifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  marginBottom: '2rem',


  // height: '100%',
  [theme.breakpoints.down('xs')]: {

  }

})


const StyledLink = styled(Link)({

  textDecoration: 'none',

})

const StyledMessage = styled('div')({

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '.9rem',
  color: backgroundBlue,



  [theme.breakpoints.down('sm')]: {

  },

  [theme.breakpoints.down('sm')]: {

  },

  [theme.breakpoints.down('xs')]: {


  }


})

const JoinWrapper = styled('div')({

  backgroundColor: 'white',
  display: 'flex',
  justifyContent: 'center',
  textTransform: 'none',

  border: '1px solid #3B30CC',
  borderRadius: '5px',
  color: 'black',
  fontWeight: 'normal',
  fontSize: '.8rem',
  padding: '0 .5rem',
  width: '6rem',
  margin: '1rem',

  '&:hover': {
    backgroundColor: lightGrey,
    textDecoration: 'none',
    border: '1px solid #A8BEED',


  }

})