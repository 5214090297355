
/*---- File - sampleSite/samComponents/Modal_s
    Modal displayed by redux/statusRedux/sam_statusSlice

    Contains children: 
       All forms
       All detail pages
 

      parent- Main - src/pages/public/sampleSite/Main

      * modal is opened when status/modal/modalDisplayed in Store
        is set to true

        modal receives all props required to be passed 
        status/modal/modalParams ... 

        modalType = form or info which is used to determine which
        group of pages are to be displayed.
        

*/



import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { lightGrey, chitBlueDull, } from '../../styles/colors'




import { selectStatus, closeModal } from '../../app/redux/statusRedux/statusSlice'

import LoginForm from '../../forms/LoginForm'
import JoinForm from '../../forms/JoinForm'
import TwoPartyChitForm from '../../forms/TwoPartyChitForm'
import PersonForm from '../../forms/PersonForm'
import NewTopicForm from '../../forms/NewTopicForm'
import TopicForm from '../../forms/TopicForm'
import NoteForm from '../../forms/NoteForm'
import SharedChitForm from '../../forms/SharedChitForm'
import PersonalChitForm from '../../forms/PersonalChitForm'
import CategoryForm from '../../forms/CategoryForm'
import SharedChitDisplay from '../../pages/private/sharedChits/SharedChit'

import ReceivedChitForm from '../../forms/ReceivedChitForm'
import LogForm from '../../forms/LogForm'
import ConvertForm from '../../forms/ConvertForm'


import TwoPartyChitsHelp from '../../pages/private/help/Help_twoPartyChits'
import PersonalChitsHelp from '../../pages/private/help/Help_personalChits'
import LogsHelp from '../../pages/private/help/Help_logs'
import TopicalsHelp from '../../pages/private/help/Help_topicals'


// import MainHelp from '../samHelp/Main_help_s'
// import HelpSpotlights from '../../sampleSite/samHelp/Help_spotlights_s'
// import HelpPersonalChits from '../../sampleSite/samHelp/Help_personalChits_s'
// import TwoPartyChits from '../../sampleSite/samHelp/Help_twoPartyChits_s'
// import HelpChronicles from '../../sampleSite/samHelp/Help_chronicles_s'

// import SpotlightForm from '../samForms/SpotlightForm_s'
// import NoteForm from '../samForms/NoteForm_s'
// import PersonalChitForm from '../samForms/PersonalChitForm_s'
// import TwoPartyChitForm from '../samForms/twoPartyChitForm_s'
// import SharedChitForm from '../samForms/sharedChitForm_s'

// import WOChitDetail from '../../features/main/views/chitView/WO_ChitDetail'
// import PEChitDetail from '../../features/main/views/chitView/PE_ChitDetail'
// import TPChitDetail from '../../features/main/views/chitView/ChitDetail'
// import NewModalDisplay from '../../features/main/new/NewModalDisplay'

// import ChitDetailTP from '../../pages/private/ChitDetail_TP'
// import ChitDetailPE from '../../pages/private/ChitDetail_PE'
// import ChitDetailWO from '../../pages/private/ChitDetail_WO'

// import WOChitDetail from '../../features/main/views/chitView/WO_ChitDetail'
// import PEChitDetail from '../../features/main/views/chitView/PE_ChitDetail'
// import TPChitDetail from '../../features/main/views/chitView/ChitDetail'
// import NewModalDisplay from '../../features/main/new/NewModalDisplay'

import Paper from '@mui/material/Paper';
import Modal from '@mui/material/Modal';
import ClearIcon from '@mui/icons-material/Clear';

import {
  clearTwoPartyViewData,
  clearSharedChitFormData
} from '../../app/redux/statusRedux/statusSlice'

import { styled, createTheme } from "@mui/material/styles"
const theme = createTheme(); // allows use of mui theme in styled component



//======================================
const ModalComponent = (props) => {


  const dispatch = useDispatch()
  const status = useSelector(selectStatus)
  const { modalDisplayed, modalParams } = status.modal
  const { modalType, dbCollection, id, login, modalPage } = modalParams


  console.log('[ ModalComponent ] modalParams ', modalParams);
  //  console.log('[ ModalComponent ] modalType ', modalType);
  //  console.log('[ ModalComponent ] dbCollection ', dbCollection);
  //  console.log('[ ModalComponent ] id ', id);



  const [open, setOpen] = useState(modalDisplayed);


  useEffect(() => {
    setOpen(modalDisplayed)
  }, [modalDisplayed])

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    if (modalType === 'form' && dbCollection === 'twoPartyChits') {
      dispatch(clearTwoPartyViewData())

    }

    if (modalType === 'form' && dbCollection === 'sharedChits') {
      dispatch(clearSharedChitFormData())

    }
    dispatch(closeModal())

  };



  // console.log('[ modal ] modalParams ', modalParams);
  // --- IMPORTANT -----------------------------------
  // id is the id for spotlight (ie. spot_1) or note (ie. note_1), etc...
  //         but for "help", id is a help page (ie. 'spotlights' or 'notes')

  // let {modalType, dbCollection, modalDisplayed, id} = useSelector(selectStatus).modal
  // let {modalParams } = useSelector(selectStatus).modal
  // let {modalType, dbCollection, modalPage} = modalParams


  // console.log('[ modal ] modalParams ', modalParams);

  return (


    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >


      <ModalDisplay>
        <Close onClick={() => handleClose()} >
          X

        </Close>

        {login === 'login' &&
          <LoginForm />
        }

        {login === 'join' &&
          <JoinForm />
        }

        {modalType === 'form' && dbCollection === 'twoPartyChits' &&
          <TwoPartyChitForm modalParams={modalParams} />

        }

        {modalType === 'form' && dbCollection === 'people' &&
          <PersonForm modalParams={modalParams} />

        }

        {modalType === 'form' && dbCollection === 'personalChits' &&
          <PersonalChitForm modalParams={modalParams} />

        }


        {modalType === 'form' && dbCollection === 'categories' &&
          <CategoryForm modalParams={modalParams} />

        }

        {modalType === 'form' && dbCollection === 'topicals' &&
          <NewTopicForm />

        }


        {modalType === 'form' && dbCollection === 'notes' &&
          <NoteForm modalParams={modalParams} />

        }

        {modalType === 'form' && dbCollection === 'topics' &&
          <TopicForm modalParams={modalParams} />

        }


        {modalType === 'form' && dbCollection === 'logs' &&
          <LogForm modalParams={modalParams} />

        }

        {modalType === 'form' && dbCollection === 'sharedChits' &&
          <SharedChitForm modalParams={modalParams} />

        }

        {modalType === 'form' && dbCollection === 'receivedChit' &&
          <ReceivedChitForm modalParams={modalParams} />

        }


        {modalType === 'info' && modalPage === 'twoPartyChits' &&
          <TwoPartyChitsHelp modalParams={modalParams} />

        }

        {modalType === 'info' && modalPage === 'personalChits' &&
          <PersonalChitsHelp modalParams={modalParams} />

        }

        {modalType === 'info' && modalPage === 'topicals' &&
          <TopicalsHelp modalParams={modalParams} />

        }

        {modalType === 'info' && modalPage === 'logs' &&
          <LogsHelp modalParams={modalParams} />

        }

        {modalType === 'display' && dbCollection === 'sharedChits' &&
          <SharedChitDisplay modalParams={modalParams} />

        }

        {modalType === 'form' && dbCollection === 'firstContacts' &&
          <ConvertForm modalParams={modalParams} />

        }


      </ModalDisplay>



    </Modal>





  )// end main return---
} // --- end func Modal ---------------------





export default ModalComponent

// -----------------------------------------------------------------






const ModalDisplay = styled('div')({


  position: 'absolute',
  top: '5%',
  left: '50%',
  margin: 'auto',
  padding: '1rem 1rem 1rem 1rem',
  backgroundColor: 'white',
  width: '30rem',
  minHeight: '20rem',
  maxHeight: '90vh',
  borderRadius: '5px',
  overflow: 'auto',
  transform: 'translate(-50%)',
  zIndex: '166',

  [theme.breakpoints.down('sm')]: {
    //  left: '.5rem',

    top: '2.6rem',
    height: '93.5vh',
    width: '98vw'
  },


})

const Close = styled(ClearIcon)({

  display: 'block',
  width: '3rem',
  height: '1.5rem',
  position: 'absolute',
  textAlign: 'center',

  top: '5px',
  right: '5px',
  color: chitBlueDull,
  cursor: 'pointer',
  borderRadius: '5px',

  '&:hover': {
    backgroundColor: lightGrey
  }

})

