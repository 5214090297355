import React  from 'react'
import{ chitRedDark, chitBurgandy} from '../../../../styles/colors'

import CopperKindness from '../../../../images/chitCoins/copper_kindness.svg'
import GoldStandard from '../../../../images/chitCoins/gold_standard.svg'
import SilverPromise from '../../../../images/chitCoins/silver_promise.svg'


import { styled, createTheme  } from "@mui/material/styles"
const theme = createTheme(); // allows use of mui theme in styled component




export const Picture1= () => {
  return (
    <PictureStyle>
      <CoinEnds>   
           
      <CoinStyle src= {CopperKindness}   alt="Copper kindness" /> 
        <div>Good will <br/> Chit</div>
      </CoinEnds>
      <CoinMiddle>  
      <CoinStyle src= {GoldStandard}   alt="Silver Standard" />  
          <div>2 Party <br/> Chit</div>
       
      </CoinMiddle>
      <CoinEnds>  
        <CoinStyle src= {SilverPromise}   alt="Silver Promise" />  
        <div>Promise <br/> Chit</div>
      </CoinEnds>
  </PictureStyle>
  )
}


export const Narration1= () => {
  return (
    <NarWrapper>
<NarHeader> 
              What are chits ?
              </NarHeader>
             
                <NarDetail>
                  <div>Chits are tokens that you can give or receive</div>
                  <div> For things you do for someone else ... or
                    things that someone else did to help you
                  </div>
                 
                  <div>Also for promises  
                  made by you to someone else ... or 
                    made by someone to you
                  </div>
           
                </NarDetail>

            </NarWrapper>
  )
}


// -----------------------------------------------------------------

const NarWrapper= styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  width: '70%',
  
  [theme.breakpoints.down('sm')] : {
    width: '100%',
 
  }

})
const NarHeader= styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  // padding: '0 auto 0 auto',
  color: chitBurgandy,
  // marginTop: '2rem',
  fontSize: '1rem',
  fontWeight: 'bold',
  // // letterSpacing: '.1rem',
  textAlign: 'center'  ,
  width: '80%',
  

  [theme.breakpoints.down('sm')] : {
    justifyContent: 'center',
    fontSize: '.9rem',
    width: '100%',
  },

  [theme.breakpoints.down('xs')] : {
    // width: '65%',
    // fontSize: '1.2rem',
  }

})

 

const NarDetail= styled('div')({


    display: 'flex',
    flexDirection: 'column',
    // padding: '0 auto 0 auto',
    color: 'black',
     marginTop: '1rem',
    fontSize: '.8rem',
    textAlign: 'left'  ,
    width: '80%',

    '& div': {
      padding: '0',
      margin: '3px'
    },

    '& span': {
      fontWeight:'bold'
    },

    '& em': {
      color: chitRedDark,
      fontStyle: 'normal',
      fontWeight: 'bold'
    },
  [theme.breakpoints.down('sm')] : {
    
    textAlign: 'left'  ,
    width: '80%',
  },
  [theme.breakpoints.down('xs')] : {
    width: '80%',
    
  }

})

// ----Slide 1 ------------------



const PictureStyle= styled('div')({

  display: 'flex',
  jutifyContent: 'center',
  alignItems: 'center',
  
  width: '60%',
  height: '15rem',



  [theme.breakpoints.down('sm')] : {
    width: '80%',
    jutifyContent: 'flex-start',
    height: '7rem',
    marginBottom: '1rem'
  }
})

const CoinEnds= styled('div')({

  display: 'flex',
  jutifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'flex-end',
  flexDirection: 'column',
  width: '9rem',
  height: '9rem',
  fontSize: '.9rem',
  textAlign: 'center',
  color: 'black',

  [theme.breakpoints.down('sm')] : {
     
    height: '7rem',
    width: '7rem',
    fontSize: '.75rem',
  }

})

const CoinMiddle= styled('div')({

  display: 'flex',
  jutifyContent: 'flex-start',
  alignItems: 'center',
  flexDirection: 'column',
  
  width: '9rem',
  height: '9rem',
  fontSize: '.9rem',
  textAlign: 'center',
  color: 'black',

  [theme.breakpoints.down('sm')] : {
     
    height: '7rem',
    width: '7rem',
    fontSize: '.75rem',
  }

})

const CoinStyle= styled('img')({
  display: 'block',
  margin: 'auto',
  width: '5rem',
  height: '5rem',

  [theme.breakpoints.down('sm')] : {
     
     width: '4rem',
  height: '4rem',
  }

})