/*---- File - TwoPartyChitForm_preview.jsx 
   Takes the data from Redux store status.view.forms.twoPartyChits

   Formats the store data for display
   Adds or updates Firestore database
      - people collection person
      - groups collection group
      - twoPartyChit collection - chit

   
*/


import React, {useState, useEffect}  from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useNavigate, useParams } from 'react-router-dom'
import { chitBlueDull, chitBurgandy, chitBurgandyDull, chitOrange, darkGrey, lightGrey, mediumGrey, veryLightGrey } from '../styles/colors'

import { 
        stripWhiteSpace ,
        checkIfWordExists, 
        cleanOptions ,
        optionDescendSorter,
        isArrayDifferent,
        doesArrayIncludeItem,
        doesArrayOfObjectsIncludeItem

      } from '../app/helpers/commonHelpers'

      import { ISOtoTraditional, UTCtoDate, UTCtoISO } from '../app/helpers/dateHelper'
      import { chooseTwoPartyChitColor, chooseTwoPartyChitCoin } from '../app/helpers/chitHelpers'

 
      

// --- Firebase imports ---------
import cuid from 'cuid'  // #### for sample site only ####
import { 
  addDocumentToFirestore,
  getDocumentFromFirestore,
  updateDocumentFromFirestore
} from '../app/firebase/fireStore.utils';

// addTwoPartyChitToStore


// --- React-hook-form imports ---------

import { FormProvider, useForm, Controller } from "react-hook-form";
 
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object  } from 'yup';
import * as Yup from 'yup';

// --- Redux slices imports ---------------------------------
import { changeLoadingStatus } from '../app/redux/statusRedux/statusSlice'
import {
 
  closeNewLogForm, 
  selectStatus, 
  updateTwoPartyViewData,
  clearTwoPartyViewData,
  changeTwoPartyFormPage


} from '../app/redux/statusRedux/statusSlice'
 
// --- imports to create options for selectors

import { 
  addTwoPartyChitToStore ,
  updateEditedTwoPartyChit

} from '../app/redux/twoPartyChitRedux/twoPartyChitsSlice'

import { selectPeople, addPersonToStore, addPersonHolder } from '../app/redux/peopleRedux/peopleSlice'
import { selectGroups, addGroupToStore, addGroupHolder } from '../app/redux/groupRedux/groupSlice'
 

// --- Form component imports ---------
import { StyledDatePicker } from '../forms/formComponents/StyledDatePicker';



import { closeModal } from '../app/redux/statusRedux/statusSlice'
// --- MUI imports ---------
import ShareIcon from '@mui/icons-material/Share';
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ForwardIcon from '@mui/icons-material/Forward';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
 
import { styled, createTheme} from '@mui/material/styles'


const theme = createTheme(); // allows use of mui theme in styled component



// ==============================================================
// ==== MAIN FUNCTION ===========================================

export default function TwoPartyChitForm_preview(props) {

  const dispatch = useDispatch()

  // -- state for "completed message"
  const [popupMessage, setPopupMessage] = useState(false)


  // --- get all people, all groups and status from Redux store

  const allPeople = useSelector(selectPeople)
  const allGroups = useSelector(selectGroups)
  const status = useSelector(selectStatus)
  const uid = status.auth.loginId

  // --- retrieve all form inputs from Redux store ---

  let statusFormParameters = status.view.forms.twoPartyChitForm

/* ---------------------------------------------------------------
  Format all the retrieved data from Redux store
  for display in the Preview

*/

  const {twoPartyChitId, otherPartyCollection, chitType, chitValue, chitBurden,chitDate, person, group, deedPerformedBy, workRelated, description, keyWordArray, otherPartyId} = statusFormParameters

 
  // --- is the other party a person or a group - from Who

    let otherPartyName 
    otherPartyCollection === 'person'? otherPartyName = person: otherPartyName = group

  // --- style the chit date for preview display ---

    let styledChitDate = ISOtoTraditional(chitDate)

 
  // --- style the "other party" for preview display ---

    let whoPerformedDeed
    deedPerformedBy === 'otherParty' ? whoPerformedDeed = otherPartyName: whoPerformedDeed = 'me'

  //--- style deedPerformedBy

  
  // --- set up IOU arrow color and message  for preview display ---
  let  youOwe 
  if(chitType === 'standard'){
   deedPerformedBy === 'you' ? youOwe = false: youOwe = true
  }
  
  if(chitType === 'promise'){
  deedPerformedBy === 'you' ? youOwe = false: youOwe = true
  }

  
  // --- format workRelated ---
  let styledWorkRelated
  workRelated === 'yes' ? styledWorkRelated = 'yes': styledWorkRelated = 'no'


  // --- format deepd performed by---
// console.log('[ PREVIEW ] deedPerformedBy ', deedPerformedBy);
    let styledDeedDoneBy
    deedPerformedBy === 'me' ? styledDeedDoneBy = 'you': styledDeedDoneBy = otherPartyName

 // --- calculate the total chit value ("burden" + "value") for preview

    let totalChitValue
    if (!chitBurden && !chitValue) {
      totalChitValue = 0
    } else {
      totalChitValue = parseInt(chitValue) + parseInt(chitBurden)
    }

  // --- determine chit color from the total chit value for preview ---

    let chitColor = chooseTwoPartyChitColor(chitType, chitValue, chitBurden)
  //  --- define which coin is displayed

  let coinAddress = chooseTwoPartyChitCoin(chitType, chitColor)
 
  const pathToCoinImages = '../../'
  const coinDisplayed = pathToCoinImages + coinAddress

  let chitDescription
  chitType ==='kindness'? chitDescription = 'good will': chitDescription = chitType


  // --- form Schema tests   ------------------------------

  // --- does newGroup already exist in people collection

  
  const formSchema = object({
  

    });

 
  
   let initialChitDate = new Date('2021-03-14T17:03:40.000Z')
    
 

// ----create default paramters if note exists ---------------------
  let defaultValues  = {
 

  };

  // --- close / cancel form 
  const cancelNewForm = () => {
    dispatch(clearTwoPartyViewData())
    dispatch(closeModal())
    // navigate(`/sample/twoPartyChits/allChits`)

  }

  const goBack = () => {
    dispatch(changeTwoPartyFormPage('chit'))


  }//--end goBack()

  function closeForm(){
    dispatch(clearTwoPartyViewData())
    dispatch(closeModal())
  }

 
// ===========  FORM  ==================================================

  const methods = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(formSchema)
  });
  const { handleSubmit, reset, control, setValue, onChange, watch, ref , formState: { errors } } = methods;

  const submitForm = async (data, newGroupObject, newPersonObject, newGroup, newPerson) => {

  


  // -- set up current date to ISO format ---

    let today = Date.now()
    let ISOCurrentDate = UTCtoISO(today)



    try {

      
      /*
        1. set loading status
        2. determine if group or person is new or exists
          a. if new add to Firestore - return id
          b. if exists get the existent id from Redux store

        3. create the data object to deploy to Firestore
        4. determine if chit id was passed from modal
          a.  if yes - update existent chit in Firestore
                       update redux store chit
          b.  if no - add new chit to Firestore - return id
                      dispatch new chit to Redux with returned id
          
        5. reset status.view.forms.twoPartyChit
        
        6. close loading status 

        7. post completed message

      */
 
    dispatch(changeLoadingStatus(true))

    
    /*  --- chit object -----

        chit data to Firestore does not include an id
        because Firestore creates it and uses it as the key
        for the object

        chit data to Redux store needs the chit id
        as part of the data object

    */

    let dbCollection , newChitOtherPartyId, newPersonObject

    const chitObject = (dataStructure, otherPartyId, data, newChitId)=> {
      let dataObject

      if (dataStructure === 'firestoreData') {
        let chitDate = data.chitDate
        
   


        let chitOwner
        data.deedPerformedBy === 'otherParty'? chitOwner = otherPartyId: chitOwner = 'person_0'

        dataObject = {
          chitType: chitType,
          chitBurden: chitBurden,
          chitValue: chitValue,
          chitColor: chitColor,
          dateCreated: ISOCurrentDate,
          chitDate: chitDate,
          deedPerformedBy: chitOwner,
          otherPartyCollection: dbCollection,
          otherPartyId: otherPartyId,
          workRelated: workRelated,
          description: description,
          sharedChitId: ''
        }

      }

      
      if (dataStructure === 'reduxData') {
        let chitDate = new Date(data.chitDate)
        let formattedChitDate= JSON.stringify(data.chitDate)
        




        console.log('[ Two Party Preview ] data.chitDate ',  chitDate);
         
        console.log('[ Two Party Preview ] formattedChitDate ', formattedChitDate);

        let chitOwner
        data.deedPerformedBy === 'otherParty'? chitOwner = otherPartyId: chitOwner = 'person_0'

        dataObject = {
          id: newChitId,
          chitType: chitType,
          chitBurden: chitBurden,
          chitValue: chitValue,
          chitColor: chitColor,
          dateCreated: ISOCurrentDate,
          chitDate: data.chitDate,
          deedPerformedBy: chitOwner,
          otherPartyCollection: dbCollection,
          otherPartyId: otherPartyId,
          workRelated: workRelated,
          description: description,
          sharedChitId: ''
        }

      }
      return dataObject

    } // --- end chitObject ---------------------


      // --- 2. determine if person is new or exists

      // --- Person ------- 

      if (otherPartyCollection === 'person') {

        dbCollection = 'people'

        /*
          - clean person string
          - check if person exists in people collection
            - if yes  - newPersonObject exists - get id of person
  
            - if no 
              - if no  - newPersonObject === undefined       
                
        */

        // --- clean person string

        let cleanedPerson = stripWhiteSpace(person)

        // --- see if person exists already in people array
        newPersonObject = allPeople.find((searchPerson) => searchPerson.name.toLowerCase() === cleanedPerson.toLowerCase())

        // --- get id of existent person if exists set otherPartyId

        if (newPersonObject) {
          newChitOtherPartyId = newPersonObject.id
          // await joy(newChitOtherPartyId)


          // ##################################################
          // ########222222222222222222 #############################
          // ##################################################



          // --- New Chit - add new chit to Firestore and Redux store

          if (!twoPartyChitId) {

            let chitFirestoreData = chitObject('firestoreData', newChitOtherPartyId, statusFormParameters)

            let newChitId = await addDocumentToFirestore(uid, 'twoPartyChits', chitFirestoreData)

            let chitReduxStoreData = chitObject('reduxData', newChitOtherPartyId, statusFormParameters, newChitId)

            dispatch(addTwoPartyChitToStore(chitReduxStoreData))
          }

          // --- Edited Chit - update chit in Firestore and Redux store

          if (twoPartyChitId) {
            let chitFirestoreData = chitObject('firestoreData', newChitOtherPartyId, statusFormParameters)

            let chitReduxStoreData = chitObject('reduxData', newChitOtherPartyId, statusFormParameters, twoPartyChitId)

            console.log('[PREVIEW ----------------- chitReduxStoreData', chitReduxStoreData)

            await updateDocumentFromFirestore(uid, 'twoPartyChits', twoPartyChitId, chitFirestoreData)

            dispatch(updateEditedTwoPartyChit(chitReduxStoreData))
          }



// ##################################################
// ##################################################
// ##################################################










        } // end if newPersonObject (person already exists)

// --- person is new
        if(!newPersonObject) {

          let personObject = {
            type: 'person',
            name: cleanedPerson
          }

          let newChitOtherPartyId = await addDocumentToFirestore(uid, 'people', personObject)
 
          dispatch(addPersonToStore({
            id: newChitOtherPartyId,
            name: person,
  
          }
          )) // end dispatach addPersonToStore

// ##################################################
// ##################################################
// ##################################################
        if(!twoPartyChitId) {


          let chitFirestoreData = chitObject(  'firestoreData', newChitOtherPartyId, statusFormParameters)

          let newChitId = await addDocumentToFirestore(uid, 'twoPartyChits', chitFirestoreData)
 
          console.log('[ TwoPartyPrivew  ] newChitId ', newChitId);


          let chitReduxStoreData = chitObject('reduxData', newChitOtherPartyId, statusFormParameters, newChitId)

          dispatch(addTwoPartyChitToStore(chitReduxStoreData))
        }

          if(twoPartyChitId) {
            let chitFirestoreData = chitObject(  'firestoreData', newChitOtherPartyId, statusFormParameters)
        
            let chitReduxStoreData = chitObject('reduxData', newChitOtherPartyId, statusFormParameters, twoPartyChitId)
        
            console.log('[PREVIEW ----------------- chitReduxStoreData', chitReduxStoreData)
        
            await updateDocumentFromFirestore(uid, 'twoPartyChits', twoPartyChitId, chitFirestoreData)
        
            dispatch( updateEditedTwoPartyChit(chitReduxStoreData))
          }

// ##################################################
// ##################################################
// ##################################################

        } // end if otherPartyCollection = people



        }





    // --- Group -------

    if (otherPartyCollection === 'group') {

      dbCollection = 'groups'

      /*
        - clean person string
        - check if person exists in people collection
          - if yes  - newPersonObject exists - get id of person
    
          - if no 
             - if no  - newPersonObject === undefined       
              
      */

      // --- clean group string

      let cleanedGroup = stripWhiteSpace(group)

      // --- see if person exists already in people array
      newGroupObject = allGroups.find((searchGroup) => searchGroup.name.toLowerCase() === cleanedGroup.toLowerCase())

      // --- get id of existent person if exists set otherPartyId

      if (newGroupObject) {
        newChitOtherPartyId = newGroupObject.id

// ##################################################
// ##################################################
// ##################################################

if(!twoPartyChitId) {
let chitFirestoreData = chitObject(  'firestoreData', newChitOtherPartyId, statusFormParameters)

let newChitId = await addDocumentToFirestore(uid, 'twoPartyChits', chitFirestoreData)

console.log('[ TwoPartyPrivew  ] newChitId ', newChitId);


let chitReduxStoreData = chitObject('reduxData', newChitOtherPartyId, statusFormParameters, newChitId)

dispatch(addTwoPartyChitToStore(chitReduxStoreData))

}

if(twoPartyChitId) {
  let chitFirestoreData = chitObject(  'firestoreData', newChitOtherPartyId, statusFormParameters)

  let chitReduxStoreData = chitObject('reduxData', newChitOtherPartyId, statusFormParameters, twoPartyChitId)

  console.log('[PREVIEW ----------------- chitReduxStoreData', chitReduxStoreData)

  await updateDocumentFromFirestore(uid, 'twoPartyChits', twoPartyChitId, chitFirestoreData)

  dispatch( updateEditedTwoPartyChit(chitReduxStoreData))
}

// ##################################################
// ##################################################
// ##################################################




      } // end if newPersonObject (person already exists)

      if(!newGroupObject) {

        let groupObject = {
          type: 'group',
          name: cleanedGroup
        }

        let newChitOtherPartyId = await addDocumentToFirestore(uid, 'groups', groupObject)

         
        dispatch(addGroupToStore({
          id: newChitOtherPartyId,
          name: group,

        }
        )) // end dispatach addGroupToStore
// ##################################################
// ##################################################
// ##################################################

if(!twoPartyChitId) {
let chitFirestoreData = chitObject(  'firestoreData', newChitOtherPartyId, statusFormParameters)

let newChitId = await addDocumentToFirestore(uid, 'twoPartyChits', chitFirestoreData)

console.log('[ TwoPartyPrivew  ] newChitId ', newChitId);


let chitReduxStoreData = chitObject('reduxData', newChitOtherPartyId, statusFormParameters, newChitId)

dispatch(addTwoPartyChitToStore(chitReduxStoreData))
}


if(twoPartyChitId) {
  let chitFirestoreData = chitObject(  'firestoreData', newChitOtherPartyId, statusFormParameters)

  let chitReduxStoreData = chitObject('reduxData', newChitOtherPartyId, statusFormParameters, twoPartyChitId)

  console.log('[PREVIEW ----------------- chitReduxStoreData', chitReduxStoreData)

  await updateDocumentFromFirestore(uid, 'twoPartyChits', twoPartyChitId, chitFirestoreData)

  dispatch( updateEditedTwoPartyChit(chitReduxStoreData))
}

// ##################################################
// ##################################################
// ##################################################
      }

      console.log('[ TwoPartyChitForm Preview in Group ] newChitOtherPartyId ', newChitOtherPartyId);

    } // end if otherPartyCollection = group




 
  
  















    //#########################################################


    dispatch(changeLoadingStatus(false))
  
    setPopupMessage(true)








    } catch (error) {
      alert(error.message)
      dispatch(changeLoadingStatus(false))

      reset(defaultValues)

    } // end catch
  } // end async submit

  




  // ==== return - Form JSX  ======================================

  return (
    <>

  


      {!popupMessage && 
    <Wrapper>
  <HeaderTitle> Chit Preview</HeaderTitle>
        <FormProvider {...methods}>
          <FormWrapper id="submit-form" onSubmit={handleSubmit(submitForm)} >

            <MainWrapper>

                <HeaderWrapper>

                  <DateContainer>{styledChitDate}</DateContainer>
                  {chitType !== 'kindness' && <> 
                  <NameContainer>
                    <NameWrapper>
                      {otherPartyName}
                    </NameWrapper>
             
             
                    {youOwe &&
                      <>
                        <IOU />
                        <div> Owes this chit to you</div>
                      </>}
                    {!youOwe &&
                      <>
                        <YouOweMe />
                        <div> You owe this chit</div>
                      </>}
    
                  </NameContainer>
 </>
                  
}
              </HeaderWrapper>



<AccordionTopWrapper >

<ChitContainer>
<StyledImage src={coinDisplayed} alt="coin" />
<ChitTypeWrapper> {chitDescription} </ChitTypeWrapper>
  
</ChitContainer>

<RightContainer>

  <TopRightContainer>
    <div> Description:</div>

  </TopRightContainer>
  <QuillDiv dangerouslySetInnerHTML={{__html: description}} />


</RightContainer>   



</AccordionTopWrapper>




<AccordionBottomWrapper>

            <DetailWrapper>
              <DetailTitle>Deed performed by:</DetailTitle>
              <Detail>{styledDeedDoneBy}</Detail>
              </DetailWrapper>

              <DetailWrapper>
              <DetailTitle>Chit created  by:</DetailTitle>
              <Detail> you </Detail>
              </DetailWrapper>

  





            <DetailWrapper>
                <DetailTitle>Work related:</DetailTitle>
                <Detail> {styledWorkRelated} </Detail>
                </DetailWrapper>

                <DetailWrapper>
                <DetailTitle>Chit value</DetailTitle>
                <Detail> {totalChitValue} </Detail>
                </DetailWrapper>





    


        </AccordionBottomWrapper>

            </MainWrapper>


            {/* ------Submit ---------- -------------------------- */}
            <SubmitContainer>
            <StyledButton

              variant="contained"
              color="primary"
              style={{ 
                textTransform: 'none' ,

            }}
              onClick={() => goBack()}
 
            >
              Back
            </StyledButton>

              <StyledButton

                variant="contained"
                color="primary"
                style={{
                  textTransform: 'none',

                }}
                onClick={() => cancelNewForm()}

              >
                Cancel
              </StyledButton>

              <StyledSubmitButton
                type="submit"
                variant="contained"
                color="primary"
                style={{ textTransform: 'none' }}
              >
                Submit Form
              </StyledSubmitButton>

            </SubmitContainer>
          </FormWrapper>

        </FormProvider>
      
    </Wrapper>
 }

{/* ------Success of submission - show popup message ------ */}

{popupMessage && 
    
  <MessageWrapper> 
    <SuccessMessage> 
      
       Your new two party chit has been created

       <ShareMessage>
        <div>
        To share this chit,
        click on share icon in ledger view.
        </div>
         <StyledShareIcon/>
       </ShareMessage>

    </SuccessMessage>

    <CloseFormButton onClick = {()=> closeForm()}> 
    Got it - thanks
    
    </CloseFormButton>
  </MessageWrapper>
  }

  </>
  );
}

// ==== Styles ===========================================


const Wrapper = styled(Paper)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  // zIndex: '95',

  width: '100%',
  // height: '400px',
   
// border: '2px solid #F285B5',
 

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',
    // backgroundColor: 'red'
  },

})



const MessageWrapper = styled(Paper)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  // zIndex: '95',
  backgroundColor: 'none',
  width: '100%',
  height: '15rem',
  overflow: 'auto',


  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',
    // backgroundColor: 'red'
  },

})

const SuccessMessage = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  // zIndex: '95',
  // backgroundColor: 'red',
 
  marginBottom: '.5rem',
  color: chitBurgandy,


  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',
    // backgroundColor: 'red'
  },

})

const CloseFormButton = styled(Button)({
  backgroundColor: 'white',
  border: '1px solid #E6E7E8',
  color: chitBurgandyDull,
  margin: '0 8px',
  width: '8rem',
  height: '1.5rem',
  fontSize: '.8rem',
  '&:hover' :{
    backgroundColor: lightGrey
  }

})


const FormWrapper = styled('form')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '98%',
  height: '100%',
  margin: '5px 0',
  padding: '4px',
  backgroundColor: 'white',



  [theme.breakpoints.down('sm')]: {
    width: '100%',
  

  },

})

const MainWrapper= styled('div')({

  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '98%',
  height: '100%',
  marginBottom: '6px',
  paddingBottom: '6px',
 
  // backgroundColor: 'green',


  [theme.breakpoints.down('sm')] : {
    // width: '100%'
  },


})

















// #######################################


//  --- Buttons -----------
const SubmitContainer= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '95%',
  margin: '.75rem',

  
  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})



const StyledButton= styled(Button)({
  backgroundColor: 'white',
  border: '1px solid #E6E7E8',
  color: chitBurgandyDull,
  margin: '0 1.5rem 0 6px',
  width: '5rem',
  height: '1.5rem',
  fontSize: '.8rem',
  '&:hover' :{
    backgroundColor: lightGrey
  }

})

const StyledSubmitButton= styled(Button)({
  backgroundColor: 'white',
  border: '1px solid #E6E7E8',
  color: chitBurgandyDull,
  margin: '0 8px',
  width: '8rem',
  height: '1.5rem',
  fontSize: '.8rem',
  '&:hover' :{
    backgroundColor: lightGrey
  }

})


 
// -----------------------------------


const DetailTitle = styled('div')({

 
  display: 'flex',

  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '40%',
  fontStyle: 'italic',
  fontSize: '.75rem',
  color: mediumGrey,
  // padding: '0 0 0 1rem',
  //  borderLeft: '1px solid #E6E7E8'

  //   [theme.breakpoints.down('sm')] : {
  //     // width: '100%'
  //   },

})

const Detail = styled('div')({

 
  display: 'flex',

  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '50%',
 
  // padding: '0 0 0 1rem',
  //  borderLeft: '1px solid #E6E7E8'

  //   [theme.breakpoints.down('sm')] : {
  //     // width: '100%'
  //   },

})

const LeftDetailWrapper = styled('div')({

 
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '3.5rem',
 
  

 
  //   [theme.breakpoints.down('sm')] : {
  //     // width: '100%'
  //   },

})


const MiddleDetailWrapper = styled('div')({
 
  display: 'flex',

  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '40%',
  height: '100%',
   
  paddingTop: '8px',
  // padding: '0 0 0 1rem',
  //  borderLeft: '1px solid #E6E7E8'

  //   [theme.breakpoints.down('sm')] : {
  //     // width: '100%'
  //   },

})

const RightDetailWrapper = styled('div')({

  // backgroundColor: 'pink',
  display: 'flex',

  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '40%',
  height: '100%',
   
  paddingTop: '8px',
  // padding: '0 0 0 1rem',
  //  borderLeft: '1px solid #E6E7E8'

  //   [theme.breakpoints.down('sm')] : {
  //     // width: '100%'
  //   },

})

const DetailWrapper = styled('div')({
 
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',

  width: '100%',



  //   [theme.breakpoints.down('sm')] : {
  //     // width: '100%'
  //   },

})

const HeaderWrapper = styled('div')({


  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontSize: '.7rem',
  borderBottom: '1px solid #E6E7E8',
  width: '100%',
  padding: '0 0 4px 0',
  margin: '0 0 4px 0',


  //   [theme.breakpoints.down('sm')] : {
  //     // width: '100%'
  //   },

})


const NameContainer = styled('div')({


  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '.7rem',
  // width: '60%',
 
 


  //   [theme.breakpoints.down('sm')] : {
  //     // width: '100%'
  //   },

})



const NameWrapper = styled('div')({


  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  fontSize: '.75rem',
  color: chitBurgandy,



  //   [theme.breakpoints.down('sm')] : {
  //     // width: '100%'
  //   },

})


const YouOweMe = styled(ForwardIcon)({
  transform: 'rotate(180deg)',
  fontSize: '1rem',
  color: 'red',
  margin: '0 8px'
          
})

const IOU = styled(ForwardIcon)({
  transform: 'rotate(0deg)',
  fontSize: '1rem',
  color: 'green',
  margin: '0 8px'
          
})

const DateContainer = styled('div')({


  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '30%',
color: mediumGrey,

  //   [theme.breakpoints.down('sm')] : {
  //     // width: '100%'
  //   },

})

const AccordionWrapper = styled('div')({
 

  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  fontSize: '.75rem',

 
  width: '100%',




  //   [theme.breakpoints.down('sm')] : {
  //     // width: '100%'
  //   },

})


const AccordionTopWrapper = styled('div')({

  // backgroundColor: 'yellow',
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  fontSize: '.85rem',
  width: '96%',
  minHeight: '30px',




  //   [theme.breakpoints.down('sm')] : {
  //     // width: '100%'
  //   },

})

const ChitContainer = styled('div')({

  // backgroundColor: 'red',
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '3.5rem',
  height: '3.5rem'
  


  //   [theme.breakpoints.down('sm')] : {
  //     // width: '100%'
  //   },

})

const ChitTypeWrapper = styled('div')({

  // backgroundColor: 'red',
  fontSize: '.65rem',
  color: mediumGrey
  


  //   [theme.breakpoints.down('sm')] : {
  //     // width: '100%'
  //   },

})

const StyledImage = styled('img')({

 
  display: 'flex',
 
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  
  width: '2.5rem',
  height: '2.5rem',
   
 




  //   [theme.breakpoints.down('sm')] : {
  //     // width: '100%'
  //   },

})


const RightContainer = styled('div')({

  // backgroundColor: 'pink',
  display: 'flex',

  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: 'calc(90% - 1rem)',
  padding: '0 0 0 1rem',
   borderLeft: '1px solid #E6E7E8'

  //   [theme.breakpoints.down('sm')] : {
  //     // width: '100%'
  //   },

})

const TopRightContainer = styled('div')({

  // backgroundColor: 'orange',
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  width: '100%',
  fontStyle: 'italic',
  fontSize: '.8rem',
  // fontWeight: 'bold',
  marginBottom: '4px',
  color: mediumGrey,



  //   [theme.breakpoints.down('sm')] : {
  //     // width: '100%'
  //   },


  
})


const QuillDiv= styled('div')({

width: '100%',
  fontSize: '14px',
  
   '& p' :{
    lineHeight: '.7'
   },
  
  '& .ql-size-small':{
    fontSize: '12px'
  },
  
  '& .ql-size-large' :{
    fontSize: '18px'
  }
  
  })


  
const AccordionBottomWrapper = styled('div')({
  
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  fontSize: '.85rem',
  width: '96%',
  minHeight: '40px',
  margin: 0, 
  
  borderTop: '1px solid grey'

  //   [theme.breakpoints.down('sm')] : {
  //     // width: '100%'
  //   },

})

const AccordionDetailWrapper = styled('div')({
 
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  fontSize: '.85rem',
  width: '100%',
  minHeight: '40px',
  margin: 0, 
  paddingLeft: '1rem',
  // borderTop: '1px solid grey'

  //   [theme.breakpoints.down('sm')] : {
  //     // width: '100%'
  //   },

})

 
const HeaderTitle = styled('div')({


  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '1.1rem',
  color: chitBurgandy,



  //   [theme.breakpoints.down('sm')] : {
  //     // width: '100%'
  //   },

})

const ShareMessage = styled('div')({


  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  fontSize: '.8rem',
  color: mediumGrey,
  width: '60%',
  margin: '4px',


  //   [theme.breakpoints.down('sm')] : {
  //     // width: '100%'
  //   },

})

const StyledShareIcon = styled(ShareIcon)({


  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '.9rem',
  color: chitOrange,



  //   [theme.breakpoints.down('sm')] : {
  //     // width: '100%'
  //   },

})
