/* Help_Topicals

*/




import React from 'react'
import { backgroundBlue, mediumGrey } from '../../../styles/colors';



import CheckIcon from '@mui/icons-material/Check';
import AddCircleIcon from '@mui/icons-material/AddCircle'


import { styled, createTheme } from "@mui/material/styles"
const theme = createTheme(); // allows use of mui theme in styled component

// -----------------------------------------------------------------


function TopicalsHelp() {
    return (
        <MainWrapper>

            <LinkWrapper>
                <LinkComponent>
                    <a href="#intro">Introduction  </a>


                    <a href="#sections">Topical Sections</a>
                    <a href="#notes">Topical Notes</a>

                    <a href="#createNew">Creating new topics </a>
                    <a href="#newSection">Creating new sections</a>
                    <a href="#timeLocks">Time locks</a>

                </LinkComponent>
            </LinkWrapper>
            <ContentWrapper>

                <HeaderWrapper name="intro"> Topicals  Introduction</HeaderWrapper>


                <div><em> Topicals  Are</em> chronicles.. <br />
                    Use topicals create a “story” about a topic. Keep track of thoughts, developments, discoveries or interactions with respect to a topic... like starting a project or business... or maybe losing weight.
                </div>
                <div>
                    Topicals are also a great place to collect and find thoughts and notes about a topic... like collecting ideas for a book or podcast.
                </div>
                <div> There are two types of topicals - sections and notes.</div>






                {/* -------- Topical Sections ----------------------------- */}

                <HeaderWrapper name="sections"> Sections</HeaderWrapper>


                <div>
                    Topicals are purposely organized into sections that are ordered by the time they are created.  So in essence, they create a time line for your topic.
                </div>

                <div>
                    Sections can be edited, deleted and time locked.
                </div>


                {/* -------- Notes----------------------------- */}

                <HeaderWrapper name="notes"> Notes</HeaderWrapper>


                <div>
                    Topicals can also include notes.  Use notes to jot
                    down quick thoughts or paste links to web sites about your topic.
                </div>

                <div>
                    Sections can be edited, deleted and time locked.
                </div>
                <div>
                    For random notes or sections that you don't want to create a topic for yet... you can post them in the "Junkyard"
                </div>





                {/* -------- createNew -------------------------------- */}

                <HeaderWrapper name="createNew"> New Topics, Sections and Notes </HeaderWrapper>

                <div> Use the + icon in the sidebar panel to create a new topic</div>
                <IconWrapper>
                    <AddCircleIconWrapper />
                </IconWrapper>
                <div>
                    To create a new Section or Note for a topic, click on one of  the buttons in the topic's header.
                </div>

                {/* -------- timeLocks -------------------------------- */}

                <HeaderWrapper name="timeLocks"> Time Locks</HeaderWrapper>

                <div>
                    Set a time lock when you wish to establish and insure a time for a section you created.
                    Use them, for instance, when you tell someone about a great idea you had.  This can be valuable in a dispute both legal and personal.


                </div>
                <div>
                    When you set a time lock, the chit can no longer be edited in any way.  Once set, the chit can only be deleted.

                </div>

                <div>
                    Use the lock-clock icon to initiate the time lock.  When you do, the edit icon will disappear.
                </div>

                <div>
                    The time lock is set at the date time you apply it... not the date of the chit.

                </div>




                {/* ----------------- */}
            </ContentWrapper>
        </MainWrapper>

    )
}

export default TopicalsHelp

const MainWrapper = styled('div')({

    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: 'white',
    width: '100%',
    height: '98%',
    marginTop: '2%',
    overflow: 'auto',

    [theme.breakpoints.down('sm')]: {
        // width: '100%'
    },


})

const ContentWrapper = styled('div')({

    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    backgroundColor: 'white',
    width: '85%',
    height: '100%',
    padding: '.75rem',
    overflow: 'auto',
    fontSize: '.8rem',

    '& div': {
        margin: '0 0 .75rem 0'
    },


    [theme.breakpoints.down('sm')]: {
        // width: '100%'
    },


})

const LinkWrapper = styled('div')({

    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',

    width: '100%',
    padding: '.35rem',
    height: '100%',
    fontSize: '.65rem',



    [theme.breakpoints.down('sm')]: {
        // width: '100%'
    },


})

const LinkComponent = styled('div')({

    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    backgroundColor: 'lightgrey',
    width: '30%',
    padding: '.35rem',
    height: '100%',
    fontSize: '.65rem',

    '& a': { color: backgroundBlue },
    '& a:visited': { color: backgroundBlue },

    [theme.breakpoints.down('sm')]: {
        // width: '100%'
    },


})

const HeaderWrapper = styled('a')({
    display: 'flex',
    position: 'relative',
    flexDirection: 'row',
    justifyContent: 'center',

    color: backgroundBlue,
    marginBottom: '.5rem',
    width: '100%',

    [theme.breakpoints.down('sm')]: {
        // width: '100%'
    },


})

// ----Icons -------------------------
const IconWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',


})

const AddCircleIconWrapper = styled(AddCircleIcon)({

    color: 'grey',
    fontSize: '1.7rem',

    '&:hover': {
        backgroundColor: 'lightGrey',
        borderRadius: '50px',
    },

})

const CheckCircleCompleted = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    width: '1.05rem',
    height: '1.05rem',
    border: '1px solid #CFD0D1',
    borderRadius: '200px',

    color: 'white',
    backgroundColor: mediumGrey,





    [theme.breakpoints.down('sm')]: {
        // height: '1.25rem',
        // backgroundColor: 'red'
    },
})

// =======================================================