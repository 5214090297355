/*---- File - SharedChitFormMessage.jsx 
 
  Last page of sharing chit form sequence.
  Displays the "copy" link to put in an email
  so that the recipient can view the chit


parent: ./SharedChitForm.jsx


*/




import React from 'react';

import {useSelector, useDispatch} from 'react-redux'


import {
  selectStatus, 
  clearSharedChitFormData,

} from '../app/redux/statusRedux/statusSlice'

 

import { selectSharedChits } from '../app/redux/sharedRedux/sharedChitsSlice';

import CopySharedChitLink from '../common_components/CopySharedChitLink';
import { closeModal } from '../app/redux/statusRedux/statusSlice'
//--- MUI imports ----

import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import ShareIcon from '@mui/icons-material/Share';

import { styled, createTheme } from "@mui/material/styles"
import { withStyles } from '@mui/styles'
import { chitBurgandy, chitBurgandyDull, lightGrey, mediumGrey, } from '../styles/colors';
const theme = createTheme(); // allows use of mui theme in styled component

// ==================================================

export default function SharedChitFormMessage(props) {

  const dispatch = useDispatch()
  const status = useSelector(selectStatus)
  const allSharedChits = useSelector(selectSharedChits)

  const sharedFormData = status.view.forms.sharedChitForm
  let chitId = sharedFormData.chitId

 

  let sharedChitObject = allSharedChits.find(sharedChit => sharedChit.chitId === chitId)

  let sharedChitLink = sharedChitObject.sharedChitLink


  console.log('[ ========= SharedChitMessage === ] shared chit Link ', sharedChitObject.sharedChitLink);


  const cancelNewForm = () => {
    dispatch(clearSharedChitFormData())
    dispatch(closeModal())
    // navigate(`/sample/twoPartyChits/allChits`)

  }

let chitLinkMessage = sharedChitLink


  return (
    <Wrapper>
      <Header> A shared chit has been created</Header>

      <TopWrapper>
        <Message>
          To share this chit, copy the link below <br />and paste it into an email or text message.
        </Message>

        <Resend>
          <div> {chitLinkMessage}  </div>
          <CopySharedChitLink chitLink={chitLinkMessage} />
        </Resend>
      </TopWrapper>


      <StyledButton

        variant="contained"
        color="primary"
        style={{
          textTransform: 'none',

        }}
        onClick={() => cancelNewForm()}

      >
        Got it
      </StyledButton>

    </Wrapper>
  );
}

// ================================

const Wrapper = styled(Paper)({
 
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
 padding: '1rem 0 1.5rem 0',
 width: '100%',

 

  [theme.breakpoints.down('sm')]: {

 
  }

})

const Header = styled('div')({


  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '1.1rem',
  color: chitBurgandy,

 

  //   [theme.breakpoints.down('sm')] : {
  //     // width: '100%'
  //   },

})

 

 


  
const TopWrapper = styled('div')({
 
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  fontSize: '.7rem',
  color: mediumGrey,
  width: '80%',
  marginTop: '1rem',

 

  [theme.breakpoints.down('sm')]: {

    width: '95%',
 

  }


})

const Message= styled('div')({
 
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '.7rem',
  color: mediumGrey,
  textAlign: 'center',
  // [theme.breakpoints.down('xs')]: {

  //   fontSize: '.95rem',
  //   padding: '0 15% 0 5%',


  // }

})
const Resend = styled('div')({

  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  border: '1px solid grey',
  borderRadius: '5px',
  padding: '2px 5px',
  width: '100%',
  margin: '1rem 0 2rem 0',


  [theme.breakpoints.down('xs')]: {

    fontSize: '.95rem',
    padding: '0 15% 0 5%',


  }


})

const StyledButton= styled(Button)({
  backgroundColor: 'white',
  border: '1px solid #E6E7E8',
  color: chitBurgandyDull,
  margin: '0 8px',
  width: '5rem',
  height: '1.5rem',
  fontSize: '.8rem',
  '&:hover' :{
    backgroundColor: lightGrey
  }

})