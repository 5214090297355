import { createSlice } from '@reduxjs/toolkit'

let InitialStore = []

export const notesSlice = createSlice({
  name: 'notes',
  initialState: InitialStore,

  reducers: {
 

    addAllNotesFromFirestore: (state = [], action) => {
    
      let firestoreData = action.payload
    
      // Clear the state array
      state = [];
    
      // Add the Firestore data to the state array
      state = [...firestoreData];
    
      return state
    },// end addAllNotesFromFirestore

    addNoteStore: (state, action) => {

      let note = action.payload

      state.push(note)
    },

    resetNotes: (state, action) => {
      let initialState = []
      return initialState
    },// end resetNotes


    deleteNote: (state, action) => {

console.log('[ deleteNote in note slice ] action.payload ', action.payload);
      let noteId = action.payload.id

      return state.filter(item => item.id !== noteId)
   
  }, // end deleteNote


  }, //end reducers

}) // end slice notesSlice 


// --- Export actions ---------------------------------------------

export const { 
  
  addAllNotesFromFirestore,
  resetNotes,
  addNoteStore,
  deleteNote
} = notesSlice.actions



// --- Export selectors ------------------------------------------

export const selectNotes = state => state.notes




// --- default Export reducers -----------------------------------

export default notesSlice.reducer