import { createSlice } from '@reduxjs/toolkit'



export const peopleSlice = createSlice({
  name: 'peopleSample',
  initialState: [],

  reducers: {


    addAllPeopleFromFirestore: (state = [], action) => {
    
      let firestoreData = action.payload
    
      // Clear the state array
      state = [];
    
      // Add the Firestore data to the state array
      state = [...firestoreData];
    
      return state
    },// end addAllPeopleFromFirestore



    resetPeople: (state, action) => {
      let initialState = []
      return initialState
    },// end resetPeople



    addPersonToStore: (state, action) => {

      console.log('[ PeopleSlice ] addAllPeopleFromFirestore Real one  ', action.payload);


      const {id, name, personHolder, dbCollection, meta} = action.payload
      let personId = id

      let newPersonHolder, newMeta 
      personHolder ? newPersonHolder  = [{dbCollection: dbCollection, id: personHolder}]: newPersonHolder  = []

      personHolder ? newMeta  = meta: newMeta  = ''

      let personObject = {
        id: personId,
        type: 'person',
        name: name,
        meta: newMeta,
        personHolders: newPersonHolder
      }


      state.push(personObject)
    },// end addPersonToStore

    addPersonHolder: (state, action) => {


      let personId = action.payload.id
      let personHolder = action.payload.personHolder
      let dbCollection = action.payload.dbCollection
      let newPersonHolder = {dbCollection: dbCollection, id: personHolder}

  
  
      let personIndex = state.findIndex(index => index.id === personId)
  
       
      state[personIndex].personHolders.push(newPersonHolder)
     
    }, // end addPeywordHolder

    deletePersonHolder: (state, action) => {
      console.log('[ sam+PeopleSlice ] deletePersonHolder ### ', action.payload);

      let person = action.payload.person
      let personHolder = action.payload.personHolder
   
   
  
      let personIndex = state.findIndex(index => index.name === person)
  
    
  
      state[personIndex].personHolders = state[personIndex].personHolders.filter(item => item.id !== personHolder)
  
    }, // end deletePersonHolder

 

  

  updateEditedPerson: (state, action) => {

    console.log('[ *** updateEditedPerson ] action.payload ', action.payload);

    let personId = action.payload.id
    let newPerson = action.payload.name
    
    

    let categoryIndex = state.findIndex(index => index.id === personId)
    
    state[categoryIndex].name  = newPerson
   


  }, // end updateEditedCategory


  deletePerson: (state, action) => {
console.log('[ peopleSlice ] deletePerson ', deletePerson);

    let personId = action.payload.id

    return state.filter(item => item.id !== personId)

  }, // end deletelogHolder


 

} //----- end reducers

}) // end slice personsSlice 


// --- Export actions ---------------------------------------------

export const { 
  addAllPeopleFromFirestore,
  addPersonToStore, 
  updateEditedPerson,
  addPersonHolder,
  deletePersonHolder,
  resetPeople,
  deletePerson

} = peopleSlice.actions



// --- Export selectors ------------------------------------------

export const selectPeople = state => state.people




// --- default Export reducers -----------------------------------

export default peopleSlice.reducer