/*   ---  ConvertForm

  1. use id to get all the first contact data
  2. ck formName if exists in people collection
     2a. if person exists - message - person exists
     2b. if person Not exist 
       
     3.  add person to people collection
          update Redux people

     4.  add new person to logHolders collection
          update Redux logHolders

     5.  add  data from 1 and new person from 2 to log section collection
           update Redux logSection

     6.  delete first Contact

     7. update statusSlice  - logs
                            - clear firstContacts

     8. navigate to Logs/newLogholder

     9. reseet form
    
     10. close form


*/




import React, {useState, useEffect}  from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useNavigate, useParams } from 'react-router-dom'
 
import { chitBlueDull, chitBurgandy, chitBurgandyDull, darkGrey, lightGrey, mediumGrey, veryLightGrey } from '../styles/colors'


// --- React-hook-form imports ---------

import { FormProvider, useForm, Controller } from "react-hook-form";
 
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object  } from 'yup';
import * as Yup from 'yup';
 
import { stripWhiteSpace } from '../app/helpers/commonHelpers';
import { changeLoadingStatus } from '../app/redux/statusRedux/statusSlice'
import {
  closeLogSectionForm, 
  closeNewLogForm, 
  selectStatus, 
  updateTwoPartyViewData,
  updateStatusView,
  closeModal,
  updateLogView

} from '../app/redux/statusRedux/statusSlice'

import { selectFirsts } from '../app/redux/firstRedux/firstSlice';
import { selectLogHolders, addLogHolderToStore } from '../app/redux/logHolderRedux/logHolderSlice'

import { deleteFirstSection } from '../app/redux/firstRedux/firstSlice';

import { addLogToStore } from '../app/redux/logRedux/logsSlice';
import { selectPeople, addPersonToStore } from '../app/redux/peopleRedux/peopleSlice'

// --- Firebase imports ---------
 
import { 
  addDocumentToFirestore,
  addLogHolderDocumentToFirestore,
  getDocumentFromFirestore,
  deleteDocumentFromFirestore
} from '../app/firebase/fireStore.utils';


import { ChronicleInput } from './formComponents/ChronicleInput'



// --- MUI imports ---------
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
 
 
 

import { styled, createTheme  } from "@mui/material/styles"
import {withStyles} from '@mui/styles'
const theme = createTheme(); // allows use of mui theme in styled component


// ===============================================

export default function ConvertForm(props) {


// console.log('[ConvertForm] - modal params', props.modalParams)


  const {id, modalType, dbCollection} = props.modalParams
  // console.log('[ConvertForm] -  props.modalParams',  props.modalParams)

  const dispatch = useDispatch()
 
  const navigate = useNavigate()

  
  const status = useSelector(selectStatus)
  const allPeople = useSelector(selectPeople)
  const allFirsts = useSelector(selectFirsts)
  const uid = status.auth.loginId

  // 1. get firstContact info for id from allFirstContacts collection

  let firstContactObject = allFirsts.find((firstContact) => firstContact.id === id)
  console.log('[ConvertFormxxxxxxxxxxxxxxxx] - firstContactObject', firstContactObject)

  let fcName = firstContactObject.name
  let fcMeta = firstContactObject.meta
  let fcDetail = firstContactObject.detail
  let fcLastEdit = firstContactObject.lastEdit
  let fcContactDate = firstContactObject.contactDate
  let fcID = firstContactObject.id

  // console.log('[ConvertForm] - params id', id)
  // console.log('[ConvertForm] - fcID', fcID)
  // console.log('[ConvertForm] - fcContactDate', fcContactDate)
  // console.log('[ConvertForm] - fcLastEdit', fcLastEdit)
  // console.log('[ConvertForm] - fcDetail', fcDetail)
  // console.log('[ConvertForm] - fcMeta', fcMeta)
  // console.log('[ConvertForm] - fcName', fcName)
  


  const [popupMessage, setPopupMessage] = useState({state: false, messageType: ''})


  const formSchema = object({

    }) // end formSchema

    function resetForm(){
    
      // navigate to Log here
  
      reset()
      setPopupMessage({state: false})
    }

      // --- close / cancel form 


  // --- close / cancel form 
  const cancelConvertForm = () => {
 
    dispatch(closeModal())
    navigate(`/main/logs`)
  }
    
    const PersonExists = ()=>{

      return(
        <>
          <div> This person already exists</div>
          <ButtonWrapper>
  
            <StyledButton
  
              variant="contained"
              color="primary"
              style={{ textTransform: 'none' }}
              onClick={() => resetForm()}
            >
              Try Again
            </StyledButton>
  
            <StyledButton
  
              variant="contained"
              color="primary"
              style={{ textTransform: 'none' }}
              onClick={() => cancelConvertForm()}
            >
              Cancel
            </StyledButton>
  
         
       
          </ButtonWrapper>
        </>
      )
    }

    let defaultValues
  defaultValues = {
 
    name: fcName,


  };


    const methods = useForm({
      defaultValues: defaultValues,
      resolver: yupResolver(formSchema)
    });
    const { handleSubmit, reset, control, setValue, onChange, watch, ref , formState: { errors } } = methods;
  
  
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
      reset()
    };

    const submitForm = async (data) => {

  console.log('[ convert FORM FORM FORM ] data ',data);

  // 2. Determine if person exists already in people collection

  let cleanedPerson = stripWhiteSpace(data.name).toLowerCase()

  let peopleNamesArray=[]

  allPeople.map((person, index) => {
    peopleNamesArray.push(person.name.toLowerCase())

    return peopleNamesArray
  }
  ) //end map

  let doesPersonExist = peopleNamesArray.includes(cleanedPerson)

  // console.log('[ConvertForm] - peopleNamesArray', peopleNamesArray)
  // console.log('[ConvertForm] - cleanedPerson', cleanedPerson)
  // console.log('[ConvertForm] - doesPersonExist', doesPersonExist)

  if(doesPersonExist){
    setPopupMessage({state: true, messageType: 'exists'})
  }
  
  if(!doesPersonExist) {
      try{


      // 3.  add person to people collection -  update Redux people

      let personObject = {
        type: 'person',
        name: cleanedPerson
      }

      let newPersonId = await addDocumentToFirestore(uid, 'people', personObject)


      dispatch(addPersonToStore({
        id: newPersonId,
        name: cleanedPerson,

      }
      )) // end dispatach addPersonToStore

  
     // 3. add logHolder ---------------------------

     const formLogHolderObject = (dataStructure, logHolderId, otherPartyType) => {
      let dataObject


      if (dataStructure === 'firestoreData') {

        // console.log('[ where ] formLogHolderObject logHolderId', logHolderId);
        // console.log('[ where ] formLogHolderObject otherPartyType', otherPartyType);

        dataObject = {
          id: logHolderId,
          collection: 'people'

        };

      }

      if (dataStructure === 'reduxData') {
        dataObject = {
          id: logHolderId,
          collection: 'people'
        };

      }
   
      return dataObject

    } // --- end func formLogHolderObject

    let logHolderFirestoreData = formLogHolderObject(  'firestoreData', newPersonId)

    await addLogHolderDocumentToFirestore(uid, newPersonId, logHolderFirestoreData)
    
    let logHolderReduxStoreData = formLogHolderObject(  'reduxData', newPersonId)

    dispatch(addLogHolderToStore(logHolderReduxStoreData))
    dispatch(updateStatusView({
      pageType: 'log',
      id: newPersonId,
      pageView: 'logs',
    }))


    // 4. add log Section ---------------------------


    let cleanedData = {
      detail: fcDetail,
      meta: fcMeta,
      lastEdit: fcLastEdit,
      logDate: fcContactDate,
      otherPartyId: newPersonId,
      type: 'person',
      timeLock: ''
    }


    
    const newLogSectionId = await addDocumentToFirestore(uid, 'logs', cleanedData)

    // console.log('[ NewLogSectionForm ] newLogSectionId  is : ', newLogSectionId);

    const newLogSectionsData = await getDocumentFromFirestore(uid, 'logs',newLogSectionId)

    let logSectionData = newLogSectionsData.data()

    // console.log('[ NewLogSectionForm ] newLogSectionId  is : ', logSectionData);


    let newLogSectionObject  = {
      id: newLogSectionId,
      otherPartyId: newPersonId,
      detail: fcDetail,
      meta: fcMeta,
      lastEdit: fcLastEdit,
      logDate: fcContactDate,
      timeLock: '',
      type: 'person'
    }


    dispatch(addLogToStore(newLogSectionObject))
    dispatch(updateLogView({pageType: 'log', sectionId: newLogSectionId}))
    await deleteDocumentFromFirestore(uid, 'firstContacts', id)
 
    // dispatch(deleteFirstSection({id: id}))

    navigate(`/main/logs/${newPersonId}`)

    dispatch(closeModal())
    dispatch(deleteFirstSection({id: id}))
    


















      } catch (error) {
        alert(error)
      }

    }// end if !doesPersonExist

    } // --- end submitForm
    
// ====Main Return =================================

    return(
      <>
      
      {popupMessage.state === true &&  popupMessage.messageType === 'exists' &&
    
    <MessageWrapper> 
 
        
      {PersonExists()}
   
  
  

    </MessageWrapper>
    }

{ popupMessage.state === false &&  
      <Wrapper>
    
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
    
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                The topic already exists.  Use existent topic or create a different topic name.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <StyledButton
                
                variant="contained"
                color="primary"
                
              onClick={()=>handleClose()}
              >
                Got it
              </StyledButton>
    
    
    
            </DialogActions>
          </Dialog> 
    
          <HeaderWrapper> Convert first contact to log </HeaderWrapper>
    
        <FormProvider {...methods}>
          <FormWrapper id="submit-form" onSubmit={handleSubmit(submitForm)} >
    
            <MainWrapper>
    
              <FormComponentWrapper>
                <ComponentName>
                  Person's name for your new log
                </ComponentName>
    
    
    
                <ChronicleInput
                  name={"name"}
                  control={control}
                  label={"name"}
                  defaultValue={''}
                  placeholder='name'
    
    
                />
              </FormComponentWrapper>
    
    
      </MainWrapper>
              {/* ------Submit ---------- -------------------------- */}
              <ButtonWrapper>
    
    
                <StyledButton
    
                  variant="contained"
                  color="primary"
                  style={{ textTransform: 'none' }}
                  onClick={() => cancelConvertForm()}
                >
                  Cancel
                </StyledButton>
    
                <StyledButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{ textTransform: 'none' }}
                >
                  Submit
                </StyledButton>
              </ButtonWrapper>
            </FormWrapper>
    
          </FormProvider>
    
        </Wrapper>
}
        </>
    )// end main return
}
 

// ==== Styles ===========================================





const Wrapper = styled(Paper)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  // zIndex: '95',

  width: '100%',
  height: '100%',

  marginTop: '2rem',
  backgroundColor: veryLightGrey,

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',
    // backgroundColor: 'red'
  },

})


const FormWrapper = styled('form')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '98%',
  height: '100%',
  margin: '5px 0',
  padding: '4px',
  backgroundColor: 'white',



  [theme.breakpoints.down('sm')]: {
    width: '100%',


  },

})

const MainWrapper = styled('div')({

  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '98%',
  height: '100%',
  marginBottom: '6px',
  paddingBottom: '6px',

  // backgroundColor: 'green',


  [theme.breakpoints.down('sm')]: {
    // width: '100%'
  },


})

// ##############################################

const FormComponentWrapper = styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '90%',
  // margin: '.25rem',

  // backgroundColor: 'yellow',

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})


const ComponentName = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  color: darkGrey,
  fontSize: '.9rem',


  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})



const ErrorMessage = styled('div')({
  fontSize: '.8rem',
  color: 'red',

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})
// #######################################


//  --- Buttons -----------
const ButtonWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '60%',
  margin: '.75rem',


  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})



const StyledButton = styled(Button)({
  backgroundColor: 'white',
  border: '1px solid #E6E7E8',
  color: chitBurgandyDull,
  margin: '0 8px',
  minWidth: '5rem',
  height: '1.5rem',
  fontSize: '.8rem',
  '&:hover': {
    backgroundColor: lightGrey
  }

})


const HeaderWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  margin: '.75rem',
  fontSize: '.8rem',
  color: chitBurgandy,



  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})


const MessageWrapper = styled(Paper)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  // zIndex: '95',
  backgroundColor: 'none',
  width: '100%',
  height: '100%',
  overflow: 'auto',
  margin : '2rem 0',
  padding: '2rem 0',
   

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',
    // backgroundColor: 'red'
  },

})

// -----------------------------------






