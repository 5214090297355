import { createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import { UTCtoDateTime } from '../../helpers/dateHelper'

// --- set params for calendar display in personal chits --

let calendarStart = Date.now()
let calendarStartMonth = UTCtoDateTime(calendarStart).month
let calendarStartYear = UTCtoDateTime(calendarStart).year


// ------------------------------------------

export const statusSlice = createSlice({
  name: 'status',

  initialState: {

    loading: false,

    auth: {
      uid: '',
      loginStatus: false,

      lastVisit: '', // date time
    },

    modal: {

      modalDisplayed: false,
      modalParams: { b: 'null' }
    },

    accordionDisplay: {

      id: ''

    },

    sidePanel: {

      view: 'hide'

    },

    view: {

      // for Chits.jsx ----------------------------------


      twoParty: {

        id: '',
        display: 'ledger'  // ledger or calendar

      },


      personal: {
        // type: 'personalChits',
        display: 'ledger',
        id: ''
      },


      // for Logs.jsx ----------------------------------

      log: {
        display: '', // '' or logs or firstContact
        id: '',  // if no logId -- all else logId stuff
        sectionId: ''
      },

      // for Logs.jsx ----------------------------------

      firstContact: {

        sectionId: ''
      },


      // for Topicals.jsx ----------------------------------

      topical: {

        id: '',
        sectionId: '',
        displayType: 'all'
      },



      // for Inspiration.jsx ----------------------------------

      inspire: {

        inspireFilter: 'story', // quote or last (chit + log + etc) or picture or story
        inspireId: ''
        // TBD when you do inspiration ! 
      },

      // for Notes.jsx ----------------------------------

      forms: {

        twoPartyChitForm: {

          twoPartyChitId: '',
          formPage: 'who',
          completedPagesArray: [],
          chitType: '',
          chitValue: 0,
          chitBurden: 0,


          chitDate: '',

          otherPartyCollection: '',
          otherPartyId: '',


          person: '',

          group: '',


          deedPerformedBy: '',
          workRelated: '',
          description: '',
          keyWordArray: [],


        },

        sharedChitForm: {
          pageView: 'form',
          chitId: '',

          chitType: '',
          chitColor: '',

          senderName: '',
          receiverName: '',


          chitDate: '',
          sharedDate: '',

          deedPerformedBy: '',
          sharedTitle: '',

          description: ''



        },

        receivedChitForm: {},



      },


    }, // end lastView

    calendarMonthDisplay: {
      utc: calendarStart,
      monthName: calendarStartMonth,
      monthYear: calendarStartYear
    }
  }, // end Initial State

  reducers: {

    changeSidePanelView: (state, action) => {

      state.sidePanel.view = action.payload

    },


    openModal: (state, action) => {


      state.modal.modalParams = action.payload.modalParams
      state.modal.modalDisplayed = true


    },

    closeModal: (state, action) => {

      state.modal.modalParams = {}
      state.modal.modalDisplayed = false

    },
    // changeLastSpotlightDisplayed: (state, action) => {
    //   state.spotLightDisplay.displayedSpotLightId = action.payload.displayedSpotLightId

    // },

    // changeStatusInitialMessage: (state, action) => {
    //   state.initialMessage[action.payload.pageType] = false

    // },


    updateStatusView: (state, action) => {
      console.log('[ statusSlice ] updateStatusView  ', action.payload);
      state.view[action.payload.pageType].display = action.payload.pageView
      state.view[action.payload.pageType].id = action.payload.id
    },

    updateAuth: (state, action) => {
      // console.log('[ statusSlice ] update UID ', action.payload);
      state.auth.loginId = action.payload.loginId
      state.auth.displayName = action.payload.displayName
      state.auth.email = action.payload.email
    },
    // --- Firebase status reducers -------------

    changeLoadingStatus: (state, action) => {
      state.loading = action.payload

    },

    updateLoginStatus: (state, action) => {
      state.auth.loginStatus = action.payload

    },

    updateAccordionDisplay: (state, action) => {
      state.accordionDisplay.id = action.payload.id



    },

    changeMonthView: (state, action) => {

      // console.log('[ samStatusSlice ] changeMonthView ', action.payload);
      state.calendarMonthDisplay = action.payload



    },



    openTopicalSectionForm: (state, action) => {

      let newTopicalId = action.payload

      // console.log('[ StatusSlice ] newTopicalId ', action.payload);
      state.view.topical.sectionId = newTopicalId

    },


    closeTopicalSectionForm: (state, action) => {
      // state.modal.modalType = ''
      // state.modal.dbCollection = ''
      // state.modal.id = ''


      state.view.topical.sectionId = ''

    },

    closeNewTopicalForm: (state, action) => {
      // state.modal.modalType = ''
      // state.modal.dbCollection = ''
      // state.modal.id = ''


      state.view.topical.id = ''

    },



    updateTopicalView: (state, action) => {

      // console.log('[ samStatus_slice $$$$$$$$$$$$$$$$$ ] pageType', action.payload.pageType);

      state.view[action.payload.pageType].displayType = action.payload.displayType

    },


    openLogForm: (state, action) => {

      let newLogId = action.payload

      // console.log('[ sStatusSlice ] sectionId ', newJournalId);
      state.view.log.sectionId = newLogId

    },

    closeLogSectionForm: (state, action) => {
      // state.modal.modalType = ''
      // state.modal.dbCollection = ''
      // state.modal.id = ''


      state.view.log.sectionId = ''

    },

    openFirstForm: (state, action) => {

      let newFirstId = action.payload

      // console.log('[ StatusSlice ] sectionId ', newJournalId);
      state.view.firstContact.sectionId = newFirstId

    },

    updateLogView: (state, action) => {

      // console.log('[ samStatus_slice $$$$$$$$$$$$$$$$$ ] pageType', action.payload.pageType);

      state.view[action.payload.pageType].displayType = action.payload.displayType

    },

    updateFirstView: (state, action) => {

      // console.log('[ samStatus_slice $$$$$$$$$$$$$$$$$ ] pageType', action.payload.pageType);

      state.view[action.payload.pageType].displayType = action.payload.displayType

    },


    closeFirstSectionForm: (state, action) => {
      // state.modal.modalType = ''
      // state.modal.dbCollection = ''
      // state.modal.id = ''


      state.view.firstContact.sectionId = ''

    },

    changeTwoPartyFormPage: (state, action) => {

      let page = action.payload

      console.log('[ statusSlice ] changeTwoPartyFormPage - ', page);

      state.view.forms.twoPartyChitForm.formPage= page

    },


    updateTwoPartyViewData: (state, action) => {

      let BobsLoginDate = new Date('2021-03-14T17:03:40.000Z')

      // console.log('[ samStatus_slice #################### ] updateTwoPartyViewData - - data', action.payload.data);

      let pageType = action.payload.pageType
      let page = action.payload.page
      let data = action.payload.data

      let otherPartyType, chitDate, workRelated, description, keywords, chitType, chitValue, chitBurden, deedPerformedBy, person, group


      switch (page) {
        case 'who':
          otherPartyType = data.otherPartyType

          person = data.person

          group = data.group

          // console.log('[ samStatus_slice #################### ] updateTwoPartyViewData - -who TEST TEST  data', action.payload.data.test);


          state.view.forms[pageType].formPage = 'when'
          state.view.forms[pageType].otherPartyCollection = otherPartyType

          state.view.forms[pageType].person = person

          state.view.forms[pageType].group = group






          break;

        case 'when':
          chitDate = data.chitDate
          state.view.forms[pageType].formPage = 'details'
          state.view.forms[pageType].chitDate = chitDate
          state.view.forms[pageType].dateCreated = BobsLoginDate.toISOString()




          break;

        case 'details':

          // console.log('[ samStatus_slice $$$$$$$$$$$$$$$$$ ] updateTwoPartyViewData - -detail data keywords', action.payload.data.keywords);


          workRelated = data.workRelated
          description = data.description
          keywords = data.keywords
          state.view.forms[pageType].formPage = 'chit'
          state.view.forms[pageType].workRelated = workRelated
          state.view.forms[pageType].description = description
          state.view.forms[pageType].keyWordArray = keywords



          break;


        case 'chit':

          //  console.log('[ samStatus_slice $$$$$$$$$$$$$$$$$ ] updateTwoPartyViewData - -detail data keywords', action.payload.data.keywords);


          chitType = data.chitType
          chitValue = data.chitValue
          chitBurden = data.chitBurden


          deedPerformedBy = data.deedPerformedBy
          state.view.forms[pageType].formPage = 'preview'
          state.view.forms[pageType].chitType = chitType
          state.view.forms[pageType].chitValue = chitValue
          state.view.forms[pageType].chitBurden = chitBurden
          state.view.forms[pageType].deedPerformedBy = deedPerformedBy



          break;

        default:
        // console.log('[ samStatus_slice $$$$$$$$$$$$$$$$$ ] no data');

      }

      state.view.forms[pageType].completedPagesArray = [...state.view.forms[pageType].completedPagesArray, page]


      // console.log('[ samStatus_slice ] updateTwoPartyViewData -page type', action.payload.pageType);
      // console.log('[ samStatus_slice] updateTwoPartyViewData - just page', action.payload.page);




      // state.view.forms[pageType].formPage = page
      // state.view[action.payload.pageType].sectionId = action.payload.sectionId
      // state.view[action.payload.pageType].id = action.payload.id

    },

    initializeTwoPartyViewData: (state, action) => {

      let BobsLoginDate = new Date('2021-03-14T17:03:40.000Z')

      // console.log('[ samStatus_slice 9999999999999999999999] initializeTwoPartyViewData ', action.payload.data);

      let pageType = action.payload.pageType
      let data = action.payload.data

      const { id, chitType, chitDate, workRelated, description, keyWordArray, chitValue, chitBurden, otherPartyId, deedPerformedBy, person, group, otherPartyCollection, formPage, completedPagesArray } = data


      state.view.forms[pageType].twoPartyChitId = id
      state.view.forms[pageType].formPage = formPage
      state.view.forms[pageType].completedPagesArray = completedPagesArray

      state.view.forms[pageType].chitType = chitType
      state.view.forms[pageType].otherPartyCollection = otherPartyCollection
      state.view.forms[pageType].chitDate = chitDate
      state.view.forms[pageType].chitValue = chitValue
      state.view.forms[pageType].chitBurden = chitBurden
      state.view.forms[pageType].otherPartyId = otherPartyId
      state.view.forms[pageType].deedPerformedBy = deedPerformedBy
      state.view.forms[pageType].workRelated = workRelated
      state.view.forms[pageType].description = description
      state.view.forms[pageType].keyWordArray = keyWordArray
      state.view.forms[pageType].person = person
      state.view.forms[pageType].group = group


    },

    updateFormPageView: (state, action) => {



      let pageType = action.payload.pageType
      let page = action.payload.page


      state.view.forms[pageType].formPage = page


    },

    clearTwoPartyViewData: (state, action) => {



      state.view.forms['twoPartyChitForm'].formPage = 'who'
      state.view.forms['twoPartyChitForm'].completedPagesArray = []
      state.view.forms['twoPartyChitForm'].twoPartyChitId = ''

      state.view.forms['twoPartyChitForm'].chitType = ''
      state.view.forms['twoPartyChitForm'].otherPartyCollection = ''
      state.view.forms['twoPartyChitForm'].chitDate = ''
      state.view.forms['twoPartyChitForm'].dateCreated = ''

      state.view.forms['twoPartyChitForm'].chitValue = ''
      state.view.forms['twoPartyChitForm'].chitBurden = ''
      state.view.forms['twoPartyChitForm'].otherPartyId = ''
      state.view.forms['twoPartyChitForm'].deedPerformedBy = ''
      state.view.forms['twoPartyChitForm'].workRelated = ''
      state.view.forms['twoPartyChitForm'].description = ''
      state.view.forms['twoPartyChitForm'].person = ''
      state.view.forms['twoPartyChitForm'].group = ''


    },

    updateSharedChitFormData: (state, action) => {


      // pageType = 'sharedChitForm'


      let pageType = action.payload.pageType
      let data = action.payload.data

      const { pageView, chitId, chitType, chitColor, senderName, receiverName, chitDate, sharedDate, deedPerformedBy, sharedTitle, sharedMessage, description } = data

      state.view.forms[pageType].pageView = pageView
      state.view.forms[pageType].chitId = chitId

      state.view.forms[pageType].chitType = chitType
      state.view.forms[pageType].chitColor = chitColor


      state.view.forms[pageType].senderName = senderName
      state.view.forms[pageType].receiverName = receiverName

      state.view.forms[pageType].chitDate = chitDate
      state.view.forms[pageType].sharedDate = sharedDate

      state.view.forms[pageType].deedPerformedBy = deedPerformedBy
      state.view.forms[pageType].sharedTitle = sharedTitle
      state.view.forms[pageType].sharedMessage = sharedMessage
      state.view.forms[pageType].description = description

    },



    clearSharedChitFormData: (state, action) => {

      // pageType = 'sharedChitForm'



      state.view.forms.sharedChitForm.pageView = ''
      state.view.forms.sharedChitForm.chitId = ''
      state.view.forms.sharedChitForm.twoPartyChitId = ''
      state.view.forms.sharedChitForm.sharedLinkId = ''
      state.view.forms.sharedChitForm.chitType = ''
      state.view.forms.sharedChitForm.chitColor = ''


      state.view.forms.sharedChitForm.senderName = ''
      state.view.forms.sharedChitForm.receiverName = ''

      state.view.forms.sharedChitForm.chitDate = ''
      state.view.forms.sharedChitForm.sharedDate = ''

      state.view.forms.sharedChitForm.deedPerformedBy = ''
      state.view.forms.sharedChitForm.sharedTitle = ''
      state.view.forms.sharedChitForm.description = ''
      state.view.forms.sharedChitForm.sharedMessage = ''


    },


    updateReceivedChitFormData: (state, action) => {


      // pageType = 'sharedChitForm'


      /*
 
  
 
      
      */

      let pageType = action.payload.pageType
      let data = action.payload.data

      const { pageView, sharedChitId, chitType, chitColor, senderName, receiverName, chitDate, sharedDate, deedPerformedBy, sharedTitle, sharedMessage } = data

      state.view.forms[pageType].pageView = pageView
      state.view.forms[pageType].sharedChitId = sharedChitId

      state.view.forms[pageType].chitType = chitType
      state.view.forms[pageType].chitColor = chitColor


      state.view.forms[pageType].senderName = senderName
      state.view.forms[pageType].receiverName = receiverName

      state.view.forms[pageType].chitDate = chitDate
      state.view.forms[pageType].sharedDate = sharedDate

      state.view.forms[pageType].deedPerformedBy = deedPerformedBy
      state.view.forms[pageType].sharedTitle = sharedTitle
      state.view.forms[pageType].sharedMessage = sharedMessage


    },

    updateSharedChitFormPageView: (state, action) => {

      // console.log('[ where ] updateSharedChitFormPageView ', myVar);

      // let pageType = action.payload.pageType
      let page = action.payload


      state.view.forms.sharedChitForm.pageView = page


    },

    clearRecievedChitFormData: (state, action) => {

      // pageType = 'sharedChitForm'



      state.view.forms.sharedChitForm.pageView = ''
      state.view.forms.sharedChitForm.chitId = ''
      state.view.forms.sharedChitForm.twoPartyChitId = ''
      state.view.forms.sharedChitForm.sharedLinkId = ''
      state.view.forms.sharedChitForm.chitType = ''
      state.view.forms.sharedChitForm.chitColor = ''


      state.view.forms.sharedChitForm.senderName = ''
      state.view.forms.sharedChitForm.receiverName = ''

      state.view.forms.sharedChitForm.chitDate = ''
      state.view.forms.sharedChitForm.sharedDate = ''

      state.view.forms.sharedChitForm.deedPerformedBy = ''
      state.view.forms.sharedChitForm.sharedTitle = ''
      state.view.forms.sharedChitForm.description = ''
      state.view.forms.sharedChitForm.sharedMessage = ''


    },

    updateReceivedChitFormPageView: (state, action) => {



      let pageType = action.payload.pageType
      let page = action.payload.page


      state.view.forms[pageType].pageView = page


    },

  }, //end reducers

}) // end slice statusSlice 


// --- Export actions ---------------------------------------------

export const {
  initializeStatus,
  openModal,
  closeModal,
  updateAccordionDisplay,
  changeSidePanelView,

  updateStatusView,
  // updateLoginStatus,
  updateAuth,
  changeLoadingStatus,

  changeMonthView,

  openTopicalSectionForm,
  closeTopicalSectionForm,
  closeNewTopicalForm,
  updateTopicalView,

  updateTwoPartyViewData,
  changeTwoPartyFormPage,
  initializeTwoPartyViewData,
  updateFormPageView,
  clearTwoPartyViewData,

  updateSharedChitFormData,
  updateSharedChitFormPageView,
  clearSharedChitFormData,

  updateReceivedChitFormPageView,
  updateReceivedChitFormData,
  clearRecievedChitFormData,

  openLogForm,
  closeLogSectionForm,
  updateLogView,

  openFirstForm,
  closeFirstSectionForm,
  updateFirstView

} = statusSlice.actions



// --- Export selectors ------------------------------------------

export const selectStatus = state => state.status //Sample site
export const selectLoadingStatus = state => state.status.loading//Sample site
export const selectLoginStatus = state => state.status.auth.loginStatus//Sample site

export const selectUid = state => state.status.auth.uid//Sample site

export const selectView = (page) => createSelector(
  [selectStatus],
  view => view[page].display
)










// --- default Export reducers -----------------------------------

export default statusSlice.reducer