
/*---- File - filename.jsx 
   What file does

   View Logic in LogForm read me ...
           src\readMe\LogForm_info.md


   Contains children: 
       input components
       src\forms\formComponents\ChronicleSelectMui.jsx
   parent: New 
*/




import React, {useState, useEffect}  from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useNavigate, useParams } from 'react-router-dom'
import { chitBlueDull, chitBurgandy, chitBurgandyDull, darkGrey, lightGrey, mediumGrey, veryLightGrey } from '../styles/colors'

// --- React-hook-form imports ---------

import { FormProvider, useForm, Controller } from "react-hook-form";
 
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object  } from 'yup';
import * as Yup from 'yup';

// --- Redux slices imports ---------------------------------
import { changeLoadingStatus } from '../app/redux/statusRedux/statusSlice'
import {
  closeLogSectionForm, 
  closeNewLogForm, 
  selectStatus, 
  updateTwoPartyViewData,
  updateStatusView

} from '../app/redux/statusRedux/statusSlice'


import { selectLogHolders, addLogHolderToStore } from '../app/redux/logHolderRedux/logHolderSlice'

import { selectPeople, addPersonToStore } from '../app/redux/peopleRedux/peopleSlice'
import { selectGroups, addGroupToStore } from '../app/redux/groupRedux/groupSlice'
 
// --- Form component imports ---------


import { StyledChitSelectMuiCreatable } from '../forms/formComponents/StyledChitSelectMuiCreatable'

import { ChitRadio } from '../forms/formComponents/ChitRadio'

import { descendSorter, stripWhiteSpace } from '../app/helpers/commonHelpers'
import { closeModal } from '../app/redux/statusRedux/statusSlice'


// --- Firebase imports ---------
 
import { 
  addDocumentToFirestore,
  getDocumentFromFirestore,
  updateDocumentFromFirestore,
  addLogHolderDocumentToFirestore
} from '../app/firebase/fireStore.utils';



// --- MUI imports ---------

import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { styled, createTheme} from '@mui/material/styles'
import {withStyles} from '@mui/styles'
import { resetLogHolders } from '../app/redux/logHolderRedux/logHolderSlice';


const theme = createTheme(); // allows use of mui theme in styled component



// === Main Function ============================

export default function LogForm(props) {
  const dispatch = useDispatch()
  let id = props.modalParams
  const navigate = useNavigate()

//--- since new log form - modalParams id should be === ""
// console.log('[ LogForm  ID ] passedId ', id);


  const status = useSelector(selectStatus)
  const allPeople = useSelector(selectPeople)
  const allGroups = useSelector(selectGroups)
  const allLogHolders = useSelector( selectLogHolders )

  const uid = status.auth.loginId

// ####  GAP HERE AT LINE 108 in TwoParty Chit Form ############
  const [popupMessage, setPopupMessage] = useState({state: false, messageType: ''})



  const formSchema = object({
    person: string().when(["otherPartyType" ], {
      is: (otherPartyType) => otherPartyType === 'person' ,
      then: string().required('You must choose a person')
      .nullable()
    })
    .nullable(),
   
    group: string().when(["otherPartyType" ], {
      is: (otherPartyType) => otherPartyType === 'group' ,
      then: string().required('You must choose a group')
      .nullable()
    })
    .nullable(),





    });



// create selector Options -----------------------------
let groupsIdArray = []

allGroups.map((log, index) => {
  groupsIdArray.push(log.id)

  return groupsIdArray
}
) //end map


// -- create Options for  people select ----- 
let peopleObjectArray = []
let peopleOptionsArray = []



let sortedFilteredPeople = descendSorter(allPeople, 'name')

sortedFilteredPeople.map((person, index) => {
  peopleObjectArray.push(person.name)


  return peopleObjectArray
}
) //end map

// console.log('[ LogForm ] sortedFilteredPeople ', sortedFilteredPeople);

sortedFilteredPeople.map((person, index) => {

  let personExists = groupsIdArray.includes(person.id)

    // console.log('[ LOG SECTION FORM  ] NO NO NO - Not INCLUDED',  person.id);
    peopleOptionsArray.push(person.name)

  

  return peopleOptionsArray
}
) //end map


// -- create Options for  group select ----- 
let groupsObjectArray = []
let groupsOptionsArray = []


 let validGroups = allGroups.filter(item => item.type !== 'company' &&  item.type !== 'organization')

 

  //  console.log('[ Two Party Chit Form  ] NO NO NO - Not INCLUDED', x)


let sortedFilteredGroups = descendSorter(validGroups, 'name')

sortedFilteredGroups.map((group, index) => {
  groupsOptionsArray.push(group.name)


  return groupsOptionsArray
}
) //end map

let defaultValues
  defaultValues = {

    otherPartyType: 'person',
    person: '',
    group: '',

  };

  const PersonExists = ()=>{

    return(
      <>
        <SuccessMessage> This person already exists</SuccessMessage>
        <ButtonWrapper>

          <StyledButton

            variant="contained"
            color="primary"
            style={{ textTransform: 'none' }}
            onClick={() => resetForm()}
          >
            Try Again
          </StyledButton>

          <StyledButton

            variant="contained"
            color="primary"
            style={{ textTransform: 'none' }}
            onClick={() => closeForm()}
          >
            Cancel
          </StyledButton>

       
     
        </ButtonWrapper>
      </>
    )
  }

  const GroupExists = ()=>{

    return(
      <>
        <SuccessMessage> This person already exists</SuccessMessage>
        <ButtonWrapper>

          <StyledButton

            variant="contained"
            color="primary"
            style={{ textTransform: 'none' }}
            onClick={() => resetForm()}
          >
            Try Again
          </StyledButton>

          <StyledButton

            variant="contained"
            color="primary"
            style={{ textTransform: 'none' }}
            onClick={() => closeForm()}
          >
            Cancel
          </StyledButton>

       
     
        </ButtonWrapper>
      </>
    )
  }

    const logHolderExists = ()=>{

      return(
        <>
          <SuccessMessage> This person has a log already</SuccessMessage>
          <ButtonWrapper>

            <StyledButton

              variant="contained"
              color="primary"
              style={{ textTransform: 'none' }}
              onClick={() => resetForm()}
            >
              Try Again
            </StyledButton>

            <StyledButton

              variant="contained"
              color="primary"
              style={{ textTransform: 'none' }}
              onClick={() => closeForm()}
            >
              Cancel
            </StyledButton>

         
       
          </ButtonWrapper>
        </>
      )
    }

    const newLogHolderCreated = ()=>{

      return(
        <> 
        <SuccessMessage> Your new Log has been created</SuccessMessage>
        <CloseFormButton onClick = {()=> closeForm()}> 
        Got it - thanks
        
        </CloseFormButton>
        </>
      )
    }



  // --- close / cancel form 

  function closeForm(){
     
    dispatch(closeModal())
  }

  // --- close / cancel form 

  function openLog(id){

    // navigate to Log here


    dispatch(closeModal())
  }

  function resetForm(){
    
    // navigate to Log here

    reset()
    setPopupMessage({state: false})
  }



  const methods = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(formSchema)
  });
  const { handleSubmit, reset, control, setValue, onChange, watch, ref , formState: { errors } } = methods;


  const submitForm = async (data) => {

    const {otherPartyType,   person, 
                          group  } = data

    // console.log('[twoPartyChitForm]...data ', data)

    let newPersonId, newGroupId
 
    try {
      /*
        1. set spinner loading status(true)
        2. if data.otherPerson type === 'person'

           - check if person exists ---

              (2a) if person exists
                   - check if person is already a logHolder
                      - if yes - get logHolderId
                          - message - logholder exists has a log
                          - button - navigate to log/logHolderId
                               or button back to log Form , reset form - 
                      - if no - not already a logHolder
                          - firestore add logholder - return id
                          - redux store add
                          - exit
               (2b) if person does not exist

                  - add person to firestore (people collection) - return Id
                  - add person to reduxStore (people collection)
                  - add personId to firestore (logHolder collection) - return Id
                  - add person to reduxStore (logHolder)  collection)
                    
      
        3. if data.otherPerson type === 'group'
           --- repeat all of 2 for groups collection

        4. set spinner loading status (false)
        5. close modal
      
      
      
      
      */
      const formLogHolderObject = (dataStructure, logHolderId, otherPartyType) => {
        let dataObject, dbCollection


        if (dataStructure === 'firestoreData') {

          console.log('[ where ] formLogHolderObject logHolderId', logHolderId);
          console.log('[ where ] formLogHolderObject otherPartyType', otherPartyType);

          dataObject = {
            id: logHolderId,
            collection: otherPartyType

          };

        }

        if (dataStructure === 'reduxData') {
          dataObject = {
            id: logHolderId,
            collection: otherPartyType
          };

        }

        return dataObject

      } 


      let dbCollection, formPersonId, formGroupId, logHolderObject

      // 2. if data.otherPerson type === 'person'

        if (otherPartyType === 'person') {

          dbCollection = 'people'

        /*
          - clean person string
          - check if person exists in people collection
            - if yes  - newPersonObject exists - get id of person
  
            - if no 
              - if no  - newPersonObject === undefined       
                
        */

        // --- clean person string

        let cleanedPerson = stripWhiteSpace(person)
        
        // --- (2a)  see if person exists already in people array
        
        let newPersonObject = allPeople.find((searchPerson) => searchPerson.name.toLowerCase() === cleanedPerson.toLowerCase())

          
console.log('[ xxxxxxxxxxxxxxxxxxxxxxxxxxx ] myVar ', newPersonObject);
          

          if( newPersonObject){   // -- person exists -------

            // set formPersonId

            formPersonId = newPersonObject.id

            // see if personId exists in logHolders -------

            console.log('[ LogForm - if  person] allLogHolders ', allLogHolders);


            logHolderObject = allLogHolders.find((logHolder) => logHolder.id === formPersonId)

            if( logHolderObject ) {  // person already has a log

              /*
                - set Popup Object open
                - set popup jsx = logHolderExists
              */

                setPopupMessage({state: true, messageType: 'exists'})

              console.log('[ LogForm - if  ] logHolderObject ', logHolderObject);
          

            } // --- end if( logHolderObject ) 

// ----------------------------------------------------------------

            if( !logHolderObject ) { // existing person has no log
                                     // create new log

                             

              let logHolderFirestoreData = formLogHolderObject(  'firestoreData', formPersonId, dbCollection)

              // let newLogHolderId = await addLogHolderDocumentToFirestore(uid, formPersonId, logHolderFirestoreData)

              await addLogHolderDocumentToFirestore(uid, formPersonId, logHolderFirestoreData)

              let logHolderReduxStoreData = formLogHolderObject(  'reduxData', formPersonId, dbCollection)

              dispatch(addLogHolderToStore(logHolderReduxStoreData))
              dispatch(updateStatusView({
                pageType: 'log',
                id: formPersonId,
                pageView: 'logs',
              }))

              navigate(`/main/logs/${formPersonId}`)  
              setPopupMessage({state: true, messageType: 'new'})
      
            }// --- end if( !logHolderObject ) 

            console.log('[ LogForm - if  person is a logHolder] logHolderObject ', logHolderObject);
            console.log('[ LogForm - if  person is a logHolder] formPersonId ', formPersonId);
            

          }// --- end if(newPersonObject)

// ----------------------------------------------------------------


          // -- person does not exist ---------

          if( !newPersonObject ){

            console.log('[ Log Form ] NO NO NO NEW person ' );

 


                        
            
            let personObject = {
              type: 'person',
              name: cleanedPerson
            }

            newPersonId = await addDocumentToFirestore(uid, 'people', personObject)

            
          

          dispatch(addPersonToStore({
            id: newPersonId,
            name: person,
  
          }
          )) // end dispatach addPersonToStore



          
          let logHolderFirestoreData = formLogHolderObject(  'firestoreData', newPersonId, dbCollection)

          // let newLogHolderId = await addLogHolderDocumentToFirestore(uid, formPersonId, logHolderFirestoreData)

          await addLogHolderDocumentToFirestore(uid, newPersonId, logHolderFirestoreData)

          let logHolderReduxStoreData = formLogHolderObject(  'reduxData', newPersonId, dbCollection)

          await dispatch(addLogHolderToStore(logHolderReduxStoreData))

           dispatch(updateStatusView({
                pageType: 'log',
                id: newPersonId,
                pageView: 'logs',
              }))

              navigate(`/main/logs/${newPersonId}`)  

          setPopupMessage({state: true, messageType: 'new'})
        } //--- end if( !newPersonObject )
// ################################################################

        }//--- end if (otherPartyType === 'person') 


// ################################################################
// ----------------------------------------------------------------
// ################################################################

 // 3. if data.otherPerson type === 'group'
 
        if (otherPartyType === 'group') {

          dbCollection = 'groups'

      /*
          - clean group string
          - check if group exists in group collection
            - if yes  - newGroupObject exists - get id of group
  
            - if no 
              - if no  - newGroupObject === undefined       
                
      */

        // --- clean group string

        let cleanedGroup= stripWhiteSpace(group)
        
        // --- (2a)  see if person exists already in people array
        
        let newGroupObject = allGroups.find((searchGroup) => searchGroup.name.toLowerCase() === cleanedGroup.toLowerCase())

          
console.log('[ xxxxxxxxxxxxxxxxxxxxxxxxxxx ] myVar ', newGroupObject);



        if( newGroupObject){   // -- group exists -------

            // set formGroupId

            formGroupId = newGroupObject.id

            // see if groupId exists in logHolders -------

            console.log('[ LogForm - if  group] allLogHolders ', allLogHolders);


            logHolderObject = allLogHolders.find((logHolder) => logHolder.id === formGroupId)

            if( logHolderObject ) {  // group already has a log

              /*
                - set Popup Object open
                - set popup jsx = logHolderExists
              */

                setPopupMessage({state: true, messageType: 'exists'})

              console.log('[ LogForm - if  ] logHolderObject ', logHolderObject);
          

            } // --- end if( logHolderObject ) 

// ----------------------------------------------------------------

            if( !logHolderObject ) { // existing group has no log
                                     // create new log

                             

              let logHolderFirestoreData = formLogHolderObject(  'firestoreData', formGroupId, dbCollection)

              // let newLogHolderId = await addLogHolderDocumentToFirestore(uid, formGroupId, logHolderFirestoreData)

              await addLogHolderDocumentToFirestore(uid, formGroupId, logHolderFirestoreData)

              let logHolderReduxStoreData = formLogHolderObject(  'reduxData', formGroupId, dbCollection)

              dispatch(addLogHolderToStore(logHolderReduxStoreData))
              dispatch(updateStatusView({
                pageType: 'log',
                id: formGroupId,
                pageView: 'logs',
              }))

              navigate(`/main/logs/${formGroupId}`)  
              setPopupMessage({state: true, messageType: 'new'})
      
            }// --- end if( !logHolderObject ) 

            console.log('[ LogForm - if  group is a logHolder] logHolderObject ', logHolderObject);
            console.log('[ LogForm - if  group is a logHolder] formGroupId ', formPersonId);
            

          }// --- end if(newGroupObject)

// ----------------------------------------------------------------




          // -- group does not exist ---------

          if( !newGroupObject ){

            console.log('[ Log Form ] NO NO NO NEW group ' );

 


                        
            
            let groupObject = {
              type: 'group',
              name: cleanedGroup
            }

            newGroupId = await addDocumentToFirestore(uid, 'groups', groupObject)

            
          

          dispatch(addGroupToStore({
            id: newGroupId,
            name: group,
  
          }
          )) // end dispatach addGroupToStore



          
          let logHolderFirestoreData = formLogHolderObject(  'firestoreData', newGroupId, dbCollection)

          // let newLogHolderId = await addLogHolderDocumentToFirestore(uid, formGroupId, logHolderFirestoreData)

          await addLogHolderDocumentToFirestore(uid, newGroupId, logHolderFirestoreData)

          let logHolderReduxStoreData = formLogHolderObject(  'reduxData', newGroupId, dbCollection)

          await dispatch(addLogHolderToStore(logHolderReduxStoreData))

           dispatch(updateStatusView({
                pageType: 'log',
                id: newGroupId,
                pageView: 'logs',
              }))

              navigate(`/main/logs/${newGroupId}`)  

          setPopupMessage({state: true, messageType: 'new'})
        } //--- end if( !newGroupObject )
// ################################################################

        }//--- end if (otherPartyType === 'group') 







    // #############  ------------------- ###########################
    // #############  ------------------- ###########################
    } catch (error) {
      alert(error.message)
      dispatch(changeLoadingStatus(false))

      reset(defaultValues)

    } // end catch
  } // end async submit

  
Yup.addMethod(Yup.string, 'customValidator', function() {
  return this.test({
    name: 'name',
    message: 'Input is not valid aaaaa',
    test: (score) => score !== 'red'


})
});

  const showOtherPartyTypeInput = watch('otherPartyType')

  return (
    <>
    
    {popupMessage.state === true &&  popupMessage.messageType === 'exists' &&
    
    <MessageWrapper> 
 
        
      {logHolderExists()}
   
  
  

    </MessageWrapper>
    }

        
{popupMessage.state === true &&  popupMessage.messageType === 'new' &&
    
    <MessageWrapper> 
 
        
      {newLogHolderCreated()}
  
   
  
  

    </MessageWrapper>
    }
  
    
  { popupMessage.state === false &&  
    <Wrapper>

      <FormProvider {...methods}>
        <FormWrapper id="submit-form" onSubmit={handleSubmit(submitForm)} >





          <MainWrapper>

            <FormComponentWrapper>
              <ComponentName>
                Who is the new party for your log?
              </ComponentName>

              <ComponentWrapper>
                <RadiotWrapper>
                  <ChitRadio
                    name={"otherPartyType"}
                    control={control}
                    label={"otherPartyType"}
                    options={[
                      {
                        label: "person",
                        value: "person",
                      },
                      {
                        label: "group",
                        value: "group",
                      },

                    ]}
                  />
                </RadiotWrapper>


              </ComponentWrapper>
            </FormComponentWrapper>


            <FormComponentWrapperDoubleIndent>


              <ComponentWrapper>


                <SelectWrapper>
                  {showOtherPartyTypeInput === 'person' &&
                    <>
                      <Instructions>Select a person, or type in a new person</Instructions>
                      <StyledChitSelectMuiCreatable
                        name={'person'}
                        control={control}
                        options={peopleOptionsArray}
                        // defaultValue = {{ value: 'ge423', label: 'home'}}
                        defaultValue={defaultValues.person}
                        placeholder=''


                      />
                      {errors.person && <ErrorMessage>{errors.person.message} </ErrorMessage>}

                    </>
                  }


                  {showOtherPartyTypeInput === 'group' &&
                    <>
                      <Instructions>Select a group, or type in a new group</Instructions>
                      <StyledChitSelectMuiCreatable
                        name={'group'}
                        control={control}
                        options={groupsOptionsArray}
                        // defaultValue = {{ value: 'ge423', label: 'home'}}
                        defaultValue={defaultValues.group}
                        placeholder=''


                      />

                      {errors.group && <ErrorMessage>{errors.group.message} </ErrorMessage>}




                    </>
                  }
                </SelectWrapper>





              </ComponentWrapper>
            </FormComponentWrapperDoubleIndent>



          </MainWrapper>



          {/* ------Submit ---------- -------------------------- */}
          <ButtonWrapper>

          <StyledButton
              type="submit"
              variant="contained"
              color="primary"
              style={{ textTransform: 'none' }}
            >
              Submit
            </StyledButton>

            <StyledButton

              variant="contained"
              color="primary"
              style={{ textTransform: 'none' }}
              onClick={() => closeForm()}
            >
              Cancel
            </StyledButton>




          </ButtonWrapper>



        </FormWrapper>

      </FormProvider>

    </Wrapper>

  }
    </>
  );
}


// ==== Styles ===========================================


const Wrapper = styled(Paper)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  // zIndex: '95',

  width: '100%',
  height: '100%',
  overflow: 'auto',
// border: '2px solid #F285B5',
 

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',
    // backgroundColor: 'red'
  },

})


const FormWrapper = styled('form')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '98%',
  height: '100%',
  margin: '5px 0',
  padding: '4px',
  backgroundColor: 'white',



  [theme.breakpoints.down('sm')]: {
    width: '100%',
  

  },

})

const MainWrapper= styled('div')({

  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '98%',
  height: '100%',
  marginBottom: '6px',
  paddingBottom: '6px',
 
  // backgroundColor: 'green',


  [theme.breakpoints.down('sm')] : {
    // width: '100%'
  },


})







// ##############################################

const FormComponentWrapper= styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '90%',
  // margin: '.25rem',
 
// backgroundColor: 'yellow',

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const FormComponentWrapperIndent= styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '90%',
  marginLeft: '2rem',

// backgroundColor: 'yellow',

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const FormComponentWrapperDoubleIndent= styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '90%',
  marginLeft: '3rem',

// backgroundColor: 'yellow',

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const ComponentName= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  color: darkGrey,
  fontSize: '.9rem',


  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const ComponentWrapper= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
 
  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const NewInputContainer= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '13rem',
  marginLeft: '9px',
 
  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const NewMetaContainer= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '20rem',
  marginLeft: '9px',
  border: '1px solid grey',
  broderRadius: '5px',
 
  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const NewSelectContainer= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '15.5rem',
 
 
  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})


const RadiotWrapper= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '30%',
 
 
  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const SelectWrapper= styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '80%',
 
 
  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})







const CreateNewWrapper= styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
 
 
 
  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const NewWrapper= styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '80%',
 
 
 
  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})











const ErrorMessage= styled('div')({
  fontSize: '.8rem',
  color: 'red',
 
  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})
// #######################################


//  --- Buttons -----------
const BottomWrapper= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  width: '95%',
  margin: '.75rem',

  
  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const ButtonWrapper= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
   

  
  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const StyledButton= styled(Button)({
  backgroundColor: 'white',
  border: '1px solid #E6E7E8',
  color: chitBurgandyDull,
  margin: '0 8px',
  minWidth: '5rem',
  height: '1.5rem',
  fontSize: '.8rem',
  '&:hover' :{
    backgroundColor: lightGrey
  }

})


const EditNameWrapper= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
   
  color: darkGrey,
  fontSize: '.9rem',
  marginLeft: '12px',

  '& span' :{
    color: chitBurgandy,
    marginRight: '.5rem',
    fontSize: '.85rem'
  },

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const Instructions= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
   
  color: mediumGrey,
  fontSize: '.7rem',


 

})


const MessageWrapper = styled(Paper)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  // zIndex: '95',
  backgroundColor: 'none',
  width: '100%',
  height: '100%',
  overflow: 'auto',
  margin : '2rem 0',
  padding: '2rem 0',
   

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',
    // backgroundColor: 'red'
  },

})

const SuccessMessage = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
 
  // zIndex: '95',
  // backgroundColor: 'red',
 
  marginBottom: '1.5rem',
  color: chitBurgandy,


  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',
    // backgroundColor: 'red'
  },

})

const CloseFormButton = styled(Button)({
  backgroundColor: 'white',
  border: '1px solid #E6E7E8',
  color: chitBurgandyDull,
  margin: '0 8px',
  width: '8rem',
  height: '1.5rem',
  fontSize: '.8rem',
  '&:hover' :{
    backgroundColor: lightGrey
  }

})

// -----------------------------------






