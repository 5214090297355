/*---- File - TopicForm.jsx 

Edits a topic in the topics collection

  1. Edit firestore topic in topics collection
  2. Updates Redux store topic in topics

parent: ModalComponent 
        initiated from PersonalChitHeader - edit_icon

*/

import React, { useState, useEffect } from 'react'

import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { chitBurgandy, chitBurgandyDull, lightGrey, darkGrey, } from '../styles/colors';


// --- Firestore imports ---------

import {
  updateDocumentFromFirestore
} from '../app/firebase/fireStore.utils';

// --- Redux slices imports ---------------------------------

import { changeLoadingStatus, closeModal, selectStatus } from '../app/redux/statusRedux/statusSlice'



import { selectTopics, updateEditedTopic } from '../app/redux/topicalRedux/topicsSlice';

// --- React-hook-form imports ---------

import { FormProvider, useForm, Controller } from "react-hook-form";

import { yupResolver } from '@hookform/resolvers/yup';
import { string, object } from 'yup';
import * as Yup from 'yup';

// --- Helpers ------

import { cleanString } from '../app/helpers/commonHelpers'

//--- Form inputs -----

import { ChronicleInput } from './formComponents/ChronicleInput'


//--- MUI imports ----

import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'

import { styled, createTheme } from "@mui/material/styles"
const theme = createTheme(); // allows use of mui theme in styled component

// ==================================================
export default function TopicForm(props) {

  let params = props.modalParams
  let topicId = params.id

  const dispatch = useDispatch()

  // -- state for success message
  const [popupMessage, setPopupMessage] = useState(false)

  const status = useSelector(selectStatus)
  const uid = status.auth.loginId

  //-- find topic object to be edited

  const allTopics = useSelector(selectTopics)

  let topicObject = allTopics.find((topic) => topic.id === topicId)


  let topicName = topicObject.topic

  const defaultValues = {
    topic: topicName,

  };

  //  -- Validation values if any

  const formSchema = object({

    topic: string().required('A topic is required'),

  });

  const methods = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(formSchema)
  });

  //======= SUBMIT ================================


  const { handleSubmit, reset, control, setValue, onChange, watch, ref, formState: { errors } } = methods

  const submitForm = async (data) => {
    // console.log('[ TopicForm] data ', data);

    // get form data elements
    const { topic } = data
    let cleanTopic = cleanString(topic)

    try {

      /*
  1. set loading status
  2. create data object to deploy to Firestore
  3. update topic in Firestore
  4. upadate topic in Redux store
  5. trigger popup message complete
  6. unset loading status

*/



      const topicObject = (dataStructure, topicId) => {
        let dataObject

        if (dataStructure === 'firestoreData') {
          dataObject = {
            topic: cleanTopic,

          };

        }

        if (dataStructure === 'reduxData') {
          dataObject = {
            id: topicId,
            topic: cleanTopic
          };

        }

        return dataObject

      }

      // --- start the loading spinner ---

      dispatch(changeLoadingStatus(true))

      let topicFirestoreData = topicObject('firestoreData')
      let topicReduxStoreData = topicObject('reduxData', topicId)

      await updateDocumentFromFirestore(uid, 'topics', topicId, topicFirestoreData)

      dispatch(updateEditedTopic(topicReduxStoreData))
      dispatch(changeLoadingStatus(false))

      setPopupMessage(true)

    } catch (error) {
      alert(error.message)
      dispatch(changeLoadingStatus(false))

      reset(defaultValues)

    } // end catch


  }// --- end submitForm

  function closeForm() {

    dispatch(closeModal())
  }

  //  === Main Return ===============================

  return (
    <Wrapper>
      <HeaderWrapper> Edit Topic  </HeaderWrapper>
      {!popupMessage &&
        <FormProvider {...methods}>
          <FormWrapper id="submit-form" onSubmit={handleSubmit(submitForm)} >

            <MainWrapper>

              <FormComponentWrapper>
                <ComponentName>
                  Edit Topic
                </ComponentName>



                <ChronicleInput
                  name={"topic"}
                  control={control}
                  label={"topic"}
                  defaultValue={defaultValues.topic}



                />
              </FormComponentWrapper>


            </MainWrapper>
            {/* ------Submit ---------- -------------------------- */}
            <ButtonWrapper>


              <StyledButton

                variant="contained"
                color="primary"
                style={{ textTransform: 'none' }}
                onClick={() => closeForm()}
              >
                Cancel
              </StyledButton>

              <StyledButton
                type="submit"
                variant="contained"
                color="primary"
                style={{ textTransform: 'none' }}
              >
                Submit
              </StyledButton>
            </ButtonWrapper>
          </FormWrapper>

        </FormProvider>

      }

      {popupMessage &&

        <MessageWrapper>
          <SuccessMessage>

            Your topic has been updated


          </SuccessMessage>

          <CloseFormButton onClick={() => closeForm()}>
            Got it - thanks

          </CloseFormButton>
        </MessageWrapper>
      }

    </Wrapper>
  );
}

// ========================================
const Wrapper = styled(Paper)({

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '1rem 0 1.5rem 0',
  width: '100%',

  marginTop: '2rem',

  [theme.breakpoints.down('sm')]: {


  }

})


const FormWrapper = styled('form')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '98%',
  height: '100%',
  margin: '5px 0',
  padding: '4px',
  backgroundColor: 'white',



  [theme.breakpoints.down('sm')]: {
    width: '100%',


  },

})

const MainWrapper = styled('div')({

  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '98%',
  height: '100%',
  marginBottom: '6px',
  paddingBottom: '6px',

  // backgroundColor: 'green',


  [theme.breakpoints.down('sm')]: {
    // width: '100%'
  },


})

// ##############################################

const FormComponentWrapper = styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '90%',
  // margin: '.25rem',

  // backgroundColor: 'yellow',

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})


const ComponentName = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  color: darkGrey,
  fontSize: '.9rem',


  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})



const ErrorMessage = styled('div')({
  fontSize: '.8rem',
  color: 'red',

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})
// #######################################


//  --- Buttons -----------
const ButtonWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '60%',
  margin: '.75rem',


  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})


const StyledButton = styled(Button)({
  backgroundColor: 'white',
  border: '1px solid #E6E7E8',
  color: chitBurgandyDull,
  margin: '0 8px',
  width: '5rem',
  height: '1.5rem',
  fontSize: '.8rem',
  '&:hover': {
    backgroundColor: lightGrey
  }

})

const HeaderWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',

  marginBottom: '.5rem',
  borderBottom: '2px solid #CFD0D1',
  boxShadow: '0 0 1px 0 #F6F7F8',
  // zIndex: '95',

  width: '100%',

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',
    // backgroundColor: 'red'
  },

})



const MessageWrapper = styled(Paper)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  // zIndex: '95',
  backgroundColor: 'none',
  width: '100%',
  height: '100%',
  overflow: 'auto',
  margin: '2rem 0',
  padding: '2rem 0',


  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',
    // backgroundColor: 'red'
  },

})

const SuccessMessage = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  // zIndex: '95',
  // backgroundColor: 'red',

  marginBottom: '.5rem',
  color: chitBurgandy,


  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',
    // backgroundColor: 'red'
  },

})



const CloseFormButton = styled(Button)({
  backgroundColor: 'white',
  border: '1px solid #E6E7E8',
  color: chitBurgandyDull,
  margin: '0 8px',
  width: '8rem',
  height: '1.5rem',
  fontSize: '.8rem',
  '&:hover': {
    backgroundColor: lightGrey
  }

})