import React from 'react'

import { useNavigate } from 'react-router-dom'
 
import { backgroundBlue, chitBurgandy, chitOrange, mediumLightGrey, veryLightGrey } from '../../../../styles/colors';
import { Scrollbars } from 'react-custom-scrollbars';

//  Pictures ------------


import GoldPersonal from '../../../../images/chitCoins/gold_personal.svg'
import SilverPersonal from '../../../../images/chitCoins/silver_personal.svg'
import CopperPersonal from '../../../../images/chitCoins/copper_personal.svg'
import Milestone from '../../../../images/chitCoins/star.svg'
 
import PersonalCategories from '../../../../images/slides/features/personalCategories_sample.jpg'
import PersonalChitCalendar from '../../../../images/slides/features/personalChitCalendar_sample.jpg'
 
import { styled, createTheme  } from "@mui/material/styles"
const theme = createTheme(); // allows use of mui theme in styled component

export default function FeatureSlides_personal(props) {
  return (
    <SlideContainer>
      <Header> Personal Chits</Header>
<Scrollbars>
      <SlideContent>




      <SlideWrapper>

<NarrationWrapper>
  <Title> Personal Chits</Title>
  <Narration>
  Personal chits are tokens you give yourself for something you accomplished. There is no other party involved.  Instead they are grouped by categories.

  </Narration>
</NarrationWrapper>



  <PictureWrapRow>

    <Picture src={PersonalCategories} alt="chit value" />
   

  </PictureWrapRow>



</SlideWrapper>




          <SlideWrapper>

            <NarrationWrapper>
              <Title> Chit Weights</Title>
              <Narration>
              Personal chits come in 4 relative weights plus a milestone you can assign when something significant is accomplished in your life or pursuit.  
              </Narration>
            </NarrationWrapper>

            <PictureWrapper>
              <PictureStyle>
                <CoinWrapper>
                  <CoinStyle src={GoldPersonal} alt="Silver Personal" />
                  
                </CoinWrapper>
                <CoinWrapper>

                  <CoinStyle src={SilverPersonal} alt="gold Standard" />
               
                </CoinWrapper>
                <CoinWrapper>
                  <CoinStyle src={CopperPersonal} alt="Silver Promise" />
               
                </CoinWrapper>

                <CoinWrapper>

<CoinStyle src={Milestone} alt="gold Standard" />
 
</CoinWrapper>
              </PictureStyle>
            </PictureWrapper>

          </SlideWrapper>
     
     


          <SlideWrapper>

<NarrationWrapper>
  <Title> Personal Chit Calendar View</Title>
  <Narration>
  Personal chits have 2 displays.  The ledger view where you can edit chits. And the calendar view to help you track progress towards a goal or help keep you motivated.

  </Narration>
</NarrationWrapper>



  <PictureWrapRow>

    <Picture src={PersonalChitCalendar} alt="chit value" />
   

  </PictureWrapRow>



</SlideWrapper>





{/* ================================================== */}

<SlideWrapper>

<NarrationWrapper>

<Narration>
End - Use tabs above to see features of other Chit Git tools.
</Narration>
</NarrationWrapper>



</SlideWrapper>


     
      </SlideContent>
      
      </Scrollbars>
        </SlideContainer>
    
  );
}



// =========================================================

const SlideContainer= styled('div')({

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',

  width: '100%',
  height: '100%',
  // marginBottom: '5rem',
  backgroundColor: veryLightGrey,
  borderBottom: '1px solid #E6E7E8',
  


  [theme.breakpoints.down('sm')] : {
  
  },

  [theme.breakpoints.down('sm')] : {
     
  },

  [theme.breakpoints.down('xs')] : {
     
    
  }


})

const Header= styled('div')({

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
 fontSize: '1.3rem',
 fontWeight: 'bold',
 color: 'white',
  width: '100%',
  minHeight: '4rem',
  // marginBottom: '5rem',
  background: backgroundBlue,
  marginBottom: '3px',
  


  [theme.breakpoints.down('sm')] : {
  
  },

  [theme.breakpoints.down('sm')] : {
     
  },

  [theme.breakpoints.down('xs')] : {
     
    
  }


})

const SlideContent= styled('div')({

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

  width: '99%',
  minHeight: '15rem',
  // marginBottom: '5rem',
  background: 'white',
  borderBottom: '1px solid #E6E7E8',
  


  [theme.breakpoints.down('sm')] : {
  
  },

  [theme.breakpoints.down('sm')] : {
     
  },

  [theme.breakpoints.down('xs')] : {
     
    
  }


})
const SlideWrapper= styled('div')({

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
flexDirection: 'column',
  width: '100vw',
  minHeight: '15rem',
  // marginBottom: '5rem',
 padding: '.5rem 0 1.5rem 0',

  maxWidth: '65rem',
  boxShadow : '2px 4px #A7A7A8',
  // overflow: 'hidden',

  [theme.breakpoints.down('sm')] : {
    flexDirection: 'column',
    padding: '.5rem 0',
    width: '96vw',
      
  }
// backgroundColor: 'green'

})



const NarrationWrapper= styled('div')({

  display: 'flex',
  flexDirection: 'column',
  jutifyContent: 'center',
  alignItems: 'center',
  width: '92%',
  padding: '1% 4%',
  height: '100%',
 
  backgroundColor: 'white',
 
  marginBottom: '1.5rem',
  borderBottom: '1px solid grey',
  [theme.breakpoints.down('sm')] : {
    width: '100%',
    
  }
})

const Title = styled('div')({

  display: 'flex',
  flexDirection: 'column',
  jutifyContent: 'center',
  alignItems: 'center',
  width:  '100%',
  color: chitBurgandy,
  fontSize: '1rem',
  fontWeight: 'bold',
  marginBottom: '8px',
  textDecoration: 'underline',
  [theme.breakpoints.down('sm')] : {
    width: '100%',
    
  }
})

const Narration = styled('div')({

  display: 'block',
  flexDirection: 'column',
  jutifyContent: 'center',
  alignItems: 'center',
  width:  '50%',
   
  fontSize: '.85rem',
  [theme.breakpoints.down('sm')] : {
    width: '100%',
    
  }
})

const PictureWrapper= styled('div')({

  display: 'flex',
  flexDirection: 'column',
  jutifyContent: 'center',
  alignItems: 'center',
  width: '70%',

  // height: '100%',
  [theme.breakpoints.down('md')] : {
    width: '85%',
    jutifyContent: 'flex-start',
  },

  // height: '100%',
  [theme.breakpoints.down('sm')] : {
    width: '100%',
    jutifyContent: 'flex-start',
  }

})

const PictureWrapRow= styled('div')({

  display: 'flex',
  flexDirection: 'row',
  jutifyContent: 'center',
  alignItems: 'center',
  width: '70%',
marginBottom: '1rem',

  // height: '100%',
  [theme.breakpoints.down('sm')] : {
    width: '80%',
    jutifyContent: 'flex-start',
  }

})

const Picture= styled('img')({
  display: 'block',
  margin: 'auto',
  width: '24rem',
   
})

const MediumPicture= styled('img')({
  display: 'flex',
  margin: 'auto',
  width: '12rem',
   
})

const CoinWrapper= styled('div')({

  display: 'flex',
  jutifyContent: 'flex-start',
  alignItems: 'center',
  flexDirection: 'column',
  
  width: '9rem',
  height: '9rem',
  fontSize: '.9rem',
  textAlign: 'center',
  color: 'black',

  [theme.breakpoints.down('sm')] : {
     
    height: '7rem',
    width: '7rem',
    fontSize: '.75rem',
  }

})


const CoinStyle= styled('img')({
  display: 'block',
  margin: 'auto',
  width: '5rem',
  height: '5rem',

  [theme.breakpoints.down('sm')] : {
     
     width: '4rem',
  height: '4rem',
  }

})
const PictureStyle= styled('div')({

  display: 'flex',
  jutifyContent: 'center',
  alignItems: 'center',
  
  width: '60%',
  height: '15rem',



  [theme.breakpoints.down('sm')] : {
    width: '80%',
    jutifyContent: 'flex-start',
    height: '7rem',
    marginBottom: '1rem'
  }
})
 