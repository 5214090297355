import React from 'react';
 
 import { backgroundBlue, chitBurgandy, chitOrange, mediumLightGrey, veryLightGrey  } from '../../../../styles/colors';
 import { Scrollbars } from 'react-custom-scrollbars';

 import Tools from '../../../../images/slides/description/AppSlide.svg'
 import Argument from '../../../../images/slides/description/argument.jpg'
 import Legal from '../../../../images/slides/description/legal.jpg'

 import Milestone from '../../../../images/chitCoins/star.svg'
 import SilverPersonal from '../../../../images/chitCoins/silver_personal.svg'
 import AwChit from '../../../../images/chitCoins/awChit.svg'
 import GoldCoin from '../../../../images/chitCoins/gold_standard.svg'
 import SilverCoin from '../../../../images/chitCoins/silver_promise.svg'
 import CopperCoin from '../../../../images/chitCoins/copper_kindness.svg'
 import Promise from '../../../../images/slides/features/promise.jpg'
 import Moving from '../../../../images/slides/description/moving.jpg'
 import IOU from '../../../../images/slides/features/iou.jpg'
import Sections from '../../../../images/slides/features/sections_sample.jpg'
import Topical from '../../../../images/slides/features/topical_sample.jpg'
import Book from '../../../../images/slides/features/book.jpg'
import Calendar from '../../../../images/slides/features/personalChitCalendar_sample.jpg'
 import { styled, createTheme  } from "@mui/material/styles"
 const theme = createTheme(); // allows use of mui theme in styled component


export default function FeatureSlides_overview(props) {
  return (

    <SlideContainer>
      <Header> Overview </Header>
      <Scrollbars>
        <SlideContent>


        <SlideWrapper>

          <NarrationWrapper>
            <Title> Chit Git applications</Title>
            <Narration>
              Chit Git is made up of several individual apps that work together to help you organize and recall events and interactions with people and groups in your life. Below is a summary of the major ones and why you use them.
            </Narration>
          </NarrationWrapper>

          <PictureWrapper>
          <ToolsPicture src= {Tools}   alt="Chit Git Tools" /> 
          </PictureWrapper>

        </SlideWrapper>



        <SlideWrapper>

          <NarrationWrapper>
            <Title> Chits (two party)  - what they are </Title>
            <Narration>
              Two party chits are tokens you can give or receive for something that someone did for you ... or something that you did for someone else.  By default, they are stored privately in your personal ledger... or you can choose to share them with the other party.
            </Narration>
          </NarrationWrapper>

          <PictureWrapper>
          <PictureStyle>
                

                <CoinEnds>
                  <CoinStyle src={SilverCoin} alt="promise chit" />
                  <div>Promise <br /> Chit</div>
                </CoinEnds>
                

                <CoinMiddle>

                  <CoinStyle src={GoldCoin} alt="standard chit" />
                  <div>Standard<br /> Chit</div>
                </CoinMiddle>

                <CoinEnds>
                  <CoinStyle src={CopperCoin} alt="Good will chit" />
                  <div>Good will<br /> Chit</div>
                </CoinEnds>
              </PictureStyle>
          </PictureWrapper>

        </SlideWrapper>
        

        <SlideWrapper>

          <NarrationWrapper>
          <Title> Chits (two party) - why use them </Title>
            <Narration>
              Give a chit to thank someone for their help... or to let them know you intend to keep a promise you made ... or record an obligation that someone owes you.
            </Narration>
          </NarrationWrapper>

          <PictureWrapRow>
          <MediumPicture src={Moving} alt="promise" />
            
                <MediumPicture src={Promise} alt="promise" />
                <MediumPicture src={IOU} alt="promise" />

              </PictureWrapRow>

        </SlideWrapper>


          {/* --- Interaction Logs ------------ */}

          <SlideWrapper>

            <NarrationWrapper>
              <Title> Logs - what they are </Title>
              <Narration>
                Logs are where you can create after-action records of any exchange you have with another person, group, organization, or company, etc. They are organized by date to create a history of interactions you had with the person or group.
              </Narration>
            </NarrationWrapper>

            <PictureWrapper>
            <Picture src={Sections} alt="logs" />
            </PictureWrapper>

          </SlideWrapper>


          <SlideWrapper>

            <NarrationWrapper>
              <Title> Logs - why use them </Title>
              <Narration>
                Use interaction logs to help win disputes... or to keep a record of phone converstaions or in person meetings... or to help you recall details of encounters with people you meet for the first time.
              </Narration>
            </NarrationWrapper>

            <PictureWrapRow>
       

                 
                <MediumPicture src={Argument} alt="argument" />
                <MediumPicture src={Legal} alt="Legal" />

              </PictureWrapRow>


          </SlideWrapper>



          {/* --- Topical Logs ------------ */}

          <SlideWrapper>

            <NarrationWrapper>
              <Title> Topicals - what they are </Title>
              <Narration>
                Topicals are where you can create after-action records of interactions, actions you take, or thoughts or ideas you have --- related to a topic.
              </Narration>
            </NarrationWrapper>

            <PictureWrapper>
            <Picture src={Topical} alt="topical" />
            </PictureWrapper>

          </SlideWrapper>


          <SlideWrapper>

            <NarrationWrapper>
              <Title> Topical - why use them </Title>
              <Narration>
                Topical entries become a time-based story, AND, a legal record about a topic.  It is a storage bin for everything related to that topic. 
              </Narration>
            </NarrationWrapper>

            <PictureWrapRow>
       

                 
       <MediumPicture src={Book} alt="Book" />
       <MediumPicture src={Legal} alt="Legal" />

     </PictureWrapRow>

          </SlideWrapper>




          {/* --- Personal Chits ------------ */}

          <SlideWrapper>

            <NarrationWrapper>
              <Title> Chits (personal) - what they are </Title>
              <Narration>
                Personal chits are tokens you give yourself for something you accomplished.
              </Narration>
            </NarrationWrapper>

            <PictureWrapper>
          <PictureStyle>
                

                <CoinEnds>
                  <CoinStyle src={SilverPersonal} alt="silver chit" />
                  <div>Personal <br /> Chit</div>
                </CoinEnds>
                

                <CoinMiddle>

                  <CoinStyle src={Milestone} alt="milestone chit" />
                  <div>Milestone  </div>
                </CoinMiddle>

                <CoinEnds>
                  <CoinStyle src={AwChit} alt="Good will chit" />
                  <div>AwChit</div>
                </CoinEnds>
              </PictureStyle>
          </PictureWrapper>

          </SlideWrapper>


          <SlideWrapper>

            <NarrationWrapper>
              <Title> Chits (personal) - why use them </Title>
              <Narration>
                Use personal chits at review time to record job related accomplishements... or to help motivate you to a goal or objective... or to record (and maybe share) a personal achievement.
              </Narration>
            </NarrationWrapper>

            <PictureWrapper>
            <Picture src={Calendar} alt="logs" />
            </PictureWrapper>

          </SlideWrapper>
        
          <Narration>
End - Use tabs above to see features of other Chit Git tools.
</Narration>

        </SlideContent>
      </Scrollbars>
    </SlideContainer>
  );
}


// -----------------------------------
const SlideContainer= styled('div')({

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',

  width: '100%',
                                  height: '100%',
  // marginBottom: '5rem',
  backgroundColor: veryLightGrey,
  // borderBottom: '1px solid #E6E7E8',
  


  [theme.breakpoints.down('sm')] : {
  
  },

  [theme.breakpoints.down('sm')] : {
     
  },

  [theme.breakpoints.down('xs')] : {
     
    
  }


})


const Header= styled('div')({

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
 fontSize: '1.3rem',
 fontWeight: 'bold',
 color: 'black',
  width: '100%',
  minHeight: '4rem',
  // marginBottom: '5rem',
  // background: backgroundBlue,
  marginBottom: '3px',
  


  [theme.breakpoints.down('sm')] : {
    minHeight: '2rem',
    fontSize: '1.1rem',
  },



})

const SlideContent= styled('div')({

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

  width: '99%',
  minHeight: '15rem',
  // marginBottom: '5rem',

  // borderBottom: '1px solid #E6E7E8',
  overflowX: 'hidden',


  [theme.breakpoints.down('sm')] : {
  
  },

  [theme.breakpoints.down('sm')] : {
     
  },

  [theme.breakpoints.down('xs')] : {
     
    
  }


})

 const SlideWrapper= styled('div')({

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
flexDirection: 'column',
  width: '100vw',
  minHeight: '15rem',
  // marginBottom: '5rem',
 padding: '.5rem 0 1.5rem 0',

  maxWidth: '65rem',
  boxShadow : '1px 2px #CFD0D1',
  // overflow: 'hidden',

  [theme.breakpoints.down('sm')] : {
    flexDirection: 'column',
    padding: '.5rem 0',
 
      
  }
// backgroundColor: 'green'

})



const NarrationWrapper= styled('div')({

  display: 'flex',
  flexDirection: 'column',
  jutifyContent: 'center',
  alignItems: 'center',
  width: '92%',
  padding: '1% 4%',
  height: '100%',
 
  backgroundColor: 'white',
 
  marginBottom: '.15rem',
  // borderBottom: '1px solid grey',
  [theme.breakpoints.down('sm')] : {
    width: '100%',
    
  }
})

const Title = styled('div')({

  display: 'flex',
  flexDirection: 'column',
  jutifyContent: 'center',
  alignItems: 'center',
  width:  '100%',
  color: chitBurgandy,
  fontSize: '1rem',
  fontWeight: 'bold',
  marginBottom: '8px',
  textDecoration: 'underline',
  [theme.breakpoints.down('sm')] : {
    width: '100%',
    
  }
})

const Narration = styled('div')({

  display: 'flex',
  flexDirection: 'column',
  jutifyContent: 'center',
  alignItems: 'center',
  width:  '50%',
   
  fontSize: '.85rem',
  [theme.breakpoints.down('sm')] : {
    width: '100%',
    
  }
})

const PictureWrapper= styled('div')({

  display: 'flex',
  flexDirection: 'column',
  jutifyContent: 'center',
  alignItems: 'center',
  width: '92%',
  backgroundColor: 'white',

  // height: '100%',
  [theme.breakpoints.down('sm')] : {
    width: '100%',
    jutifyContent: 'flex-start',
  }

})

const ToolsPicture= styled('img')({
  display: 'block',
  // marginRight: '18px',
  width: '20rem',
   
})


const PictureStyle= styled('div')({

  display: 'flex',
  jutifyContent: 'center',
  alignItems: 'center',
  
  width: '60%',
  height: '15rem',



  [theme.breakpoints.down('sm')] : {
    width: '80%',
    jutifyContent: 'flex-start',
    height: '7rem',
    marginBottom: '1rem'
  }
})

const CoinEnds= styled('div')({

  display: 'flex',
  jutifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'flex-end',
  flexDirection: 'column',
  width: '9rem',
  height: '9rem',
  fontSize: '.9rem',
  textAlign: 'center',
  color: 'black',

  [theme.breakpoints.down('sm')] : {
     
    height: '7rem',
    width: '7rem',
    fontSize: '.75rem',
  }

})

const CoinMiddle= styled('div')({

  display: 'flex',
  jutifyContent: 'flex-start',
  alignItems: 'center',
  flexDirection: 'column',
  
  width: '9rem',
  height: '9rem',
  fontSize: '.9rem',
  textAlign: 'center',
  color: 'black',

  [theme.breakpoints.down('sm')] : {
     
    height: '7rem',
    width: '7rem',
    fontSize: '.75rem',
  }

})

const CoinStyle= styled('img')({
  display: 'block',
  margin: 'auto',
  width: '5rem',
  height: '5rem',

  [theme.breakpoints.down('sm')] : {
     
     width: '4rem',
  height: '4rem',
  }

})

const PictureWrapRow= styled('div')({

  display: 'flex',
  flexDirection: 'row',
  jutifyContent: 'center',
  alignItems: 'center',

marginBottom: '1rem',
backgroundColor: 'white',
  // height: '100%',
  [theme.breakpoints.down('sm')] : {
    width: '80%',
    jutifyContent: 'flex-start',
  }

})
 
const MediumPicture= styled('img')({
  display: 'flex',
  margin: 'auto',
  width: '12rem',

  // height: '100%',
  [theme.breakpoints.down('sm')] : {
    width: '5rem',
  }

   
})

const Picture= styled('img')({
  display: 'block',
  margin: 'auto',
  width: '24rem',

  // height: '100%',
  [theme.breakpoints.down('sm')] : {
    width: '15rem',
  }
   
})
