
// *** Sample Chrons Selectors ----------

import { createSelector } from 'reselect'

// Get all logs sections --------------------

import { createSlice } from '@reduxjs/toolkit'


export const firstSlice = createSlice({
  name: 'firstSample',
  initialState: [],

  reducers: {


    addAllFirstsFromFirestore: (state = [], action) => {
    
      let firestoreData = action.payload
    
      // Clear the state array
      state = [];
    
      // Add the Firestore data to the state array
      state = [...firestoreData];
    
      return state
    },// end addAllFirstsFromFirestore


    updateEditedFirst: (state, action) => {
      console.log('[ firstlice ] payload ', action.payload);
  
      let firstId = action.payload.id
   
      
    
      let newFirstMeta = action.payload.meta
      let newFirstName = action.payload.name
      let newFirstLastEdit = action.payload.lastEdit
      let newFirstDate = action.payload.contactDate
  
      let newLogDetail = action.payload.detail
     
      
      
      
  
      let logIndex = state.findIndex(index => index.id === firstId)
  
      console.log('[ sam_logSlice ] state[logIndex] ', state[logIndex]);
  
      
      state[logIndex].detail = newLogDetail
    
    state[logIndex].meta = newFirstMeta
    state[logIndex].name = newFirstName
    state[logIndex].lastEdit = newFirstLastEdit
    state[logIndex].contactDate = newFirstDate
  
  
    }, // end updateEditedLogs
  



    resetFirstContacts: (state, action) => {
      let initialState = []
      return initialState
    },// end resetFirstContacts



    addFirstToStore: (state, action) => {
console.log('FirstSlice - addFirstSlice', action.payload)
      let first = action.payload
      state.push(first)
    },


    deleteFirstSection: (state, action) => {

      console.log('[ deleteFirstlSection ] payload ', action.payload);
          let firstSectionId = action.payload.id
      
          return state.filter(item => item.id !== firstSectionId)
       
      }, // end deleteFirstSection
      
      
      }

}) // end slice firstSlice 


// --- Export actions ---------------------------------------------

export const { 
  addAllFirstsFromFirestore,
  addFirstToStore, 
  deleteFirstSection,
  resetFirstContacts,
  updateEditedFirst
 
} = firstSlice.actions



// --- Export selectors ------------------------------------------

export const selectFirsts = state => state.firstContacts

export const selectFirstFromArray = (firstArray, id) => {
 
  let first = firstArray.find(first => first.id === id) 
 
  return first
}


// --- default Export reducers -----------------------------------

export default firstSlice.reducer