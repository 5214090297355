/* function Logs() -------------------
  There are 5 scenarios
  1) user comes to page for first time ever
      redux display = ''
      sees links to first contact  and logs
         + 
      message about why use logs

  2) user comes to page for first time this session but has logs
      redux display = ''
      sees links to first contact  and logs
         no message

  3) user comes to page with redux state = logs but no logs ever
    message create a new log

  4) user comes to page with redux state = logs +  a log Id
     load LogMain

  5) user comes to page with redux state = firstContact 
     load FirstContactMian


------------------------------------*/

import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch} from 'react-redux'
 
import { useParams, useNavigate } from 'react-router-dom'
import {veryLightGrey, lightGrey, chitBurgandy} from '../../../styles/colors'

import New from '../components/New_Circle'
import {selectLogs} from  '../../../app/redux/logRedux/logsSlice'
import { selectLogHolders } from '../../../app/redux/logHolderRedux/logHolderSlice'
import { selectFirsts } from '../../../app/redux/firstRedux/firstSlice'
import LogMain from './LogMain'
import FirstMain from './FirstMain'

import { selectStatus, updateStatusView } from '../../../app/redux/statusRedux/statusSlice'
import { openModal } from '../../../app/redux/statusRedux/statusSlice'

import LogViewNav from './LogView_nav';
// -------Material UI 
import Button from '@mui/material/Button'; 
import InfoIcon from '@mui/icons-material/Info';

import { styled, createTheme  } from "@mui/material/styles"
const theme = createTheme(); // allows use of mui theme in styled component






// ====================================
 
// ===================================
export default function Logs(props) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  let match = useParams()
  // let navigate = useNavigate()

  let status = useSelector(selectStatus)
  let display = status.view.log.display
  let displayId = status.view.log.id

  console.log('[ Logs ] display ', display);

  let logHoldersArray = useSelector(selectLogHolders)
  let logsArray = useSelector(selectLogs)
  let firstContactsArray = useSelector(selectFirsts)

  // let logsArray = []

  const logId = match.id

  console.log('[ Logs ]   logHoldersArray ', logHoldersArray);
  console.log('[ Logs ]  logsArray ', logsArray.length);
  console.log('[ Logs ]  display ', display);

  const openForm = () => {

    //  define which Form to open in Modal by passing



    // props.openModal(dbCollection, id)

    dispatch(openModal(
      {
        modalParams: {
          modalType: 'form',
          dbCollection: 'logs',
          id: ''
        }
      }

    ))

  }// end openForm


  
  const updateDisplayStatus = (evt) => {

    let display = evt.currentTarget.id

    //  define which Form to open in Modal by passing



    // props.openModal(dbCollection, id)

    dispatch(updateStatusView(
      {
        pageType: 'log',
        pageView: display
      }

    ))

    if(display === 'firstContact'){

      navigate(`/main/logs`)
    }

  }// end openForm

  const openMewForm = ()=>{

    //  define which Form to open in Modal by passing
    //  dbCollection to Modal depending on pageView in browser URL 
    // ------------------------------------------------------------
      
    // props.openModal(dbCollection, id)
  
    dispatch(openModal(
      { modalParams: {
            modalType: 'form',
            dbCollection: 'logs',
            id: ''
        }
      }
  
    ))
  
  }// end openMewForm

  return (

    <Wrapper>

      {/* <LogViewNav/> */}


      {/* (1) --- brand new user --------------------- */}

      {firstContactsArray.length === 0 && logHoldersArray.length === 0 && !display && 

        <>
          <NoneMessage>

            <ButtonWrapper>
              <StyledButton
                id='logs'
                onClick={() => openMewForm()}
              >Create first new log</StyledButton>

              <StyledButton
                id='firstContact'
                onClick={(evt) => updateDisplayStatus(evt)}
              >Go to First Contacts</StyledButton>

            </ButtonWrapper>

          </NoneMessage>

          <NoneDescription>
            <Header>Use Logs if you want to : </Header>
            <StyledUl>

              <StyledLi> document a dispute with a person or company  </StyledLi>
              <StyledLi> remember details of someone you just met</StyledLi>
              <StyledLi> document ongoing interactions with someone else</StyledLi>


            </StyledUl>
            <KeyFeature> Key Features - Time lock </KeyFeature>
            <Info>
              <InfoIconWrapper />
              <div> click info icon above for details on how to use  </div>
            </Info>

          </NoneDescription>

        </>

      }


      {/* (2) --- log holder yes - no logs - no first contacts--------- */}

      {logHoldersArray.length > 0 && !display &&

        <>

          <NoneMessage>

            <ButtonWrapper>
              <div>
              Choose a log or <br/> Create a new log holder <br/>
              <New/>
              </div>
              <div>or </div>
             

              <StyledButton
                id='firstContact'
                onClick={(evt) => updateDisplayStatus(evt)}
              >Go to First Contacts</StyledButton>

            </ButtonWrapper>

          </NoneMessage>



        </>

      }



      {display === 'firstContact' &&


        <FirstMain />


      }


      {display === 'logs' && logsArray.length === 0 && displayId && 
 
<LogMain/>
 

      }

      {display === 'logs' && logsArray.length > 0 && !logId &&

<NoneMessage>

<ButtonWrapper>
  <div>
  Choose a log <br/> Create a new log holder 
  </div>
  <div>or </div>
 

  <StyledButton
    id='firstContact'
    onClick={(evt) => updateDisplayStatus(evt)}
  >Go to First Contacts</StyledButton>

</ButtonWrapper>

</NoneMessage>

      }

      {display === 'logs' && logId && logsArray.length > 0 &&



        <LogMain />



      }


    </Wrapper>
  )
}


// -----------------------------------------------------------------
const Wrapper= styled('div')({

  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  backgroundColor: veryLightGrey,
  width: '100%',
  height: '100%',
 

  [theme.breakpoints.down('sm')] : {
    // width: '100%'
  },

})

const NoneMessage= styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '1.5rem 0',
  width: '80%',
  height: '8rem',
  backgroundColor: 'white',
  marginTop: '2rem',
 

  [theme.breakpoints.down('sm')] : {
    width: '95%',
    height: '18rem',
  },
  
})

const NoneDescription= styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '1.5rem 0',
  width: '80%',
  maxHeight: '20rem',
  backgroundColor: 'white',
  marginTop: '2rem',
 


  [theme.breakpoints.down('sm')] : {
    width: '95%',
    padding: '1.5rem 0',
  },
  
})

const Header= styled('div')({
  
fontStyle: 'italic',

 
  [theme.breakpoints.down('sm')] : {
    // height: '1.25rem',
    fontSize: '.9rem',
  },
  
})
const ButtonWrapper= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  alignItems: 'center',
  width: '80%',
  margin: '.75rem',

  [theme.breakpoints.down('md')]: {
    width: '80%',

  },
  
  [theme.breakpoints.down('sm')]: {
    width: '90%',
    flexDirection: 'column',
    alignItems: 'center'

  },

})

const StyledButton= styled(Button)({
  backgroundColor: 'white',
  border: '1px solid #F58634',
  color: chitBurgandy,
  margin: '0 8px',
  padding: ' 0 1rem',
  height: '1.5rem',
  fontSize: '.75rem',
  '&:hover' :{
    backgroundColor: veryLightGrey
  },
  [theme.breakpoints.down('md')] : {
    // height: '1.25rem',
  
    
  },

  [theme.breakpoints.down('sm')] : {
    // height: '1.25rem',
    fontSize: '.65rem',
  },

})


const Small= styled('span')({

  fontSize : '.7rem',
  
 

})
const StyledUl= styled('ul')({
  
  marginLeft: '2rem'

})

const StyledLi= styled('li')({

  fontSize : '.9rem',
  lineHeight: '1rem',
  marginBottom: '10px',
  
 
  [theme.breakpoints.down('sm')] : {
    // height: '1.25rem',
    fontSize: '.8rem',
  },
 

})

const KeyFeature= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '80%',
  margin: '.75rem',
 fontSize: '.8rem',
  color: chitBurgandy,
  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const Info= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '80%',
  margin: '.75rem',
 fontSize: '.7rem',
  
  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const InfoIconWrapper= styled(InfoIcon)({

  color: 'grey',
  fontSize : '1.6rem',
  
 

})

 