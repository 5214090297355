/* Landing_page - main pitch
   contains:
    Join
   parent: /src/public/Landing
*/

import React, {useState, useEffect}  from 'react'
import {Link, useNavigate} from 'react-router-dom'
import { useDispatch} from 'react-redux'
import { stripWhiteSpace } from '../../../app/helpers/commonHelpers'

import { 
  signInWithGooglePopup, 
  createUserDocumentFromAuth 
} from '../../../app/firebase/firebase.utils';


import{ chitDullYellow, backgroundBlue, chitYellow, chitBlueDull, veryLightGrey, lightGrey } from '../../../styles/colors'

import { openModal } from '../../../app/redux/statusRedux/statusSlice'

import Logo from '../../../images/ChitPro_logo.svg'
import GoogleLogo from '../../../images/googleLogo.svg'
import GoldCoin from '../../../images/chitCoins/gold_standard.svg'
import SilverCoin from '../../../images/chitCoins/silver_personal.svg'
import CopperCoin from '../../../images/chitCoins/copper_promise.svg'
import AwChit from '../../../images/chitCoins/awChit.svg'


 
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import { styled, createTheme  } from "@mui/material/styles"
const theme = createTheme(); // allows use of mui theme in styled component


// ==============================

const Landing_page = (props) => {
  let navigate = useNavigate()
  const dispatch = useDispatch()

  // --- sign in with Google---------
  const logGoogleUser = async () => {
    const {user}  = await signInWithGooglePopup()
    const userDocRef = await createUserDocumentFromAuth(user)
    
    // console.log('[Log Form ]',  user)
  }

  const handlePageChange = (evt) => {
    evt.persist()
    // console.log('I BE CLICKED in TP :: ', evt.currentTarget.id)
    props.setPage(evt.currentTarget.id) //XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX

  }


  const [chitInput, setChitInput] = useState('')

  const handleInput = (evt) =>{
    // console.log('[Landing_page ] Input form value ', evt.target.value);
    setChitInput(evt.target.value)
  }

  const handleSubmit = (code)=>{
    
    navigate(`/sharedChit`)
 
  }

  const openJoinForm = ()=>{

    //  define which Form to open in Modal by passing
    //  dbCollection to Modal depending on pageView in browser URL 
    // ------------------------------------------------------------
    // console.log('[ Header Landing ] openJoinForm clicked ');
    // props.openModal(dbCollection, id)
    
    dispatch(openModal(
      { modalParams: {
            modalType: 'form',
            dbCollection: '',
            id: '',
            login: 'join'
        }
      }
  
    ))
  
  }// end openJoinForm

  const openLoginForm = ()=>{

    //  define which Form to open in Modal by passing
    //  dbCollection to Modal depending on pageView in browser URL 
    // ------------------------------------------------------------
    // console.log('[ Header Landing ] openLoginform clicked ');
    // props.openModal(dbCollection, id)
    
    dispatch(openModal(
      { modalParams: {
            modalType: 'form',
            dbCollection: '',
            id: '',
            login: 'login'
        }
      }
  
    ))
  
  }// end openLoginForm

  return (



    <LandingWrapper>


      <LandingContent>

        <LeftSide>
          <LogoStyle src={Logo} alt="Chit Git Logo" />
          <LogoTag>A suite of  tools</LogoTag>

          <Value>
            <div> Improve your life</div>
            
          </Value>

          <ListWrapper>
            <ListStyle>
              
              <li> your work </li>
              <li> your relationships </li>
              <li> your pursuits </li>
              <li> yourself </li>
              




            </ListStyle>

          </ListWrapper>
 


        

          <ScrollDown>
         
             - scroll down to learn more -
             

          </ScrollDown>

        </LeftSide>


        {/* --------------------------------------------------------------------- */}

        <Center />

        <RightSide>

<RepoWrapper> Have total recall of all <br/>your deeds, dealings and doings</RepoWrapper>
          <ChitsWrapper>

            <CoinStyle src={GoldCoin} alt="Chit Coin Gold" />
            <CoinStyle src={SilverCoin} alt="Chit Coin Silver" />
            <CoinStyle src={CopperCoin} alt="Chit Coin Copper" />
            <CoinStyle src={AwChit} alt="Chit Coin Red" />
          </ChitsWrapper>

          <Notice >


            <div onClick={() => handleSubmit()}> I received a chit </div>

          </Notice>

 
          <FormWrapper>
          <GoogleButton onClick={() => {
        logGoogleUser()
      }}>
              <Google src={GoogleLogo} alt="Chit Git Logo"/>
              <span> Sign up with Google </span>
               </GoogleButton>
               
              <div> or </div>
              <JoinButton onClick = {() => openJoinForm()} > Sign up with Email</JoinButton>

              <Spacer> Already have an account </Spacer>
              <JoinButton onClick={() => openLoginForm()}> Sign in</JoinButton>
          </FormWrapper>

          <Free>Chit Git is free</Free>
          
        </RightSide>


      </LandingContent>

       
      <WhiteBreak>      </WhiteBreak>
    </LandingWrapper>

  )
}




export default Landing_page


// -----------------------------------------------------------------

const LandingWrapper= styled('div')({

  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',

  width: '100vw',
  
  paddingTop: '3rem',
  paddingBottom: '1rem',
  backgroundCOlor: backgroundBlue,
  backgroundImage: 'linear-gradient(#04102F,#062A4D, #096E99)',
  // overflow: 'hidden',
  

  borderBottom: '1px solid #606062',

  [theme.breakpoints.down('sm')] : {
    paddingTop: '1rem',
  
  }


})




const LandingContent= styled('div')({

//  backgroundColor: 'yellow',
position: 'relative',
  display: 'flex',
  
  justifyContent: 'center',
  alignItems: 'flex-start',
  margin: '0 auto 0 auto',  
  maxWidth: '65rem',
  minWidth: '40rem',
  height: '28rem',

  [theme.breakpoints.down('md')] : {
    maxWidth: '65rem',
    minWidth: '37rem',
  },
 
  [theme.breakpoints.down('sm')] : {
    flexDirection: 'column',
    height: 'auto',
    maxWidth: '100%',
    minWidth: '100%',
  }

})


const ScrollDown= styled('div')({

  color: 'yellow',
 

bottom: '8rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '1rem',
  marginTop: '2.4rem',
  width: '100%', 
  height: '1rem',
 
  [theme.breakpoints.down('sm')] : {
    display: 'none'
  }

})
 

const WhiteBreak = styled('div')({

  // backgroundColor: 'yellow',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '1rem',
  backgroundColor: 'white',
  marginTop: '1rem',

  [theme.breakpoints.down('xs')]: {
    flexDirection: 'column'

  }

})



const LeftSide= styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  margin: '0 ',  
  width: '48%',
  height: '100%',
   

  [theme.breakpoints.down('sm')] : {

    width: '100%',
    paddingLeft: '2%',

},

  [theme.breakpoints.down('xs')] : {
                                
     alignItems: 'center',                           
     width: '100%',
     padding: '0',
    
  
  }

})

const Center= styled('div')({
  position: 'relative',
  backgroundColor: 'black',
  display: 'block',
 
  borderRight: '1px solid #8293B8   ',
  borderLeft: '1px solid   #040952 ',

  height: '100%',
  
  [theme.breakpoints.down('xs')] : {
    width: '100%',
  }

})

const RightSide= styled('div')({

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  margin: '0 auto 0 auto',  
  width: '48%',
  // backgroundColor: 'green',
  height: '100%',
  
  [theme.breakpoints.down('xs')] : {
    width: '100%',
  }

})

// -------- Left Side-------------------


const LogoStyle= styled('img')({

  height: '5.5rem',

  [theme.breakpoints.down('sm')]: {
    height: '4.25rem',
    margin: '2rem 0 1rem 0'
  }

})





const Value = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  color: chitYellow,
  marginTop: '1.25rem',
  marginBottom: '1rem',

  textAlign: 'center',
  width: '100%',
  padding: '0 5%',
  fontSize: ' 1.15rem',
  fontFamily: 'Lato, sans-serif',
  fontWeight: '400',
  lineHeight: '1.5',



  [theme.breakpoints.down('sm')]: {
    width: '90%',
    
    fontSize: '1.1rem',
    marginTop: '1rem',
    marginBottom: '1rem',
  }

})

const ListWrapper= styled('div')({
  display: 'block',
  padding: '0 auto 0 auto',

  fontSize: '.9rem',
  fontWeight: '300',
  marginBottom: '1.9rem',
  [theme.breakpoints.down('sm')] : {
    fontSize: '.9rem',
  },

  [theme.breakpoints.down('xs')] : {
     
    fontSize: '1rem',
    // padding: '0 15% 0 5%',
  }

})

const ListStyle = styled('ul' )({
 margin: '0',
  color: 'white',
listStyleType: 'circle',
listStylePosition: 'outside',
marginLeft: '-2rem'
 
 })

 const ButtonWrapper= styled('div')({

  display: 'flex',
  marginTop: '2rem',
  justifyContent: 'center',
  alignItems: 'center',
  width: '90%',

  [theme.breakpoints.down('sm')] : {
    marginTop: '1rem',
    
  },
  
  [theme.breakpoints.down('xs')] : {
    padding: '0 15% 0 5%',
    
  },

})




const Notice= styled('div')({

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '90%',
  // backgroundColor: 'orange',
  marginTop: '.85rem',
  color: chitDullYellow,
  textDecoration: 'underline',
  cursor: 'pointer',
  fontSize: '1rem',
  fontWeight: '400',

    [theme.breakpoints.down('sm')] : {
       
      fontSize: '.75rem'
      
    },

    [theme.breakpoints.down('xs')] : {
       
      fontSize: '.95rem',
      padding: '0 15% 0 5%',
      
      
    }
   

})
const FormWrapper = styled('div')({

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
 
  
  width: '90%',
  marginTop: '2rem' ,

  '& div' : {
    color: 'white',
    fontSize: '.8rem'
  
  },
            
 
    [theme.breakpoints.down('sm')] : {
       
 
      
    },

    [theme.breakpoints.down('xs')] : {
       
      fontSize: '.95rem',
      padding: '0 15% 0 5%',
      
      
    }
   

})





// ------- Right Side------------------------------

const JoinButton = styled(Button)({

  backgroundColor: 'white',
  display: 'flex',
  justifyContent: 'center',
  textTransform: 'none',
  
  border: '1px solid white' ,
  color: 'black',
  fontWeight: 'normal',
  fontSize: '.8rem',
  padding: '0 .5rem',
  width: '11rem',
  
  '&:hover' : {
    backgroundColor: lightGrey,
    textDecoration: 'none',
    border: '1px solid #A8BEED' ,
    

  }


})
const Google= styled('img')({

  height: '.9rem',
  marginRight: '.7rem'

})
const GoogleButton = styled(Button)({

 
backgroundColor: 'white',
  display: 'flex',
  justifyContent: 'center',
  textTransform: 'none',
  
  border: '1px solid white' ,
  color: 'black',
  fontWeight: 'normal',
  fontSize: '.8rem',
  padding: '0 .5rem',
  width: '11rem',
  
  '&:hover' : {
    backgroundColor: lightGrey,
    textDecoration: 'none',
    border: '1px solid #A8BEED' ,
    

  }


})

const Spacer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
   
  margin: '1.5rem 0 .5rem 0',
  color: 'white',
  fontSize: '.85rem',
  width: '100%',
 

})

const ChitsWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
   
  marginTop: '.5rem',
  
  height: '4.5rem',
  width: '100%',
 
  [theme.breakpoints.down('sm')] : {
       
    height: '3.5rem',
  },

  [theme.breakpoints.down('xs')] : {
       
    display: 'none'
    
  }

})


const RepoWrapper= styled('div')({

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
 textAlign: 'center',
color: 'white',
fontSize: '.9rem',
 
  [theme.breakpoints.down('xs')] : {
       
  
    
    
  }


})

const ChitsWrapperSmall = styled('div')({
  display: 'none',
  [theme.breakpoints.down('xs')] : {
       
    display: 'flex',
    justifyContent: 'center',
    padding: '0 15% 0 5%',
     
    marginTop: '1rem',
    
    height: '3rem',
    width: '90%',
  
    
  }

})

const CoinStyle= styled('img')({

  height: '3rem',

  margin: '14px',
})






const Free= styled('div')({

  display: 'block',
  width: '90%',
  textAlign: 'center',
  marginTop: '1.25rem',
  color: 'yellow',
 
  fontSize: '1rem',
  fontWeight: '400',

    [theme.breakpoints.down('sm')] : {
       
      fontSize: '.75rem'
      
    },

    [theme.breakpoints.down('xs')] : {
       
      fontSize: '.95rem',
      padding: '0 15% 0 5%',
      
      
    }
   

})


const LogoTag= styled('div')({

  fontSize: '1rem',
 fontWeight: '300',
  color: 'white',
  fontStyle: 'italic',


})
