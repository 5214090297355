/*---- File - filename.jsx 
 
1. use props.id to get data object from twoPartyChitCollection
2. convert fields to preload default inputs in form
    ex - if(deedPerforemed by === otherPartyId) 
             shared deedPerformed by = otherParty
3. on submit - update Redux store data
4. go to Preview


*/




import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { chitBlueDull, chitBurgandy, chitBurgandyDull, darkGrey, lightGrey, mediumGrey, mediumLightGrey, veryLightGrey } from '../styles/colors'



// --- React-hook-form imports ---------

import { FormProvider, useForm, Controller } from "react-hook-form";

import { yupResolver } from '@hookform/resolvers/yup';
import { string, object } from 'yup';
import * as Yup from 'yup';

// --- Form component imports ---------

import { Editor } from '../forms/formComponents/JournalEditor'
import { ChitRadio } from '../forms/formComponents/SharedRadio'
import { ChronicleInput } from './formComponents/ChronicleInput'
import RadioGroup from '@mui/material/RadioGroup';
import { closeModal } from '../app/redux/statusRedux/statusSlice'

// --- Redux slices imports ---------------------------------
import { changeLoadingStatus } from '../app/redux/statusRedux/statusSlice'
import {

  selectStatus,
  updateSharedChitFormData,
  clearSharedChitFormData


} from '../app/redux/statusRedux/statusSlice'

// --- imports to create options for selectors

// --- MUI imports ---------

import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'

import { styled, createTheme } from "@mui/material/styles"
import { withStyles } from '@mui/styles'
const theme = createTheme(); // allows use of mui theme in styled component

// ==================================================

export default function SharedChitFormForm(props) {

  const dispatch = useDispatch()
  const status = useSelector(selectStatus)

  const initialData = status.view.forms.sharedChitForm

  const { pageView, chitId, chitType, chitColor, senderName, receiverName, deedPerformedBy, chitDate, sharedDate, sharedTitle, sharedMessage, description } = initialData

  console.log('[ SharedChitFormForm ] initialData ', initialData);

  const formSchema = object({
    message: string().required('A description is required')

  });


  // --- set default shared Message 

  let defaultSharedMessage

  !sharedMessage ? defaultSharedMessage = description : defaultSharedMessage = sharedMessage

  !sharedMessage ? defaultSharedMessage = description : defaultSharedMessage = sharedMessage

  // ----create default paramters if note exists ---------------------

  let defaultValues = {

    formChitColor: chitColor,
    formTitle: sharedTitle,
    message: description,
    formReceiverName: receiverName,
    formSenderName: senderName,
    formSharedMessage: defaultSharedMessage

  };

  // --- close / cancel form 
  const cancelNewForm = () => {

    console.log('[ Cancel ] I BE CLICKED  ');
    dispatch(clearSharedChitFormData())
    dispatch(closeModal())
    // navigate(`/sample/twoPartyChits/allChits`)

  }

  // ===========  FORM  ==================================================

  const methods = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(formSchema)
  });
  const { handleSubmit, reset, control, setValue, onChange, watch, ref, formState: { errors } } = methods;

  const submitForm = async (data) => {
    // console.log('[SharedChitForm details]...data ', data)

    const { formSharedMessage, formTitle, formChitColor, formReceiverName, formSenderName } = data

    /*
     form shared messages come from Quill with <P> tags
     to format correctly in display all <P> tags need
     to be converted to <div> 

     2 step process = exchange <p> ... then exchange </p>
    
    
    */

    let modifiedSharedMessage = formSharedMessage.replaceAll('<p>', '<div>')
    let modifiedCleanSharedMessage = modifiedSharedMessage.replaceAll('</p>', '</div>')



    let sharedObject = {

      pageView: 'preview',
      chitId: chitId,
      chitType: chitType,
      chitColor: formChitColor,
      senderName: formSenderName,
      receiverName: formReceiverName,
      chitDate: chitDate,
      sharedDate: sharedDate,
      deedPerformedBy: deedPerformedBy,
      sharedTitle: formTitle,
      sharedMessage: modifiedCleanSharedMessage,
      description: description
    }

    dispatch(updateSharedChitFormData({
      pageType: 'sharedChitForm',
      data: sharedObject
    }
    ))

  }

  return (

    <Wrapper>

      <Header> Shared Chit Form</Header>





      <FormProvider>
        <FormWrapper id="submit-form" onSubmit={handleSubmit(submitForm)} >
          <MainWrapper>

            {/* ------Work related -------------------------- */}

            <FormComponentWrapper>
              <ComponentName>
                Shared Chit Color
              </ComponentName>


              <ComponentWrapper>
                <RadiotWrapper >
                  <ChitRadio
                    name={"formChitColor"}
                    control={control}
                    label={"formChitColor"}

                    options={[
                      {
                        label: "gold",
                        value: "gold",
                      },
                      {
                        label: "silver",
                        value: "silver",
                      },
                      {
                        label: "copper",
                        value: "copper",
                      },
                      {
                        label: "red",
                        value: "red",
                      },

                    ]}
                    defaultValue={defaultValues.workRelated}
                  />
                </RadiotWrapper>





              </ComponentWrapper>
            </FormComponentWrapper>

            <FormComponentWrapper>
              <ComponentName>
                Title
              </ComponentName>



              <ChronicleInput
                name={"formTitle"}
                control={control}
                label={"formTitle"}
                defaultValue={''}
                placeholder=' Add a title'


              />
            </FormComponentWrapper>




            {/* ------Description  -------------------------- */}

            <FormComponentWrapper>
              <ComponentName>
                Message
              </ComponentName>

              <QuillComponentWrapper>
                <QuillWrapper>


                  <Controller

                    name="formSharedMessage"
                    control={control}
                    initialNote={'hi quill description'}

                    render={({ field }) => (
                      <Editor
                        {...field}
                        ref={null}
                        IniitalValue={defaultValues.formSharedMessage} />
                    )}

                  />

                </QuillWrapper>

              </QuillComponentWrapper>
              {errors.description && <ErrorMessage>{errors.description.message} </ErrorMessage>}
            </FormComponentWrapper>



            <FormComponentWrapper>
              <ComponentName>
                Your Name
              </ComponentName>



              <ChronicleInput
                name={"formSenderName"}
                control={control}
                label={"Your Shared ChitDisplayed Name"}
                defaultValue={defaultValues.senderName}
                placeholder='Your name or nickname'


              />
            </FormComponentWrapper>

            <FormComponentWrapper>
              <ComponentName>
                Other Party's Name
              </ComponentName>



              <ChronicleInput
                name={"formReceiverName"}
                control={control}
                label={"receiverName"}
                defaultValue={defaultValues.receiverName}
                placeholder=' Your name'


              />
            </FormComponentWrapper>

          </MainWrapper>

          {/* end twoPartyCHitId &&  */}

          {/* ------Submit ---------- -------------------------- */}
          <BottomWrapper>
            <StyledButton

              variant="contained"
              color="primary"
              style={{
                textTransform: 'none',

              }}
              onClick={() => cancelNewForm()}

            >
              Cancel
            </StyledButton>

            <ButtonWrapper>
              <StyledButton
                type="submit"
                variant="contained"
                color="primary"
                style={{ textTransform: 'none' }}


              >
                Preview
              </StyledButton>

            </ButtonWrapper>


          </BottomWrapper>
        </FormWrapper>

      </FormProvider>

    </Wrapper>
  )// end return
} // end function



// ==== Styles ===========================================


const Wrapper = styled(Paper)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  // zIndex: '95',
  // marginTop: '2rem',
  width: '100%',
  height: '99%',
  overflow: 'auto',
  // border: '2px solid #F285B5',


  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const Header = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  color: chitBurgandy,

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',
    // backgroundColor: 'red'
  },

})


const Description = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  color: mediumLightGrey,
  fontSize: '.8rem',
  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',
    // backgroundColor: 'red'
  },

})

const FormWrapper = styled('form')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '98%',
  height: '100%',
  margin: '5px 0',
  padding: '4px',
  backgroundColor: 'white',



  [theme.breakpoints.down('sm')]: {
    width: '100%',


  },

})

const MainWrapper = styled('div')({

  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '98%',
  height: '100%',
  marginBottom: '6px',
  paddingBottom: '6px',

  // backgroundColor: 'green',


  [theme.breakpoints.down('sm')]: {
    // width: '100%'
  },


})







// ##############################################

const FormComponentWrapper = styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '90%',
  // margin: '.25rem',
  marginBottom: '8px',
  // backgroundColor: 'yellow',

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const FormComponentWrapperIndent = styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '90%',
  marginLeft: '2rem',

  // backgroundColor: 'yellow',

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const FormComponentWrapperDoubleIndent = styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '90%',
  marginLeft: '3rem',

  // backgroundColor: 'yellow',

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const ComponentName = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  color: darkGrey,
  fontSize: '.9rem',


  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const ComponentWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const NewInputContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '13rem',
  marginLeft: '9px',

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const NewMetaContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '20rem',
  marginLeft: '9px',
  border: '1px solid grey',
  broderRadius: '5px',

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const NewSelectContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '15.5rem',


  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})


const RadiotWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',


  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const SelectWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '80%',


  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})







const CreateNewWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',



  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const NewWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '80%',



  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})











const ErrorMessage = styled('div')({
  fontSize: '.8rem',
  color: 'red',

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})
// #######################################


//  --- Buttons -----------
const BottomWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  width: '95%',
  margin: '.75rem',


  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const ButtonWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',



  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const StyledButton = styled(Button)({
  backgroundColor: 'white',
  border: '1px solid #E6E7E8',
  color: chitBurgandyDull,
  margin: '0 8px',
  width: '5rem',
  height: '1.5rem',
  fontSize: '.8rem',
  '&:hover': {
    backgroundColor: lightGrey
  }

})


const EditNameWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',

  color: darkGrey,
  fontSize: '.9rem',
  marginLeft: '12px',

  '& span': {
    color: chitBurgandy,
    marginRight: '.5rem',
    fontSize: '.85rem'
  },

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const Instructions = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',

  color: mediumGrey,
  fontSize: '.7rem',




})




const QuillComponentWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
  // border: '1px solid grey',
  borderRadius: '5px',
  // backgroundColor: 'white',
  padding: '10px',
  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },
})

const QuillWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '95%',
  height: '95%',
  border: '1px solid orange',
  borderRadius: '5px',
  backgroundColor: 'white',
  padding: '2px',
  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },
})