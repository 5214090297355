import React  from 'react'
import{chitRedDark, chitBurgandy} from '../../../../styles/colors'


import ManBox from '../../../../images/slides/description/manBox.jpg'


import { styled, createTheme  } from "@mui/material/styles"
const theme = createTheme(); // allows use of mui theme in styled component

// -----------------------------------------------------------------

const NarWrapper= styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  width: '70%',
  
  [theme.breakpoints.down('sm')] : {
    width: '100%',
 
  }

})
const NarHeader= styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  // padding: '0 auto 0 auto',
  color: chitBurgandy,
  // marginTop: '2rem',
  fontSize: '1rem',
  fontWeight: 'bold',
  // // letterSpacing: '.1rem',
  textAlign: 'center'  ,
  width: '80%',
  

  [theme.breakpoints.down('sm')] : {
    justifyContent: 'center',
    fontSize: '.9rem',
    width: '100%',
  },

  [theme.breakpoints.down('xs')] : {
    // width: '65%',
    // fontSize: '1.2rem',
  }

})

 

const NarDetail= styled('div')({


    display: 'flex',
    flexDirection: 'column',
    // padding: '0 auto 0 auto',
    color: 'black',
     marginTop: '1rem',
    fontSize: '.8rem',
    textAlign: 'left'  ,
    width: '80%',

    '& div': {
      padding: '0',
      margin: '3px'
    },

    '& span': {
      fontWeight:'bold'
    },

    '& em': {
      color: chitRedDark,
      fontStyle: 'normal',
      fontWeight: 'bold'
    },
  [theme.breakpoints.down('sm')] : {
    
    textAlign: 'left'  ,
    width: '80%',
  },
  [theme.breakpoints.down('xs')] : {
    width: '80%',
    
  }

})

// ----Slide 1 ------------------



const PictureStyle= styled('div')({

  display: 'flex',
  jutifyContent: 'center',
  alignItems: 'center',
  
  width: '60%',


  marginBottom: '1rem',

  [theme.breakpoints.down('sm')] : {
    width: '80%',
    jutifyContent: 'flex-start',
     
    marginBottom: '1rem'
  }
})








const PhonePortraitStyle= styled('img')({
  display: 'block',
  margin: 'auto',
  width: '18rem',
   
})



export const Picture6= () => {
  return (
    <PictureStyle>
      <PhonePortraitStyle src= {ManBox}   alt="Fired Man" /> 
      {/* <PhonePortraitStyle src= {ManComputer}   alt="Man on Computer" />  */}
  </PictureStyle>
  )
}


export const Narration6= () => {
  return (
    <NarWrapper>
      <NarHeader>
      Why use ChitGit - #7 
      </NarHeader>
      
      <NarDetail>
        <div>
          ChitGit data is yours.  It travels with you over time and distance.  
          ... throughout your
          career and life.
        </div>

        <div>
          Recall it when you need it -  6 days from now ... 6 months from now,  or ... 6 years from now.


        </div>
      </NarDetail>

    </NarWrapper>
  )
}