
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { Scrollbars } from 'react-custom-scrollbars';

import { chitBlueDull, mediumGrey, veryLightGrey, lightGrey, chitBurgandyDull, mediumLightGrey, chitLightBlueDull } from '../styles/colors';

import {
  addDocumentToFirestore,
  getDocumentFromFirestore
} from '../app/firebase/fireStore.utils';

import { FormProvider, useForm, Controller } from "react-hook-form";

import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';

import { UTCtoDate, UTCtoISO } from '../app/helpers/dateHelper';
// ---Retrieve all needed collections from Redux store -------

import { changeLoadingStatus, selectStatus, closeFirstSectionForm, updateFirstView } from '../app/redux/statusRedux/statusSlice';

import { selectFirsts, addFirstToStore, } from '../app/redux/firstRedux/firstSlice';

import { Editor } from './formComponents/JournalEditor'

import { StyledJournalDatePicker } from './formComponents/StyledJournalDatePicker'

import { ChronicleInput } from './formComponents/ChronicleInput'

import { ChronicleInputMultiline } from './formComponents/ChronicleInputMultiline'

// --- MUI imports ---------

import InfoIcon from '@mui/icons-material/Info';

import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import { styled, createTheme } from '@mui/material/styles'


const theme = createTheme(); // allows use of mui theme in styled component

// ========================================================
// ========================================================
export default function NewFirstSectionForm(props) {

  const dispatch = useDispatch()
  const status = useSelector(selectStatus)

  const uid = status.auth.loginId

  // --- open and closes form in JSecions_s


  // --- dialog box open and close
  const [open, setOpen] = React.useState(false);

  const handleClickAway = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // --- popover for search 
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const id = openPopover ? 'simple-popover' : undefined;

  // (1) ---Retrieve all needed collections from Redux store -------

  let retrievedFirstSections = useSelector(selectFirsts)
  const [allFirstSections, setAllFirstSections] = useState(retrievedFirstSections)
  useEffect(() => {
    setAllFirstSections(retrievedFirstSections)
  }, [retrievedFirstSections])



  // --- set up defaultValues

  let defaultValues, sectionId, detail, meta, name,
    sectionCreatedDate, dateTime, first, contactDate

  name = ''
  meta = ''
  detail = ''
  name = ''

  // -- set up current date to ISO format ---

  let today = Date.now()
  let ISODate = UTCtoDate(today)
  contactDate = new Date(ISODate)
  dateTime = new Date(ISODate)




  defaultValues = {
    name: name,
    detail: detail,
    meta: meta,
    lastEdit: dateTime,  // used in last Edit (auto)
    contactDate: contactDate  // Bob's login time Mar 14 - used in input field  

  };

  // --- close / cancel form 
  const cancelNewForm = () => {

    dispatch(closeFirstSectionForm())
    // navigate(`/sample/log`)
  }


  // ===========  FORM  ==================================================

  //  -- Input requirements for user for each component (if any)

  const formSchema = object({

    // journalContent: string().required('Your journal needs some content'),


  });

  const methods = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(formSchema)
  });

  const { handleSubmit, reset, control, setValue, onChange, watch, ref } = methods;


  const submitForm = async (data) => {
    console.log('[ FIRST SECTION FORM FORM FORM ] data ', data);

    cancelNewForm()
    // - replace the <p>s with <div>s in Quill editor to make it appear better
    let noPtagDetail = data.detail.replaceAll('<p>', '<div>')
    let cleanDetail = noPtagDetail.replaceAll('</p>', '</div>')

    let noPtagMeta = data.meta.replaceAll('<p>', '<div>')
    let cleanMeta = noPtagMeta.replaceAll('</p>', '</div>')

    let formattedlastEdit = new Date(data.lastEdit).toISOString()
    let formattedFirstDate = new Date(data.contactDate).toISOString()


    try {

      let cleanedData = {
        detail: cleanDetail,
        name: data.name,
        meta: cleanMeta,
        lastEdit: formattedlastEdit,
        contactDate: formattedFirstDate,

      }



      const newFirstSectionId = await addDocumentToFirestore(uid, 'firstContacts', cleanedData)

      console.log('[ NewFirstSectionForm ] newFirstSectionId  is : ', newFirstSectionId);

      const newFirstSectionsData = await getDocumentFromFirestore(uid, 'firstContacts', newFirstSectionId)


      let firstSectionData = newFirstSectionsData.data()

      console.log('[ NewFirstSectionForm ] newFirstSectionId  is : ', firstSectionData);

      let newFirstSectionObject = {
        id: newFirstSectionId,
        name: firstSectionData.name,
        detail: firstSectionData.detail,
        meta: firstSectionData.meta,
        lastEdit: formattedlastEdit,
        contactDate: formattedFirstDate,
        timeLock: '',

      }


      dispatch(addFirstToStore(newFirstSectionObject))
      dispatch(updateFirstView({ pageType: 'firstContact', sectionId: newFirstSectionId }))


      cancelNewForm()
    } catch (error) {
      alert(error)
      cancelNewForm()
    }







  } // end submitForm

  // --- MAIN Return --------------------------


  // === Main Return ===============================



  return (



    <Wrapper>



      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">

        </DialogTitle>
        <DialogContent>
          <div>
            Your new first contact has not been saved.  <br /> Either save it  or cancel.
          </div>
        </DialogContent>
        <DialogActions>


          <StyledButton
            form="submit-form"
            variant="contained"
            color="primary"

            onClick={handleClose}
          >
            Got it
          </StyledButton>

        </DialogActions>
      </Dialog>


      <FormProvider {...methods}>

        <ClickAwayListener
          onClickAway={handleClickAway}
          mouseEvent="onMouseDown"
          touchEvent="onTouchStart"
        >


          <FormWrapper id="submit-form" onSubmit={handleSubmit(submitForm)} >


            <MainWrapper>


              <ButtonWrapper>

                <StyledButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{ textTransform: 'none' }}
                >
                  Save and Close

                </StyledButton>

                <StyledButton onClick={() => cancelNewForm()}> Cancel</StyledButton>
              </ButtonWrapper>


              <TitleWrapper>

                <ChronicleInput
                  name={"name"}
                  control={control}
                  label={"newPerson"}
                  defaultValue={''}
                  placeholder='name - or - descriptor'


                />


              </TitleWrapper>


              <DateContainer>
                <CalendarTodayIcon style={{
                  color: '#A7A7A8',
                  fontSize: '1.2rem',
                  marginLeft: '5px'
                }}
                />
                <DateWrapper>


                  <Controller

                    name="contactDate"
                    control={control}


                    render={({ field }) => (
                      <StyledJournalDatePicker {...field} ref={null} />
                    )}
                  />

                </DateWrapper>

              </DateContainer>



              <OuterContentWrapper>

                <TitleWrapper>

                  <ChronicleInputMultiline
                    name={"meta"}
                    control={control}
                    label={"meta"}
                    defaultValue={''}
                    placeholder=' location or time'


                  />


                </TitleWrapper>


                <ContentWrapper>

                  {/* Quill here */}

                  <Controller

                    name="detail"
                    control={control}
                    initialNote={'description'}

                    render={({ field }) => (
                      <Editor
                        {...field}
                        ref={null}
                        IniitalValue={defaultValues.detail} />
                    )}

                  />


                </ContentWrapper>


              </OuterContentWrapper>



            </MainWrapper>


          </FormWrapper>
        </ClickAwayListener>
      </FormProvider>



    </Wrapper>


  )
}

// -----------


// ==== Styles ===========================================


const Wrapper = styled(Paper)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  // zIndex: '95',
  marginLeft: '3%',
  backgroundColor: 'white',
  width: '94%',
  height: '100%',
  // overflow: 'auto',



  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',
    // backgroundColor: 'red'
  },

})

//  --- Buttons Wrapper  ---------------------------------

const ButtonWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  margin: '0 0 6px  0',
  padding: '3px 0',

  backgroundColor: mediumLightGrey,
  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const StyledButton = styled(Button)({
  backgroundColor: 'white',
  border: '1px solid #E6E7E8',
  color: chitBurgandyDull,
  margin: '0 8px',
  padding: ' 0 1rem',
  height: '1.5rem',
  fontSize: '.8rem',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: lightGrey
  }

})

// ---- search Inputs ---------------------------------------

const SearchWrapper = styled('div')({

  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',

  width: '100%',
  padding: '2px 8px',
  margin: '0 0 3px o',
  backgroundColor: veryLightGrey,
  fontSize: '.6rem',
  height: '2rem',
  color: mediumGrey,
  [theme.breakpoints.down('sm')]: {
    // width: '100%'
  },


})

const SearchTitle = styled('div')({

  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',

  marginRight: '1.5rem',
  fontSize: '.75rem',
  fontStyle: 'italic',
  height: '100%',
  [theme.breakpoints.down('sm')]: {
    // width: '100%'
  },


})


const PeopleWrapper = styled('div')({

  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  // backgroundColor: 'aqua',
  width: '30%',
  height: '100%',
  [theme.breakpoints.down('sm')]: {
    // width: '100%'
  },


})

const KeyWordWrapper = styled('div')({

  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  // backgroundColor: 'orange',
  marginLeft: '8px',
  width: '40%',

  [theme.breakpoints.down('sm')]: {
    // width: '100%'
  },


})




const FormWrapper = styled('form')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '98%',
  height: '100%',
  margin: '5px 0',
  padding: '4px',
  backgroundColor: 'white',



  [theme.breakpoints.down('sm')]: {
    width: '100%',


  },

})

const MainWrapper = styled('div')({

  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  // backgroundColor: 'green',
  width: '98%',
  height: '100%',
  marginBottom: '6px',
  paddingBottom: '6px',

  [theme.breakpoints.down('sm')]: {
    // width: '100%'
  },


})


const DateContainer = styled('div')({

  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '98%',

  padding: '2px 8px',
  margin: '0 0 3px o',
  backgroundColor: veryLightGrey,



  width: '100%',
  margin: '3px 0',

  height: '2rem',
  [theme.breakpoints.down('sm')]: {
    // width: '100%'
  },


})

const DateWrapper = styled('div')({

  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  // backgroundColor: 'green',
  width: '35%',
  padding: '2px 6px',

  fontSize: '.8rem',
  height: '.8rem',

  '& span': {
    color: mediumGrey,
    marginLeft: '6px',

  },

  [theme.breakpoints.down('sm')]: {
    width: '100%'
  },


})


const OuterContentWrapper = styled('div')({

  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',

  width: '100%',


  [theme.breakpoints.down('sm')]: {
    // width: '100%'
  },


})

// ---- Content Wrapper -------------------------------------

const ContentWrapper = styled('div')({

  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  border: '1px solid pink',
  width: '100%',
  // marginTop:'6px',
  margin: 'auto',



  [theme.breakpoints.down('sm')]: {
    // width: '100%'
  },


})


const TitleWrapper = styled('div')({

  display: 'flex',

  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  fontSize: '.75rem',
  width: '100%',
  margin: '5px 0 8px 0',
  padding: '2px 8px',





  [theme.breakpoints.down('sm')]: {
    // width: '100%'
  },


})

const TimesWrapper = styled('div')({

  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',

  width: '100%',
  padding: '2px 6px',

  fontSize: '.65rem',
  color: mediumGrey,

  [theme.breakpoints.down('sm')]: {
    // width: '100%'
  },


})

const HeadlineWrapper = styled('div')({

  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  color: chitBlueDull,
  width: '99%',
  padding: '6px 0',

  [theme.breakpoints.down('sm')]: {
    // width: '100%'
  },


})





const Content = styled('div')({
  flexGrow: 1,
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  fontSize: '.85rem',
  width: '70%',


  // borderLeft: '1px solid #E6E7E8',
  backgroundColor: 'white',

  [theme.breakpoints.down('sm')]: {
    // width: '100%'
  },


})



// ##############################################

const FormComponentWrapper = styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '100%',
  margin: '.5rem',


  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const ComponentName = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  color: 'darkGrey',


  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const ComponentWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',


  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const InfoIconWrapper = styled(InfoIcon)({

  color: mediumLightGrey,
  fontSize: '1.6rem',

  '&:hover': {
    backgroundColor: chitLightBlueDull,
    borderRadius: '50px',
    cursor: 'pointer'
  },

})

const NewFormSectionContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',


  width: '94%',
  // height: '65%',
  // maxHeight: '23rem',
  marginLeft: '3%',
  padding: '4px 0 8px 0',
  border: '2px solid orange',
  borderRadius: '5px',
  // minHeight: '10rem',
  // height: '90%',


  // overflowY: 'hidden',

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },



})



