import React from 'react'

import { useNavigate } from 'react-router-dom'
 
import { backgroundBlue, chitBurgandy, chitOrange, mediumLightGrey, veryLightGrey } from '../../../../styles/colors';
import { Scrollbars } from 'react-custom-scrollbars';

import Journal from '../../../../images/slides/features/journal_sample.jpg'
import Log from '../../../../images/slides/features/log_sample.jpg'
import Form from '../../../../images/slides/features/form_sample.jpg'
import ChronicleDiagram from '../../../../images/slides/features/chronicleDiagram.svg'

import Topical from '../../../../images/slides/features/topical_sample.jpg'
import TopicalNote from '../../../../images/slides/features/topicalNote_sample.jpg'
import People from '../../../../images/slides/features/people.jpg'


import Group from '../../../../images/slides/features/group.jpg'

import ToolBar from '../../../../images/slides/features/toolBar_sample.jpg'
import LockClockIcon from '@mui/icons-material/LockClock';
import CachedIcon from '@mui/icons-material/Cached';
import GoldCoin from '../../../../images/chitCoins/gold_standard.svg'
import SilverCoin from '../../../../images/chitCoins/silver_personal.svg'
import CopperCoin from '../../../../images/chitCoins/copper_promise.svg'
import AwChit from '../../../../images/chitCoins/awChit.svg'
import LogoIconWhite from '../../../../images/logo_icon_white.svg'
import { styled, createTheme  } from "@mui/material/styles"
const theme = createTheme(); // allows use of mui theme in styled component
 

// ==============================================

export default function FeatureSlides_chronicles(props) {
  return (
    <SlideContainer>
      <Header> Logs and Topicals</Header>
      <Scrollbars>
        <SlideContent>


          <SlideWrapper>

            <NarrationWrapper>
              <Title> Logs vs Topicals</Title>
              <Narration>
                Logs and Topicals are similar in look but each serves a different function.  Logs are about you and another person.  Topicals are about you and a topic.
              </Narration>
            </NarrationWrapper>

            <PictureWrapper>
              <Picture src={Topical} alt="Chronicle Diagram" />
            </PictureWrapper>

          </SlideWrapper>




          <SlideWrapper>

            <NarrationWrapper>
              <Title> Sections</Title>
              <Narration>
                Both Logs and Chronicles are  divided into sections.  They create a timeline of interactions, events and ideas.  

              </Narration>
            </NarrationWrapper>

            <PictureWrapper>
              <Picture src={Log} alt="Chronicle sections" />
              
            </PictureWrapper>

          </SlideWrapper>





          <SlideWrapper>

            <NarrationWrapper>
              <Title> Logs</Title>
              <Narration>
                Logs is designed to keep track of interactions between you and another party... or you and a group.  There is always another party involved.   One use of logs is to create a written case during a dispute with the other party.
              </Narration>
            </NarrationWrapper>

            <PictureWrapper>
              <PeoplePicture src={People} alt="people" />
              <Picture src={Group} alt="group" />
            </PictureWrapper>

          </SlideWrapper>





          <SlideWrapper>

            <NarrationWrapper>
              <Title> Topicals</Title>
              <Narration>
                Chit Git Topicals are used to chronicle a “story” about a topic.  Keep track of thoughts, developments, discoveries or interactions with respect to a topic... like starting a business or project... or self-improvement endeavor, etc.
              </Narration>
            </NarrationWrapper>

            <PictureWrapper>
              <Picture src={Topical} alt="Topical" />
            </PictureWrapper>

          </SlideWrapper>




          <SlideWrapper>

            <NarrationWrapper>
              <Title> Topical Notes</Title>
              <Narration>
                In addition to sections, topicals can have notes attached to the topic.  Use notes for very short entries - like web links or phone numbers, etc.
              </Narration>
            </NarrationWrapper>

            <PictureWrapper>
              <Picture src={TopicalNote} alt="Topical notes" />
            </PictureWrapper>

          </SlideWrapper>


          <SlideWrapper>

            <NarrationWrapper>
              <Title> Time Lock</Title>
              <Narration>
                Both Log and Topical sections can be time locked.  A time locked section can NOT be edited.  This creates a unchangeable, permanent record of when it was written which can be useful in legal disputes.
              </Narration>
            </NarrationWrapper>

            <PictureWrapper>
              <ChitsWrapperBottom>

                <StyledToolBar src={ToolBar} alt="Chit Git Logo" />
                <div> Time Lock - </div>
                <LockWrapper>
                  <TimeLockIcon alt="Chit Git Logo" />
                </LockWrapper>

              </ChitsWrapperBottom>
            </PictureWrapper>

          </SlideWrapper>


          {/* ============================================================ */}

          <SlideWrapper>

            <NarrationWrapper>

              <Narration>
                End - Use tabs above to see features of Chit Git tools.
              </Narration>
            </NarrationWrapper>



          </SlideWrapper>





        </SlideContent>

      </Scrollbars>
    </SlideContainer>
  );
}

 
const SlideContainer= styled('div')({

display: 'flex',
flexDirection: 'column',
justifyContent: 'flex-start',
alignItems: 'center',

width: '100%',
height: '100%',
// marginBottom: '5rem',
backgroundColor: veryLightGrey,
borderBottom: '1px solid #E6E7E8',



[theme.breakpoints.down('sm')] : {

},

[theme.breakpoints.down('sm')] : {
   
},

[theme.breakpoints.down('xs')] : {
   
  
}


})

const Header= styled('div')({

display: 'flex',
flexDirection: 'column',
justifyContent: 'center',
alignItems: 'center',
fontSize: '1.3rem',
fontWeight: 'bold',
color: 'white',
width: '100%',
minHeight: '4rem',
// marginBottom: '5rem',
background: backgroundBlue,
marginBottom: '3px',



[theme.breakpoints.down('sm')] : {

},

[theme.breakpoints.down('sm')] : {
   
},

[theme.breakpoints.down('xs')] : {
   
  
}


})

const SlideContent= styled('div')({

display: 'flex',
flexDirection: 'column',
justifyContent: 'center',
alignItems: 'center',

width: '99%',
minHeight: '15rem',
// marginBottom: '5rem',
background: 'white',
borderBottom: '1px solid #E6E7E8',



[theme.breakpoints.down('sm')] : {

},

[theme.breakpoints.down('sm')] : {
   
},

[theme.breakpoints.down('xs')] : {
   
  
}


})

const SlideWrapper= styled('div')({

display: 'flex',
justifyContent: 'center',
alignItems: 'center',
flexDirection: 'column',
width: '100vw',
minHeight: '15rem',
// marginBottom: '5rem',
padding: '.5rem 0 1.5rem 0',

maxWidth: '65rem',
boxShadow : '2px 4px #A7A7A8',
// overflow: 'hidden',

[theme.breakpoints.down('sm')] : {
  flexDirection: 'column',
  padding: '.5rem 0',
  width: '96vw',
    
}
// backgroundColor: 'green'

})



const NarrationWrapper= styled('div')({

display: 'flex',
flexDirection: 'column',
jutifyContent: 'center',
alignItems: 'center',
width: '92%',
padding: '1% 4%',
height: '100%',

backgroundColor: 'white',

marginBottom: '1.5rem',
borderBottom: '1px solid grey',
[theme.breakpoints.down('sm')] : {
  width: '100%',
  
}
})

const Title = styled('div')({

display: 'flex',
flexDirection: 'column',
jutifyContent: 'center',
alignItems: 'center',
width:  '100%',
color: chitBurgandy,
fontSize: '1rem',
fontWeight: 'bold',
marginBottom: '8px',
textDecoration: 'underline',
[theme.breakpoints.down('sm')] : {
  width: '100%',
  
}
})

const Narration = styled('div')({

display: 'flex',
flexDirection: 'column',
jutifyContent: 'center',
alignItems: 'center',
width:  '50%',
 
fontSize: '.85rem',
[theme.breakpoints.down('sm')] : {
  width: '100%',
  
}
})

const PictureWrapper= styled('div')({

display: 'flex',
flexDirection: 'column',
jutifyContent: 'center',
alignItems: 'center',
width: '70%',


// height: '100%',
[theme.breakpoints.down('sm')] : {
  width: '100%',
  jutifyContent: 'flex-start',
}

})



const Picture= styled('img')({
display: 'block',
margin: 'auto',
width: '24rem',
 
})

const PeoplePicture= styled('img')({
  display: 'block',
  margin: 'auto',
  width: '7rem',
   
  })

const StyledToolBar= styled('img')({
  display: 'block',
  marginRight: '12px',
  

   
})
const ChitsWrapperBottom = styled('div')({
 
       
  display: 'flex',
  justifyContent: 'center',

  alignItems: 'center',

   height: '5rem',
  marginTop: '1rem',
  width: '98%',
 


})
const ConvertOrange= styled(CachedIcon)({
  backgroundColor: 'orange',
  borderRadius: '5px',
  fontSize: '1.4rem',
  color: 'white',
  margin: '0 .5rem .3rem 0',
  cursor: 'pointer',
 

  '&:hover': {
    backgroundColor: mediumLightGrey
    // backgroundColor: mediummediumLightGrey
  },

})


const IconTitle= styled('div')({
  display: 'flex',
  // marginRight: '18px',
 margin: '1.5rem',
   
})



const StyledIconWrapper= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  height: '100%',
  

   
})


const CoinStyle= styled('img')({

  height: '2.5rem',

  marginRight: '14px',
})


const LogoStyle= styled('img')({

  height: '1.4rem',
  width: '1.4rem',
  // marginRight: '14px',
})

const ChitsWrapperSmall = styled('div')({
 
       
  display: 'flex',
  justifyContent: 'center',
  
   
  marginTop: '1rem',
  
  height: '3rem',
  width: '98%',

  


})


const LogoWrapperOrange= styled('div')({

  height: '2rem', 
  width: '2rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: '8px',
  
 
borderRadius: '50px',
  backgroundColor: chitOrange,
  '&:hover': {
    backgroundColor: mediumLightGrey
    // backgroundColor: mediummediumLightGrey
  },


})

const LockWrapper= styled('div')({

  height: '2.25rem', 
  width: '2.25rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: '8px',
  
 
 
 
  '&:hover': {
    backgroundColor: mediumLightGrey
    // backgroundColor: mediummediumLightGrey
  },


})


const TimeLockIcon= styled(LockClockIcon)({
  backgroundColor: 'white',
  borderRadius: '5px',
  fontSize: '2rem',
  color: chitOrange,
 
  margin: '0 .5rem .3rem .5rem',
  cursor: 'pointer',
  


  '&:hover': {
    color: mediumLightGrey
    // backgroundColor: mediumLightGrey
  },


  [theme.breakpoints.down('sm')] : {
    // height: '1.25rem',
    // backgroundColor: 'red'
  },
})