/* pages/private/Home in chitgit_2022

Equivalent to Landing Page...  for authenticated user

contains: 
 - links to load different apps
 - Inspiration page display

Each link goes to pages/private/Main/:{page to load}

Special Circumstances: 
   1. logged in user goes to www.chitgit/sharedChit/:id
       show the received chit
  
   2. if NON-logged in user first goes to www.chitgit/sharedChit/:id
      AND then logs in
        show Dialog box

*/

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom'
import { handleRefresh } from '../../common_components/handleRefresh';
import { backgroundBlue, chitBurgandyDull, lightGrey } from '../../styles/colors';
// import FirebaseAuthService from '../../app/firebase/FirebaseAuthService';
// import { selectUid } from '../../app/redux/statusRedux/statusSlice';

import { updateAuth, updateUid } from '../../app/redux/statusRedux/statusSlice';
import { signOutUser } from '../../app/firebase/firebase.utils';

import { UTCtoDateTime } from '../../app/helpers/dateHelper';

import { getCollectionFromFirestore } from '../../app/firebase/fireStore.utils';
import Modal from '../../common_components/modal/ModalComponent';
import { openModal } from '../../app/redux/statusRedux/statusSlice';
// -----------------
import MainHeader from '../navComponents/privateNav/Main_header';
import MainLinks from '../navComponents/privateNav/MainPage_bigLinks';
import Aspire from '../../images/aspire.jpg'

import ReceivedSharedChitUser from './sharedChits/ReceivedSharedChitUser';

import { Scrollbars } from 'react-custom-scrollbars';

// ###### Import all collections from Redux   ####################

import { addAllPeopleFromFirestore } from '../../app/redux/peopleRedux/peopleSlice';

import { addAllCategoriesFromFirestore } from '../../app/redux/categoryRedux/categorySlice';

import { addAllGroupsFromFirestore } from '../../app/redux/groupRedux/groupSlice';

import { addAllLogHoldersFromFirestore } from '../../app/redux/logHolderRedux/logHolderSlice';

import { addAllLogsFromFirestore } from '../../app/redux/logRedux/logsSlice';

import { addAllFirstsFromFirestore } from '../../app/redux/firstRedux/firstSlice';

import { addAllNotesFromFirestore } from '../../app/redux/noteRedux/notesSlice';

import { addAllPersonalChitsFromFirestore } from '../../app/redux/personalChitRedux/personalChitSlice';

import { addAllTopicalSectionsFromFirestore } from '../../app/redux/topicalRedux/topicalSectionsSlice';

import { addAllTopicsFromFirestore } from '../../app/redux/topicalRedux/topicsSlice';

import { addAllTwoPartyChitsFromFirestore } from '../../app/redux/twoPartyChitRedux/twoPartyChitsSlice';

import { addAllSharedChitsFromFirestore } from '../../app/redux/sharedRedux/sharedChitsSlice';

import { addAllReceivedChitsFromFirestore } from '../../app/redux/receivedRedux/receivedChitsSlice';

import { selectStatus, changeLoadingStatus } from '../../app/redux/statusRedux/statusSlice';

import { initializeStatus } from '../../app/redux/statusRedux/statusSlice';

// ###############################################################

// import { addCollectionToFirestore } from '../../app/firebase/fresStore.utils';
import { tempData } from './tempData'



// --- MUI

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button'

import { styled, createTheme } from "@mui/material/styles"
const theme = createTheme(); // allows use of mui theme in styled component

let calendarStart = Date.now()
let calendarStartMonth = UTCtoDateTime(calendarStart).month
let calendarStartYear = UTCtoDateTime(calendarStart).year


// ======= HOME - main function ===========================

const Home = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const match = useParams()


 

  /*
    If logged in user goes to www.chitgit.com/sharedChit/:id
        show the shared chit
  
        if sharedChitId --->  get SharedChit from public shared chit
                              collection and put it into redux
        add popup that says ---> new received shared chit
                                    view it or add it
  
    Else - show Home Page Links                              
  
  */

  let sharedChitId = match.sharedChitId


  /*
    If non-logged in user first goes to a sharedChit AND THEN
    joins or logs in -- the received chit is already loaded 
    in the Redux store = status.view.forms.receivedChits
  
    if sharedChitStatus --->  get SharedChit data from redux
    show dialog box that says ---> new received shared chit
                                view it or add it
  
  */


  // --- Dialog Functions
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };



const initializeState = {


  loading: false,

  auth: {
    uid: '',
    loginStatus: false,

    lastVisit: '', // date time
  },

  modal: {

    modalDisplayed: false,
    modalParams: { b: 'null' }
  },

  accordionDisplay: {

    id: ''

  },

  sidePanel: {

    view: 'hide'

  },

  view: {

    // for Chits.jsx ----------------------------------


    twoParty: {

      id: '',
      display: 'ledger'  // ledger or calendar

    },


    personal: {
      // type: 'personalChits',
      display: 'ledger',
      id: ''
    },


    // for Logs.jsx ----------------------------------

    log: {
      display: '', // '' or logs or firstContact
      id: '',  // if no logId -- all else logId stuff
      sectionId: ''
    },

    // for Logs.jsx ----------------------------------

    firstContact: {

      sectionId: ''
    },


    // for Topicals.jsx ----------------------------------

    topical: {

      id: '',
      sectionId: '',
      displayType: 'all'
    },



    // for Inspiration.jsx ----------------------------------

    inspire: {

      inspireFilter: 'story', // quote or last (chit + log + etc) or picture or story
      inspireId: ''
      // TBD when you do inspiration ! 
    },

    // for Notes.jsx ----------------------------------

    forms: {

      twoPartyChitForm: {

        twoPartyChitId: '',
        formPage: 'who',
        completedPagesArray: [],
        chitType: '',
        chitValue: 0,
        chitBurden: 0,


        chitDate: '',

        otherPartyCollection: '',
        otherPartyId: '',


        person: '',

        group: '',


        deedPerformedBy: '',
        workRelated: '',
        description: '',
        keyWordArray: [],


      },

      sharedChitForm: {
        pageView: 'form',
        chitId: '',

        chitType: '',
        chitColor: '',

        senderName: '',
        receiverName: '',


        chitDate: '',
        sharedDate: '',

        deedPerformedBy: '',
        sharedTitle: '',

        description: ''



      },

      receivedChitForm: {},



    },


  }, // end lastView

  calendarMonthDisplay: {
    utc: calendarStart,
    monthName: calendarStartMonth,
    monthYear: calendarStartYear
  }

}



  // ###############################################################
  // ###############################################################
  // ###############################################################
  // ###############################################################


  // const [user, setUser ] = useState(null)
  let status = useSelector(selectStatus)
  let loginId = status.auth.loginId
  let sharedChitStatus = status.view.forms.receivedChitForm

  // console.log('[ sharedChitStatu] myVar ', sharedChitStatus);



  // ###############################################################
  // ########## Adds Firestore data from X_initialStore #######
  // ###############################################################
  //   useEffect(() => {
  // addCollectionToFirestore()


  //   }, [])
  // ###############################################################
  // ###############################################################

  // let collections = [ categories, groups, logHolders, logs, notes, organizations, people, personalChits, topicalSections, topics, twoPartyChits]

  // if there was a received chit before login or join - open dialog



  useEffect(() => {
    let receivedChit = Object.keys(sharedChitStatus)
    if (sharedChitStatus.sharedChitId && loginId) {

      setOpen(true)
    }

    const getdata = async () => {

      // changeLoadingStatus to true - spinner
      dispatch(changeLoadingStatus(true)) 

      const groups = await getCollectionFromFirestore(loginId, 'groups')
      if (groups) { dispatch(addAllGroupsFromFirestore(groups)) }


      const people = await getCollectionFromFirestore(loginId, 'people')
      if (people) { dispatch(addAllPeopleFromFirestore(people)) }

      const categories = await getCollectionFromFirestore(loginId, 'categories')
      if (categories) { dispatch(addAllCategoriesFromFirestore(categories)) }

      const logHolders = await getCollectionFromFirestore(loginId, 'logHolders')
      if (logHolders) { dispatch(addAllLogHoldersFromFirestore(logHolders)) }

      const firstContacts = await getCollectionFromFirestore(loginId, 'firstContacts')
      if (firstContacts) { dispatch(addAllFirstsFromFirestore(firstContacts)) }


      const logs = await getCollectionFromFirestore(loginId, 'logs')
      if (logs) { dispatch(addAllLogsFromFirestore(logs)) }

      const notes = await getCollectionFromFirestore(loginId, 'notes')
      if (notes) { dispatch(addAllNotesFromFirestore(notes)) }

      const personalChits = await getCollectionFromFirestore(loginId, 'personalChits')
      if (personalChits) { dispatch(addAllPersonalChitsFromFirestore(personalChits)) }

      const topicalSections = await getCollectionFromFirestore(loginId, 'topicalSections')
      if (topicalSections) { dispatch(addAllTopicalSectionsFromFirestore(topicalSections)) }

      const topics = await getCollectionFromFirestore(loginId, 'topics')
      if (topics) { dispatch(addAllTopicsFromFirestore(topics)) }


      const twoPartyChits = await getCollectionFromFirestore(loginId, 'twoPartyChits')
      if (twoPartyChits) { dispatch(addAllTwoPartyChitsFromFirestore(twoPartyChits)) }

      const sharedChits = await getCollectionFromFirestore(loginId, 'sharedChits')
      if (sharedChits) { dispatch(addAllSharedChitsFromFirestore(sharedChits)) }

      const receivedChits = await getCollectionFromFirestore(loginId, 'receivedChits')
      if (sharedChits) { dispatch(addAllReceivedChitsFromFirestore(receivedChits)) }

      // dispatch(initializeStatus(initializeState))
    }



    getdata()

    // console.log('[ HOME ] userId ', uid );

          // changeLoadingStatus to false - spinner
          dispatch(changeLoadingStatus(false)) 

  }, [])


  // const  handleLogout =()=>{
  //   FirebaseAuthService.logoutUser()
  //   dispatch(updateUid(''))

  // }

  const handleSignOut = () => {

    signOutUser()
    dispatch(updateAuth({
      loginId: ''

    }))
    navigate(`/`)
  }


  const openForm = () => {

    //  define which Form to open in Modal by passing
    //  dbCollection to Modal depending on pageView in browser URL 
    // ------------------------------------------------------------

    // props.openModal(dbCollection, id)

    // console.log('[ HOME - open form ] sharedChit - I be opened ', sharedChitId);
    dispatch(openModal(
      {
        modalParams: {
          modalType: 'form',
          dbCollection: 'receivedChit',
          data: sharedChitStatus,
          receivedChit: sharedChitId
        }
      }

    ))
    setOpen(false)
  }// end openForm

  return (
    <>
      <Modal />

      <Container>

        <Scrollbars>
          <Wrapper>



            <MainHeader />

            {!sharedChitId && <>


              <ButtonContainer>
                <ButtonsWrapper>
                  <MainLinks />
                </ButtonsWrapper>
              </ButtonContainer>

              <InspirationWrapper>

                <InspirationPicture src={Aspire} alt="Aspire" />

              </InspirationWrapper>


            </>}

            {sharedChitId && <>
              <ReceivedSharedChitUser sharedChitId={sharedChitId} />
            </>}

          </Wrapper>
        </Scrollbars>
      </Container>
    </>
  )
}

export default Home


// -----------------------------------------------------------------

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  position: 'relative',
  backgroundColor: backgroundBlue,
  height: '100vh',

  fontFamily: 'Lato, sans-serif',
  overflowY: 'auto',
  overflowX: 'hidden',
  [theme.breakpoints.down('sm')]: {

    alignItems: 'center',
    width: '100%',
    padding: '0',


  }


  // backgroundColor: testColors.testGreen

})

const Wrapper = styled('div')({

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',


  [theme.breakpoints.down('sm')]: {


  }

})
const ButtonContainer = styled('div')({



  display: 'flex',

  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '3rem',
  width: '100%',



  [theme.breakpoints.down('sm')]: {
    width: '100%'
  }



})

const ButtonsWrapper = styled('div')({



  display: 'flex',

  justifyContent: 'center',
  alignItems: 'center',

  width: '60%',



  [theme.breakpoints.down('sm')]: {
    width: '100%'
  }
})

const InspirationWrapper = styled('div')({



  display: 'flex',
  marginTop: '5rem',
  width: '55%',
  padding: '1.2%',
  backgroundColor: 'black',
  borderRadius: '10px',

  [theme.breakpoints.down('md')]: {
    width: '70%'
  },

  [theme.breakpoints.down('sm')]: {
    width: '98%'
  }
})







const InspirationPicture = styled('img')({
  display: 'block',

  width: '100%',




  [theme.breakpoints.down('sm')]: {
    width: '100%'
  }

})
const StyledButton = styled(Button)({
  backgroundColor: 'white',
  border: '1px solid #E6E7E8',
  color: chitBurgandyDull,
  margin: '1.2rem',
  // width: '5rem',
  padding: '0 3px',
  height: '1.5rem',
  fontSize: '.8rem',
  '&:hover': {
    backgroundColor: lightGrey
  }

})
