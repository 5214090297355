import React from 'react'

import { useNavigate } from 'react-router-dom'
 
import { backgroundBlue, chitBurgandy, chitOrange, mediumLightGrey, veryLightGrey } from '../../../../styles/colors';
import { Scrollbars } from 'react-custom-scrollbars';

//  Pictures ------------
import TwoPartyLedger from '../../../../images/slides/features/twoPartyLedger_sample.jpg'
import HandingCoin from '../../../../images/slides/description/handing_coin.jpg'

import TwoPartyChitToolbar from '../../../../images/slides/features/twoPartyChitToolbar_sample.jpg'
import Promise from '../../../../images/slides/features/promise.jpg'
import Promise2 from '../../../../images/slides/features/Promise2.jpg'
import Moving from '../../../../images/slides/description/moving.jpg'
import Homeless from '../../../../images/slides/features/homeless.jpg'
import BeachCleanup from '../../../../images/slides/features/beachCleanup.jpg'
import PromiseBreaker from '../../../../images/slides/features/promiseBreaker.jpg'
 
import SilverPromise from '../../../../images/chitCoins/silver_promise.svg'
import CopperStandard from '../../../../images/chitCoins/copper_standard.svg'
import SilverStandard from '../../../../images/chitCoins/silver_standard.svg'
import GoldCoin from '../../../../images/chitCoins/gold_standard.svg'
import SilverPersonal from '../../../../images/chitCoins/silver_promise.svg'
import CopperCoin from '../../../../images/chitCoins/copper_kindness.svg'
import AwChit from '../../../../images/chitCoins/awChit.svg'
import Slider from '../../../../images/slides/features/slider_sample.jpg'
import KarmicBalance from '../../../../images/slides/features/karmicBalance_sample.jpg'



import ShareIcon from '@mui/icons-material/Share';


import LogoIconWhite from '../../../../images/logo_icon_white.svg'
import { styled, createTheme  } from "@mui/material/styles"
const theme = createTheme(); // allows use of mui theme in styled component
 
 

export default function FeatureSlides_twoParty(props) {
  return (
    <SlideContainer>
      <Header> Two Party Chits</Header>
<Scrollbars>
      <SlideContent>

          <SlideWrapper>

            <NarrationWrapper>
              <Title> What are two party chits</Title>
              <Narration>
                Two party chits are tokens you can give or receive for something you did for someone else or something they did for you.  
              </Narration>
            </NarrationWrapper>

            <PictureWrapper>
              <PictureStyle>
                <CoinEnds>
                  <CoinStyle src={CopperStandard} alt="Copper" />
                  <div>Copper <br /> Chit</div>
                </CoinEnds>
                <CoinMiddle>

                  <CoinStyle src={GoldCoin} alt="gold Standard" />
                  <div>2 Party <br /> Chit</div>
                </CoinMiddle>
                <CoinEnds>
                  <CoinStyle src={SilverStandard} alt="Silver Promise" />
                  <div>Silver <br /> Chit</div>
                </CoinEnds>
              </PictureStyle>
            </PictureWrapper>

          </SlideWrapper>




          <SlideWrapper>

            <NarrationWrapper>
              <Title> Ledger</Title>
              <Narration>
                You can create a chit for your ledger irrespective of whether you performed the action <em>(a chit is owed to you)</em> or the other party performed the action <em>(you owe a chit)</em>.
              </Narration>
            </NarrationWrapper>

        



              <PictureWrapRow>
                <SmallPictureReverse src={HandingCoin} alt="handing coin" />
                <SmallPicture src={HandingCoin} alt="handing coin" />

              </PictureWrapRow>

              <Picture src={TwoPartyLedger} alt="ledger" />

 

          </SlideWrapper>

 
 

          <SlideWrapper>

            <NarrationWrapper>
              <Title> Private vs Shared Chits</Title>
              <Narration>
                Chits you create for your ledger are by default private.  The other party will not know about the chit. You can choose to let them know about the chit by sharing it with them. <Small>note: Chit Git does not need or require that you provide us the other party's email or any contact info to share a chit</Small>
              </Narration>
            </NarrationWrapper>

            <PictureWrapper>

              <ChitsWrapperBottom>
                <StyledToolBar src={TwoPartyChitToolbar} alt="Chit Git Logo" />
                <div> Share this chit - </div>
                <LogoWrapperOrange>
                  <IconStyle src={LogoIconWhite} alt="Chit Git Logo" />
                </LogoWrapperOrange>

              </ChitsWrapperBottom>




            </PictureWrapper>

          </SlideWrapper>


          <SlideWrapper>

            <NarrationWrapper>
              <Title> Chit Types</Title>
              <Narration>
              There are 4 types of two party chits… they each come in various colors.  The colors represent  values attached to the chits
              </Narration>
            </NarrationWrapper>

            <PictureWrapper>
              <PictureStyle>
                
                <CoinMiddle>

                  <CoinStyle src={GoldCoin} alt="gold Standard" />
                  <div>Standard <br /> Chit</div>
                </CoinMiddle>
                <CoinEnds>
                  <CoinStyle src={SilverPersonal} alt="Silver Personal" />
                  <div>Promise <br /> Chit</div>
                </CoinEnds>
                

                <CoinMiddle>

                  <CoinStyle src={CopperCoin} alt="copper" />
                  <div>Good Will<br /> Chit</div>
                </CoinMiddle>

                <CoinEnds>
                  <CoinStyle src={AwChit} alt="Silver Promise" />
                  <div>AwChit</div>
                </CoinEnds>
              </PictureStyle>
            </PictureWrapper>

          </SlideWrapper>


          <SlideWrapper>

            <NarrationWrapper>
              <Title> Standard Two Party Chit</Title>
              <Narration>
              Standard chits are the default chit created for two party interactions that have already taken place.
              </Narration>
            </NarrationWrapper>

    

              <PictureWrapRow>
              <CoinMiddle>

                  <CoinStyle src={GoldCoin} alt="gold Standard" />
                  <div>Standard <br /> Chit</div>
                </CoinMiddle>
                <MediumPicture src={Moving} alt="moving" />
               

              </PictureWrapRow>

         

          </SlideWrapper>




          <SlideWrapper>

            <NarrationWrapper>
              <Title> Promise Chit</Title>
              <Narration>
              Promise chits are given or received for some action that is to take place in the future.
              </Narration>
            </NarrationWrapper>

    

              <PictureWrapRow>
              <CoinMiddle>

                  <CoinStyle src={SilverPromise} alt="promise chit" />
                  <div>Promise <br /> Chit</div>
                </CoinMiddle>
            
                <MediumPicture src={Promise2} alt="promise" />
               

              </PictureWrapRow>

         

          </SlideWrapper>



          <SlideWrapper>

            <NarrationWrapper>
              <Title> Good Will Chit</Title>
              <Narration>
              Good will chits are given for “charitable” actions you do.
              </Narration>
            </NarrationWrapper>

    

              <PictureWrapRow>
              <CoinMiddle>

                  <CoinStyle src={CopperCoin} alt="good will chit" />
                  <div>Good Will <br /> Chit</div>
                </CoinMiddle>
            
                <MediumPicture src={BeachCleanup} alt="promise" />
                <MediumPicture src={Homeless} alt="promise" />

              </PictureWrapRow>

         

          </SlideWrapper>



          <SlideWrapper>

            <NarrationWrapper>
              <Title> AwChit</Title>
              <Narration>
              AwChits are given for actions taken by someone else that affected you negatively.
              </Narration>
            </NarrationWrapper>

    

              <PictureWrapRow>
              <CoinMiddle>

                  <CoinStyle src={AwChit} alt="aw chit" />
                  <div>AwChit</div>
                </CoinMiddle>
            
                <MediumPicture src={PromiseBreaker} alt="promise" />
                

              </PictureWrapRow>

         

          </SlideWrapper>


          <SlideWrapper>

            <NarrationWrapper>
              <Title> Chit Values</Title>
              <Narration>
              Standard and Good will chits can be assigned a value.  

Those values are used to calculate your karmic balance.  For instance, a gold chit with value 80 is equal to 2 silver chits each with value of 40

              </Narration>
            </NarrationWrapper>

    

              <PictureWrapRow>
          
                <Picture src={Slider} alt="chit value" />
               

              </PictureWrapRow>

         

          </SlideWrapper>


          <SlideWrapper>

            <NarrationWrapper>
              <Title> Karmic Value</Title>
              <Narration>
              The karmic balance tells whether you owe more than you are owed in life.  So if your balance is positive,  you give to others more than receive.

              </Narration>
            </NarrationWrapper>

    

              <PictureWrapper>
          
                <Picture src={KarmicBalance} alt="karmic Balance" />
               

              </PictureWrapper>

         

          </SlideWrapper>







{/* ================================================== */}

<SlideWrapper>

<NarrationWrapper>

<Narration>
End - Use tabs above to see features of other Chit Git tools.
</Narration>
</NarrationWrapper>



</SlideWrapper>

         


















        </SlideContent>
      
  </Scrollbars>
    </SlideContainer>


  )// --- end main return 
} //--- end FeatureSlides_twoParty

// =========================================================

const SlideContainer= styled('div')({

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',

  width: '100%',
  height: '100%',
  // marginBottom: '5rem',
  backgroundColor: veryLightGrey,
  borderBottom: '1px solid #E6E7E8',
  


  [theme.breakpoints.down('sm')] : {
  
  },

  [theme.breakpoints.down('sm')] : {
     
  },

  [theme.breakpoints.down('xs')] : {
     
    
  }


})

const Header= styled('div')({

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
 fontSize: '1.3rem',
 fontWeight: 'bold',
 color: 'white',
  width: '100%',
  minHeight: '4rem',
  // marginBottom: '5rem',
  background: backgroundBlue,
  marginBottom: '3px',
  


  [theme.breakpoints.down('sm')] : {
  
  },

  [theme.breakpoints.down('sm')] : {
     
  },

  [theme.breakpoints.down('xs')] : {
     
    
  }


})

const SlideContent= styled('div')({

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

  width: '99%',
  minHeight: '15rem',
  // marginBottom: '5rem',
  background: 'white',
  borderBottom: '1px solid #E6E7E8',
  


  [theme.breakpoints.down('sm')] : {
  
  },

  [theme.breakpoints.down('sm')] : {
     
  },

  [theme.breakpoints.down('xs')] : {
     
    
  }


})
const SlideWrapper= styled('div')({

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
flexDirection: 'column',
  width: '100vw',
  minHeight: '15rem',
  // marginBottom: '5rem',
 padding: '.5rem 0 1.5rem 0',

  maxWidth: '65rem',
  boxShadow : '2px 4px #A7A7A8',
  // overflow: 'hidden',

  [theme.breakpoints.down('sm')] : {
    flexDirection: 'column',
    padding: '.5rem 0',
    width: '96vw',
      
  }
// backgroundColor: 'green'

})



const NarrationWrapper= styled('div')({

  display: 'flex',
  flexDirection: 'column',
  jutifyContent: 'center',
  alignItems: 'center',
  width: '92%',
  padding: '1% 4%',
  height: '100%',
 
  backgroundColor: 'white',
 
  marginBottom: '1.5rem',
  borderBottom: '1px solid grey',
  [theme.breakpoints.down('sm')] : {
    width: '100%',
    
  }
})

const Title = styled('div')({

  display: 'flex',
  flexDirection: 'column',
  jutifyContent: 'center',
  alignItems: 'center',
  width:  '100%',
  color: chitBurgandy,
  fontSize: '1rem',
  fontWeight: 'bold',
  marginBottom: '8px',
  textDecoration: 'underline',
  [theme.breakpoints.down('sm')] : {
    width: '100%',
    
  }
})

const Narration = styled('div')({

  display: 'block',
  flexDirection: 'column',
  jutifyContent: 'center',
  alignItems: 'center',
  width:  '50%',
   
  fontSize: '.85rem',
  [theme.breakpoints.down('sm')] : {
    width: '100%',
    
  }
})

const PictureWrapper= styled('div')({

  display: 'flex',
  flexDirection: 'column',
  jutifyContent: 'center',
  alignItems: 'center',
  width: '70%',


  // height: '100%',
  [theme.breakpoints.down('sm')] : {
    width: '100%',
    jutifyContent: 'flex-start',
  }

})

const PictureWrapRow= styled('div')({

  display: 'flex',
  flexDirection: 'row',
  jutifyContent: 'center',
  alignItems: 'center',
  width: '70%',
marginBottom: '1rem',

  // height: '100%',
  [theme.breakpoints.down('sm')] : {
    width: '80%',
    jutifyContent: 'flex-start',
  }

})

const Picture= styled('img')({
  display: 'block',
  margin: 'auto',
  width: '24rem',
   
})

const MediumPicture= styled('img')({
  display: 'flex',
  margin: 'auto',
  width: '12rem',
   
})

const SmallPicture= styled('img')({
  display: 'block',
  margin: 'auto',
  width: '10rem',
   
})

const SmallPictureReverse= styled('img')({
  display: 'block',
  margin: 'auto',
  width: '10rem',
  transform: 'scaleX(-1)'
})




const ToolsPicture= styled('img')({
  display: 'block',
  // marginRight: '18px',
  width: '20rem',
   
})


 


const PictureStyle= styled('div')({

  display: 'flex',
  jutifyContent: 'center',
  alignItems: 'center',
  
  width: '60%',
  height: '15rem',



  [theme.breakpoints.down('sm')] : {
    width: '80%',
    jutifyContent: 'flex-start',
    height: '7rem',
    marginBottom: '1rem'
  }
})

const CoinEnds= styled('div')({

  display: 'flex',
  jutifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'flex-end',
  flexDirection: 'column',
  width: '9rem',
  height: '9rem',
  fontSize: '.9rem',
  textAlign: 'center',
  color: 'black',

  [theme.breakpoints.down('sm')] : {
     
    height: '7rem',
    width: '7rem',
    fontSize: '.75rem',
  }

})

const CoinMiddle= styled('div')({

  display: 'flex',
  jutifyContent: 'flex-start',
  alignItems: 'center',
  flexDirection: 'column',
  
  width: '9rem',
  height: '9rem',
  fontSize: '.9rem',
  textAlign: 'center',
  color: 'black',

  [theme.breakpoints.down('sm')] : {
     
    height: '7rem',
    width: '7rem',
    fontSize: '.75rem',
  }

})

const CoinStyle= styled('img')({
  display: 'block',
  margin: 'auto',
  width: '5rem',
  height: '5rem',

  [theme.breakpoints.down('sm')] : {
     
     width: '4rem',
  height: '4rem',
  }

})


const IconStyle= styled(ShareIcon)({
  color: 'orange',
  height: '1.4rem',
  width: '1.4rem',
  // marginRight: '14px',
})

const ChitsWrapperBottom = styled('div')({
 
       
  display: 'flex',
  justifyContent: 'center',

  alignItems: 'center',

   height: '5rem',
  marginTop: '1rem',
  width: '98%',
 


})

const StyledToolBar= styled('img')({
  display: 'flex',
  marginRight: '12px',
  height: '3rem',
  

   
})

const LogoWrapperOrange= styled('div')({

  height: '2rem', 
  width: '2rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: '8px',
  
 
borderRadius: '50px',
  border: '1px solid orange',
  '&:hover': {
    backgroundColor: mediumLightGrey
    // backgroundColor: mediummediumLightGrey
  },


})

const Small= styled('div')({

  display: 'block',

  fontSize: '.7rem',
 
  color: 'blue',


})