/*---- File - SharedChitFormPreview.jsx 
 
1. use props.id to get data object from twoPartyChitCollection
2. convert fields to preload default inputs in form
    ex - if(deedPerforemed by === otherPartyId) 
             shared deedPerformed by = otherParty
3. on submit 
    - create new Shared chit in user's collections
    - update redux store
    - set new Shared chit in the site's collection
4. go to Preview


*/




import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { chitBlueDull, chitBurgandy, chitBurgandyDull, darkGrey, lightGrey, mediumGrey, mediumLightGrey, veryLightGrey, backgroundBlue } from '../styles/colors'



// --- Firebase imports ---------
import {
  addSharedChitDocumentToFirestore,
  addSiteDocumentToFirestore,
  updateDocumentFromFirestore
} from '../app/firebase/fireStore.utils';

// --- React-hook-form imports ---------

import { FormProvider, useForm, Controller } from "react-hook-form";

import { yupResolver } from '@hookform/resolvers/yup';
import { string, object } from 'yup';
import * as Yup from 'yup';

// --- Form component imports ---------

import { closeModal } from '../app/redux/statusRedux/statusSlice'

// --- Redux slices imports ---------------------------------
import { changeLoadingStatus } from '../app/redux/statusRedux/statusSlice'
import {
  closeLogSectionForm,
  closeNewLogForm,
  selectStatus,
  clearSharedChitFormData,
  updateSharedChitFormPageView

} from '../app/redux/statusRedux/statusSlice'

import { updateTwoPartyChitSharedId } from '../app/redux/twoPartyChitRedux/twoPartyChitsSlice';

import { addSharedChitToStore } from '../app/redux/sharedRedux/sharedChitsSlice';

import { chooseTwoPartyChitCoin } from '../app/helpers/chitHelpers'

// --- imports to create options for selectors

// --- MUI imports ---------
import ForwardIcon from '@mui/icons-material/Forward';
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'

import { styled, createTheme } from "@mui/material/styles"
import { withStyles } from '@mui/styles'
const theme = createTheme(); // allows use of mui theme in styled component

// ==================================================

export default function SharedChitFormPreview(props) {

  const dispatch = useDispatch()
  const status = useSelector(selectStatus)
  const uid = status.auth.loginId

  const sharedFormData = status.view.forms.sharedChitForm

  const { chitId, chitType, chitColor, senderName, receiverName, deedPerformedBy, chitDate, sharedDate, sharedTitle, sharedMessage } = sharedFormData


  // --- create condition for who owes arrow

  let whoOwes, performedBy
  deedPerformedBy === 'me' ? whoOwes = true : whoOwes = false
  deedPerformedBy === 'me' ? performedBy = senderName : performedBy = receiverName

  let coinAddress = chooseTwoPartyChitCoin('standard', chitColor)

  const pathToCoinImages = '../../'
  const coinDisplayed = pathToCoinImages + coinAddress

  const cancelNewForm = () => {

    dispatch(closeModal())
    // navigate(`/sample/twoPartyChits/allChits`)

  }

  function goBack() {
    dispatch(updateSharedChitFormPageView('form'))
    // dispatch(closeModal())
  }


  // ----create default paramters if note exists ---------------------
  let defaultValues = {


  };

  // ===========  FORM  ==================================================

  const methods = useForm({

  });
  const { handleSubmit, reset, control, setValue, onChange, watch, ref, formState: { errors } } = methods;

  const submitForm = async (data, newGroupObject, newPersonObject, newGroup, newPerson) => {



    try {

      /*
        
        1. set loading status 
        2. create data object for site's Firebase sharedChit document
        3. add sharedChit to site's Firebase sharedChit document
        4. retrieve id for new sharedChit
    
        5. create sharedChit link address using retrievd id (#4)
        
        6. create data object for user's Firebase sharedChit document
        7. add (set) new sharedChit to user's Firebase sharedChit document
    
        8. add new sharedChit to Redux store 
    
        9. set status.view.forms.sharecChitForm.pageView to message
    
        10. clear loading status
    
        
        
        
      */

      // #########################################################

      dispatch(changeLoadingStatus(true))

      //  2. create data object for site's Firebase sharedChit document

      let siteFirestoreSharedChitObject = {

        chitType: chitType,
        chitColor: chitColor,
        senderName: senderName,
        senderId: uid,
        receiverName: receiverName,
        deedPerformedBy: deedPerformedBy,
        chitDate: chitDate,
        sharedDate: sharedDate,
        sharedTitle: sharedTitle,
        sharedMessage: sharedMessage
      }

      //  3. add sharedChit to site's Firebase sharedChit document
      // 4. retrieve id for new sharedChit

      let newSharedChitId = await addSiteDocumentToFirestore('siteSharedChits', siteFirestoreSharedChitObject)


      // 5. create sharedChit link address using retrievd id 

      const BASE_URL = window.location.origin
      // console.log('[ SharedChitFormPreview ] BASE_URL ', BASE_URL);


      //#######################################################################
      //#######################################################################
      //#######################################################################
      //                      URL for product vs development



      // let sharedChitLink = BASE_URL + '/sharedChit/' + newSharedChitId
      let sharedChitLink = 'http://www.chitgit.com/sharedChit/' + newSharedChitId





      //#######################################################################
      //#######################################################################
      //#######################################################################


      // 6. create data object for user's Firebase sharedChit document


      let userFirestoreSharedChitObject = {
        chitId: chitId,
        chitType: chitType,
        chitColor: chitColor,
        senderName: senderName,
        receiverName: receiverName,
        deedPerformedBy: deedPerformedBy,
        chitDate: chitDate,
        sharedDate: sharedDate,
        sharedTitle: sharedTitle,
        sharedMessage: sharedMessage,
        sharedChitLink: sharedChitLink
      }

      //  7. add (set) new sharedChit to user's Firebase sharedChit document

      await addSharedChitDocumentToFirestore(uid, newSharedChitId, userFirestoreSharedChitObject)

      // 8. add new sharedChit to Redux store 

      let userSharedChitReduxStoreObject = {
        id: newSharedChitId,
        sharedChitLink: sharedChitLink,
        chitId: chitId,
        chitType: chitType,
        chitColor: chitColor,
        senderName: senderName,
        receiverName: receiverName,
        deedPerformedBy: deedPerformedBy,
        chitDate: chitDate,
        sharedDate: sharedDate,
        sharedTitle: sharedTitle,
        sharedMessage: sharedMessage,

      }

      dispatch(addSharedChitToStore(userSharedChitReduxStoreObject))

      // 9. set status.view.forms.sharecChitForm.pageView to message

      await updateDocumentFromFirestore(uid, 'twoPartyChits', chitId, { sharedChitId: newSharedChitId })

      dispatch(updateTwoPartyChitSharedId({ id: chitId, sharedChitId: newSharedChitId }))

      dispatch(updateSharedChitFormPageView('message'))

      dispatch(changeLoadingStatus(false))
      // #########################################################  

    } catch (error) {
      alert(error.message)
      dispatch(changeLoadingStatus(false))

      reset(defaultValues)

    } // end catch







  }// end submitForm

  // ----- MAIN RETURN ---------------------------
  return (

    <Wrapper>

      <HeaderTitle> Shared Chit Preview</HeaderTitle>
      <FormProvider {...methods}>
        <FormWrapper id="submit-form" onSubmit={handleSubmit(submitForm)} >

          {/* ------Buttons --------------  */}

          <TopWrapper>

            <TopWrapperLeft>

              <StyledButton

                variant="contained"
                color="primary"
                style={{
                  textTransform: 'none',

                }}
                onClick={() => goBack()}

              >
                Back
              </StyledButton>

            </TopWrapperLeft>

            <TopWrapperRight>


              <StyledButton

                variant="contained"
                color="primary"
                style={{
                  textTransform: 'none',

                }}
                onClick={() => cancelNewForm()}

              >
                Cancel
              </StyledButton>

              <ButtonWrapper>
                <StyledButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{ textTransform: 'none' }}


                >
                  Publish
                </StyledButton>

              </ButtonWrapper>
            </TopWrapperRight>

          </TopWrapper>

          {/* #########################################################   */}

          <ContentBox>

            <SubHeader>
              This chit has been sent to you  <br /> from  {senderName}
            </SubHeader>

            <OweWrapper>

              <NameContainer>

                {!whoOwes &&
                  <>
                    <IOU />


                    <div> {senderName} Owes this chit to {receiverName}</div>
                  </>}
                {whoOwes &&
                  <>
                    <YouOweMe />
                    <div> {receiverName} owe this chit to {senderName} </div>


                  </>}

              </NameContainer>





            </OweWrapper>







            <ChitBox>
              <DetailWrapper>
                <ActionWrapper>
                  <ActionLeft>chit date :   </ActionLeft>
                  <ActionRight> July 4, 2021 </ActionRight>

                </ActionWrapper>



                <StyledChitImage src={coinDisplayed} alt='silver coin' />



                <ActionWrapper>
                  <ActionLeft>performed by: </ActionLeft>
                  <ActionRight>{performedBy}  </ActionRight>

                </ActionWrapper>
              </DetailWrapper>

              <SummaryWrapper>
                <SummaryTop>Summary:   </SummaryTop>
                <SummaryBottom> {sharedTitle} </SummaryBottom>

              </SummaryWrapper>


              <MessageWrapper>
                <MessageTop>Message from {senderName}:    </MessageTop>

                <MessageBottom dangerouslySetInnerHTML={{ __html: sharedMessage }} >


                </MessageBottom>

              </MessageWrapper>


            </ChitBox>

          </ContentBox>













          {/* #########################################################   */}
        </FormWrapper>



      </FormProvider>
    </Wrapper>
  )// end return
} // end function



// ==== Styles ===========================================


const Wrapper = styled(Paper)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  // zIndex: '95',
  marginTop: '2rem',
  width: '100%',
  height: '99%',
  overflow: 'auto',
  // border: '2px solid #F285B5',


  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',
    // backgroundColor: 'red'
  },

})

const FormWrapper = styled('form')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '98%',
  height: '100%',
  // margin: '5px 0',
  // padding: '4px',




  [theme.breakpoints.down('sm')]: {
    width: '100%',


  },

})


const HeaderTitle = styled('div')({


  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '1.1rem',
  color: chitBurgandy,



  //   [theme.breakpoints.down('sm')] : {
  //     // width: '100%'
  //   },

})



const YouOweMe = styled(ForwardIcon)({
  // transform: 'rotate(180deg)',
  fontSize: '1rem',
  color: 'red',
  margin: '0 8px'

})

const IOU = styled(ForwardIcon)({
  transform: 'rotate(0deg)',
  fontSize: '1rem',
  color: 'green',
  margin: '0 8px'

})


//  --- Buttons -----------
const TopWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '95%',

  marginBottom: '8px',

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const TopWrapperLeft = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',

  margin: '.75rem',


  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const TopWrapperRight = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',

  margin: '.75rem',


  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const ButtonWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',



  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const StyledButton = styled(Button)({
  backgroundColor: 'white',
  border: '1px solid #E6E7E8',
  color: chitBurgandyDull,
  margin: '0 8px',
  width: '5rem',
  height: '1.5rem',
  fontSize: '.8rem',
  '&:hover': {
    backgroundColor: lightGrey
  }

})

// ----------------------

const ContentBox = styled('div')({


  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  position: 'relative',
  borderTop: '1px solid grey',
  width: '100%',



  [theme.breakpoints.down('sm')]: {
    width: '96%',
    // height: '96%',

  }


})

const SentDate = styled('div')({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  fontSize: '.7rem',
  color: mediumGrey,
  top: '10px',
  right: '16px',

  [theme.breakpoints.down('xs')]: {

    fontSize: '.95rem',
    padding: '0 15% 0 5%',


  }


})
const SubHeader = styled('div')({

  display: 'block',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  textAlign: 'center',

  width: '100%',

  color: chitBurgandy,
  fontSize: '.9rem',
  marginTop: '8px',



  [theme.breakpoints.down('xs')]: {
    overflow: 'auto',
  }


})

const OweWrapper = styled('div')({

  display: 'block',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  textAlign: 'center',

  width: '100%',

  margin: '4px 0',




  [theme.breakpoints.down('xs')]: {
    overflow: 'auto',
  }


})

// -----------------------------------------
const ChitBox = styled('div')({


  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  position: 'relative',

  width: 'calc(100% - 15px)',
  height: '80%',
  marginTop: '8px',


  borderRadius: '20px',


  [theme.breakpoints.down('sm')]: {

    height: '86%'
  }


})
const ActionWrapper = styled('div')({

  // width: '50%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'space-between',
  fontSize: '.85rem',

  [theme.breakpoints.down('xs')]: {

  }

})


// --- detail section -----

const DetailWrapper = styled('div')({

  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  alignItems: 'center',
  margin: ' .5rem 3rem 1.3rem 3rem',
  width: 'calc(100% - 6rem)',
  [theme.breakpoints.down('sm')]: {

    width: '96%',
    margin: 0
  }

})

const StyledChitImage = styled('img')({

  margin: '0 1.5rem',
  height: '4rem',

})






const ActionLeft = styled('div')({

  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  marginRight: '6px',
  fontStyle: 'italic',
  color: mediumGrey,


  [theme.breakpoints.down('xs')]: {

  }

})


const ActionRight = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  fontSize: '.9rem',
  color: backgroundBlue,

  [theme.breakpoints.down('xs')]: {

  }

})


// --- Summary --------
const SummaryWrapper = styled('div')({

  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
  padding: '6px',
  color: chitBurgandy,
  backgroundColor: veryLightGrey,

  [theme.breakpoints.down('sm')]: {
    width: '100%',
    margin: '.5rem 0 ',

  }

})

const SummaryTop = styled('div')({

  marginRight: '6px',
  fontStyle: 'italic',
  fontSize: '.84rem',
  color: mediumGrey,
  [theme.breakpoints.down('xs')]: {

  }

})

const SummaryBottom = styled('div')({
  fontSize: '.9rem',

  [theme.breakpoints.down('xs')]: {

  }

})



// --- Message ---------

const MessageWrapper = styled('div')({



  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',

  borderRadius: '5px',
  [theme.breakpoints.down('sm')]: {
    width: '96%',

  }

})

const MessageTop = styled('div')({

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',

  margin: ' 6px 0 ',
  width: 'calc(100% - 12px)',
  fontSize: '.85rem',
  fontStyle: 'italic',
  color: mediumGrey,

  [theme.breakpoints.down('sm')]: {
    borderBottom: '1px solid #E6E7E8'
  }

})


const MessageBottom = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',

  borderRadius: '5px',
  padding: '6px',
  width: 'calc(100% - 12px)',
  fontSize: '.9rem',
  minHeight: '5rem',


  [theme.breakpoints.down('xs')]: {

  }

})



const NameContainer = styled('div')({


  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '.7rem',





  //   [theme.breakpoints.down('sm')] : {
  //     // width: '100%'
  //   },

})