

import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'


import { selectFirsts } from '../../../app/redux/firstRedux/firstSlice';
import { selectStatus, openFirstForm } from '../../../app/redux/statusRedux/statusSlice';

//  ---- Material Ui ------------------

import Paper from '@mui/material/Paper'
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button'

import { styled, createTheme } from "@mui/material/styles"
const theme = createTheme(); // allows use of mui theme in styled component


// ================================================

export default function FirstHeader(props) {
  const dispatch = useDispatch()
  const handleClick = () => {


    //  console.log('[ LOG HEADER] open new form ');
    dispatch(openFirstForm('new'))

  }

  return (
    <Wrapper>
      <TitleWrapper>

        <Title>
          First Contacts
        </Title>


        <ButtonWrapper>

          <FormButton
            startIcon={<AddIcon />}
            onClick={() => handleClick()}
          >
            add New First Contact
          </FormButton>

        </ButtonWrapper>
      </TitleWrapper>
    </Wrapper>
  );
}

// -------------------------------------------

const Wrapper = styled(Paper)({
  display: 'flex',
  flexDirection: 'column',
  // justifyContent: 'flex-start',
  // alignItems: 'center',
  margin: '1rem  0 .5rem 0',
  padding: '.5rem',
  height: '5rem',

  border: '1px solid red',

  width: '95%',



  [theme.breakpoints.down('md')]: {


  },
})



const TitleWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex0start',
  alignItems: 'center',

  width: '100%',




  fontSize: '1rem',

  // backgroundColor: 'orange',
  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',
    // backgroundColor: 'red'
  },
})

const Title = styled('div')({
  display: 'flex',

  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '98%',



  color: 'red',


  fontSize: '1.2rem',



  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',
    // backgroundColor: 'red'
  },
})

const ButtonWrapper = styled('div')({
  display: 'flex',
  width: '98%',

  justifyContent: 'flex-start',
  alignItems: 'center',


  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',
    // backgroundColor: 'red'
  },
})

const FormButton = styled(Button)({



  display: 'flex',
  textTransform: 'none',


  color: 'white',
  backgroundColor: '#727376',
  fontWeight: 'normal',
  fontSize: '.85rem',
  padding: '0 1.5rem',
  marginTop: '1rem',
  width: '15rem',
  height: '1.2rem',
  // margin: '0 1rem',

  '&:hover': {
    // backgroundColor: chitBlueDull,
    textDecoration: 'none',
    border: '1px solid #A8BEED',
    color: '#3B30CC'

  },

  [theme.breakpoints.down('md')]: {
    fontSize: '.75rem',
    padding: '0 .75rem',
    width: '12.5rem',
  },


})