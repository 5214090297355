/* Landing.jsx
   contains 2 child components: 
      Header_landing - top navigation and login
      Landing_page - main body - advertisement
      Landing_slides - pitch slides

    parent: src/app/App.js

*/

import React from 'react'
import { backgroundBlue } from '../../styles/colors'
import { useParams } from 'react-router-dom'

import { Scrollbars } from 'react-custom-scrollbars';

import HeaderLanding from './landingElements/Header_landing'
import HeaderPublic from './landingElements/Header_public';
import LandingPage from './landingElements/Landing_page'
import LandingSlides from './landingElements/Landing_slides'

import Features from './Features';
import SharedChit from './SharedChit'
import ReceivedChit from './sharedFolder/ReceivedSharedChitPublic';
import ContactPublic from './ContactPublic';
import Login from './Login';
import ResetPassword from './ResetPassword'

import Modal from '../../common_components/modal/ModalComponent';


import { styled, createTheme } from "@mui/material/styles"
const theme = createTheme(); // allows use of mui theme in styled component






// =============================================



const Landing = () => {

  let match = useParams()
  let pageView = match.pageView
  let id = match.id
  let sharedChitId = match.sharedChitId

  // console.log('[ Landing - shared shit public ] id ', id);
  // console.log('[ Landing - shared shit public ] sharedChitId ', sharedChitId);
  let page

  !pageView ? page = 'home' : page = pageView

  return (
    <BodyWrapper>
      <Modal />

      <Wrapper>


        <HeaderWrapper>
          {page === 'home' &&
            <HeaderLanding />
          }
          {page !== 'home' &&
            <HeaderPublic />
          }

        </HeaderWrapper>
        {page === 'home' &&
          <>
            <LandingPage />
            <SlidesWrapper>

              <LandingSlides />
            </SlidesWrapper>
          </>
        }
        {page === 'features' &&
          <>
            <Features />

          </>
        }

        {page === 'contact' &&
          <>
            <ContactPublic />

          </>
        }

        {page === 'sharedChit' && !id &&
          <>
            <SharedChit />

          </>
        }

        {page === 'sharedChit' && id &&
          <>
            <ReceivedChit id={id} />

          </>
        }



        {page === 'login' &&
          <>
            <Login />

          </>
        }

        {page === 'resetPassword' &&
          <>
            <ResetPassword />

          </>
        }

        {page !== 'contact' && page !== 'features' && page !== 'login' && page !== 'home' && page !== 'sharedChit' && page !== 'resetPassword' &&
          <NoMatch>
            <div> No such page </div>
            <div>Choose a link above </div>

          </NoMatch>
        }




      </Wrapper>








    </BodyWrapper>
  )
}

export default Landing


// --------------------------------------------------------

const BodyWrapper = styled('div')({
  display: 'block',

  backgroundCOlor: backgroundBlue,
  backgroundImage: 'linear-gradient(#04102F,#062A4D, #096E99)',
  height: '100vh',
  overflowY: 'auto',
  overflowX: 'hidden',
  fontFamily: 'Lato, sans-serif',



  [theme.breakpoints.down('xs')]: {

    alignItems: 'center',
    width: '100%',
    padding: '0',


  }


  // backgroundColor: testColors.testGreen

})

const Wrapper = styled('div')({

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',


  [theme.breakpoints.down('sm')]: {


  }

})

const HeaderWrapper = styled('div')({

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
  marginBottom: '2.5rem',

  [theme.breakpoints.down('sm')]: {

    marginBottom: '1.5rem'
  }

})

const SlidesWrapper = styled('div')({

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
  backgroundColor: 'white',

  [theme.breakpoints.down('sm')]: {


  }

})

const NoMatch = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '5rem',
  fontSize: '1.4rem',

  color: 'white',
  [theme.breakpoints.down('sm')]: {


  }

})