import { createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
// import InitialStore from '../store/receivedChit_initialStore'



export const receivedChitsSlice = createSlice({
  name: 'receivedChitSlice',

  initialState: [],


  reducers: {

  
    addAllReceivedChitsFromFirestore: (state = [], action) => {
    
      let firestoreData = action.payload
    
      // Clear the state array
      state = [];
    
      // Add the Firestore data to the state array
      state = [...firestoreData];
    
      return state
    },// end addAllReceivedChitsFromFirestore

    addReceivedChitToStore: (state, action) => {

      let receivedChit = action.payload

      state.push(receivedChit)


    },

    /**
        id:  newReceivedChitId,
        chitColor: chitColor,
        chitDate: chitDate,
        chitType: chitType,
        deedPerformedBy: deedPerformedBy,
        receiverName: receiverName,
        senderId: newPersonId,
        senderName: senderName,
        sharedDate: sharedDate,
        sharedMessage: sharedMessage,
        sharedTitle: sharedTitle
    
     */



    updateReceivedChit: (state, action) => {

      let data = action.payload
      let twoPartyChitId = action.payload.id


      const { otherPartyCollection, chitType, chitColor, chitValue, chitBurden, chitDate, person, group, deedPerformedBy, workRelated, description, keyWordArray, otherPartyId } = data


      let twoPartyIndex = state.findIndex(index => index.id === twoPartyChitId)



      state[twoPartyIndex].id = twoPartyChitId
      state[twoPartyIndex].chitType = chitType
      state[twoPartyIndex].chitColor = chitColor
      state[twoPartyIndex].chitBurden = chitBurden
      state[twoPartyIndex].chitValue = chitValue
      state[twoPartyIndex].chitDate = chitDate
      state[twoPartyIndex].deedPerformedBy = deedPerformedBy
      state[twoPartyIndex].otherPartyId = otherPartyId
      state[twoPartyIndex].otherPartyCollection = otherPartyCollection
      state[twoPartyIndex].workRelated = workRelated
      state[twoPartyIndex].description = description





    }, // end updateEditedtwoParty



    resetReceivedChits: (state, action) => {
      let initialState = []
      return initialState
    },// end resetReceivedChits



    deleteReceivedChit: (state, action) => {

      console.log('[ ReceivedSlice - deleteReceivedChhit] payload ', action.payload);
      let receivedChit = action.payload.id

      return state.filter(item => item.id !== receivedChit)

    }, // end deleteReceivedChit


  }, //end reducers

}) // end slice statusSlice 


// --- Export actions ---------------------------------------------

export const {
  addAllReceivedChitsFromFirestore,
  addReceivedChitToStore,
  updateReceivedhit,
  deleteReceivedChit,
  resetReceivedChits

} = receivedChitsSlice.actions



// --- Export selectors ------------------------------------------

export const selectReceivedChits = state => state.receivedChits //Sample site










// --- default Export reducers -----------------------------------

export default receivedChitsSlice.reducer