
/* function TopicalsMain(props) -------------------
       parent: sampleSite/Topicals

  Holds Topicals,

  parent: Topicals - pages/private/topicals/Topicals
------------------------------------*/

import React, {useState, useEffect} from 'react'
import { useSelector} from 'react-redux'

import { chitBurgandy } from '../../../styles/colors';

import { useParams } from 'react-router-dom'
import { Scrollbars } from 'react-custom-scrollbars';

import TopicalHeader from './TopicalHeader'
import Topical from './Topical'

import NewTopicForm from '../../../forms/NewTopicForm';
import NewTopicalSectionForm from '../../../forms/NewTopicalSectionForm';

import { selectStatus } from '../../../app/redux/statusRedux/statusSlice';

//  ---- Material Ui ------------------

import Paper from '@mui/material/Paper'



import { styled, createTheme  } from "@mui/material/styles"
const theme = createTheme(); // allows use of mui theme in styled component




// ==============================================

export default function TopicalMain(props) {

  let match = useParams()
  let urlId = match.id

  let status = useSelector(selectStatus)

  let topicalFormDisplay = status.view.topical.sectionId
  let statusId = status.view.topical.id
 
  console.log('[ Topical main ] topicalFormDisplay ', topicalFormDisplay);

  const [topicalId, setTopicalId] = useState(statusId)
  useEffect(()=>{
    setTopicalId(statusId)

  },[statusId])

  const [arrayOrder, setArrayOrder] = useState(false)



  return (
    <OuterContainer>

     {/* --- New Log Icon prssed --- puts 'newLog' in URL id param ---------*/}
     

     {urlId === 'newTopical' &&
        <>

          {/* <LogHeader /> */}

          <MainWrapper>

            <HeaderWrapper>New Topical</HeaderWrapper>

            <Container>

              <FormContainer>
                
                  <NewTopicForm/>
                  
                  </FormContainer>

            </Container>

          </MainWrapper>

        </>
  
      } 

{/* --------------------------------------------------------- */}

     {urlId !== 'newTopical'  &&




        <MiddleWrapper>
          <TopicalHeader />


          <MainWrapper>





             
            {topicalFormDisplay === 'new' &&
            <NewFormSectionContainer>

              <NewTopicalSectionForm />

            </NewFormSectionContainer>
          }
              <SectionsContainer>
                <Topical />
              </SectionsContainer>
            

          </MainWrapper>

        </MiddleWrapper>

        }
    </OuterContainer>
  )
}

// -----------------------------------------------------------------

const OuterContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
position: 'relative',

  backgroundColor: 'white',
 

  // minHeight: '10rem',
  // height: '90%',
 width: '100%',
 height: '98%',
 paddingBottom: '1%',
 
 overflow: 'hidden',

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },


})


const MainWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
position: 'relative',

 
 

  // minHeight: '10rem',
  // height: '90%',
 width: '100%',
 height: '100%',
 overflow: 'auto',

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },


})



 
const Container= styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',


  color: 'charcoal',
  width: '95%',

  // minHeight: '10rem',
  // height: '90%',
  margin: '0 0 5% 0',

  // overflowY: 'hidden',

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

  backgroundColor: 'white',


})


const FormContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  

  width: '100%',

 
  border: '2px solid yellow',
  // minHeight: '10rem',
  // height: '90%',

  
  // overflowY: 'hidden',

  [theme.breakpoints.down('sm')] : {
    // height: '1.25rem',

  },

 

})

const NewFormSectionContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  

  width: '94%',
  // height: '65%',
  // maxHeight: '23rem',
  marginLeft: '3%',
 padding: '4px 0 8px 0',
  border: '2px solid orange',
  borderRadius: '5px',
  // minHeight: '10rem',
  // height: '90%',

  
  // overflowY: 'hidden',

  [theme.breakpoints.down('sm')] : {
    // height: '1.25rem',

  },

 

})


const HeaderWrapper = styled(Paper)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  margin: '.2rem  0 2rem 0',
  padding: '0 0 .5rem 2rem',
  height: '4rem',
  color: chitBurgandy,
  fontSize: '1.3rem',


  width: 'calc(98% - 2rem)',
 


  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },
})


const SectionsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
 
  // paddingTop: '6px',

  width: '98%',
 height: '100%',
 

  [theme.breakpoints.down('sm')] : {
    // height: '1.25rem',

  },

 

})


const MiddleWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
 
  // paddingTop: '6px',

  width: '100%',
  height: '100%',
  overflowX: 'hidden',
 

  [theme.breakpoints.down('sm')] : {
    // height: '1.25rem',

  },

 

})