
/* function TwoPartyLedgerRow (props) -------------------
  Display for received chits added to ledger...
  there is a corresponding display for two party chits row
 
  parent: ./TwoPartyLedger
------------------------------------*/

import React   from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { lightGrey, veryLightGrey, mediumGrey, chitBurgandy, chitOrangeVeryLight} from '../../../styles/colors';
 
import { selectStatus,  updateAccordionDisplay} from '../../../app/redux/statusRedux/statusSlice';

import { selectPeople } from '../../../app/redux/peopleRedux/peopleSlice';
import { selectGroups } from '../../../app/redux/groupRedux/groupSlice';

import { chooseTwoPartyChitCoin } from '../../../app/helpers/chitHelpers';

import { ISOtoTraditional } from '../../../app/helpers/dateHelper';



// ---MUI ------


import ForwardIcon from '@mui/icons-material/Forward';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import DeleteIcon from '../components/Delete_icon'

import { styled, createTheme } from "@mui/material/styles"
import { withStyles } from '@mui/styles'

const theme = createTheme(); // allows use of mui theme in styled component


// ======================================================
export default function ReceivedChitLedgerRow(props) {


/*  OLD ######
  const {id, chitType, chitValue, chitBurden, chitColor, dateCreated, chitDate, timeLock, otherPartyCollection, otherPartyId, deedPerformedBy, } = props.data



*/

  
    const {chitColor, chitDate, chitType, deedPerformedBy, id,  senderName, sharedDate, sharedMessage, sharedTitle} = props.data
  

 console.log('[ ReceivedChitLedgerRow ] chitType ', chitType);
  
  let passedId = id
  

  //  --- define which coin is displayed

  let coinAddress = chooseTwoPartyChitCoin(chitType, chitColor)
 
  const pathToCoinImages = '../../../'
  const coinDisplayed = pathToCoinImages + coinAddress

  let chitDescription
  chitType ==='kindness'? chitDescription = 'good will': chitDescription = chitType

  let styledChitDate = ISOtoTraditional(chitDate)

  // --- define arrow and message for who owes who
  let youOwe

  if(chitType === 'standard' || chitType === 'kindness'){
    deedPerformedBy === 'me'? youOwe = true: youOwe = false
  } else {
    deedPerformedBy !== 'me'? youOwe = true: youOwe = false
  }
 


  return (
    
    <Wrapper key = {passedId}>
     <HeaderWrapper>
        <DateContainer>{styledChitDate}</DateContainer>

        <NameContainer>
          <NameWrapper>
            {senderName}
          </NameWrapper>
          {!youOwe && chitType !== 'awChit' && 
          <>
          <IOU/>
          <div> Owes this chit to you</div>
          </>}
          {youOwe && chitType !== 'awChit' && 
          <>
          <YouOweMe/>
          <div> You owe this chit</div>
          </>}    

          {chitType === 'awChit' &&
            <>
              <YouOweMe/>
              <div> Owes this chit to you</div>
            </>
          }   

        </NameContainer> 

        <IconWrapper> 

        <DeleteIcon id = {id} source = 'receivedChits'/>
        </IconWrapper> 

      </HeaderWrapper>


      <AccordionWrapper>



        <AccordionTopWrapper >

       
       <ChitContainer>
          <StyledImage src={coinDisplayed} alt="coin" />
          <ChitTypeWrapper> {chitType} </ChitTypeWrapper>
            
          </ChitContainer>
    
          <RightContainer>

            <TopRightContainer>
              <Title> 
        
                <div> {sharedTitle} </div>
              
              </Title>
     

            </TopRightContainer>
            <QuillDiv dangerouslySetInnerHTML={{__html: sharedMessage}} />
       

          </RightContainer>   



        </AccordionTopWrapper>


      </AccordionWrapper>




    </Wrapper>
  );
      }
// -----------------------------------------------------------------

const Wrapper = styled('div')({


  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  //   alignItems: 'center',
 
  width: '98%',
  padding: '3px 6px',
  margin: '3px 0',
  borderRadius: '5px',
  border: '1px solid #A49AED',

  boxShadow: '1px 2px #CFD0D1',

  backgroundColor: lightGrey,

  //   [theme.breakpoints.down('sm')] : {
  //     // width: '100%'
  //   },

})

// ---- Top header-------------------

const HeaderWrapper = styled('div')({


  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontSize: '.7rem',
  borderBottom: '1px solid #E6E7E8',
  width: '100%',
  padding: '0 0 4px 0',
  margin: '0 0 4px 0',


  //   [theme.breakpoints.down('sm')] : {
  //     // width: '100%'
  //   },

})

const NameContainer = styled('div')({


  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '.7rem',
  width: '30%',
 
 


  //   [theme.breakpoints.down('sm')] : {
  //     // width: '100%'
  //   },

})



const NameWrapper = styled('div')({


  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  fontSize: '.75rem',
  color: chitBurgandy,



  //   [theme.breakpoints.down('sm')] : {
  //     // width: '100%'
  //   },

})


const YouOweMe = styled(ForwardIcon)({
  transform: 'rotate(180deg)',
  fontSize: '1rem',
  color: 'red',
  margin: '0 8px'
          
})

const IOU = styled(ForwardIcon)({
  transform: 'rotate(0deg)',
  fontSize: '1rem',
  color: 'green',
  margin: '0 8px'
          
})

const DateContainer = styled('div')({


  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '30%',
color: mediumGrey,

  //   [theme.breakpoints.down('sm')] : {
  //     // width: '100%'
  //   },

})

// ==- Accordion ------------------------

const AccordionWrapper = styled('div')({
 

  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  fontSize: '.75rem',

 
  width: '100%',




  //   [theme.breakpoints.down('sm')] : {
  //     // width: '100%'
  //   },

})

const AccordionTopWrapper = styled('div')({

  // backgroundColor: 'yellow',
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  fontSize: '.85rem',
  width: '96%',
  minHeight: '30px',




  //   [theme.breakpoints.down('sm')] : {
  //     // width: '100%'
  //   },

})

const ChitContainer = styled('div')({

  // backgroundColor: 'red',
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '3.5rem',
  height: '3.5rem'
  


  //   [theme.breakpoints.down('sm')] : {
  //     // width: '100%'
  //   },

})

const ChitTypeWrapper = styled('div')({

  // backgroundColor: 'red',
  fontSize: '.65rem',
  color: mediumGrey
  


  //   [theme.breakpoints.down('sm')] : {
  //     // width: '100%'
  //   },

})

const StyledImage = styled('img')({

 
  display: 'flex',
 
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  
  width: '2.5rem',
  height: '2.5rem',
   
 




  //   [theme.breakpoints.down('sm')] : {
  //     // width: '100%'
  //   },

})

const RightContainer = styled('div')({

  // backgroundColor: 'pink',
  display: 'flex',

  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: 'calc(90% - 1rem)',
  padding: '0 0 0 1rem',
   borderLeft: '1px solid #E6E7E8'

  //   [theme.breakpoints.down('sm')] : {
  //     // width: '100%'
  //   },

})

const TopRightContainer = styled('div')({

  // backgroundColor: 'orange',
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  fontStyle: 'italic',
  fontSize: '14px',
  // fontWeight: 'bold',
  marginBottom: '4px',
  color: mediumGrey,



  //   [theme.breakpoints.down('sm')] : {
  //     // width: '100%'
  //   },


  
})
const BottomRightContainer = styled('div')({
 
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
   
 




  //   [theme.breakpoints.down('sm')] : {
  //     // width: '100%'
  //   },

})

const AccordionBottomWrapper = styled('div')({
  
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  fontSize: '.85rem',
  width: '96%',
  minHeight: '40px',
  margin: 0, 
  
  borderTop: '1px solid grey'

  //   [theme.breakpoints.down('sm')] : {
  //     // width: '100%'
  //   },

})

const AccordionDetailWrapper = styled('div')({
 
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  fontSize: '.85rem',
  width: '100%',
  minHeight: '40px',
  margin: 0, 
  paddingLeft: '1rem',
  // borderTop: '1px solid grey'

  //   [theme.breakpoints.down('sm')] : {
  //     // width: '100%'
  //   },

})

 


// const AccordionIconWrapper= styled('div')({
//     backgroundColor: 'grey',
//     display: 'flex',
//     position: 'relative',
//     flexDirection: 'row',
//     justifyContent: 'space-between',
//     alignItems: 'center',
//     fontSize: '.7rem',
//     width: '16px',

//     margin:0, padding: 0,


//   //   [theme.breakpoints.down('sm')] : {
//   //     // width: '100%'
//   //   },

//     })

const MoreIcon = styled(ExpandMoreIcon)({

  display: 'flex',

  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',

  position: 'absolute',
  bottom: '6px',
  right: '6px',

  borderRadius: '50px',
  fontSize: '.7rem',
  width: '16px',
  height: '16px',

  '&:hover': {
    backgroundColor: lightGrey
  }


  //   [theme.breakpoints.down('sm')] : {
  //     // width: '100%'
  //   },

})

const LessIcon = styled(ExpandLessIcon)({

  display: 'flex',

  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',

  position: 'absolute',
  bottom: '6px',
  right: '6px',
  borderRadius: '50px',
  fontSize: '.7rem',
  width: '16px',
  height: '16px',


  '&:hover': {
    backgroundColor: lightGrey
  }

  //   [theme.breakpoints.down('sm')] : {
  //     // width: '100%'
  //   },

})

const IconWrapper= styled('div')({
  display: 'flex',
  
  justifyContent: 'flex-end',
  alignItems: 'center',
 
  marginRight: '6px',

  width: '40%',
  [theme.breakpoints.down('sm')] : {
    // height: '1.25rem',
    // backgroundColor: 'red'
  },
})


// --- Accordion Detail CSS ---

const LeftDetailWrapper = styled('div')({

 
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '3.5rem',
 
  

 
  //   [theme.breakpoints.down('sm')] : {
  //     // width: '100%'
  //   },

})


const MiddleDetailWrapper = styled('div')({
 
  display: 'flex',

  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '40%',
  height: '100%',
   
  paddingTop: '8px',
  // padding: '0 0 0 1rem',
  //  borderLeft: '1px solid #E6E7E8'

  //   [theme.breakpoints.down('sm')] : {
  //     // width: '100%'
  //   },

})

const RightDetailWrapper = styled('div')({

  // backgroundColor: 'pink',
  display: 'flex',

  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '40%',
  height: '100%',
   
  paddingTop: '8px',
  // padding: '0 0 0 1rem',
  //  borderLeft: '1px solid #E6E7E8'

  //   [theme.breakpoints.down('sm')] : {
  //     // width: '100%'
  //   },

})

const DetailWrapper = styled('div')({
 
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',

  width: '100%',



  //   [theme.breakpoints.down('sm')] : {
  //     // width: '100%'
  //   },

})

const DetailTitle = styled('div')({

 
  display: 'flex',

  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '40%',
  fontStyle: 'italic',
  fontSize: '.75rem',
  color: mediumGrey,
  // padding: '0 0 0 1rem',
  //  borderLeft: '1px solid #E6E7E8'

  //   [theme.breakpoints.down('sm')] : {
  //     // width: '100%'
  //   },

})

const Detail = styled('div')({

 
  display: 'flex',

  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '50%',
 
  // padding: '0 0 0 1rem',
  //  borderLeft: '1px solid #E6E7E8'

  //   [theme.breakpoints.down('sm')] : {
  //     // width: '100%'
  //   },

})

const SearchWrapper= styled('div')({

  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  // backgroundColor: veryLightGrey,
  width: '100%',
  
  margin: '5px 0 0 0',
backgroundColor: veryLightGrey,
  fontSize: '.6rem',
  height: '.8rem',
  color: mediumGrey,
  [theme.breakpoints.down('sm')] : {
    // width: '100%'
  },


})

const LeftSearchWrapper = styled('div')({

 
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '4.5rem',
 
  

 
  //   [theme.breakpoints.down('sm')] : {
  //     // width: '100%'
  //   },

})

const KeyWordWrapper= styled('div')({

  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  // backgroundColor: 'grey',
 
  '& em' :{
    marginRight: '1rem'
  },
  [theme.breakpoints.down('sm')] : {
    // width: '100%'
  },


})


const Title= styled('div')({

  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',

color: chitBurgandy
  
  })

const QuillDiv= styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  fontSize: '14px',
 
  width: '100%',
  
   '& p' :{
    lineHeight: '.7'
   },
  
  '& .ql-size-small':{
    fontSize: '12px'
  },
  
  '& .ql-size-large' :{
    fontSize: '18px'
  }
  
  })

  const AwChitIOU = styled(ForwardIcon)({
    transform: 'rotate(0deg)',
    fontSize: '1rem',
    color: 'red',
    margin: '0 8px'
            
  })