
import React, {useState, useEffect} from "react"
import { useParams, useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
 
import { chitBlueDull } from "../../../styles/colors";


import { selectStatus, updateStatusView } from '../../../app/redux/statusRedux/statusSlice'


// --- MUI ---
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { styled, createTheme} from "@mui/material/styles"
import {withStyles} from '@mui/styles'
const theme = createTheme(); // allows use of mui theme in styled component

// =============================================

export default function LogViewNav(props) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const status = useSelector(selectStatus)

  let initialValue = props.initialValue
  
  

  let logView = status.view.log.display
  let logId = status.view.log.id
  console.log('[ LogView logId ] logId ', status.view.log.id);
  let match = useParams()
  let page = match.id
  

  /* func handleViewChange ---------------------------------
     changes the sample/statusview/chit - display - in store
        based on what was clicked
  */
        function handleViewChange(evt) {

          let display = evt.target.value

          console.log('[ LogView handleViewChange ] display ', display);

          dispatch(updateStatusView(
            {
              pageType: 'log',
              pageView: display,
              id : logId
            }
      
          ))

      
      // console.log('[ PAGE NAV ] newPage ', newPage);
      // console.log('[ PAGE NAV ] newPage ', newPage);
      
      if(display === 'logs' && !logId){
        navigate(`/main/logs`)
      }

      if(display === 'logs' && logId){
        navigate(`/main/logs/${logId}`)
      }

      if(display === 'firstContact'){
        navigate(`/main/logs`)
      }
          
        }


  return (
    <Wrapper>

      <FormControl component="fieldset" onChange={(evt) => handleViewChange(evt)}>

        <RadioGroup
          row aria-label="view"
          name="row-radio-buttons-group"
          defaultValue={logView}
        >
          <StyledLabel value="logs"

            control={<StyledRadio />} label="Logs" />
          <StyledLabel value="firstContact" control={<StyledRadio />} label="First Contacts" />


        </RadioGroup>
      </FormControl>

    </Wrapper>
  );
}



// -----------------------------------------------------------------
const Wrapper = styled('div')({


  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  height: '2.25rem',
  width: '100%',
  fontSize: '.8rem',
 
  borderBottom: '2px solid #CFD0D1',


  [theme.breakpoints.down('sm')] : {
    // fontWeight: 'bold',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    padding: '2px 0'
    
    // width: '150px'
    
  },


})



const StyledLabel= styled(FormControlLabel)({
  color: 'red',
 
 
  '& .MuiFormControlLabel-label ': {
    fontSize: '.8rem',
    
  }
  

})
// -----------------------------------

const StyledRadio = withStyles({
  root: {
    marginLeft: '2rem',
    color: chitBlueDull,
   
    icon: {
      color: chitBlueDull,
      
    },
    '&$checked': {
      color: chitBlueDull,
    },
    '&:hover': {
      backgroundColor: 'none',
    },

    '& svg ' : {
      width : '1rem',
      height: '1rem',
      // backgroundColor: 'green'
    
    }

    
    
  },

  
  
  checked: {},
})((props) => <Radio color="default" {...props} />);



// ================================================
 