/*---- File - LogHeader.jsx
     Displays details about Log retrieved from Redux store
     
    Contains children: 
        CountdownDisplay_s'

      parent: LogMain -./LogsMain
*/

import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'


import { Scrollbars } from 'react-custom-scrollbars';

import EditIcon from '../components/Edit_icon'
// import DeleteIcon from '../components/Delete_icon'

import { selectLogs } from '../../../app/redux/logRedux/logsSlice';
import { selectStatus, openLogForm } from '../../../app/redux/statusRedux/statusSlice';

import { deleteLogHolder } from '../../../app/redux/logHolderRedux/logHolderSlice';
import DeleteIcon from '../components/DeleteMany_icon';
import { selectPeople } from '../../../app/redux/peopleRedux/peopleSlice';
import { selectGroups } from '../../../app/redux/groupRedux/groupSlice';


//  ---- Material Ui ------------------

import Paper from '@mui/material/Paper'
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { styled, createTheme } from "@mui/material/styles"
const theme = createTheme(); // allows use of mui theme in styled component


// ================================================

function LogHeader(props) {

  const match = useParams()
  const dispatch = useDispatch()
  let navigate = useNavigate()
  const matchId = match.id
  let allLogsArray = useSelector(selectLogs) //immutable
  let allGroups = useSelector(selectGroups)
  let allPeople = useSelector(selectPeople)
  let status = useSelector(selectStatus)
  let statusId = status.view.log.id

  let allPeopleAndGroups = [...allGroups, ...allPeople]

  console.log('[ LogHeader ] matchId ', matchId);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = (evt) => {

    let logHolderId = evt.currentTarget.id
    console.log('[ LOG HEADER] delete clicked', logHolderId);
    dispatch(deleteLogHolder(logHolderId))
    navigate(`/main/logs`)

  }

  // --- get the name 
  let name, type, collection, meta

  console.log('[ LogHeader ] matchId ', allPeopleAndGroups);

  let nameObject = allPeopleAndGroups.find(element => element.id === statusId)

  nameObject.type === 'person' ? collection = 'people' : collection = 'groups'
  name = nameObject.name
  meta = nameObject.meta
  const handleClick = () => {


    //  console.log('[ LOG HEADER] open new form ');
    dispatch(openLogForm('new'))

  }




  // ====  Main Return =======================================

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">

        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are your sure you want to delete this section?
          </DialogContentText>
        </DialogContent>
        <DialogActions>

          <StyledButton
            form="submit-form"
            variant="contained"
            color="primary"
            id={statusId}
            onClick={(evt) => handleDelete(evt)}
          >
            Yes
          </StyledButton>

          <StyledButton
            form="submit-form"
            variant="contained"
            color="primary"
            type="submit"
            onClick={handleClose}
          >
            No
          </StyledButton>

        </DialogActions>
      </Dialog>
      {/* --- Large Screen ---------------------- */}
      <LargeWrapper>




        <TitleWrapper>
          <TitleWrapperLeft>
            <Title>
              {name}
            </Title>


            <ButtonWrapper>

              <FormButton
                startIcon={<AddIcon />}
                onClick={() => handleClick()}
              >
                add Section
              </FormButton>

            </ButtonWrapper>
          </TitleWrapperLeft>

          {/* <IconWrapper id = {statusId} onClick={(evt)=>handleDelete(evt)}> */}
          <IconWrapper >
            <EditIcon id={statusId} dbCollection='people'/>
            <DeleteIcon id={statusId} source='logs' />
          </IconWrapper>



          {/* <DetailWrapper> 
        <Scrollbars style={{ height: 50 }}>
      
            {meta}  
            
         </Scrollbars>
        </DetailWrapper> */}


        </TitleWrapper>


      </LargeWrapper>

      {/* --- SMALL SCREEN ---------------------- */}


      <SmallWrapper>
        <Title>
          {name}
        </Title>
        <SmallBottom>
          <ButtonWrapper>

            <FormButton
              startIcon={<AddIcon />}
              onClick={() => handleClick()}
            >
              add Section
            </FormButton>

          </ButtonWrapper>


          <IconWrapper >
            <EditIcon />
            <DeleteIcon id={statusId} source='logs' />
          </IconWrapper>

        </SmallBottom>

        {/* <SmallMeta>
       
       
      <DetailWrapper> 
        
      
            {meta}  
            
       
        </DetailWrapper>
      
      </SmallMeta> */}

      </SmallWrapper>




    </>
  )
}// end func LogDetail


export default LogHeader


// -----------------------------------------------------------------

// --- Large Screen -----
const LargeWrapper = styled(Paper)({
  display: 'flex',
  flexDirection: 'column',
  // justifyContent: 'flex-start',
  // alignItems: 'center',
  margin: '1rem  0 .5rem 0',
  padding: '.25rem',
  height: '5rem',

  width: '95%',



  [theme.breakpoints.down('md')]: {
    display: 'none'

  },
})



const TitleWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',

  width: '100%',




  fontSize: '1rem',

  // backgroundColor: 'orange',
  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',
    // backgroundColor: 'red'
  },
})

const TitleWrapperLeft = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  height: '100%',
  width: '40%',
  padding: ' .5rem',


  fontSize: '1rem',

  //  backgroundColor: 'yellow',

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',
    // backgroundColor: 'red'
  },
})



const DetailWrapper = styled('div')({
  display: 'block',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',


  padding: '4px 8px',
  // width: '18rem',
  width: '35%',
  height: '95%',
  border: '1px solid #E6E7E8',
  fontSize: '.75rem',
  backgroundColor: '#F6F7F8',
  margin: '.25rem',

  overflow: 'auto',

  [theme.breakpoints.down('md')]: {
    // height: '1.25rem',
    width: '100%',
    margin: 0,
    border: 'none',
  },
})


const Title = styled('div')({
  display: 'flex',

  justifyContent: 'flex-start',
  alignItems: 'center',



  color: 'red',
  flexWrap: 'wrap',

  fontSize: '1.2rem',



  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',
    // backgroundColor: 'red'
  },
})


const ButtonWrapper = styled('div')({
  display: 'flex',

  justifyContent: 'flex-end',
  alignItems: 'center',


  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',
    // backgroundColor: 'red'
  },
})

const FormButton = styled(Button)({



  display: 'flex',
  textTransform: 'none',


  color: 'white',
  backgroundColor: '#727376',
  fontWeight: 'normal',
  fontSize: '.85rem',
  padding: '0 1.5rem',
  width: '9.5rem',
  height: '1.2rem',
  // margin: '0 1rem',

  '&:hover': {
    // backgroundColor: chitBlueDull,
    textDecoration: 'none',
    border: '1px solid #A8BEED',
    color: '#3B30CC'

  },

  [theme.breakpoints.down('md')]: {
    fontSize: '.75rem',
    padding: '0 .75rem',
    width: '8.5rem',
  },


})

const IconWrapper = styled('div')({
  display: 'flex',

  justifyContent: 'center',
  alignItems: 'flex-end',

  height: '98%',
  //  width: '30%',
  marginRight: '8px',



  [theme.breakpoints.down('md')]: {
    marginRight: 0,
  },
})


const StyledButton = styled(Button)({
  color: 'white',
  margin: '0 8px',
  fontSize: '.8rem',
  padding: '2px',



  [theme.breakpoints.down('sm')]: {
    fontSize: '.8rem',
  },


})


// --- Small Screen
const SmallWrapper = styled(Paper)({

  display: 'none',


  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    margin: '1rem  0 .5rem 0',
    padding: '.25rem ',
    height: '8rem',

    // backgroundColor: 'pink',
    width: '95%',

  },
})



const SmallBottom = styled('div')({

  display: 'none',


  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '.25rem  0 .25rem 0',
    padding: '.25rem',


    width: '100%',


  },
})

const SmallMeta = styled(Paper)({

  display: 'none',


  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'flex-start',
    // alignItems: 'center',

    padding: '.25rem',
    minHeight: '1rem',
    fontSize: '.85rem',
    backgroundColor: '#F6F7F8',
    width: '98%',
    marginLeft: '1%'

  },
})