/*---- File - PersonalChitForm.jsx 
   Takes the data from Redux store status.view.forms.twoPartyChits

   Formats the store data for display
   Adds or updates Firestore database
      - categories collection category
      - groups collection group
      - personalChits collection - chit


*/


import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { chitBurgandy, chitBurgandyDull, darkGrey, lightGrey, mediumGrey, veryLightGrey } from '../styles/colors'

import { Scrollbars } from 'react-custom-scrollbars';


import {
  stripWhiteSpace,
  checkIfWordExists,
  cleanOptions,
  optionDescendSorter,
  isArrayDifferent,
  doesArrayIncludeItem,
  cleanString,


} from '../app/helpers/commonHelpers'


import { ISOtoTraditional, UTCtoDate } from '../app/helpers/dateHelper'

import { changeLoadingStatus, closeModal, selectStatus, updateStatusView } from '../app/redux/statusRedux/statusSlice'

import {
  selectCategories,
  getCategoryObjectFromId,
  getCategoryObjectFromName,
  addCategoryToStore

} from '../app/redux/categoryRedux/categorySlice'

import {
  selectAllPersonalChits,
  addPersonalChitToStore,
  updateEditedPersonalChit

} from '../app/redux/personalChitRedux/personalChitSlice'


// --- Firebase imports ---------

import {
  addDocumentToFirestore,
  getDocumentFromFirestore,
  updateDocumentFromFirestore
} from '../app/firebase/fireStore.utils';

// --- Form component imports ---------

import { StyledInput } from '../forms/formComponents/StyledInput'
import { StyledSelect } from '../forms/formComponents/StyledSelect';
import { StyledSliderMui } from '../forms/formComponents/StyledSliderMui';
import { ChitRadio } from '../forms/formComponents/ChitRadioHorizontal'

import { StyledDatePicker } from '../forms/formComponents/StyledDatePicker';
import { StyledChitSelectMuiCreatable } from '../forms/formComponents/StyledChitSelectMuiCreatable'
import { Editor } from './formComponents/JournalEditor'

import { StyledAutocomplete } from '../forms/formComponents/StyledAutocomplete';

// --- MUI imports ---------

import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

// --- React-hook-form imports ---------

import { FormProvider, useForm, Controller } from "react-hook-form";

import { yupResolver } from '@hookform/resolvers/yup';
import { string, object } from 'yup';


import { styled, createTheme } from '@mui/material/styles'
const theme = createTheme(); // allows use of mui theme in styled component


// ==============================================================
// ==== MAIN FUNCTION ===========================================

function PersonalChitForm(props) {

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const status = useSelector(selectStatus)
  const uid = status.auth.loginId

  let sampleDate = new Date('2021-03-14T17:03:40.000Z')

  const [popupMessage, setPopupMessage] = useState(false)

  // --- get arrays from collections
  let allCategories, allPersonalChits

  allCategories = useSelector(selectCategories) // get all categories
  allPersonalChits = useSelector(selectAllPersonalChits)

  //--- Create categpory select Options ----

  let categoryOptionsArray = allCategories.map(category => category.category);
  let sortedCategoryOptions = optionDescendSorter(categoryOptionsArray)


  /*----------------------------------------

    decide  if new personal chit 
        or existent personal chitto be edited 
  */

  let params = props.modalParams


  let existentChitId, existentChit, existentChitDate, existentChitColor, existentChitType, existentCategoryId, existentWorkRelated, existentDetail

  // --- if params - Edit personal chit ---


  if (params.id) {
    existentChitId = params.id

    existentChit = allPersonalChits.find((chit) => chit.id === existentChitId)

    console.log('[ PersonalChitForm  ] XXX existentChit ', existentChit);

    existentChitDate = new Date(existentChit.chitDate)


    if (existentChit.chitColor === 'red') {

      existentChitType = 'awChit'
      existentChitColor = 'red'

    } else if (existentChit.chitColor === 'milestone') {

      existentChitType = 'milestone'
      existentChitColor = 'milestone'
    } else {

      existentChitType = 'personal'
      existentChitColor = existentChit.chitColor
    }


    existentCategoryId = existentChit.category
    existentWorkRelated = existentChit.workRelated
    existentDetail = existentChit.detail


  }// --- end if params




  // ====  Yup setup ==================================

  //  --- set default values conditioned on 
  //      whether new or edit existing chit 

  let defaultValues, defaultCategoryName, id, headerMessage, defaultChitDate, defaultDetail, defaultWorkRelated, defaultCategory, defaultChitColor


  //--- set up header message (edit or new)

  !existentChitId ? headerMessage = 'Create New Personal Chit' : headerMessage = 'Edit Personal Chit'


  // --- if edit chit - get category name ---

  if (existentChitId) {


    let categoryObject = getCategoryObjectFromId(allCategories, existentCategoryId)
    defaultCategoryName = categoryObject.category
  }


  !existentChitId ? defaultCategory = '' : defaultCategory = defaultCategoryName

  // --- set default color to copper if new personal chit

  !existentChitId ? defaultChitColor = 'copper' : defaultChitColor = existentChitColor

  let today = Date.now()
  let ISODate = UTCtoDate(today)

  let ISODateTime = new Date(ISODate)
  let maxDate = new Date(ISODate)

  // --- set remainder of default values for form ---

  !existentChitId ? defaultChitDate = ISODateTime : defaultChitDate = existentChitDate

  let formattedExistentChitDate = ISOtoTraditional(existentChitDate)


  !existentChitId ? defaultDetail = '' : defaultDetail = existentDetail
  !existentChitId ? defaultWorkRelated = 'notWorkRelated' : defaultWorkRelated = existentWorkRelated

  defaultValues = {
    category: defaultCategory,
    chitDate: defaultChitDate,
    detail: defaultDetail,
    workRelated: defaultWorkRelated,
    chitColor: defaultChitColor,
  };

  //  -- Validation values if any

  const formSchema = object({

    category: string().required('You must choose or create a category'),

  });


  const methods = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(formSchema)
  });

  //======= SUBMIT ================================ 


  const { handleSubmit, reset, control, setValue, onChange, watch, ref, formState: { errors } } = methods

  const submitForm = async (data) => {

    // get form data elements
    const { chitDate, chitColor, detail, workRelated, category } = data


    // - replace the <p>s with <div>s in Quill editor to make it appear better
    let noPtagContent = detail.replaceAll('<p>', '<div>')
    let cleanDetail = noPtagContent.replaceAll('</p>', '</div>')

    // ---check to see if category exists or new category

    let cleanCategory = cleanString(category)

    let doesCategoryExist = checkIfWordExists(cleanCategory, allCategories, 'categories')

    let ISOChitDate = new Date(chitDate).toISOString()


    try {

      /*
        1. set loading status
        2. determine if category is new or exists
          a. if new - add to Firestore - return Id
          b. if exists - get existent id from Redux store

        3. create data object to deploy to Firestore
        4. determine if chit id was passed from modal
            a.  if yes - update existent chit in Firestore
                     update redux store chit
            b.  if no - add new chit to Firestore - return id
                    dispatch new chit to Redux with returned id

      */


      // --- start the loading spinner ---

      dispatch(changeLoadingStatus(true))


      /*  2. determine if category is new or exists
          note: category and existing inputs in form pass category names
          whilecateogry field in personal chit in store / collection uses an id
          
          Convert name in existing category to id, 
              or make a new category id
  
      */

      let formCategoryId

      // --- category is new ---------------

      if (!doesCategoryExist) {

        //-- add new category to Firestore and Redux

        let categoryObject = {
          category: cleanCategory
        }

        let newCategoryId = await addDocumentToFirestore(uid, 'categories', categoryObject)

        dispatch(addCategoryToStore({
          id: newCategoryId,
          category: cleanCategory,

        }
        )) // end dispatach addCategoryToStore    


        //--- set formCategoryId to newly created Firestore id

        formCategoryId = newCategoryId


      } // end if doesCategoryExist = false


      // --- category exists ------------------

      if (doesCategoryExist) {

        //--- set formCategoryId to existent category id

        formCategoryId = doesCategoryExist.id

      }// end ifdoesCategoryExist = true



      const chitObject = (dataStructure, newChitId) => {
        let dataObject

        if (dataStructure === 'firestoreData') {
          dataObject = {
            category: formCategoryId,
            chitDate: ISOChitDate,
            detail: cleanDetail,
            workRelated: workRelated,
            chitColor: chitColor,
          };

        }

        if (dataStructure === 'reduxData') {
          dataObject = {
            id: newChitId,
            category: formCategoryId,
            chitDate: ISOChitDate,
            detail: cleanDetail,
            workRelated: workRelated,
            chitColor: chitColor,
          };

        }

        return dataObject

      }
      // --- end chitObject ---------------------

      // --- New Chit - add new chit to Firestore and Redux store

      if (!existentChitId) {

        let chitFirestoreData = chitObject('firestoreData')

        let newChitId = await addDocumentToFirestore(uid, 'personalChits', chitFirestoreData)

        let chitReduxStoreData = chitObject('reduxData', newChitId)

        dispatch(addPersonalChitToStore(chitReduxStoreData))
      }

      // --- Existent Chit - update Firestore and Redux store

      if (existentChitId) {
        let chitFirestoreData = chitObject('firestoreData')
        let chitReduxStoreData = chitObject('reduxData', existentChitId)

        await updateDocumentFromFirestore(uid, 'personalChits', existentChitId, chitFirestoreData)


        dispatch(updateEditedPersonalChit(chitReduxStoreData))

      }// end existentChitId - update

      dispatch(changeLoadingStatus(false))

      setPopupMessage(true)

      dispatch(updateStatusView({
        pageType: 'personal',
        pageView: 'ledger',
        type: 'personalChits',
        id: formCategoryId
      }))
      navigate(`/main/personalChits/${formCategoryId}`)

      console.log('[ PErsonal Chit form ] formCategoryId ', formCategoryId);


    } catch (error) {
      alert(error.message)
      dispatch(changeLoadingStatus(false))

      reset(defaultValues)

    } // end catch


  }// --- end async submit ------------

  /* --- exclude dates from datepicker  ==================
      In each specific category - for specific day -
          - only one chit is allowed for each day
      
      - So, create an array of days that already have a chit for
        that category

      - This only affects new (not edited) chits with
        existing categories.
  */

  const categorySelected = watch("category");
  let existentCategory = checkIfWordExists(categorySelected, allCategories, 'categories')

  let formCategoryId, filteredCategories
  let excludedDates = []
  if (existentCategory) {
    formCategoryId = existentCategory.id




    filteredCategories = allPersonalChits.filter(chit => chit.category === formCategoryId)

    filteredCategories.map((chit, index) => {
      let dateWithChit = chit.chitDate



      excludedDates.push(new Date(dateWithChit))

      return excludedDates
    }
    ) //end map

  }


  function closeForm() {

    dispatch(closeModal())
  }


  //======= Main Return ================================ 
  return (
    <>
      {!popupMessage &&
        <Wrapper>

          <HeaderWrapper> {headerMessage} </HeaderWrapper>

          <FormProvider {...methods}>


            <FormWrapper onSubmit={handleSubmit(submitForm)}>

              <FormComponentWrapper>
                <ComponentName>
                  Choose a category for your chit
                </ComponentName>
                <Instructions>Select a category, or type in a new category</Instructions>
                <StyledChitSelectMuiCreatable
                  name={'category'}
                  control={control}
                  options={sortedCategoryOptions}
                  // defaultValue = {{ value: 'ge423', label: 'home'}}
                  defaultValue={defaultValues.category}
                  placeholder=''


                />
                {errors.category && <ErrorMessage>{errors.category.message} </ErrorMessage>}


              </FormComponentWrapper>



              {/* ------DatePicker Component (endEst) -------------------------- */}

              {existentChitId &&
                <FormComponentWrapper>
                  <ExistentWrapper>
                    <div>Chit date :   </div>
                    <em>{formattedExistentChitDate}  </em>

                  </ExistentWrapper>
                </FormComponentWrapper>

              }

              {!existentChitId &&
                <FormComponentWrapper>


                  <ComponentName>
                    Chit date ? <StyledCalendarIcon />
                  </ComponentName>

                  <ComponentWrapper>
                    <Controller

                      name="chitDate"
                      control={control}
                      initialNote={'hi'}

                      render={({ field }) => (
                        <StyledDatePicker
                          {...field}
                          excludedDates={excludedDates}
                          maxDate={maxDate}
                          ref={null} />
                      )}
                    />

                  </ComponentWrapper>
                  {/* {errors.chitDate && <ErrorMessage>{errors.chitDate.message} </ErrorMessage>} */}
                </FormComponentWrapper>
              }
              {/* ------Chit-------------------------- */}

              <FormComponentWrapper>
                <ComponentName>
                  Chit Type
                </ComponentName>

                <ComponentWrapperIndent>
                  <RadiotWrapper>
                    <ChitRadio
                      name={"chitColor"}
                      control={control}
                      label={"logType"}
                      options={[

                        {
                          label: "copper",
                          value: "copper",
                        },
                        {
                          label: "silver",
                          value: "silver",
                        },

                        {
                          label: "gold",
                          value: "gold",
                        },

                        {
                          label: "awChit",
                          value: "red",
                        },

                        {
                          label: "milestone",
                          value: "milestone",
                        },


                      ]}
                      defaultValue={defaultValues.chitColor}
                    />
                  </RadiotWrapper>

                </ComponentWrapperIndent>

              </FormComponentWrapper>

              {/* ------Detail  -------------------------- */}


              <QuillComponentWrapper>
                <ComponentName>
                  Description  of chit
                </ComponentName>

                <QuillWrapper>


                  <Controller

                    name="detail"
                    control={control}
                    initialNote={'hi quill description'}

                    render={({ field }) => (
                      <Editor
                        {...field}
                        ref={null}
                        IniitalValue={defaultValues.detail}

                      />
                    )}

                  />

                </QuillWrapper>
              </QuillComponentWrapper>



              {/* ------Work related -------------------------- */}

              <FormComponentWrapper>
                <ComponentName>
                  Is this chit work related ?
                </ComponentName>


                <ComponentWrapper>
                  <RadiotWrapper>
                    <ChitRadio
                      name={"workRelated"}
                      control={control}
                      label={"workRelated"}
                      options={[
                        {
                          label: "yes",
                          value: "workRelated",
                        },
                        {
                          label: "no",
                          value: "notWorkRelated",
                        },

                      ]}
                      defaultValue={defaultValues.workRelated}
                    />
                  </RadiotWrapper>

                </ComponentWrapper>
              </FormComponentWrapper>

              {/* ------Submit ---------- -------------------------- */}
              <SubmitContainer>
                <StyledButton

                  variant="contained"
                  color="primary"
                  style={{
                    textTransform: 'none',

                  }}
                  onClick={() => closeForm()}

                >
                  Cancel
                </StyledButton>

                <StyledSubmitButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{ textTransform: 'none' }}
                >
                  Submit Form
                </StyledSubmitButton>

              </SubmitContainer>

            </FormWrapper>

          </FormProvider>















        </Wrapper>
      }

      {/* ------Success of submission - show popup message ------ */}

      {popupMessage && !existentChitId &&

        <MessageWrapper>
          <SuccessMessage>

            Your new personal chit has been created


          </SuccessMessage>

          <CloseFormButton onClick={() => closeForm()}>
            Got it - thanks

          </CloseFormButton>
        </MessageWrapper>
      }

      {popupMessage && existentChitId &&

        <MessageWrapper>
          <SuccessMessage>

            Your personal chit has been updated



          </SuccessMessage>

          <CloseFormButton onClick={() => closeForm()}>
            Got it - thanks

          </CloseFormButton>
        </MessageWrapper>
      }

    </>
  );
}

export default PersonalChitForm

// ==== Styles ===========================================


// ---------------------------------------------
const Wrapper = styled(Paper)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  // zIndex: '95',
  backgroundColor: 'none',
  width: '100%',
  height: '100%',
  overflow: 'auto',
  marginTop: '1rem',

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',
    // backgroundColor: 'red'
  },

})

const MessageWrapper = styled(Paper)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  // zIndex: '95',
  backgroundColor: 'none',
  width: '100%',
  height: '100%',
  overflow: 'auto',
  margin: '2rem 0',
  padding: '2rem 0',


  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',
    // backgroundColor: 'red'
  },

})

const SuccessMessage = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  // zIndex: '95',
  // backgroundColor: 'red',

  marginBottom: '.5rem',
  color: chitBurgandy,


  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',
    // backgroundColor: 'red'
  },

})

const CloseFormButton = styled(Button)({
  backgroundColor: 'white',
  border: '1px solid #E6E7E8',
  color: chitBurgandyDull,
  margin: '0 8px',
  width: '8rem',
  height: '1.5rem',
  fontSize: '.8rem',
  '&:hover': {
    backgroundColor: lightGrey
  }

})

const HeaderWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '.5rem 0 .5rem 0',
  marginBottom: '.5rem',
  borderBottom: '2px solid #CFD0D1',
  boxShadow: '0 0 1px 0 #F6F7F8',
  // zIndex: '95',

  width: '100%',

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',
    // backgroundColor: 'red'
  },

})

const FormWrapper = styled('form')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '98%',
  padding: '0 5%',

  backgroundColor: veryLightGrey,
  [theme.breakpoints.down('sm')]: {
    width: '100%',


  },

})
const FormComponentWrapper = styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '100%',
  margin: '.25rem',
  padding: '1rem',
  borderRadius: '5px',
  backgroundColor: 'white',
  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const CategoryFormComponentWrapper = styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '4rem',
  margin: '.25rem',
  borderRadius: '5px',

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const CategoryLeft = styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '30%',
  //   margin: '.25rem',
  //   padding: '1rem',
  //   borderRadius: '5px',
  // backgroundColor: 'white',
  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})


const CategoryRight = styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'flex-start',
  width: '70%',

  //   margin: '.25rem',
  //   padding: '1rem',
  //   borderRadius: '5px',
  // backgroundColor: 'white',
  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const ExistentRow = styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',


  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const ExistentRowLeft = styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  color: mediumGrey,
  fontSize: '.9rem',
  width: '6rem',
  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const ExistentRowRight = styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  color: chitBurgandy,

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const ComponentName = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  color: darkGrey,


  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})


const ExistentWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
  color: darkGrey,

  '& div': {
    color: chitBurgandy,
    marginRight: '1rem'
  },

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const ComponentWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',


  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const ComponentWrapperIndent = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '95%',
  marginLeft: '1.5rem',


  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const ButtonWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '60%',
  margin: '.75rem',


  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})



const StyledCalendarIcon = styled(CalendarTodayIcon)({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: '8px',
  width: '16px',
  color: '#CFD0D1',



  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const StyledSubmitButton = styled(Button)({
  backgroundColor: 'white',
  border: '1px solid #E6E7E8',
  color: chitBurgandyDull,
  margin: '0 8px',
  width: '8rem',
  height: '1.5rem',
  fontSize: '.8rem',
  '&:hover': {
    backgroundColor: lightGrey
  }

})

//  --- Buttons -----------
const SubmitContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '95%',
  margin: '.75rem',


  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})



const StyledButton = styled(Button)({
  backgroundColor: 'white',
  border: '1px solid #E6E7E8',
  color: chitBurgandyDull,
  margin: '0 1.5rem 0 6px',
  width: '5rem',
  height: '1.5rem',
  fontSize: '.8rem',
  '&:hover': {
    backgroundColor: lightGrey
  }

})

const DetailComponentWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
  border: '1px solid orange',
  borderRadius: '5px',
  padding: '2px',

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})
const RadiotWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',

  //  backgroundColor: 'yellow',
  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const QuillComponentWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',

  margin: '.25rem',
  padding: '1rem',

  backgroundColor: 'white',
  borderRadius: '5px',
  // backgroundColor: 'red',

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },
})

const QuillWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
  height: '95%',
  // border: '1px solid orange',
  borderRadius: '5px',
  backgroundColor: 'white',
  padding: '2px',
  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },
})


const ErrorMessage = styled('div')({
  fontSize: '.8rem',
  color: 'red',
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  marginTop: '-1rem',
  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const Instructions = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  margin: '8px 0 -4px 12px',
  color: mediumGrey,
  fontSize: '.7rem',




})

const ShareMessage = styled('div')({


  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  fontSize: '.8rem',
  color: mediumGrey,
  width: '60%',
  margin: '4px',


  //   [theme.breakpoints.down('sm')] : {
  //     // width: '100%'
  //   },

})
