/* LoginForm.jsx

Form to join 
Parent: pages/public/Login.jsx
Children:  form components in -  ./formCompnents

*/

import React  from 'react'

import {useNavigate} from   'react-router-dom'

import { FormProvider, useForm} from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { changeLoadingStatus, closeModal } from '../app/redux/statusRedux/statusSlice'
 
import { string, object, array } from 'yup';
import {StyledInput} from './formComponents/StyledInput'

import GoogleLogo from '../images/googleLogo.svg'

import {styled, createTheme}  from '@mui/material/styles'
import Button from '@mui/material/Button'; 

import { 
  createAuthUserWithEmailAndPassword, 
  createUserDocumentFromAuth,
  signInWithGooglePopup 

} from '../app/firebase/firebase.utils';

import { getUserDataFromFirestore } from '../app/firebase/fireStore.utils';

// import FirebaseAuthService from '../app/firebase/FirebaseAuthService';
// import {updateLastVisit} from '../app/firebase/FirebaseFirestoreService';
import { backgroundBlue, chitBurgandy, chitBurgandyDull, lightGrey } from '../styles/colors';

const theme = createTheme(); // allows use of mui theme in styled component



// ===================================================

// --- Yup setup ---
const defaultValues = {
  displayName: "",
  // firstName: "",
  // lastName: "",
  email: "",
  password: "",
  confirmPassword: ""
};

//  -- Input requirements for user for each component (if any)

const formSchema = object({
  displayName: string().required('last name required is required'),
  // firstName: string().required('last name required is required'),
  // lastName: string().required('last name required is required'),
  email: string().required('email is required').email('not an email'),
  
  password: string()
            .required('Confirm Password is required')
            .min(8, 'minimum of 8 characters'),
  confirmPassword: string()
            .required('Confirm Password is required')
            .oneOf([yup.ref('password')], 'Passwords must match')

  
});

// ===================================================

function JoinForm({existingUser}) {
  const navigate = useNavigate()
  const dispatch = useDispatch()

    // --- sign in with Google---------
    const logGoogleUser = async () => {
      await signInWithGooglePopup()

      dispatch(closeModal())
    
    }
  
  
    const methods = useForm({
      defaultValues: defaultValues,
      resolver: yupResolver(formSchema)
    });
  
    const { handleSubmit, reset, control  } = methods;
 
  
    const submitForm = async (data ) => {

      const {displayName, email, firstName, lastName, password} = data
   
      console.log('[Join Form ] data : ', email)
      // --- Login functions ---
      
      try {

      // --- start the spinner ---
      dispatch(changeLoadingStatus(true))

      // --- add user to Firebase auth
        const {user} = await createAuthUserWithEmailAndPassword(
          email, 
          password
          )

          console.log('[Join Form ] response : ', user)

      // let displayName = firstName + ' ' + lastName

      // console.log('[Join Form ] displayName : ', displayName)
      // console.log('[Join Form] response uid', user.uid)

      //--- create new doc in Firestore "users" collection
        await createUserDocumentFromAuth(user, {displayName})

        const joy = await getUserDataFromFirestore(user.uid)

     console.log('[Join Form] joy uid', joy)

          
            dispatch(closeModal())
            dispatch(changeLoadingStatus(false))
          reset()
      //   }
     

      //   reset(defaultValues)

      } catch (error) {

      //   // --- alert error + navigate + end spinner + reset form ---

        if(error.code === 'auth/email-already-in-use')
        alert('Cannot create user... email already in use')
       dispatch(changeLoadingStatus(false))
      //   navigate('/login')
      //   reset(defaultValues)



      } // --- end try catch

    };
  
    // --- reset function - called in link below ---

    const goToReset = () => {
      navigate('/resetPassword')
    }
  
     // --- Actual Form ---------------------------------------------
  return (
    <Wrapper>



<GoogleButton onClick={() => {
        logGoogleUser()
      }}>
        <Google src={GoogleLogo} alt="Chit Git Logo" />
        <span> Sign up with Google </span>
      </GoogleButton>

      <OrWrapper> or Sign Up in with email</OrWrapper>

      <FormProvider {...methods}>
        <FormWrapper onSubmit={handleSubmit(submitForm)}>


          {/* ------Input Component  -------------------------- */}

          <ComponentWrapper>
            <StyledInput
              name={"displayName"}
              control={control}
              label={"displayName"}
              defaultValue={''}
              placeholder=' Display  Name'


            />

          </ComponentWrapper>
{/* 
          <ComponentWrapper>
            <StyledInput
              name={"firstName"}
              control={control}
              label={"firstName"}
              defaultValue={''}
              placeholder=' First Name'


            />

          </ComponentWrapper> */}



{/* 
          <ComponentWrapper>
            <StyledInput
              name={"lastName"}
              control={control}
              label={"lastName"}
              defaultValue={''}
              placeholder=' Last Name'


            />

          </ComponentWrapper> */}






          <ComponentWrapper>
            <StyledInput
              name={"email"}
              control={control}
              label={"email"}
              defaultValue={''}
              placeholder='email'


            />

          </ComponentWrapper>


          {/* ------Input Component  -------------------------- */}




          <ComponentWrapper>
            <StyledInput
              name={"password"}
              control={control}
              label={"password"}
              defaultValue={''}
              placeholder='password'
              type='password'

            />

          </ComponentWrapper>





          <ComponentWrapper>
            <StyledInput
              name={"confirmPassword"}
              control={control}
              label={"confirmPassword"}
              defaultValue={''}
              placeholder='confirm password'
              type='password'

            />

          </ComponentWrapper>



          {/* ------Submit ---------- -------------------------- */}
          <ButtonWrapper>

            <StyledButton
              type="submit"
              variant="contained"
              color="primary"
              style={{ textTransform: 'none' }}
            >
              Submit
            </StyledButton>
          </ButtonWrapper>
        </FormWrapper>

        
      </FormProvider>



    </Wrapper>
  )// end return
}

export default JoinForm


// ----------------------
const Wrapper= styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  
  
  width: '100%',


})


const FormWrapper = styled('form')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '74%',
  // marginTop: '.5rem', 
  padding: '1rem 2rem',
  border: '1px solid #CFD0D1',
  borderRadius: '5px',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
     

  },

})
const FormComponentWrapper= styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '100%',

 
 
  [theme.breakpoints.down('sm')]: {
    width: '55%'

  },

})

const ComponentName= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  color: 'darkGrey',
 

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const ComponentWrapper= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
  // overflowY: 'hidden',
  marginBottom: '1rem',
  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const ButtonWrapper= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '60%',
  margin: '.75rem',
 
  
  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const StyledButton= styled(Button)({
  backgroundColor: 'white',
  border: '1px solid #E6E7E8',
  color: chitBurgandyDull,
  margin: '0 8px',
  padding: ' 0 1rem',
  height: '1.5rem',
  fontSize: '.8rem',
  '&:hover' :{
    backgroundColor: lightGrey
  }

})
const ResetWrapper= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '60%',
  margin: '.75rem',
 
  cursor: 'pointer',
  color: backgroundBlue,
  fontSize: '.85rem',
  textDecoration: 'underline',
  
  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const Google= styled('img')({

  height: '.9rem',
  marginRight: '.7rem'

})
const GoogleButton = styled(Button)({

 
backgroundColor: 'white',
  display: 'flex',
  justifyContent: 'center',
  textTransform: 'none',
  
  border: '1px solid #CFD0D1',
  color: 'black',
  fontWeight: 'normal',
  fontSize: '.8rem',
  padding: '.4rem',
  width: '60%',

  '&:hover' : {
    backgroundColor: lightGrey,
    textDecoration: 'none',
    border: '1px solid #A8BEED' ,
    

  }


})

const OrWrapper= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  margin: '.75rem',
  fontSize: '.8rem',
  color: chitBurgandy,
  
  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

 