/*  --- Firestore functions ---
    getUserDataFromFirestore

    addCollectionAndDocuments

    getCollectionFromFirestore
    addCollectionToFirestore

*/


// #### Temp data for loading Firestore ########################
import PersonalChits from '../X_initialStore/ex_personalChit_initialStore'
// ############################################################# 


// Firestore query utilities

import {
  getFirestore,
  doc,
  addDoc,
  getDoc,
  setDoc,
  collection,
  onSnapshot,
  writeBatch,
  getDocs,
  query,
  deleteDoc,
  updateDoc

} from 'firebase/firestore'
import { batch } from 'react-redux'

const db = getFirestore()

// --- getUserDataFromFirestore ----------

export const getUserDataFromFirestore = async (uid) => {

  const userDocRef = doc(db, 'users', uid)
  const userSnapshot = await getDoc(userDocRef)


  return userSnapshot.data()

}



// --- addCollectionAndDocuments ---- batch write -----

export const addCollectionAndDocuments = async (
  userId,
  collectionKey,
  objectsToAdd,
  field
) => {

  // const userDocRef = doc(db, 'users', userId)
  const collectionRef = collection(db, collectionKey)

  const batch = writeBatch(db)

  objectsToAdd.forEach((object) => {

    const docRef = doc(collectionRef, object[field])
    batch.set(docRef, object)
  })

  await batch.commit()


}

// --- getCollectionFromFirestore  -----

export const getCollectionFromFirestore = async (uid, collectionName) => {

  const colRef = collection(db, `data/${uid}/${collectionName}`)
  const q = query(colRef)

  const querySnapshot = await getDocs(q)

  const categoryMap = querySnapshot.docs.map((docSnapshot) => {

    let a = { ...docSnapshot.data(), id: docSnapshot.id }
    return a

  })

  return categoryMap

}


//--- addCollectionToFirestore ---   This adds to Joe Blow

let userId = 'g6q3v1JmoZTWEJg1shBMD9BeZVN2'
let objectsToAdd = PersonalChits
let collectionName = 'personalChits'

export const addCollectionToFirestore = async () => {

  const colRef = collection(db, `data/${userId}/${collectionName}`)
  const batch = writeBatch(db)

  objectsToAdd.forEach((object) => {

    const docRef = doc(colRef, object.tempId)
    batch.set(docRef, object)
  })

  await batch.commit()


}

// ---- addDocumentToFirestore --------------

export const addDocumentToFirestore = async (uid, passedCollectionName, data) => {


  const colRef = collection(db, `data/${uid}/${passedCollectionName}`)

  const newDocumentAdded = await addDoc(colRef, data)

  let newDocId = newDocumentAdded.id

  return newDocId


}

// ---- addDocumentToFirestore --------------

export const addMailToFirestore = async (data) => {


  const colRef = collection(db, `contactMail`)

  const newDocumentAdded = await addDoc(colRef, data)

  let newDocId = newDocumentAdded.id

  return newDocId


}





export const getDocumentFromFirestore = async (uid, passedCollectionName, documentId) => {
  const docRef = doc(db, `data/${uid}/${passedCollectionName}`, documentId)
  const docSnap = await getDoc(docRef)
  if (docSnap.exists()) {
  } else {
    // doc.data() will be undefined in this case
   
  }

  return docSnap

}

export const deleteDocumentFromFirestore = async (uid, passedCollectionName, documentId) => {
  const docRef = doc(db, `data/${uid}/${passedCollectionName}`, documentId)
  deleteDoc(docRef)

}

export const updateDocumentFromFirestore = async (uid, passedCollectionName, documentId, formData) => {



  const docRef = doc(db, `data/${uid}/${passedCollectionName}`, documentId)
  updateDoc(docRef, formData)

}


export const getSiteDocumentToFirestore = async (passedCollectionName, documentId) => {

  const userDocRef = doc(db, passedCollectionName, documentId)
  const userSnapshot = await getDoc(userDocRef)


  return userSnapshot.data()

}


export const addSiteDocumentToFirestore = async (passedCollectionName, data) => {



  const colRef = collection(db, `${passedCollectionName}`)

  const newDocumentAdded = await addDoc(colRef, data)

  let newDocId = newDocumentAdded.id

  return newDocId


}

export const updateSiteDocumentFromFirestore = async (passedCollectionName, documentId, data) => {



  const docRef = doc(db, `${passedCollectionName}`, documentId)
  updateDoc(docRef, data)

}


export const addSharedChitDocumentToFirestore = async (uid, sharedChitId, data) => {


  const colRef = collection(db, `data/${uid}/sharedChits`)

  await setDoc(doc(colRef, sharedChitId), data)




}

export const addLogHolderDocumentToFirestore = async (uid, logHolderId, data) => {


  const colRef = collection(db, `data/${uid}/logHolders`)

  await setDoc(doc(colRef, logHolderId), data)




}
