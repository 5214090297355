
/*---- File - NoteForm.jsx 
    


*/


import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { chitBurgandy, chitBurgandyDull, darkGrey, lightGrey, mediumGrey, veryLightGrey } from '../styles/colors'


import { changeLoadingStatus, closeModal, selectStatus } from '../app/redux/statusRedux/statusSlice'
import { addNoteStore } from '../app/redux/noteRedux/notesSlice'

import { UTCtoDate } from '../app/helpers/dateHelper'
// --- Firebase imports ---------

import {
  addDocumentToFirestore,
  getDocumentFromFirestore,
  updateDocumentFromFirestore
} from '../app/firebase/fireStore.utils';

import { Editor } from './formComponents/JournalEditor'

// --- MUI imports ---------

import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
// --- React-hook-form imports ---------

import { FormProvider, useForm, Controller } from "react-hook-form";

import { yupResolver } from '@hookform/resolvers/yup';
import { string, object } from 'yup';


import { styled, createTheme } from '@mui/material/styles'
const theme = createTheme(); // allows use of mui theme in styled component


// ==============================================================
// === Main Function =====================================
export default function NoteForm(props) {
  console.log('[ NoteForm  ] XXX props ', props);

  let params = props.modalParams
  const { noteHolderId, id } = params
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const status = useSelector(selectStatus)
  const uid = status.auth.loginId

  let today = Date.now()
  let ISODate = UTCtoDate(today)
  let noteDate = new Date(ISODate).toISOString()


  let defaultValues = {
    noteContent: '',
    alarm: ''
  };


  const formSchema = object({

    // category: string().required('You must choose or create a category'),

  });



  const methods = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(formSchema)
  });

  //======= SUBMIT ================================ 


  const { handleSubmit, reset, control, setValue, onChange, watch, ref, formState: { errors } } = methods

  const submitForm = async (data) => {

    console.log('[ NoteForm  ] XXX data ', data);

    // get form data elements
    const { noteContent } = data

    let noPtagDetail = noteContent.replaceAll('<p>', '<div>')
    let cleanNoteContent = noPtagDetail.replaceAll('</p>', '</div>')

    let dataObject = {
      noteContent: noteContent,

    };

    const chitObject = (dataStructure, newNoteId) => {
      let dataObject


      if (dataStructure === 'firestoreData') {
        dataObject = {
          noteContent: cleanNoteContent,
          noteDate: noteDate,
          noteHolderCollection: 'topicals',
          noteHolderId: noteHolderId,
          alarm: ''
        };

      }

      if (dataStructure === 'reduxData') {
        dataObject = {
          id: newNoteId,
          noteContent: cleanNoteContent,
          noteDate: noteDate,
          noteHolderCollection: 'topicals',
          noteHolderId: noteHolderId,
          alarm: ''
        };

      }

      return dataObject
    }

    try {

      /*
             1. set loading status
             2. create data object to deploy to Firestore
             3. update Redux store
             4. set loading status
     
           */



      dispatch(changeLoadingStatus(true))
      let noteFirestoreData = chitObject('firestoreData')

      let newNoteId = await addDocumentToFirestore(uid, 'notes', noteFirestoreData)

      let noteReduxStoreData = chitObject('reduxData', newNoteId)
      dispatch(addNoteStore(noteReduxStoreData))
      dispatch(changeLoadingStatus(false))
      closeForm()
    } catch (error) {
      alert(error.message)
      dispatch(changeLoadingStatus(false))

      reset(defaultValues)
      closeForm()
    } // end catch



  }// --- end async submitForm




  function closeForm() {

    dispatch(closeModal())
  }






  //=== Main Return ====================================
  return (
    <Wrapper>

      <HeaderWrapper> New Note</HeaderWrapper>

      <FormProvider {...methods}>


        <FormWrapper id="submit-form" onSubmit={handleSubmit(submitForm)} >


          {/* ------Detail  -------------------------- */}


          <QuillComponentWrapper>
            <ComponentName>
              Description  of note
            </ComponentName>

            <QuillWrapper>


              <Controller

                name="noteContent"
                control={control}
                initialNote={'hi quill description'}

                render={({ field }) => (
                  <Editor
                    {...field}
                    ref={null}
                    IniitalValue={defaultValues.note}

                  />
                )}

              />

            </QuillWrapper>
          </QuillComponentWrapper>




          {/* ------Submit ---------- -------------------------- */}
          <SubmitContainer>
            <StyledButton

              variant="contained"
              color="primary"
              style={{
                textTransform: 'none',

              }}
              onClick={() => closeForm()}

            >
              Cancel
            </StyledButton>

            <StyledSubmitButton
              type="submit"
              variant="contained"
              color="primary"
              style={{ textTransform: 'none' }}
            >
              Submit Form
            </StyledSubmitButton>

          </SubmitContainer>


        </FormWrapper>
      </FormProvider>
    </Wrapper>
  );
}// === end main function

const Wrapper = styled(Paper)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  // zIndex: '95',
  backgroundColor: 'none',
  width: '100%',
  height: '100%',
  overflow: 'auto',
  marginTop: '1rem',

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',
    // backgroundColor: 'red'
  },

})


const CloseFormButton = styled(Button)({
  backgroundColor: 'white',
  border: '1px solid #E6E7E8',
  color: chitBurgandyDull,
  margin: '0 8px',
  width: '8rem',
  height: '1.5rem',
  fontSize: '.8rem',
  '&:hover': {
    backgroundColor: lightGrey
  }

})

const HeaderWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '.5rem 0 .5rem 0',
  marginBottom: '.5rem',
  borderBottom: '2px solid #CFD0D1',
  boxShadow: '0 0 1px 0 #F6F7F8',
  // zIndex: '95',

  width: '100%',

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',
    // backgroundColor: 'red'
  },

})

const FormWrapper = styled('form')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '98%',
  padding: '0 5%',

  backgroundColor: veryLightGrey,
  [theme.breakpoints.down('sm')]: {
    width: '100%',


  },

})

const FormComponentWrapper = styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '100%',
  margin: '.25rem',
  padding: '1rem',
  borderRadius: '5px',
  backgroundColor: 'white',
  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})




const QuillComponentWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',

  margin: '.25rem',
  padding: '1rem',

  backgroundColor: 'white',
  borderRadius: '5px',
  // backgroundColor: 'red',

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },
})

const QuillWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
  height: '95%',
  // border: '1px solid orange',
  borderRadius: '5px',
  backgroundColor: 'white',
  padding: '2px',
  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },
})


const ErrorMessage = styled('div')({
  fontSize: '.8rem',
  color: 'red',
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  marginTop: '-1rem',
  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})
const ComponentName = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  color: darkGrey,


  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

//  --- Buttons -----------
const SubmitContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '95%',
  margin: '.75rem',


  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const StyledSubmitButton = styled(Button)({
  backgroundColor: 'white',
  border: '1px solid #E6E7E8',
  color: chitBurgandyDull,
  margin: '0 8px',
  width: '8rem',
  height: '1.5rem',
  fontSize: '.8rem',
  '&:hover': {
    backgroundColor: lightGrey
  }

})

const StyledButton = styled(Button)({
  backgroundColor: 'white',
  border: '1px solid #E6E7E8',
  color: chitBurgandyDull,
  margin: '0 1.5rem 0 6px',
  width: '5rem',
  height: '1.5rem',
  fontSize: '.8rem',
  '&:hover': {
    backgroundColor: lightGrey
  }

})