import { createSlice } from '@reduxjs/toolkit'



export const groupsSlice = createSlice({
  name: 'groupsSample',
  initialState: [],

  reducers: {
    

    addAllGroupsFromFirestore: (state = [], action) => {
    
      let firestoreData = action.payload
    
      // Clear the state array
      state = [];
    
      // Add the Firestore data to the state array
      state = [...firestoreData];
    
      return state
    },// end addAllGroupsFromFirestore

    addGroupToStore: (state, action) => {
      const { id, name, groupHolder, dbCollection, meta } = action.payload
      let groupId = id

      let newGroupHolder, newMeta
      groupHolder ? newGroupHolder = [{ dbCollection: dbCollection, id: groupHolder }] : newGroupHolder = []

      groupHolder ? newMeta = meta : newMeta = ''

      let groupObject = {
        id: groupId,
        type: 'other',
        name: name,
        meta: newMeta,
        groupHolders: newGroupHolder
      }
      state.push(groupObject)
    },


    resetGroups: (state, action) => {
      let initialState = []
      return initialState
    },// end resetGroups



    updateEditedGroup: (state, action) => {


      let groupId = action.payload.id
      let newgroup = action.payload.group



      let groupIndex = state.findIndex(index => index.id === groupId)

      state[groupIndex].group = newgroup



    }, // end updateEditedgroup

    addGroupHolder: (state, action) => {


      let groupId = action.payload.id
      let groupHolder = action.payload.groupHolder
      let dbCollection = action.payload.dbCollection
      let newgroupHolder = { dbCollection: dbCollection, id: groupHolder }




      let groupIndex = state.findIndex(index => index.id === groupId)

      console.log('[ sam_groupSlice ] groupIndex ', state)
      console.log('[ sam_groupSlice ] groupIndex ', groupIndex)

      state[groupIndex].groupHolders.push(newgroupHolder)

    }, // end addgroupHolder




    deleteGroup: (state, action) => {


      let groupId = action.payload.id

      return state.filter(item => item.id !== groupId)

    }, // end deletelogHolder
  }, // end reducers
}) // end slice groupsSlice 


// --- Export actions ---------------------------------------------

export const { 
  addAllGroupsFromFirestore,
  addGroupToStore, 
  updateEditedGroup,
  addGroupHolder,
  resetGroups,
  deleteGroup

} = groupsSlice.actions



// --- Export selectors ------------------------------------------

export const selectGroups = state => state.groups




// --- default Export reducers -----------------------------------

export default groupsSlice.reducer