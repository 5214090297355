// Sample Store - Categories
//   SampleCategories


const SampleCategories =  [

  { 
    id: 'accomplishments',
    category: 'Accomplishments',

   
  },

  // { 
  //   id: 'cat_2',
  //   category: 'Work',
   
  // },

  { 
    id: 'cat_3',
    category: 'Diet',

   
  },
  

  // { 
  //   id: 'cat_5',
  //   category: 'Ideas',
  // },

 
  { 
    id: 'cat_8',
    category: 'Exercise',

   
  },

 
 
 
 
 
 ] // end SampleCategories  ============================================
 
 
 
 
 
 
 
 export default SampleCategories