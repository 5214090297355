// Sample Store - keywords
// Samp Spotlights


const SampleLogHolders =  [

  { 
    id: 'person_4',
    collection: 'people',
    meta: ''
   
  },

  { 
    id: 'group_3',
    collection: 'groups',
    meta: ''
   
  },
 
  { 
    id: 'group_1',
    collection: 'groups',
    meta: ''
   
  },

 
  { 
    id: 'group_2',
    collection: 'groups',
    meta: ''
   
  },
 
  { 
    id: 'person_2',
    collection: 'people',
    meta: ''
   
  },

   
  { 
    id: 'person_1',
    collection: 'people',
    meta: ''
   
  },
 
 ] // end SampleLogHolders  ============================================
 
 
 
 
 
 
 
 export default SampleLogHolders