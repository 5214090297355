/* function KarmicView (props) -------------------
 
  Weighted assets - liabilities

  1. Retrieve all twoPartyChits + all receivedChits
  2. Map through each separately and extract 
     chitBurden and chitValue from each chit
  3. calculate liabilities and assets from both
  4. combine to calculate final karmic value and 
     # of chits owed and # of chits owed to user

  parent: ./TwoPartyMain
------------------------------------*/

import React from 'react'
import { useSelector } from 'react-redux'


import DonutGraph from './DonutGraph'
import KarmicLegend from './KarmicLegend'

import { selectAllTwoPartyChits } from '../../../app/redux/twoPartyChitRedux/twoPartyChitsSlice';

import { selectReceivedChits } from '../../../app/redux/receivedRedux/receivedChitsSlice';

// ---MUI ------
import { Paper } from '@mui/material';

import { styled, createTheme } from "@mui/material/styles"
import { mediumGrey } from '../../../styles/colors';
// import {withStyles} from '@mui/styles'
const theme = createTheme(); // allows use of mui theme in styled c


// ===============================================
export default function KarmicView(props) {

  let allTwoPartyChits = useSelector(selectAllTwoPartyChits)
  let allReceivedChits = useSelector(selectReceivedChits)

  let liabilities = 0
  let assets = 0
  let chitsOwed = 0
  let owedChits = 0
  // let karmicSum = 0

  // Evaluate all two party Chits

  allTwoPartyChits.map((chit) => {
    // liability

    if (chit.chitType === 'awChit') {
      // assets

      assets = assets + chit.chitValue + chit.chitBurden
      owedChits = owedChits + 1

    } // -- end chitType === awChit 

    if (chit.chitType === 'standard') {
      if (chit.deedPerformedBy === chit.otherPartyId) {
        liabilities = liabilities + chit.chitValue + chit.chitBurden
        owedChits = owedChits + 1
      }

      // assets
      if (chit.deedPerformedBy !== chit.otherPartyId) {
        assets = assets + chit.chitValue + chit.chitBurden
        chitsOwed = chitsOwed + 1
      }

    } // -- end chitType === standard 

    if (chit.chitType === 'kindness') {
      if (chit.deedPerformedBy === chit.otherPartyId) {
        liabilities = liabilities + chit.chitValue + chit.chitBurden
        chitsOwed = chitsOwed + 1
      }

      // assets
      if (chit.deedPerformedBy !== chit.otherPartyId) {
        assets = assets + chit.chitValue + chit.chitBurden
        owedChits = owedChits + 1
      }

    } // -- end chitType === kindness 

    if (chit.chitType === 'promise') {
      if (chit.deedPerformedBy !== chit.otherPartyId) {
        liabilities = liabilities + chit.chitValue + chit.chitBurden
        chitsOwed = owedChits + 1
      }

      // assets
      if (chit.deedPerformedBy === chit.otherPartyId) {
        assets = assets + chit.chitValue + chit.chitBurden
        owedChits = chitsOwed + 1
      }

    } // -- end chitType === promise 


    return { liabilities: liabilities, assets: assets, chitsOwed: chitsOwed, owedChits: owedChits }
  }

  )// -- end all two party chits map -----------------

  // karmicSum =  assets - liabilities  

  /*
    note for received chits 
    received chits were created by someone else
    so deedPerformedBy = otherParty  is an asset to receiver
       deedPeformedBy = me is a liability
       an awChit is a liability

  */

  let recievedLiabilities = 0
  let receivedAssets = 0
  let receivedChitsOwed = 0
  let receivedOwedChits = 0
  // let receivedKarmicSum = 0

  // Evaluate all two party Chits

  // allReceivedChits.map((receivedChit) => {

  //   console.log('[ where ] receivedChit ', receivedChit);

  //   if (receivedChit.chitType === 'awChit') {
  //     // assets

  //     recievedLiabilities = recievedLiabilities + receivedChit.chitValue + receivedChit.chitBurden
  //     receivedOwedChits = receivedOwedChits + 1

  //   } // -- end chitType === 'awChit'

  //   if (receivedChit.chitType === 'standard') {
  //     if (receivedChit.deedPerformedBy === 'me') {
  //       recievedLiabilities = recievedLiabilities + receivedChit.chitValue + receivedChit.chitBurden
  //       receivedChitsOwed = receivedChitsOwed + 1
  //     }

  //     // assets
  //     if (receivedChit.deedPerformedBy !== 'me') {
  //       receivedAssets = receivedAssets + receivedChit.chitValue + receivedChit.chitBurden
  //       receivedOwedChits = receivedOwedChits + 1
  //     }

  //   } // -- end chitType === 'standard'

  //   if (receivedChit.chitType === 'kindness') {
  //     if (receivedChit.deedPerformedBy === 'me') {
  //       recievedLiabilities = recievedLiabilities + receivedChit.chitValue + receivedChit.chitBurden
  //       receivedChitsOwed = receivedChitsOwed + 1
  //     }

  //     // assets
  //     if (receivedChit.deedPerformedBy !== 'me') {
  //       receivedAssets = receivedAssets + receivedChit.chitValue + receivedChit.chitBurden
  //       receivedOwedChits = receivedOwedChits + 1
  //     }

  //   } // -- end chitType === 'kindness'

  //   if (receivedChit.chitType === 'promise') {
  //     if (receivedChit.deedPerformedBy !== 'me') {
  //       recievedLiabilities = recievedLiabilities + receivedChit.chitValue + receivedChit.chitBurden
  //       receivedChitsOwed = receivedChitsOwed + 1
  //     }

  //     // assets
  //     if (receivedChit.deedPerformedBy === 'me') {
  //       receivedAssets = receivedAssets + receivedChit.chitValue + receivedChit.chitBurden
  //       receivedOwedChits = receivedOwedChits + 1
  //     }

  //   } // -- end chitType === 'promise'


  //   return { recievedLiabilities: recievedLiabilities, receivedAssets: receivedAssets, receivedChitsOwed: receivedChitsOwed, receivedOwedChits: receivedOwedChits }
  // }

  // )// -- end all two party chits map -----------------

  // receivedKarmicSum = receivedAssets - recievedLiabilities


  let totalAssets = assets + receivedAssets
  let totalLiabilities = liabilities + recievedLiabilities
  let totalChitsOwed = chitsOwed + receivedChitsOwed
  let totalOwedChits = owedChits + receivedOwedChits

  let totalKarmicSum = totalAssets - totalLiabilities



  return (
    <Wrapper>
      <TopWrapper>
        <NoteWrapper>
          Your <span>
            karmic balance </span> is the
          total of weights you assigned to all of your
          two party chits.
          <div> (chits you owe - chits owed to you) </div>
        </NoteWrapper>

      </TopWrapper>

      <BottomWrapper>

        <HeaderWrapper> Karmic Balance</HeaderWrapper>
        <SubHeaderWrapper> for all chits you created</SubHeaderWrapper>
        <GraphWrapper>

          < DonutGraph
            assets={totalAssets}
            liabilities={totalLiabilities}

          />

          {totalKarmicSum < 0 &&
            <CenterValueRed>{totalKarmicSum}</CenterValueRed>
          }
          {totalKarmicSum >= 0 &&
            <CenterValueGreen>{totalKarmicSum}</CenterValueGreen>
          }


        </GraphWrapper>


        <KarmicLegend
          chitsOwed={totalChitsOwed}
          owedChits={totalOwedChits}
          assets={totalAssets}
          liabilities={totalLiabilities}
        />

      </BottomWrapper>





    </Wrapper>
  );
}




// ---------------------------------

const Wrapper = styled(Paper)({



  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: 'calc(100% - 16px)',
  height: '30rem',

  // backgroundColor: 'pink',
  [theme.breakpoints.down('md')]: {
    height: '33rem'
  },

})

const TopWrapper = styled('div')({



  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  width: '100%',




  //   [theme.breakpoints.down('sm')] : {
  //     // width: '100%'
  //   },

})

const HeaderWrapper = styled('div')({



  fontSize: '1.3rem',
  // color: chitBurgandy




  [theme.breakpoints.down('md')]: {
    marginTop: '1.5rem'
  },

})

const SubHeaderWrapper = styled('div')({



  fontSize: '.85rem',
  color: mediumGrey,
  fontStyle: 'italic',



  [theme.breakpoints.down('md')]: {
    
  },

})


const BottomWrapper = styled('div')({



  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'space-around',
  alignItems: 'center',
  width: '100%',
  height: '75%',




  //   [theme.breakpoints.down('sm')] : {
  //     // width: '100%'
  //   },

})

const LeftBottomWrapper = styled('div')({



  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '40%',
  height: '100%',



  //   [theme.breakpoints.down('sm')] : {
  //     // width: '100%'
  //   },

})

const RightBottomWrapper = styled('div')({



  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '40%',
  height: '100%',

  backgroundColor: 'yellow'




  //   [theme.breakpoints.down('sm')] : {
  //     // width: '100%'
  //   },

})




const GraphWrapper = styled('div')({



  display: 'flex',


  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
  height: '75%',
  marginTop: '5vh'




  //   [theme.breakpoints.down('sm')] : {
  //     // width: '100%'
  //   },

})

const CenterValueRed = styled('div')({

  position: 'absolute',
  // left: '50%',
  top: '40%',
  display: 'block',
  color: 'red',


  fontSize: '1.2rem',

  textAlign: 'justify',
  '& .emphasize': {
    decoration: 'underline',
    fontWeight: 'bold'
  },



  //   [theme.breakpoints.down('sm')] : {
  //     // width: '100%'
  //   },

})

const CenterValueGreen = styled('div')({

  position: 'absolute',
  // left: '50%',
  top: '40%',
  display: 'block',
  color: '#00C49F',


  fontSize: '1.22rem',

  textAlign: 'justify',
  '& .emphasize': {
    decoration: 'underline',
    fontWeight: 'bold'
  },



  //   [theme.breakpoints.down('sm')] : {
  //     // width: '100%'
  //   },

})


const NoteWrapper = styled('div')({



  display: 'block',
  color: '#2B6D6D',
  textAlign: 'center',
  width: '25%',
  fontSize: '.75rem',
  margin: '.5rem .5rem 0 0',
  border: '1px solid grey',
  borderRadius: '5px',
  padding: '3px',
  '& span': {
    decoration: 'underline',
    fontWeight: 'bold'
  },

  '& div': {
    fontSize: '.65rem',
    textAlign: 'center',

  },

  [theme.breakpoints.down('md')]: {
    width: '90%'
  },





  //   [theme.breakpoints.down('sm')] : {
  //     // width: '100%'
  //   },

})
