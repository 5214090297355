/* LoginForm.jsx

Form to join 
Parent: pages/public/Login.jsx
Children:  form components in -  ./formCompnents

*/

import React from 'react'
import {
  signInWithGooglePopup,
  createUserDocumentFromAuth,
  signInAuthUserWithEmailAndPassword
} from '../app/firebase/firebase.utils';

import { useNavigate } from 'react-router-dom'

import { FormProvider, useForm } from "react-hook-form";

import { useDispatch } from 'react-redux';
import { updateAuth, changeLoadingStatus, closeModal } from '../app/redux/statusRedux/statusSlice'

import { yupResolver } from '@hookform/resolvers/yup';
import { string, object, array } from 'yup';
import { StyledInput } from './formComponents/StyledInput'

import GoogleLogo from '../images/googleLogo.svg'

import { styled, createTheme } from '@mui/material/styles'
import Button from '@mui/material/Button';
// import FirebaseAuthService from '../app/firebase/FirebaseAuthService';
// import {updateLastVisit} from '../app/firebase/FirebaseFirestoreService';
import { backgroundBlue, chitBurgandy, chitBurgandyDull, lightGrey } from '../styles/colors';

const theme = createTheme(); // allows use of mui theme in styled component



// ===================================================

// --- Yup setup ---
const defaultValues = {
  email: "",
  password: "",

};

//  -- Input requirements for user for each component (if any)

const formSchema = object({
  email: string().required('email is required').email('not an email'),
  // password: string().max(10, 'no more than 10'),

});

// ===================================================

function LoginForm({ existingUser }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()


  // --- sign in with Google---------
  const logGoogleUser = async () => {
    await signInWithGooglePopup()
    dispatch(closeModal())

  }

  const methods = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(formSchema)
  });

  const { handleSubmit, reset, control } = methods;


  const submitForm = async (data) => {

    // --- Login functions ---
    // console.log('[LoginForm] data', data)

    const { email, password } = data
    try {

      const response = await signInAuthUserWithEmailAndPassword(email, password)

 

      //  DISPATCH current user to Redux (statusSLice)
      dispatch(updateAuth({ loginId: response.user.uid }))

  
      dispatch(closeModal())
      reset(defaultValues)

    } catch (error) {
      alert(error.message)
      //     // --- alert error + navigate + end spinner + reset form ---
      //     alert(error.message)
      //     dispatch(changeLoadingStatus(false))
      //     navigate('/login')
      //     reset(defaultValues)
      //   }

    }//end try catch
  }

  // --- reset function - called in link below ---

  const goToReset = () => {
    navigate('/resetPassword')
    dispatch(closeModal())
  }

  // --- Actual Form ---------------------------------------------
  return (
    <Wrapper>



      <GoogleButton onClick={() => {
        logGoogleUser()
      }}>
        <Google src={GoogleLogo} alt="Chit Git Logo" />
        <span> Login with Google </span>
      </GoogleButton>

      <OrWrapper> or login in with email</OrWrapper>

      <FormProvider {...methods}>
        <FormWrapper onSubmit={handleSubmit(submitForm)}>


          {/* ------Input Component  -------------------------- */}
          <ComponentWrapper>
            <StyledInput
              name={"email"}
              control={control}
              label={"email"}
              defaultValue={''}
              placeholder='email'


            />

          </ComponentWrapper>

          {/* ------Input Component  -------------------------- */}



          <ComponentWrapper>
            <StyledInput
              name={"password"}
              control={control}
              label={"password"}
              defaultValue={''}
              placeholder='password'
              type='password'

            />

          </ComponentWrapper>




          {/* ------Submit ---------- -------------------------- */}
          <ButtonWrapper>

            <StyledButton type="submit" variant="contained" color="primary">
              Submit
            </StyledButton>
          </ButtonWrapper>
        </FormWrapper>

        <ResetWrapper onClick={goToReset} > I forgot my password </ResetWrapper>

      </FormProvider>



    </Wrapper>
  )// end return
}

export default LoginForm


// ----------------------
const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',


  width: '100%',


})


const FormWrapper = styled('form')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '74%',
  marginTop: '.5rem',
  padding: '1rem 3rem',
  border: '1px solid #CFD0D1',
  borderRadius: '15px',
  [theme.breakpoints.down('sm')]: {
    width: '100%',


  },

})
const FormComponentWrapper = styled('div')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '100%',



  [theme.breakpoints.down('sm')]: {
    width: '55%'

  },

})

const ComponentName = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  color: 'darkGrey',


  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const ComponentWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
  marginBottom: '1rem',
  // overflowY: 'hidden',

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const ButtonWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '60%',
  margin: '.75rem',


  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const StyledButton = styled(Button)({
  backgroundColor: 'white',
  border: '1px solid #E6E7E8',
  color: chitBurgandyDull,
  margin: '0 8px',
  padding: ' 0 1rem',
  height: '1.5rem',
  fontSize: '.8rem',
  '&:hover': {
    backgroundColor: lightGrey
  }

})
const ResetWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '60%',
  margin: '.75rem',

  cursor: 'pointer',
  color: backgroundBlue,
  fontSize: '.85rem',
  textDecoration: 'underline',

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const Google = styled('img')({

  height: '.9rem',
  marginRight: '.7rem'

})
const GoogleButton = styled(Button)({


  backgroundColor: 'white',
  display: 'flex',
  justifyContent: 'center',
  textTransform: 'none',

  border: '1px solid #CFD0D1',
  color: 'black',
  fontWeight: 'normal',
  fontSize: '.8rem',
  padding: '.4rem',
  width: '60%',

  '&:hover': {
    backgroundColor: lightGrey,
    textDecoration: 'none',
    border: '1px solid #A8BEED',


  }


})

const OrWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  margin: '.75rem',
  fontSize: '.8rem',
  color: chitBurgandy,

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

