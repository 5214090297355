
// *** Sample Chrons Selectors ----------

import { createSelector } from 'reselect'

// Get all topicals sections --------------------

import { createSlice } from '@reduxjs/toolkit'

 

export const topicsSlice = createSlice({
  name: 'topicalsSample',
  initialState: [],

  reducers: {


    addAllTopicsFromFirestore: (state = [], action) => {
    
      let firestoreData = action.payload
    
      // Clear the state array
      state = [];
    
      // Add the Firestore data to the state array
      state = [...firestoreData];
    
      return state
    },// end addAllTopicsFromFirestore


    addTopicToStore: (state, action) => {

      let topicId = action.payload.id
      let topic = action.payload.topic
      
    
    
      let topicObject = {
        id: topicId,
        topic: topic,
       
      }
    
    
    
      state.push(topicObject)
    },// end addTopicToStore
    



    resetTopics: (state, action) => {
      let initialState = []
      return initialState
    },// end resetTopics




    updateEditedTopic: (state, action) => {

      console.log('[ updateEditedTopic] action.payload ', action.payload);
  
      let topicId = action.payload.id
      let newTopic = action.payload.topic
      
      
  
      let topicIndex = state.findIndex(index => index.id === topicId)
      
      state[topicIndex].topic  = newTopic
     
  
  
    }, // end updateEditedTopic

 
    deleteTopic: (state, action) => {


      let topicId = action.payload.id
  
      return state.filter(item => item.id !== topicId)
  
    }, // end deleteTopic
  

} // --- end reducer

}) // end slice topicalsSlice 


// --- Export actions ---------------------------------------------

export const { 
  addAllTopicsFromFirestore,
  addTopicToStore, 
  updateEditedTopic,
  resetTopics,
  deleteTopic


} = topicsSlice.actions



// --- Export selectors ------------------------------------------

export const selectTopics = state => state.topics



// --- default Export reducers -----------------------------------

export default topicsSlice.reducer