import React  from 'react'
import { useSelector, useDispatch} from 'react-redux'
import {useParams} from 'react-router-dom'

import { chitBurgandy } from '../../../styles/colors';
// import { twoPartyChitFilter } from '../../../app/helpers/chitHelpers';
import { selectAllTwoPartyChits } from '../../../app/redux/twoPartyChitRedux/twoPartyChitsSlice';

 import { selectPeople } from '../../../app/redux/peopleRedux/peopleSlice';
 import { selectGroups } from '../../../app/redux/groupRedux/groupSlice';

 import TwoPartyChitViewNav from '../../navComponents/privateNav/TwoPartyChit_View_nav';
  import { openModal } from '../../../app/redux/statusRedux/statusSlice';
 //  ---- Material Ui ------------------

import Paper from '@mui/material/Paper'
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button'

// ================================================
import { styled, createTheme  } from "@mui/material/styles"
const theme = createTheme(); // allows use of mui theme in styled component
 

export default function KarmicHeader(props) {

  const dispatch = useDispatch()

  const openForm = () => {

    //  define which Form to open in Modal by passing
    //  dbCollection to Modal depending on pageView in browser URL 
    // ------------------------------------------------------------

    // props.openModal(dbCollection, id)

    dispatch(openModal(
      {
        modalParams: {
          modalType: 'form',
          dbCollection: 'twoPartyChits',
          id: ''
        }
      }

    ))

  }// end openForm

  return (
    <Wrapper>
    <TitleWrapper>
  
      <Title>
        Karmic View
      </Title>
    </TitleWrapper>
    <BottomWrapper>
      <ButtonWrapper>

        <FormButton  onClick={() => openForm()} startIcon={<AddIcon />}> add Chit</FormButton>
    
      </ButtonWrapper>

      <ViewNavWrapper>
      
       <TwoPartyChitViewNav/>
      </ViewNavWrapper>
    



    </BottomWrapper>

</Wrapper>
  );
}


// ---------------------------------------------

const Wrapper = styled(Paper)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  margin: '1rem  .5rem .5rem .5rem',
  paddingBottom: '.5rem',
 
   
  width: '90%',
 


  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },
})

const TitleWrapper= styled('div')({
  display: 'flex',
   
  justifyContent: 'flex-start',
  alignItems: 'center',
   
  
 
 
  width: '98%',
  padding: '0 1rem',
  // marginBottom: '.5rem',

  fontSize: '1rem',
  
  '&.backgroundCompleted' : {
     
    color: 'white', 
     
  
  },


  [theme.breakpoints.down('sm')] : {
    // height: '1.25rem',
    // backgroundColor: 'red'
  },
})


const Title = styled('div')({
  display: 'flex',
  
  justifyContent: 'flex-start',
  alignItems: 'center',
   

  
  color: chitBurgandy,
  flexWrap: 'wrap',

  fontSize: '1.2rem',
  


  [theme.breakpoints.down('sm')] : {
    // height: '1.25rem',
    // backgroundColor: 'red'
  },
})

const BottomWrapper = styled('div')({
  display: 'flex',
  
  justifyContent: 'space-between',
  alignItems: 'center',
  width: 'calc(100% - 12px)',
 
 
  [theme.breakpoints.down('sm')] : {
    flexDirection: 'column'
  },
})

const ButtonWrapper = styled('div')({
  display: 'flex',
  
  justifyContent: 'flex-start',
  alignItems: 'center',
  
  width: '50%',
[theme.breakpoints.down('sm')] : {
  justifyContent: 'center',
    width: '100%'
  },
})

const FormButton = styled(Button)({

 

  display: 'flex',
  textTransform: 'none',
  
 
  color: 'white',
  backgroundColor:  '#727376',
  fontWeight: 'normal',
  fontSize: '.85rem',
  padding: '0 1.5rem',
  width: '9.5rem',
  height: '1.2rem',
margin: '0 1rem',
  
  '&:hover' : {
    // backgroundColor: chitBlueDull,
    textDecoration: 'none',
    border: '1px solid #A8BEED' ,
    color: '#3B30CC'

  }


})

const ViewNavWrapper= styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
 

  position: 'relative',
  top: 0,
 
 
width: '50%',
  height: '2rem',
  // color: 'white',



  [theme.breakpoints.down('sm')] : {
    justifyContent: 'center',
      width: '100%'
    },


})

const BottomSpacerWrapper= styled('div')({
  display: 'flex',
  
  justifyContent: 'flex-end',
  alignItems: 'center',
 
  marginRight: '6px',
  width: '33%',

  [theme.breakpoints.down('sm')] : {
    // height: '1.25rem',
    // backgroundColor: 'red'
  },
})

// ================================================

 