/*---- File - SharedChitForm.jsx 
 
1. contains page views:
  sharedChitFormForm ,
  sharedChitFormPreview,
  sharedChitFormMessage

  page view ...  defined by status.forms.sharedChits.pageview


*/




import React, {useState, useEffect}  from 'react'
import {useSelector, useDispatch} from 'react-redux'

import { chitBlueDull} from '../styles/colors'



import SharedChitFormForm from './SharedChitFormForm'
import SharedChitFormPreview from './SharedChitFormPreview'
import SharedChitFormMessage from './SharedChitFormMessage'


import { selectStatus } from '../app/redux/statusRedux/statusSlice'
 
// --- imports to create options for selectors

// --- MUI imports ---------

import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'

import { styled, createTheme } from "@mui/material/styles"
import { withStyles } from '@mui/styles'
const theme = createTheme(); // allows use of mui theme in styled component

// ==================================================

export default function SharedChitForm(props) {

  const dispatch = useDispatch()
  const status = useSelector(selectStatus) 

  let pageView = status.view.forms.sharedChitForm.pageView
 

  return (

    <Wrapper>
      {pageView === 'form' &&

        <SharedChitFormForm />

      }

      {pageView === 'preview' &&

        <SharedChitFormPreview />

      }

      {pageView === 'message' &&

        <SharedChitFormMessage />

      }


    </Wrapper>
  )// end return
} // end function



// ==== Styles ===========================================


const Wrapper = styled(Paper)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  // zIndex: '95',
  marginTop: '2rem',
  width: '100%',
  height: '99%',
  overflow: 'auto',
// border: '2px solid #F285B5',
 

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',
    // backgroundColor: 'red'
  },

})
