
// *** Sample topical sections ----------

import { createSelector } from 'reselect'

// Get all topicals sections --------------------

import { createSlice } from '@reduxjs/toolkit'

 

export const topicalSectionsSlice = createSlice({
  name: 'topicalSections',
  initialState: [],

  reducers: {
 


    addAllTopicalSectionsFromFirestore: (state = [], action) => {
    
      let firestoreData = action.payload
    
      // Clear the state array
      state = [];
    
      // Add the Firestore data to the state array
      state = [...firestoreData];
    
      return state
    },// end addAllTopicalSectionsFromFirestore

    addTopicalSectionsToStore: (state, action) => {

      let topical = action.payload

      state.push(topical)
    },

    
    resetTopicalSections: (state, action) => {
      let initialState = []
      return initialState
    },// end resetTopicalSections


  updateEditedTopicalSection: (state, action) => {

    let topicalSectionId = action.payload.id

    let editedTopicalSectionTopic = action.payload.topic
     
    let editedTopicalSectionTitle = action.payload.title
 
    let editedTopicalSectionLastEdit = action.payload.lastEdit
    let editedTopicalSectionDate = action.payload.topicalDate

    let editedTopicalSectionDetail = action.payload.detail


    let topicalIndex = state.findIndex(index => index.id === topicalSectionId)

console.log('[ topicalSectionsSlice ] xxxxxx topicalSectionId ', topicalSectionId);
console.log('[topicalSectionsSlice] ] ooooooooooo topicalIndex ',state[topicalIndex]);

state[topicalIndex].id = topicalSectionId
    state[topicalIndex].topic = editedTopicalSectionTopic
    state[topicalIndex].detail = editedTopicalSectionDetail
 
    state[topicalIndex].title = editedTopicalSectionTitle
 
    state[topicalIndex].lastEdit = editedTopicalSectionLastEdit
    state[topicalIndex].topicalDate = editedTopicalSectionDate
   


  }, // end updateEditedTopicalSection


    updateEditedTopicalSectionTimeLock: (state, action) => {

      let id = action.payload.id
      let topicalIndex = state.findIndex(index => index.id === id)

      let timeLock = action.payload.timeLock




      // console.log('[ topicalSectionsSlice ] xxxxxx topicalSectionId ', timeLock);


      state[topicalIndex].timeLock = timeLock




    }, // end updateEditedTopicalSection




  deleteTopicalSection: (state, action) => {

console.log('[ deleteTopicalSection ] payload ', action.payload);
    let topicalSectionId = action.payload.id

    return state.filter(item => item.id !== topicalSectionId)
 
}, // end deleteTopicalSection


}

}) // end slice topicalSectionssSlice 


// --- Export actions ---------------------------------------------

export const { 
  addAllTopicalSectionsFromFirestore,
  addTopicalSectionsToStore, 
  updateEditedTopicalSection,
  updateEditedTopicalSectionTimeLock,
  addTopicalSectionsHolder,
  deleteTopicalSection,
  resetTopicalSections 

} = topicalSectionsSlice.actions



// --- Export selectors ------------------------------------------

export const selectTopicalSections = state => state.topicalSections

export const selectTopicalSectionFromArray = (topicalSectionArray, id) => {
 
  let topicalSection = topicalSectionArray.find(topicalSection => topicalSection.id === id) 
 
  return topicalSection
}



// --- default Export reducers -----------------------------------

export default topicalSectionsSlice.reducer