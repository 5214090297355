import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'; 
import {useNavigate, useParams } from 'react-router-dom'

import Logo from '../../../images/ChitPro_2021_logo_sm.svg'

import { signOutUser } from '../../../app/firebase/firebase.utils';
import { selectStatus,  updateAuth } from '../../../app/redux/statusRedux/statusSlice';

import { resetPeople } from '../../../app/redux/peopleRedux/peopleSlice';
import { resetCategories } from '../../../app/redux/categoryRedux/categorySlice';

import { resetGroups } from '../../../app/redux/groupRedux/groupSlice';
import { resetLogHolders } from '../../../app/redux/logHolderRedux/logHolderSlice';

import { resetLogs } from '../../../app/redux/logRedux/logsSlice';
import { resetNotes } from '../../../app/redux/noteRedux/notesSlice'
import { resetPersonalChits } from '../../../app/redux/personalChitRedux/personalChitSlice';

import { resetTopicalSections } from '../../../app/redux/topicalRedux/topicalSectionsSlice';

import { resetTopics } from '../../../app/redux/topicalRedux/topicsSlice';
import { resetTwoPartyChits } from '../../../app/redux/twoPartyChitRedux/twoPartyChitsSlice';

import { resetFirstContacts } from '../../../app/redux/firstRedux/firstSlice';
import { resetSharedChits } from '../../../app/redux/sharedRedux/sharedChitsSlice';

import { resetReceivedChits } from '../../../app/redux/receivedRedux/receivedChitsSlice';

import MainPageNav from './MainPage_nav';
import HamburgerViewNav from './Hamburger_view_nav';
// -- MUI imports 
import Button from '@mui/material/Button'
 
import { styled, createTheme  } from "@mui/material/styles"
import { bodyBlue, darkGrey, shadowBlue, veryLightGrey } from '../../../styles/colors';
const theme = createTheme(); // allows use of mui theme in styled component

// -------------------------

export default function Main_header(props) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const status = useSelector(selectStatus)

  let userName = status.auth.displayName


  const handleSignOut = ()=>{
    console.log('i be clicked')


  
    dispatch(resetPeople())
    dispatch(resetCategories())
    dispatch(resetGroups())
    dispatch(resetLogHolders())
    dispatch(resetLogs())
    dispatch(resetNotes())

    dispatch(resetPersonalChits())
    dispatch(resetTopicalSections())
    dispatch(resetTopics())
    dispatch(resetTwoPartyChits())

    dispatch(resetFirstContacts())
    dispatch(resetSharedChits())
    dispatch(resetReceivedChits())
    signOutUser()
    dispatch(updateAuth({
      loginId: ''
  
    }))
    navigate(`/`)
  }

  return (
    
    <HeaderWrapper>
      <Header>
        <LogoWrapper>
          <LogoStyle src={Logo} alt="Chit Git Logo" />
        </LogoWrapper>


    
        {/* <StyledDonateButton>
            Donate
          </StyledDonateButton> */}
         
<StyledLogout onClick = {()=>handleSignOut() }> Sign out </StyledLogout>

      </Header>
<WelcomeWrapper> 
      <Greeting>Welcome {userName} </Greeting>
      <HamburgerMenu> <HamburgerViewNav/></HamburgerMenu>
      </WelcomeWrapper>
      
      <MainPageNav/>
      
    </HeaderWrapper>



)
}


// --------------------------------

const HeaderWrapper = styled('div')({
  display: 'block',

  height: '5.5rem', 
 
  border: 'none',
  width: '100%',
  backgroundColor: darkGrey,

  [theme.breakpoints.down('sm')] : {
    height: '4.5rem'
    
  }
})


const Header= styled('div')({

  height: '2.5rem', 
 
 width: '96%',
 padding: '0 2%',
  display: 'flex',

  justifyContent: 'space-between',
  alignItems: 'center',

  [theme.breakpoints.down('sm')] : {
    height: '3rem'
    
  }
 

})

const WelcomeWrapper= styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
 
  height: '1.5rem', 
  width: '100%',
  backgroundColor: shadowBlue,
  color: 'white',



  [theme.breakpoints.down('sm')] : {
     
    height: '1.5rem',
  }

})


const NavWrapper= styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
 
  height: '1.5rem', 
  width: '100%',
  backgroundColor: shadowBlue,
  color: 'white',



  [theme.breakpoints.down('sm')] : {
     
 
  }

})

const LogoWrapper= styled('div')({

  height: '2.5rem', 
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
 
 
  [theme.breakpoints.down('sm')] : {
     
    width: '23%',
    height: '2.5rem',
    marginLeft: '3%', 
  }

})

const LogoStyle= styled('img')({

  height: '1.5rem',

  [theme.breakpoints.down('sm')] : {
 
     height: '1.25rem',
  }
})


const NavBox= styled('div')({
 
  height: '100%', 
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  width: '72%',
  

  [theme.breakpoints.down('sm')] : {
    
    width: '70%',
    
  }

})

const ButtonBox= styled('div')({

  height: '100%', 
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  width: '20%',
  marginRight: '4%',
  

  [theme.breakpoints.down('sm')] : {
    justifyContent: 'flex-end',
     
    marginRight: '2%'
  }

})

const ButtonWrapper = styled('div')({
  display: 'flex',
   
  justifyContent: 'center',
  alignItems: 'center',
 
  marginLeft: '1rem',

  [theme.breakpoints.down('xs')]: {
    
  }
})

const LoginButtonWrapper = styled('div')({
  display: 'flex',
   
  justifyContent: 'center',
  alignItems: 'center',
 
  marginLeft: '1rem',

  [theme.breakpoints.down('md')]: {
    display: 'none'
  }
})





const StyledLogout= styled(Button)({


  
  textTransform: 'none',
 
  // backgroundColor: shadowBlue,
 
  borderRadius: '0',
  color: 'white',
  fontWeight: '300',
  
  padding: '0 10px',

  '&:disabled ' : {
    color: '#8293B8'
  },

  '& :hover': {
    backgroundColor: veryLightGrey,
  },
   
  [theme.breakpoints.down('sm')] : {
     

    
  },
  [theme.breakpoints.down('xs')] : {

  }

})

const StyledDonateButton= styled(Button)({

 
  display: 'block',
  textTransform: 'none',
  
  border: '1px solid white' ,
  color: 'white',
  fontWeight: 'normal',
  fontSize: '.8rem',
  padding: '1px 1.4rem',
  
  '&:hover' : {
     // backgroundColor: chitSkyBlue,
     textDecoration: 'none',
     border: '1px solid #A8BEED' ,
     color: '#A8BEED'
  }


})


const HamburgerMenu= styled('div')({

  marginLeft: '5px',
  display: 'none',
  
  [theme.breakpoints.down('sm')] : {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
 

    // backgroundColor: 'yellow'
    
  }

})

const Greeting = styled('div')({
  marginLeft: '1rem',
  fontSize: '.9rem',
  [theme.breakpoints.down('sm')] : {
    display: 'none'
    
  }

})