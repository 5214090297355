/* Delete_icon

  Displays the garbage icon used by all ChitGit apps.
  A given app's delete function can trigger multiple actions.
  For example deleting a task removes the task from the tasks collection, 
  and delete's the task from the task's parent spotlight taskArray.

  @props - id - from item
         - source - task, spotlight, logSection etc.




*/




import React from 'react';

import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import{mediumLightGrey, chitOrange, } from '../../../styles/colors'

// --- redux imports



import { selectStatus, openModal } from '../../../app/redux/statusRedux/statusSlice';

 



// Material UI --------------------
import CachedIcon from '@mui/icons-material/Cached';
import Tooltip from '@mui/material/Tooltip';





import { styled, createTheme } from "@mui/material/styles"
import { withStyles } from '@mui/styles'
const theme = createTheme(); // allows use of mui theme in styled component


export default function ConvertToLog_icon(props) {
  const {dbCollection, id} = props
  const dispatch = useDispatch()

  function handleClick(dbCollection, id) {
    console.log('[ EditIcon aa  ] props ', dbCollection, id);
    dispatch(openModal(
      {
        modalParams: {
          modalType: 'form',
          dbCollection: dbCollection,
          id: id

        }
      }

    ))
  }



// --- main return   -----------------------------

  return (
    <>


      <LightTooltip title='Convert to Log' arrow>
        <Icon


onClick={()=>handleClick(dbCollection, id)}

        />
      </LightTooltip  >



    </>
  )
}


// ----------------------------



const Icon= styled(CachedIcon)({
  backgroundColor: 'white',
  borderRadius: '5px',
  fontSize: '.95rem',
  color: chitOrange,
  margin: '0 .5rem .3rem .5rem',
  cursor: 'pointer',
  


  '&:hover': {
    color: mediumLightGrey
    // backgroundColor: mediumLightGrey
  },


  [theme.breakpoints.down('sm')] : {
    // height: '1.25rem',
    // backgroundColor: 'red'
  },
})




const LightTooltip = withStyles({
  tooltip: {
    color: "grey",
    backgroundColor: "white",
    boxShadow: '2px 3px 3px black',
    border: '1px solid grey',
  }
})(Tooltip);
