import React from "react"


import { NavLink, useParams } from 'react-router-dom'

import { useSelector } from 'react-redux'

import { backgroundBlue, bodyBlue, chitBlueLight, mediumGrey, mediumLightGrey, shadowBlue, veryLightGrey } from '../../../styles/colors'

import { selectStatus } from "../../../app/redux/statusRedux/statusSlice"


import { styled, createTheme } from "@mui/material/styles"
const theme = createTheme(); // allows use of mui theme in styled component

// -----------------------------------------------------------------





// ================================================

function MainPage_nav(props) {
  let match = useParams()


  const status = useSelector(selectStatus)

  let twoPartyId, personalId, topicalId, logId,
    twoPartyLink, personalLink, topicalLink, logLink

  twoPartyId = status.view.twoParty.id
  twoPartyId ? twoPartyLink = `/main/twoPartyChits/${twoPartyId}` : twoPartyLink = `/main/twoPartyChits`


  personalId = status.view.personal.id
  personalId ? personalLink = `/main/personalChits/${personalId}` : personalLink = `/main/personalChits`


  topicalId = status.view.topical.id
  topicalId ? topicalLink = `/main/topicals/${topicalId}` : topicalLink = `/main/topicals`


  logId = status.view.log.id
  logId ? logLink = `/main/logs/${logId}` : logLink = `/main/logs`


  let page
  !match.pageView ? page = 'home' : page = match.pageView




  // Popover ---------
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };



  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;



  return (



    <Wrapper>


      {/* <StyledLink to="/main/home" >
        {page !== 'home' &&
          <NavButton
            id='home'
          // onClick = {handleClose}

          >Home </NavButton>
        }

        {page === 'home' &&
          <NavButtonDisabled disabled
            id='home'
          // onClick = {handleClose}

          >Home </NavButtonDisabled>
        }


      </StyledLink> */}


      {/* ========================================================= */}

      <StyledLink to={twoPartyLink} >
        {page !== 'twoPartyChits' &&
          <NavButton
            aria-describedby={id} variant="contained" onClick={handleClick}

          >Two Party Chits </NavButton>
        }

        {page === 'twoPartyChits' &&
          <NavButtonDisabled disabled
            id='twoPartyChits'
          // onClick = {handleClose}

          >Two Party Chits </NavButtonDisabled>
        }





      </StyledLink>

      {/* ========================================================= */}

      <StyledLink to={logLink} >

        {page !== 'logs' &&
          <NavButton
            id='logs'
          // onClick = {handleClose}

          >Logs </NavButton>
        }

        {page === 'logs' &&
          <NavButtonDisabled disabled
            id='logs'
          // onClick = {handleClose}

          >Logs </NavButtonDisabled>
        }


      </StyledLink>



      <StyledLink to={topicalLink} >


        {page !== 'topicals' &&
          <NavButton
            id='topicals'
          // onClick = {handleClose}

          >Topicals </NavButton>
        }

        {page === 'topicals' &&
          <NavButtonDisabled disabled
            id='topicals'
          // onClick = {handleClose}

          >Topicals </NavButtonDisabled>
        }


      </StyledLink>

      <StyledLink to={personalLink} >


        {page !== 'personalChits' &&
          <NavButton
            id='personalChits'
          // onClick = {handleClose}

          >Personal Chits </NavButton>
        }

        {page === 'personalChits' &&
          <NavButtonDisabled disabled
            id='personalChits'
          // onClick = {handleClose}

          >Personal Chits </NavButtonDisabled>
        }


      </StyledLink>


      <StyledLink to="/main/contact" >


        {page !== 'contact' &&
          <NavButton
            id='contact'
          // onClick = {handleClose}

          >Contact </NavButton>
        }

        {page === 'contact' &&
          <NavButtonDisabled disabled
            id='contact'
          // onClick = {handleClose}

          >Contact </NavButtonDisabled>
        }


      </StyledLink>


    </Wrapper>
  );
}


export default MainPage_nav


const Wrapper = styled('div')({

  padding: 0,
  margin: 0,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'white',
  height: '1.5rem',
  [theme.breakpoints.down('sm')]: {

    // flexDirection: 'column',
    // justifyContent: 'flex-start',
    // alignItems: 'flex-start',
    // padding: '2px 0'

    display: 'none'

  },


})

const NavButton = styled('div')({

  // border: 'none',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  height: '100%',

  color: shadowBlue,

  textTransform: 'none',
  fontWeight: '400',
  fontSize: '.8rem',
  paddingRight: '10px',
  paddingLeft: '10px',
  margin: '0 .5rem',

  '&:hover': {
    color: chitBlueLight,
    boxShadow: 'none',

  },


  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontSize: '.85rem',
    padding: '2px 5px',
    width: '10rem',
    borderTop: '1px solid white'
  },

})

const NavButtonDisabled = styled('div')({

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',


  borderRadius: '5px',
  height: '1.25rem',
  color: mediumGrey,

  textTransform: 'none',
  fontWeight: '400',
  fontSize: '.8rem',
  paddingRight: '10px',
  paddingLeft: '10px',
  margin: '0 .5rem',
  backgroundColor: mediumLightGrey,

  '& :hover': {
    backgroundColor: veryLightGrey,
  },

  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontSize: '.9rem',
    padding: '2px 15px',
    width: '10rem',
    margin: '1px 0',
  },

})




const StyledLink = styled(NavLink)({

  textDecoration: 'none',

})

const Greeting = styled('div')({
  paddingLeft: '5px',
  display: 'none',

  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    color: 'white',
    height: '1.25rem',
    marginTop: '.25rem',
    fontSize: '.rem',
    backgroundColor: backgroundBlue,
    marginBottom: '3px'

  }

})

const Submenu = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '5px',


  [theme.breakpoints.down('sm')]: {


  }

})