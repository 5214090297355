import React from 'react'

import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';

import { chitBurgandy, chitBurgandyDull, darkGrey, lightGrey, mediumGrey, veryLightGrey } from '../styles/colors'

import { FormProvider, useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object, array } from 'yup';

import { ISOtoTraditional, UTCtoDate } from '../app/helpers/dateHelper'

import {
  addMailToFirestore,
  
} from '../app/firebase/fireStore.utils';


import { signOutUser } from '../app/firebase/firebase.utils';

import { StyledInput } from './formComponents/StyledInput'
import { Editor } from './formComponents/JournalEditor'

import ClickAwayListener from '@mui/material/ClickAwayListener';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper'

import { styled, createTheme } from "@mui/material/styles"
import { selectStatus } from '../app/redux/statusRedux/statusSlice';
import { eventNames } from 'process';



// import { 
//   createAuthUserWithEmailAndPassword, 
//   createUserDocumentFromAuth,
//   signInWithGooglePopup 

// } from '../app/firebase/firebase.utils';

// import { getUserDataFromFirestore } from '../app/firebase/fireStore.utils';

const theme = createTheme(); // allows use of mui theme in styled component

// =========================================================
// --- Yup setup ---



//---------Main function -----------------------------------

function ContactForm(props) {
  let navigate = useNavigate()
  const status = useSelector(selectStatus)
  const email = status.auth.email

  console.log('[ Contact form ] email ', email);


  const [open, setOpen] = React.useState(false);
  const [dialogMessage, setDialogMessage] = React.useState('');

  const handleClickAway = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    window.location.reload()
  };

  let today = Date.now()
  let ISODate = UTCtoDate(today)

  let ISODateTime = new Date(ISODate)

  const defaultValues = {
    firstName: "",
    lastName: "",

    subject: "",
    message: ""
  };

  // --- close / cancel form 
  const cancelNewForm = () => {
   
    reset()
    window.location.reload()
  }

  const goTo = (evt) => {

    let target = evt.target.id

    console.log('[ ContactForm ] evt.target.id ', evt.target.id);
    switch (target) {
      case 'twoPartyChits':
        navigate('/main/twoPartyChits')
        break;
      case 'logs':
        navigate('/main/logs')
        break;
      case 'topicals':
        navigate('/main/topicals')
        break;
      case 'personalChits':
        navigate('/main/personalChits')
        break;
 

      default:
        cancelNewForm()
    }
  }

  // ===========  FORM  ==================================================

  //  -- Input requirements for user for each component (if any)

  const formSchema = object({

    message: string().required('Your mail needs some content'),
   
  

  });

  const methods = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(formSchema)
  });

  const { handleSubmit, reset, control, setValue, onChange, watch, ref } = methods;

  const submitForm = async (data) => {
    console.log('[Contact FORM FORM FORM ] data ', data);
    // get form data elements
    const { firstName, lastName, subject, message } = data

    

    // - replace the <p>s with <div>s in Quill editor to make it appear better
    let noPtagDetail = data.message.replaceAll('<p>', '<div>')
    let cleanMessage = noPtagDetail.replaceAll('</p>', '</div>')



    try {

      let cleanedData = {
        dateTime: ISODateTime,
        firstName: firstName,
        lastName: lastName,
        email: email,
        subject: subject,
        message: cleanMessage,

      }

      let newSharedChitId = await addMailToFirestore(cleanedData)


      setOpen(true);
      reset()
    } catch (error) {
      alert(error)
      
    }




    













  }// -- end submitForm

  //  ====== main return ==================================

  return (



    <Wrapper>

      <Dialog
        open={open}
      
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">

        </DialogTitle>
        <DialogContent>
          <> 
          <StyledThanks>
            Thank you for your feedback
          </StyledThanks>
          <StyledGoTo>
            Go To: 
          </StyledGoTo>
          <StyledLinkContanier>
            <StyledLink id = 'twoPartyChits'  onClick = {(evt)=> goTo(evt)} >Two Party Chits</StyledLink>
             <StyledLink id = 'logs' onClick = {(evt)=> goTo(evt)} >Logs</StyledLink>
             <StyledLink id = 'topicals'onClick = {(evt)=> goTo(evt)} >Topicals</StyledLink>
             <StyledLink id = 'personalChits'onClick = {(evt)=> goTo(evt)} >Personal Chits</StyledLink>
              
          </StyledLinkContanier>
          </>
        </DialogContent>
        <DialogActions>


          <StyledButton
            form="submit-form"
            variant="contained"
            color="primary"

            onClick={handleClose}
          >
            Close
          </StyledButton>

        </DialogActions>
      </Dialog>

      <Header> Contact Us</Header>

      <FormProvider {...methods}>

        <FormWrapper id="submit-form" onSubmit={handleSubmit(submitForm)} >

          <ComponentWrapper>
            <StyledInput
              name={"firstName"}
              control={control}
              label={"firstName"}
              defaultValue={''}
              placeholder=' First  Name'


            />

          </ComponentWrapper>

          <ComponentWrapper>
            <StyledInput
              name={"lastName"}
              control={control}
              label={"lastName"}
              defaultValue={''}
              placeholder=' Last  Name'


            />

          </ComponentWrapper>



          <ComponentWrapper>
            <StyledInput
              name={"subject"}
              control={control}
              label={"subject"}
              defaultValue={''}
              placeholder=' Subject'


            />

          </ComponentWrapper>



          <StyledEditor>
            <Controller

              name="message"
              control={control}
              // initialNote={'hi quill description'}
              placeholder=' Message'
              render={({ field }) => (
                <Editor
                  {...field}
                  ref={null}
                  />
              )}

            />

          </StyledEditor>









          <ButtonWrapper>


            <StyledButton

              variant="contained"
              color="primary"
              style={{ textTransform: 'none' }}
              onClick={() => cancelNewForm()}
            >
              Cancel
            </StyledButton>

            <StyledButton
              type="submit"
              variant="contained"
              color="primary"
              style={{ textTransform: 'none' }}
            >
              Submit
            </StyledButton>
          </ButtonWrapper>


        </FormWrapper>

      </FormProvider>


    </Wrapper>


  )
}


export default ContactForm

// ========================================


const Wrapper = styled('div')({

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
  height: '100%',



  [theme.breakpoints.down('sm')]: {


  }

})

const Header = styled('div')({

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '70%',
  fontSize: '1.25rem',
  margin: '1rem',

  // color: 'yellow',




  [theme.breakpoints.down('sm')]: {


  }

})


const FormWrapper = styled('form')({

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '80%',
  padding: '2rem',

  backgroundColor: 'white',
  color: 'black',
  borderRadius: '10px',


  [theme.breakpoints.down('sm')]: {
    width: '90%',

  }

})

const ButtonWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '60%',
  margin: '.75rem',


  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const StyledButton = styled(Button)({
  backgroundColor: 'white',
  border: '1px solid #E6E7E8',
  color: chitBurgandyDull,
  margin: '0 8px',
  padding: ' 0 1rem',
  height: '1.5rem',
  fontSize: '.8rem',
  '&:hover': {
    backgroundColor: lightGrey
  }

})

const StyledLinkContanier = styled('div')({
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
  justifyContet: 'flex-start',
  alignItems: 'center',


})

const StyledLink = styled('div')({
  backgroundColor: 'white',
  display: 'flex',
  justifyContet: 'flex-start',
  alignItems: 'center',
  height: '1.2rem',
  cursor: 'pointer',
  width: '80%',
  margin: '4px',
  padding: '8px',
  border: '1px solid #CFD0D1',
  borderRadius: '5px',
  '&:hover': {
    backgroundColor: lightGrey
  }

})


const ComponentWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
  // overflowY: 'hidden',
  marginBottom: '1rem',
  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',

  },

})

const StyledEditor = styled('div')({
  display: 'flex',
  border: '1px solid #CFD0D1',
  borderRadius: '3px',
  width: '98%'
})

const StyledThanks = styled('div')({
  marginBottom: '4px',
  fontWeight: 'bold',
  fontSize: '1.1rem'
})

const StyledGoTo = styled('div')({
  margin: '4px 0',
  color: chitBurgandyDull,
  fontWeight: 'bold'
})






