/*---- File - ReceivedSharedChitUser.jsx 
see Read me -SharedChit.md   for details how to implement

parent: Home

Displays a shared chit received when the user is signed in.
note : there is a similar display for not signed in users

This file takes data from Firestore siteSharedChits collection
using the shared chit id received in <Home> and passed to this
component.

  - receive the shared chit data from Firestore
  - convert the data for display
          



*/

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'

import { getSiteDocumentToFirestore } from '../../../app/firebase/fireStore.utils';


import { Scrollbars } from 'react-custom-scrollbars';

import { openModal } from '../../../app/redux/statusRedux/statusSlice';

import { backgroundBlue,   chitBurgandy, chitSkyBlue, chitBlueDull, chitOrangeLight,
  mediumGrey, veryLightGrey, mediumLightGrey, chitBurgandyDull, lightGrey, chitOrangeVeryLight } from '../../../styles/colors'

import LinkCode from '../../../images/linkCode.svg'
import { ISOtoTraditional } from '../../../app/helpers/dateHelper';

import { useParams, useNavigate } from 'react-router-dom'
// import HeaderPublic from '../landingElements/Header_public.jsx'
import { stripWhiteSpace } from '../../../app/helpers/commonHelpers'

// import { selectSharedChits } from '../../../app/redux/sharedChitRedux/sharedChitSlice';

import CopySharedChitLink from '../../../common_components/CopySharedChitLink';

import { choosePersonalCoin, chooseTwoPartyChitCoin } from '../../../app/helpers/chitHelpers';

import { updateReceivedChitFormData, changeLoadingStatus } from '../../../app/redux/statusRedux/statusSlice';

//--- MUI
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button'
import Info from '@mui/icons-material/Info';
import Paper from '@mui/material/Paper';
import AddCircleIcon from '@mui/icons-material/AddCircle'

import { styled, createTheme } from "@mui/material/styles"
import { mediumMediumGrey } from '../../../styles/colors';
const theme = createTheme(); // allows use of mui theme in styled component



// Shelby URL for shared chit from Jerry
// http://localhost:3000/sharedChit/wm17sdyTfM5SmxDY0IJb



// =======Main Function ======================================

export default function ReceivedSharedChitPublic(props) {
  const BASE_URL = window.location.origin
  const navigate = useNavigate()
  const dispatch = useDispatch()
  let sharedChitId = props.id
  // console.log('[ ReceivedSharedChitUser ] props ', props);

  const [sharedObject, setSharedObject] = useState({})



  // --- function to receive shared chit from Firestore siteSharedChit

  async function getData() {

    // --- 
// changeLoadingStatus to true
dispatch(changeLoadingStatus(true))  



    let sharedChitObject = await getSiteDocumentToFirestore('siteSharedChits', sharedChitId)

    setSharedObject(sharedChitObject)
    // console.log('[ ReceivedSharedChitUser inside Await ] props ', sharedChitObject);


    const { chitColor, chitDate, chitType, receiverName, senderName, sharedDate, sharedMessage, deedPerformedBy, sharedTitle } = sharedChitObject




    let receivedObject = {

      pageView: 'form',
      sharedChitId: sharedChitId,
      chitType: chitType,
      chitColor: chitColor,
      senderName: senderName,
      receiverName: receiverName,
      chitDate: chitDate,
      sharedDate: sharedDate,
      deedPerformedBy: deedPerformedBy,
      sharedTitle: sharedTitle,
      sharedMessage: sharedMessage,

    }

    dispatch(updateReceivedChitFormData({
      pageType: 'receivedChitForm',
      data: receivedObject
    }
    ))
    
    // changeLoadingStatus to false
    dispatch(changeLoadingStatus(false))  

  } // end getData


  useEffect(() => {

    getData()


  }, [])

  if (!sharedObject) {
    navigate(`/`)

  }



  // console.log('[ ReceivedSharedChit USer ] Yes SHARED OBJECT ', sharedObject);

  let sharedId, sharedLinkAddress, senderId, receiverId, receiverName, senderName, chitCategory, chitType, chitColor, chitDate, sharedDate, sharedTitle, sharedMessage, deedPerformedBy

  if (sharedObject) {
    sharedLinkAddress = `${BASE_URL}/sharedChit/${sharedChitId}`
  } else {
    sharedLinkAddress = ''
  }


  sharedObject ? senderId = sharedObject.senderId : senderId = ''
  sharedObject ? receiverId = sharedObject.receiverId : receiverId = ''
  sharedObject ? receiverName = sharedObject.receiverName : receiverName = ''
  sharedObject ? senderName = sharedObject.senderName : senderName = ''

  sharedObject ? chitType = sharedObject.chitType : chitType = ''
  sharedObject ? chitColor = sharedObject.chitColor : chitColor = ''
  sharedObject ? chitDate = sharedObject.chitDate : chitDate = ''
  sharedObject ? sharedDate = sharedObject.sharedDate : sharedDate = ''
  sharedObject ? sharedTitle = sharedObject.sharedTitle : sharedTitle = ''
  sharedObject ? sharedMessage = sharedObject.sharedMessage : sharedMessage = ''
  sharedObject ? deedPerformedBy = sharedObject.deedPerformedBy : deedPerformedBy = ''


  let styledSharedDate, styledChitDate
  sharedObject ? styledSharedDate = ISOtoTraditional(sharedDate) : styledSharedDate = ''
  sharedObject ? styledChitDate = ISOtoTraditional(chitDate) : styledChitDate = ''

  let performedByName
  deedPerformedBy === 'me' ? performedByName = senderName : performedByName = receiverName




  //  --- define which coin is displayed

  let coinAddress = chooseTwoPartyChitCoin(chitType, chitColor)

  const pathToCoinImages = '../../../'
  const twoPartyCoinDisplayed = pathToCoinImages + chooseTwoPartyChitCoin(chitType, chitColor)
  const personalCoinDisplayed = pathToCoinImages + choosePersonalCoin(chitColor)

  // let sharedChit = allSharedChits.find(chit => chit.id === codeId)


  // --- popover functions ----
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  // ---end popover functions ---


  const [chitInput, setChitInput] = useState('')

  const handleInput = (evt) => {
    // console.log('[Landing_page ] Input form value ', evt.target.value);
    setChitInput(evt.target.value)
  }

  const handleSubmit = (code) => {
    let cleanCode = stripWhiteSpace(code)
    navigate(`/sharedChit/${cleanCode}`)
    //  console.log('[ Landing_page ] I submitted ', code);
  }

  const goToHome = () => {

    navigate(`/`)
    // alert('[ Landing_page ] I submitted ');
  }

  const goToLogin = () => {

    dispatch(openModal(
      {
        modalParams: {
          modalType: 'form',
          dbCollection: '',
          id: '',
          login: 'login'
        }
      }

    ))
  }

  const goToJoin = () => {

    dispatch(openModal(
      { modalParams: {
            modalType: 'form',
            dbCollection: '',
            id: '',
            login: 'join'
        }
      }
  
    ))
  }


  /* ----- function to display chit details------
    or Popup sayng bad shared chit code
 */
  let SharedChitDisplay = (codeId) => {

    let chitLinkMessage = sharedLinkAddress

    return (
      <>
        <ContentBox>
          <SentDate> shared: {styledSharedDate} </SentDate>

          <SubHeader>
            This chit has been sent to you <br />  from  {senderName}
          </SubHeader>

          <ChitBox>


            <DetailWrapper>

              <ActionWrapper>
                <ActionLeft>chit date :   </ActionLeft>
                <ActionRight> {styledChitDate} </ActionRight>

              </ActionWrapper>


              <StyledChitImage src={twoPartyCoinDisplayed} alt='silver coin' />

              <ActionWrapper>
                <ActionLeft>performed by: </ActionLeft>
                <ActionRight>{performedByName} </ActionRight>

              </ActionWrapper>


            </DetailWrapper>

            <SummaryWrapper>
              <SummaryTop>Summary:   </SummaryTop>
              <SummaryBottom> {sharedTitle} </SummaryBottom>

            </SummaryWrapper>

            <MessageWrapper>
              <MessageTop>Message from {senderName}:    </MessageTop>

              <MessageBottom dangerouslySetInnerHTML={{ __html: sharedMessage }} >

              </MessageBottom>

            </MessageWrapper>


            <LoginWrapper>

              <div>If you would like to add this chit to your repo</div>
              <div>login or join Chit Git</div>

            </LoginWrapper>

            <LinkWrapper>
            <StyledLink onClick={() => goToLogin()}>Login</StyledLink>
              <StyledLink onClick={() => goToHome()}>Learn all ChitGit can do</StyledLink>
              <StyledLink onClick={() => goToJoin()}>Join </StyledLink>
            </LinkWrapper>


            <ResendWrapper>

              <div>If you would like to show this chit to someone else</div>
              <div>Click icon to copy link and send it to them in an IM or email</div>
              <Resend>
                <div> {chitLinkMessage}  </div>
                <CopySharedChitLink chitLink={chitLinkMessage} />
              </Resend>
            </ResendWrapper>


          </ChitBox>





        </ContentBox>
      </>

    )// end return SharedChitDisplay



  }// end func SharedChitDisplay




  // --------------------------------------------------

  return (
    <>



      <HeadWrapper>


        <HeaderMsg>
          <div>Give yourself total recall 
          </div>
          <div>  of your deeds, dealings and doings</div>

        </HeaderMsg>
        {/* <HeaderLink onClick={() => goToHome()}>Learn about ChitGit </HeaderLink> */}

      </HeadWrapper>

      <ContentWrapper>
        {SharedChitDisplay()}
      </ContentWrapper>
    </>
  ) // end main return
} // end main Function ReceivedSharedChitUser

// ========================================================

const Wrapper = styled(Paper)({
  marginTop: '3rem',
  width: '50%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '.85rem',
  backgroundColor: 'white',

  [theme.breakpoints.down('xs')]: {

  }

})



// -----------------------------------------------------------------


const HeadWrapper = styled('div')({

  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',


  width: '100%',
  color: 'yellow',
  height: '1.5rem',
  margin: '2rem 0 1rem 0',



  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    margin: '.5rem 0 1.5rem 0',
    fontSize: '1.4rem',
  }


})


const HeaderMsg = styled('div')({


  color: 'white',
  marginLeft: '2rem',
  fontSize: '1.25rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '2rem',
  marginBottom: '1rem',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1rem',
  }

})

const HeaderLink = styled('div')({



  color: 'black',
  fontSize: '.85rem',
  marginLeft: '16px',
  border: '1px soiid white',
  padding: '2px 8px',
  borderRadius: '5px',
  backgroundColor: chitOrangeLight,
  cursor: 'pointer',
  fontWeight: 'bold',
  '&:hover': {
    backgroundColor: veryLightGrey
  },

  [theme.breakpoints.down('sm')]: {
    fontSize: '1rem',
    marginBottom: '1rem',
    fontWeight: 'normal'
  }


})


const ContentWrapper = styled('div')({

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',

  // height: 'calc(100vh  - 2.5rem)',

  // maxHeight: '45rem',


  // height: '100%',

  // marginTop: '2.5rem',
  paddingBottom: '1rem 0',
  // backgroundColor: backgroundBlue,


  [theme.breakpoints.down('sm')]: {
    width: '100%',


  }

})



const ContentBox = styled(Paper)({


  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  position: 'relative',
  marginTop: '3rem',
  width: '45rem',
  paddingBottom: '1rem',
  borderRadius: '20px',

  [theme.breakpoints.down('sm')]: {
    width: '96%',
    paddingTop: '2rem'

  }


})
// --- Summary --------
const SummaryWrapper = styled('div')({

  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '75%',
  padding: '6px',
  color: chitBurgandy,


  [theme.breakpoints.down('sm')]: {
    width: '100%',
    margin: '.5rem 0 ',

  }

})

const SummaryTop = styled('div')({

  marginRight: '6px',
  fontStyle: 'italic',
  fontSize: '.9rem',
  color: mediumGrey,
  [theme.breakpoints.down('xs')]: {

  }

})

const SummaryBottom = styled('div')({


  [theme.breakpoints.down('xs')]: {

  }

})

const SubHeader = styled('div')({

  display: 'block',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  textAlign: 'center',

  width: '100$',

  color: chitBurgandy,
  fontSize: '1rem',
  marginTop: '8px',



  [theme.breakpoints.down('xs')]: {
    overflow: 'auto',

  }


})


// --- detail section -----

const DetailWrapper = styled('div')({

  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  alignItems: 'center',
  margin: ' .5rem 3rem',
  width: 'calc(75% - 6rem)',
  [theme.breakpoints.down('sm')]: {

    width: '96%',
    margin: 0
  }

})

const StyledChitImage = styled('img')({

  margin: '0 1.5rem',
  height: '5rem',
})


const StyledLinkImage = styled('img')({

  height: '1rem',

})

const ActionWrapper = styled('div')({

  // width: '50%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'space-between',
  fontSize: '.85rem',

  [theme.breakpoints.down('xs')]: {

  }

})

const ActionLeft = styled('div')({

  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  marginRight: '6px',
  fontStyle: 'italic',


  [theme.breakpoints.down('xs')]: {

  }

})


const ActionRight = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  fontSize: '.9rem',
  color: backgroundBlue,

  [theme.breakpoints.down('xs')]: {

  }

})


// --- Message ---------

const MessageWrapper = styled('div')({



  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '75%',

  borderRadius: '5px',
  [theme.breakpoints.down('sm')]: {
    width: '96%',

  }

})

const MessageTop = styled('div')({

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',

  // margin: '0 0 6px 0 ',
  width: 'calc(100% - 12px)',
  fontSize: '.9rem',
  fontStyle: 'italic',


  [theme.breakpoints.down('sm')]: {
    borderBottom: '1px solid #E6E7E8'
  }

})


const MessageBottom = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',

  borderRadius: '5px',
  padding: '12px 8px',
  width: 'calc(100% - 12px)',
  fontSize: '.9rem',
  minHeight: '5rem',
  marginBottom: '1rem',
  border: '1px solid #CFD0D1',
  [theme.breakpoints.down('xs')]: {

  }

})




// -----------------------------------------
const ChitBox = styled('div')({


  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  position: 'relative',

  width: 'calc(100% - 15px)',
  height: '80%',
  marginTop: '8px',


  borderRadius: '20px',


  [theme.breakpoints.down('sm')]: {

    height: '86%'
  }


})

// --- Add this chit ------------

const AddWrapper = styled('div')({

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
  fontSize: '.8rem',
  borderTop: '1px solid #CFD0D1',
  borderBottom: '1px solid #CFD0D1',
  marginTop: '1rem',

  [theme.breakpoints.down('xs')]: {

    fontSize: '.95rem',
    padding: '0 15% 0 5%',


  }


})
const AddHeader = styled('div')({

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
  color: chitBurgandy,
  background: veryLightGrey,
  paddingBottom: '5px',

  [theme.breakpoints.down('xs')]: {

    fontSize: '.95rem',
    padding: '0 15% 0 5%',


  }


})

const AddLinks = styled('div')({

  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  backgroundColor: 'white',
  margin: '.3rem 0 .6rem 0',

  [theme.breakpoints.down('xs')]: {

    fontSize: '.95rem',
    padding: '0 15% 0 5%',


  }


})

const AddLeft = styled('div')({

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '30%',



  [theme.breakpoints.down('xs')]: {

    fontSize: '.95rem',
    padding: '0 15% 0 5%',


  }


})

const AddRight = styled('div')({

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '30%',



  [theme.breakpoints.down('xs')]: {

    fontSize: '.95rem',
    padding: '0 15% 0 5%',


  }


})

const LinkButton = styled(Button)({

  display: 'block',
  textTransform: 'none',

  border: '1px solid #2D259C',
  color: '#2D259C',
  fontWeight: 'normal',
  fontSize: '.85rem',
  padding: '0 1.5rem',
  marginTop: '.5rem',
  // '&:hover' : {
  //   // backgroundColor: chitBlueDull,
  //   textDecoration: 'none',
  //   border: '1px solid #A8BEED' ,
  //   color: '#3B30CC'

  // }


})

// --- end Add this chit -----

const SentDate = styled('div')({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  fontSize: '.7rem',
  color: mediumGrey,
  top: '10px',
  right: '16px',

  [theme.breakpoints.down('xs')]: {

    fontSize: '.95rem',
    padding: '0 15% 0 5%',


  }


})

const ResendWrapper = styled('div')({
  // position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  fontSize: '.7rem',
  color: mediumGrey,
  bottom: '10px',
  right: '16px',
  margin: '8px 0 6px 0',

  [theme.breakpoints.down('xs')]: {

    fontSize: '.95rem',
    padding: '0 15% 0 5%',


  }


})

const LoginWrapper = styled('div')({
  // position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  fontSize: '.9rem',
  color: chitBurgandy,
  bottom: '10px',
  right: '16px',
  margin: '8px 0 6px 0',

  [theme.breakpoints.down('xs')]: {

    fontSize: '.95rem',
    padding: '0 15% 0 5%',


  }


})

const Resend = styled('div')({

  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  border: '1px solid grey',
  borderRadius: '5px',
  padding: '2px 5px',
  width: '100%',
  margin: '.5rem',


  [theme.breakpoints.down('xs')]: {

    fontSize: '.95rem',
    padding: '0 15% 0 5%',


  }


})
// ---------------------------

const ErrorBox = styled(Paper)({

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',

  width: '45rem',
  height: '25rem',
  color: chitBurgandy,

  backgroundColor: 'white',
  borderRadius: '20px',




  [theme.breakpoints.down('xs')]: {
    width: '25rem',
    height: '25rem',
  }


})


const NoticeForm = styled('div')({

  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',

  // backgroundColor: 'red',

  color: backgroundBlue,

  fontSize: '1rem',
  fontWeight: '400',
  marginTop: '6px',
  marginLeft: '22px',
  [theme.breakpoints.down('sm')]: {

    fontSize: '.75rem'

  },

  [theme.breakpoints.down('xs')]: {

    fontSize: '.95rem',
    padding: '0 15% 0 5%',


  }


})



const StyledInput = styled('input')({

  fontSize: '.75rem',
  fontWeight: '400',
  width: '10rem',

  [theme.breakpoints.down('sm')]: {

    fontSize: '.75rem'

  },

  [theme.breakpoints.down('xs')]: {

    fontSize: '.95rem',
    padding: '0 15% 0 5%',


  }


})

const InfoIconWrapper = styled(Info)({

  color: mediumLightGrey,
  fontSize: '1rem',
  marginLeft: '8px',


})

const HomeLink = styled('div')({
  position: 'absolute',
  bottom: '1rem',
  display: 'block',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  fontSize: '.9rem',

  marginTop: '3rem',
  textDecoration: 'underline',
  color: 'blue',

  cursor: 'pointer',

  [theme.breakpoints.down('sm')]: {

    margin: 0


  }


})

const Container = styled('div')({


  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  height: '100%',
  width: '100%',

  [theme.breakpoints.down('sm')]: {




  }


})

const StyledScrollbars = styled(Scrollbars)({
  margin: '6px',
  '& .track-vertical ': {
    top: '2px',
    bottom: '2px',
    right: '2px',
    borderRadius: '3px',
    background: '#040952',

    '& .thumb-vertical': {
      position: 'relative',
      display: 'block',
      width: '100%',
      cursor: 'pointer',
      borderRadius: 'inherit',
      backgroundColor: '#A8BEED', // changed the color
    }

  }


  // .view {
  //     position: absolute;
  //     top: 0px;
  //     left: 0px;
  //     right: 0px;
  //     bottom: 0px;
  //     overflow: scroll;
  //     margin-right: -15px;
  //     margin-bottom: -17px !important; // changed from -15px  (default) to -17px (native scrollbar had a portion visible) 
  // }


})

const StyledButton = styled(Button)({
  backgroundColor: 'white',
  border: '1px solid #E6E7E8',
  color: chitBurgandyDull,
  margin: '1.2rem',
  // width: '5rem',
  padding: '0 3px',
  height: '1.5rem',
  fontSize: '.8rem',
  '&:hover': {
    backgroundColor: veryLightGrey
  }

})

const StyledAddCircleIcon = styled(AddCircleIcon)({


  fontSize: '.8rem',
  marginRight: '.5rem',
  '&:hover': {
    backgroundColor: 'lightGrey',
    borderRadius: '50px',
    cursor: 'pointer'
  },

})


const StyledLink = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: chitBlueDull,
  backgroundColor: chitOrangeVeryLight,
  border: '1px solid #FADAC1',
  borderRadius: '5px',
  padding: '8px 16px',


  fontSize: '.95rem',
  height: '1.2rem',

  '&:hover': {
    backgroundColor: veryLightGrey,

    cursor: 'pointer'
  },

  [theme.breakpoints.down('xs')]: {

    fontSize: '.95rem',



  }

})

const LinkWrapper =  styled('div')({
   
display: 'flex',
justifyContent: 'space-between',
alignItems: 'center',
  margin: '1rem 0',
  width: '70%',
  backgroundColor: mediumMediumGrey,
  borderRadius: '8px',
  height: '2.4rem',
  padding: '0 5%',

  [theme.breakpoints.down('sm')]: {

    width: '90%',
 

  }


})

