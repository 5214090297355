import React from "react"
 

import {NavLink, useLocation} from 'react-router-dom'
import {useHistory, useParams, match} from 'react-router-dom'
import { useSelector } from "react-redux"
import {connect} from 'react-redux'
// import{setPage} from '../../../app/redux/actions/landingActions'
import { selectStatus } from "../../../app/redux/statusRedux/statusSlice"
// import {getPage} from '../../../app/helpers/locationHelper'
import{backgroundBlue, bodyBlue, chitOrange, chitOrangeVeryLight, darkGrey, highlightGrey, lightGrey, shadowBlue, veryLightGrey} from '../../../styles/colors'



 
import { styled, createTheme  } from "@mui/material/styles"
const theme = createTheme(); // allows use of mui theme in styled component




// ================================================

function PageNav(props) {
  let match = useParams()

  const status = useSelector(selectStatus)
  let userName = status.auth.displayName
  const twoPartyId = status.view.twoParty.id
  const twoPartyLink = `/main/twoPartyChits/${twoPartyId}`

  const personalId = status.view.personal.id
  const personalLink = `/main/personalChits/${personalId}`

  const topicalId = status.view.topical.id
  const topicalLink = `/main/topicals/${topicalId}`

  const logId = status.view.log.id
  const logLink = `/main/logs/${logId}`

  let handleClose = props.handleClose
  let page

  !match.pageView ? page = 'home' : page = match.pageView

  console.log('[PageNav ] - page location : ', page)
  // let page = 'twoPartyChits'

  // Popover ---------
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };



  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;




  return (



    <Wrapper>
      <Greeting> Welcome {userName} </Greeting>
      <StyledLink to="/main/home" >

        {page !== 'home' &&
          <NavButton
            id='home'
            onClick={handleClose}

          >Home </NavButton>
        }

        {page === 'home' &&
          <NavButtonDisabled disabled
            id='home'
          // onClick = {handleClose}

          >Home </NavButtonDisabled>
        }


      </StyledLink>


      {/* ========================================================= */}


      <StyledLink to={twoPartyLink} >

        {page !== 'twoPartyChits' &&
          <NavButton
            id='twoPartyChits'
            onClick={handleClose}

          >Two Party Chits </NavButton>
        }

        {page === 'twoPartyChits' &&
          <NavButtonDisabled disabled
            id='twoPartyChits'
          // onClick = {handleClose}

          >Two PartyChits </NavButtonDisabled>
        }

      </StyledLink>



      <StyledLink to={logLink} >

        {page !== 'logs' &&
          <NavButton
            id='logs'
            onClick={handleClose}

          >Logs </NavButton>
        }

        {page === 'logs' &&
          <NavButtonDisabled disabled
            id='logs'
          // onClick = {handleClose}

          >Logs </NavButtonDisabled>
        }


      </StyledLink>


      <StyledLink to={topicalLink} >

        {page !== 'topicals' &&
          <NavButton
            id='topicals'
            onClick={handleClose}

          >Topicals </NavButton>
        }

        {page === 'topicals' &&
          <NavButtonDisabled disabled
            id='topicals'
          // onClick = {handleClose}

          >Topicals </NavButtonDisabled>
        }


      </StyledLink>






      <StyledLink to={personalLink} >
        {page !== 'personalChits' &&
          <NavButton
            aria-describedby={id} variant="contained" onClick={handleClick}

          >Personal Chits </NavButton>
        }

        {page === 'personalChits' &&
          <NavButtonDisabled disabled
            id='personalChits'
          // onClick = {handleClose}

          >Personal Chits </NavButtonDisabled>
        }





      </StyledLink>




      <StyledLink to="/main/contact">
        {page !== 'contact' &&
          <NavButton
            aria-describedby={id} variant="contained" onClick={handleClick}

          >Contact </NavButton>
        }

        {page === 'contact' &&
          <NavButtonDisabled disabled
            id='contact'
          // onClick = {handleClose}

          >Contact </NavButtonDisabled>
        }





      </StyledLink>



    </Wrapper>
  );
}

const actions = {
  // setPage 
}

const mapState = state => ({
  page: state,

});

export default connect(mapState, actions)(PageNav)

// -----------------------------------------------------------------


const Wrapper = styled('div')({

  padding: 0,
  margin: 0,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',

  [theme.breakpoints.down('sm')]: {
    // fontWeight: 'bold',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    padding: '2px 0'

    // width: '150px'

  },


})

const NavButton = styled('div')({

  // border: 'none',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  height: '100%',

  color: shadowBlue,

  textTransform: 'none',
  fontWeight: '400',
  paddingRight: '10px',
  paddingLeft: '10px',

  '&:hover': {
    backgroundColor: veryLightGrey,
    boxShadow: 'none',

  },


  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontSize: '.85rem',
    padding: '2px 5px',
    width: '10rem',
    borderTop: '1px solid white'
  },

})

const NavButtonDisabled = styled('div')({

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  height: '100%',

  color: chitOrange,

  textTransform: 'none',
  fontWeight: '400',
  paddingRight: '10px',
  paddingLeft: '10px',
  backgroundColor: veryLightGrey,

  '& :hover': {
    backgroundColor: veryLightGrey,
  },

  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontSize: '.9rem',
    padding: '2px 15px',
    width: '10rem',
    margin: '1px 0',
  },

})




const StyledLink = styled(NavLink)({

  textDecoration: 'none',

})

const Greeting = styled('div')({
  paddingLeft: '5px',
  display: 'none',

  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    color: 'white',
    height: '1.25rem',
    marginTop: '.25rem',
    fontSize: '.6rem',
    backgroundColor: shadowBlue,
    marginBottom: '3px'

  }

})

const Submenu = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '5px',


  [theme.breakpoints.down('sm')]: {


  }

})