import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app/App'
import { BrowserRouter } from 'react-router-dom'

import reportWebVitals from './reportWebVitals';

//redux imports
import {Provider} from 'react-redux'

import {configureStore} from '@reduxjs/toolkit'
import rootReducer from './app/redux/reducers/rootReducer'
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Defaults to localStorage
import { PersistGate } from 'redux-persist/integration/react';

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});

const persistor = persistStore(store);

// const store = configureStore({
//   reducer: rootReducer
// });

ReactDOM.render(
  <Provider store = {store}> 
   <PersistGate loading={null} persistor={persistor}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
    </PersistGate>
  </Provider> 
, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
