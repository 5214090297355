/* function Shared_icon-------------
     1. shared icon is only displayed on twoParty "people" ledger rows
     2. icon has 3 states:
        - orange - no shared chit yet
        - green - shared chit published
    

     3.  note: Once published, shared chit is not editiable.

     4.  onClick 
         - orange -> go to new form
         - green -> popup with 2 options
                - a) copy link
                - b) view sent shared Chit

 
------------------------------------*/

import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {useHistory, useRouteMatch, match} from 'react-router-dom'
import{mediumLightGrey, chitOrange, lightGrey, chitBurgandyDull, mediumGrey} from '../../../styles/colors'
 
import CopySharedChitLink from '../../../common_components/CopySharedChitLink'

import { openModal } from '../../../app/redux/statusRedux/statusSlice'

import { selectStatus, updateSharedChitFormData } from '../../../app/redux/statusRedux/statusSlice'
 
import { selectAllTwoPartyChits } from '../../../app/redux/twoPartyChitRedux/twoPartyChitsSlice'

import { selectSharedChits } from '../../../app/redux/sharedRedux/sharedChitsSlice'

import { selectPeople } from '../../../app/redux/peopleRedux/peopleSlice'
 

import { UTCtoISO } from '../../../app/helpers/dateHelper'


// Material UI --------------------
 
import Tooltip from '@mui/material/Tooltip';
import ShareIcon from '@mui/icons-material/Share';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button'




import { styled, createTheme} from "@mui/material/styles"
import {withStyles} from '@mui/styles'
const theme = createTheme(); // allows use of mui theme in styled component


// ================================




function Shared(props) {

/*
- retrieve the passed chitId which is passed from twoPartyLedgerRow
- retrieve the chit from twoPartyChits where = passed chitId
- convert twoParty chit fields to sharedChit fields
- dispatch new sharedChit Object to status.view.forms.sharedChitForm
- open modal component

*/  
// --- get the chitId to retrieve the originating chit object

  let passedChitId = props.chitId


  const dispatch = useDispatch()

// --- get the user's name

  const status = useSelector(selectStatus)
  let userName = status.auth.displayName

// --- retrieve the data from the initiating twoPartyChitRow

  const allTwoPartyChits = useSelector(selectAllTwoPartyChits)
  let chitObject = allTwoPartyChits.find(chit => chit.id === passedChitId)




  const sharedChitId = chitObject.sharedChitId


  const allSharedChits = useSelector(selectSharedChits)
  let sharedChitLink
  if(sharedChitId) {
    console.log('[ Icon ] There is a sharedChitId ', sharedChitId);
  

    let sharedChitObject = allSharedChits.find(sharedChit => sharedChit.chitId === passedChitId)

    sharedChitLink = sharedChitObject.sharedChitLink


    console.log('[ Icon ] There is a sharedChitObject ', sharedChitObject);
  }



  // const sharedChitLink = chitObject.sharedChitLink

  const {description, chitColor, chitType, chitDate, deedPerformedBy, otherPartyId, } = chitObject



// --- create the data Object to be passed to redux
//          status.view.forms.sharedChitForm

const allPeople = useSelector(selectPeople)
  let personObject = allPeople.find(person => person.id === otherPartyId)
  let receiverName = personObject.name

let sharedDeedPerformedBy
deedPerformedBy === otherPartyId ? sharedDeedPerformedBy = 'otherParty': sharedDeedPerformedBy ='me'

// console.log('[ Shared ] receiverName ', receiverName);
// console.log('[ Shared ] sharedDeedPerformedBy ', sharedDeedPerformedBy);

// -- set up current date to ISO format ---

  let today = Date.now()
  let ISOCurrentDate = UTCtoISO(today)


 let sharedObject = {

     pageView: 'form',
     chitId: passedChitId,
     chitType: chitType,
     chitColor: chitColor,
     senderName: userName,
     receiverName: receiverName ,
     chitDate: chitDate,
     sharedDate: ISOCurrentDate , 
     deedPerformedBy: sharedDeedPerformedBy,
     sharedTitle: '',
     sharedMessage: '',
     description: description

  }


  // Temp variables #############################################
  let chitId = 'xxx'
  
  let sharedChitStatus = ''
 
  // Temp variables #############################################


  // --- Popup Dialog functions
  const [open, setOpen] = React.useState(false)

  const handleClick = (id, source) => {
    setOpen(true);

  };

  const handleDialogClose = () => {
    setOpen(false);
  };

 
  function handleOpenEditForm(id, sharedChitId){
    
    dispatch(updateSharedChitFormData({
      pageType: 'sharedChitForm',
      data: sharedObject
    }
    ))
  
    dispatch(openModal(
      { modalParams: {
            modalType: 'form',
            dbCollection: 'sharedChits',
            id: sharedChitId,
            sharedChitId: sharedChitId,
          }
        }
    
      ))
  
    console.log('Shared Icon - I be clicked')
  
    // dispatch(openModal(modalType, modalParams))
  }

  function handleOpenForm(id, sharedChitId){
    dispatch(updateSharedChitFormData({
      pageType: 'sharedChitForm',
      data: sharedObject
    }
    ))
  
    dispatch(openModal(
      { modalParams: {
            modalType: 'form',
            dbCollection: 'sharedChits',
            id: '',
            sharedChitId: '',
          }
        }
    
      ))
  

  
    // dispatch(openModal(modalType, modalParams))
  }


  return (
    <>

<Dialog
        open={open}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">

        </DialogTitle>
        <DialogContent>
        <Message>
          Copy the link and paste it in an email to share it<br/> or <br/>
          Paste it into another browser window to view it.
        </Message>

        <Resend>
          <div> {sharedChitLink}  </div>
          <CopySharedChitLink chitLink={sharedChitLink} />
        </Resend>
        </DialogContent>
        <DialogActions>
  
        <ButtonWrapper> 
          <StyledButton
            form="submit-form"
            variant="contained"
            color="primary"

            onClick={()=>handleDialogClose()}
          >
            Close Window
          </StyledButton>
          </ButtonWrapper>
        </DialogActions>
      </Dialog>
    {!sharedChitId && <>   
   
      <LightTooltip   title = 'Share this chit '  arrow> 
      <IconOrange


        onClick={()=>handleOpenForm(passedChitId, sharedChitId)}
       
      />
      </LightTooltip  >
 
      </>}


      {sharedChitId  && <>

        <LightTooltip title='Share this chit ' arrow>
          <IconGreen


        onClick={()=>handleClick()}

          />
        </LightTooltip  >

      </>}

 

    </>
  )
}


export default Shared
// -----------------------------------------------------------------

 
 

const IconOrange= styled(ShareIcon)({
  backgroundColor: 'white',
  borderRadius: '5px',
  fontSize: '.95rem',
  color: chitOrange,
  
  margin: '0 .5rem .3rem .5rem',
  cursor: 'pointer',
  


  '&:hover': {
    color: mediumLightGrey
    // backgroundColor: mediumLightGrey
  },


  [theme.breakpoints.down('sm')] : {
    // height: '1.25rem',
    // backgroundColor: 'red'
  },
})


const IconRed= styled(ShareIcon)({
  backgroundColor: 'white',
  borderRadius: '5px',
  fontSize: '.95rem',
  color: 'red',
  
  margin: '0 .5rem .3rem .5rem',
  cursor: 'pointer',
  


  '&:hover': {
    color: mediumLightGrey
    // backgroundColor: mediumLightGrey
  },


  [theme.breakpoints.down('sm')] : {
    // height: '1.25rem',
    // backgroundColor: 'red'
  },
})


const IconGreen= styled(ShareIcon)({
  backgroundColor: 'white',
  borderRadius: '5px',
  fontSize: '.95rem',
  color: 'green',
  
  margin: '0 .5rem .3rem .5rem',
  cursor: 'pointer',
  


  '&:hover': {
    color: mediumLightGrey
    // backgroundColor: mediumLightGrey
  },


  [theme.breakpoints.down('sm')] : {
    // height: '1.25rem',
    // backgroundColor: 'red'
  },
})


const LightTooltip = withStyles({
  tooltip: {
    color: "grey",
    backgroundColor: "white",
    boxShadow: '2px 3px 3px black',
    border: '1px solid grey',
  }
})(Tooltip);

const StyledButton= styled(Button)({
  backgroundColor: 'white',
  border: '1px solid #E6E7E8',
  color: chitBurgandyDull,
  margin: '0 1.5rem 0 6px',
 
  height: '1.5rem',
  fontSize: '.8rem',
  '&:hover' :{
    backgroundColor: lightGrey
  }

})
const Message= styled('div')({
 
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '.7rem',
  color: mediumGrey,
  textAlign: 'center',
  // [theme.breakpoints.down('xs')]: {

  //   fontSize: '.95rem',
  //   padding: '0 15% 0 5%',


  // }

})
const Resend = styled('div')({

  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  border: '1px solid grey',
  borderRadius: '5px',
  padding: '2px 5px',
  width: '100%',
  margin: '1rem 0 2rem 0',
  fontSize: '.8rem', 

  [theme.breakpoints.down('xs')]: {

    fontSize: '.95rem',
    padding: '0 15% 0 5%',


  }


})

const ButtonWrapper = styled('div')({

  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
 width: '100%',
 marginBottom: '8px',

  [theme.breakpoints.down('xs')]: {

    fontSize: '.95rem',
    padding: '0 15% 0 5%',


  }


})