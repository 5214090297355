
// *** Sample Chrons Selectors ----------

import { createSelector } from 'reselect'

// Get all twoParty sections --------------------

import { createSlice } from '@reduxjs/toolkit'

 

export const twoPartySlice = createSlice({
  name: 'twoPartysSample',
  initialState: [],

  reducers: {

    

    addAllTwoPartyChitsFromFirestore: (state = [], action) => {
    
      let firestoreData = action.payload
    
      // Clear the state array
      state = [];
    
      // Add the Firestore data to the state array
      state = [...firestoreData];
    
      return state
    },// end addAllTwoPartyChitsFromFirestore

    addTwoPartyChitToStore: (state, action) => {

      let twoPartyChit = action.payload

      state.push(twoPartyChit)
    },



    resetTwoPartyChits: (state, action) => {
      let initialState = []
      return initialState
    },// end resetTwoPartyChits




  updateEditedTwoPartyChit: (state, action) => {

    let data = action.payload
    let twoPartyChitId = action.payload.id

    
    const { otherPartyCollection, chitType, chitColor,  chitValue, chitBurden,chitDate, person, group, deedPerformedBy, workRelated, description, keyWordArray, otherPartyId} = data


    let twoPartyIndex = state.findIndex(index => index.id === twoPartyChitId)
    


    state[twoPartyIndex].id  = twoPartyChitId
    state[twoPartyIndex].chitType  = chitType
    state[twoPartyIndex].chitColor  = chitColor
    state[twoPartyIndex].chitBurden  = chitBurden
    state[twoPartyIndex].chitValue  = chitValue
    state[twoPartyIndex].chitDate  = chitDate
    state[twoPartyIndex].deedPerformedBy  = deedPerformedBy
    state[twoPartyIndex].otherPartyId  = otherPartyId
    state[twoPartyIndex].otherPartyCollection  = otherPartyCollection
    state[twoPartyIndex].workRelated  = workRelated
    state[twoPartyIndex].description  = description

    
   


  }, // end updateEditedtwoParty

  deleteTwoPartyChit: (state, action) => {

    console.log('[ deleteTwoPartyChit ] payload ', action.payload);
        let twoPartyChitId = action.payload.id
    
        return state.filter(item => item.id !== twoPartyChitId)
     
    }, // end deleteTwoPartyChit

    
    updateTwoPartyChitSharedId: (state, action) => {

      console.log('[ updateTwoPartyChitId ] payload ', action.payload);
      let twoPartyChitId = action.payload.id
      let sharedChitId = action.payload.sharedChitId
    
 
  
  
      let twoPartyIndex = state.findIndex(index => index.id === twoPartyChitId)
      
      state[twoPartyIndex].sharedChitId  = sharedChitId
       
      }, // end deleteTwoPartyChit




}

}) // end slice twoPartysSlice 


// --- Export actions ---------------------------------------------

export const { 
  addAllTwoPartyChitsFromFirestore,
  addTwoPartyChitToStore, 
  updateEditedTwoPartyChit,
  deleteTwoPartyChit,
  updateTwoPartyChitSharedId,
  resetTwoPartyChits

} = twoPartySlice.actions



// --- Export selectors ------------------------------------------

export const selectAllTwoPartyChits = state => state.twoPartyChits




// --- default Export reducers -----------------------------------

export default twoPartySlice.reducer