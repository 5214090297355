/* Help_spotlights_s.jsx

  Contains navigation buttons to parts of the Sample Site:
     Spotlights, Chits, Logs etc.
  
  Also contains the opening description about Bob and how
  he uses the sample site.

   contains child  components: 
      none

    parent: Main_help_s - pages/public/sample/samHelp/mainHelp

*/




import React from 'react'
import { backgroundBlue, mediumGrey } from '../../../styles/colors';



import CheckIcon from '@mui/icons-material/Check';
import AddCircleIcon from '@mui/icons-material/AddCircle'


import { styled, createTheme } from "@mui/material/styles"
const theme = createTheme(); // allows use of mui theme in styled component

// -----------------------------------------------------------------


function PersonalChitsHelp() {
  return (
    <MainWrapper>

      <LinkWrapper>
        <LinkComponent>
          <a href="#intro">Introduction  </a>
          <a href="#types">Chit Types</a>
          <a href="#createNew">Creating new chits  </a>
          <a href="#timeLocks">Time locks</a>

        </LinkComponent>
      </LinkWrapper>
      <ContentWrapper>

        <HeaderWrapper name="intro"> Personal Chits Introduction</HeaderWrapper>


        <div><em> Personal Chits Are</em> "tokens: <br />
          Tokens that you give yourself .  Use tokens record your personal accomplishments... both big and small.
        </div>
        <div>
          They are organized by categories.  As such there is no second party involved.  For chits involving other people or groups, use two party chits.
        </div>
        <div>
          Personal chits are designed to do two things.
          <ul>
            <li> First they will help motivate you while you pursue a personal goal or objective.  For this there is a calendar view of your daily progress.
            </li>
            <li> Second they are designed to mark milestones or breakthroughs you achieve.  For these you can see them in either the calendar view or the ledger view.
            </li>
          </ul>
        </div>



        {/* -------- CHit Types -------------------------------- */}

        <HeaderWrapper name="types">Chit Types</HeaderWrapper>

        <div>There are 3 types of personal Chits(types).</div>
        <ul>
          <li>
            Standard personal chits - <br />

            Standard chits mark positive progress towards a goal or objective... like going to the gym on a day you planned to accomplish your exercise goal.
            <div>
              They come in 3 colors.  Use silver chits to mark your expected progress.  Use copper chits to mark less than hoped for progress towards the daily objective, but progress all the same.  And use gold chits to mark days you exceed expectaions.
            </div>

          </li>
          <li>
            Then there are Aw Chits- <br />
            Red Aw Chits  mark negative daily progress towards your goal or objective for a given category.  For instance - breaking your  dieting objective  one day by indulging in junk food or ice cream.
          </li>

          <li>
            Finally there are Milestones- <br />
            Milestones mark significant achievments in progress toward a goal ... or achieving the goal itself. Like reaching your weight loss goal...  or making a big sale at work.
          </li>


        </ul>



        {/* -------- createNew -------------------------------- */}

        <HeaderWrapper name="createNew"> Creating new Chits</HeaderWrapper>

        <div> New chits can be created in  3 ways </div>
        <div>
          The first way is - In the side panel click the + icon to create a new personal chit for any category.
        </div>
        <IconWrapper>
          <AddCircleIconWrapper />
        </IconWrapper>


        <div>
          The 2nd way  is the add Chit button in the header of each category.  There too, you can create a new chit for any category.

        </div>

        <div>
          The 3rd way  is by clicking on a day in the calendar view that displays a + sign.  This will create a new personal chit specific to the category that is being displayed.

        </div>








        {/* ----------------- */}
      </ContentWrapper>
    </MainWrapper >

  )
}

export default PersonalChitsHelp

const MainWrapper = styled('div')({

  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  backgroundColor: 'white',
  width: '100%',
  height: '98%',
  marginTop: '2%',
  overflow: 'auto',

  [theme.breakpoints.down('sm')]: {
    // width: '100%'
  },


})

const ContentWrapper = styled('div')({

  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  backgroundColor: 'white',
  width: '85%',
  height: '100%',
  padding: '.75rem',
  overflow: 'auto',
  fontSize: '.8rem',

  '& div': {
    margin: '0 0 .75rem 0'
  },


  [theme.breakpoints.down('sm')]: {
    // width: '100%'
  },


})

const LinkWrapper = styled('div')({

  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',

  width: '100%',
  padding: '.35rem',
  height: '100%',
  fontSize: '.65rem',



  [theme.breakpoints.down('sm')]: {
    // width: '100%'
  },


})

const LinkComponent = styled('div')({

  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  backgroundColor: 'lightgrey',
  width: '30%',
  padding: '.35rem',
  height: '100%',
  fontSize: '.65rem',

  '& a': { color: backgroundBlue },
  '& a:visited': { color: backgroundBlue },

  [theme.breakpoints.down('sm')]: {
    // width: '100%'
  },


})

const HeaderWrapper = styled('a')({
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  justifyContent: 'center',

  color: backgroundBlue,
  marginBottom: '.5rem',
  width: '100%',

  [theme.breakpoints.down('sm')]: {
    // width: '100%'
  },


})

// ----Icons -------------------------
const IconWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',


})

const AddCircleIconWrapper = styled(AddCircleIcon)({

  color: 'grey',
  fontSize: '1.7rem',

  '&:hover': {
    backgroundColor: 'lightGrey',
    borderRadius: '50px',
  },

})

const CheckCircleCompleted = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  width: '1.05rem',
  height: '1.05rem',
  border: '1px solid #CFD0D1',
  borderRadius: '200px',

  color: 'white',
  backgroundColor: mediumGrey,





  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',
    // backgroundColor: 'red'
  },
})

// =======================================================