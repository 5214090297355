/* Header_public.jsx
   contains child components: 
      HeaderNav - public nav to SampleSite, Landing, etc
                  navComponents/publicNav/Header_nav

    parent: src/pages/public/Features.jsx

*/

import React from 'react'
import { useDispatch } from 'react-redux'
import { Link, useParams, useLocation } from 'react-router-dom'
import { backgroundBlue, highlightGrey, veryLightGrey } from '../../../styles/colors'

import { getPage } from '../../../app/helpers/locationHelper'


import AppBar from '@mui/material/AppBar'
import Button from '@mui/material/Button'
import Logo from '../../../images/ChitPro_2021_logo_sm.svg'
// import LoginButton from '../../navComponents/buttons/LoginButton'
// import HeaderLogin from './Header_login'

import { openModal } from '../../../app/redux/statusRedux/statusSlice'

import HeaderNav from '../../navComponents/publicNav/Header_nav'

import { styled, createTheme } from "@mui/material/styles"
const theme = createTheme(); // allows use of mui theme in styled component





// -------------------------

const Header_public = (props) => {
  let dispatch = useDispatch()
  let location = useLocation()
  let page = getPage(location)
  console.log('[Header_Public @@@@ ] - page location : ', page)

  let handlePageChange = props.handlePageChange


  const openJoinForm = () => {

    //  define which Form to open in Modal by passing
    //  dbCollection to Modal depending on pageView in browser URL 
    // ------------------------------------------------------------
    console.log('[ Header Landing ] openJoinForm clicked ');
    // props.openModal(dbCollection, id)

    dispatch(openModal(
      {
        modalParams: {
          modalType: 'form',
          dbCollection: '',
          id: '',
          login: 'join'
        }
      }

    ))

  }// end openJoinForm

  return (

    <HeaderWrapper position="fixed" elevation={0}>
      <Header>

        <LogoWrapper>
          <LogoStyle src={Logo} alt="Chit Git Logo" />


        </LogoWrapper>





        <NavBox>
          <HeaderNav
            handlePageChange={handlePageChange}


          />
        </NavBox>

        <SpacerBox />



      </Header>
      <SubHeader>

        <JoinBox >
          <StyledJoinButton onClick={() => openJoinForm()}>
            Join
          </StyledJoinButton>
        </JoinBox>







      </SubHeader>
      <AppBarSpacer />

    </HeaderWrapper>



  )
}


export default Header_public
// -----------------------------------------------------------------

const HeaderWrapper = styled('div')({
  height: '2.5rem',
  backgroundColor: backgroundBlue,
  border: 'none',

  [theme.breakpoints.down('sm')]: {
    height: '5rem'

  }

})

const Header = styled('div')({

  height: '2.5rem',
  marginBottom: '1rem',
  width: '100vw',
  display: 'flex',

  justifyContent: 'space-between',
  alignItems: 'center',

  [theme.breakpoints.down('sm')]: {
    height: '3rem',
    margin: 0
    ,
  }


})

const SubHeader = styled('div')({

  width: '100vw',
  display: 'flex',

  justifyContent: 'center',
  alignItems: 'center',

  [theme.breakpoints.down('sm')]: {

    width: '100vw',
    display: 'flex',

    justifyContent: 'center',
    alignItems: 'center',

  }


})

const AppBarSpacer = styled('div')({

  height: '2.5rem',
  display: 'block',



  [theme.breakpoints.down('sm')]: {
    height: '15rem',

  }

})

const LogoWrapper = styled('div')({


  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  height: '100%',
  width: '25%',
  marginLeft: '2%',

  [theme.breakpoints.down('sm')]: {

    width: '25%',
    height: '2rem',
    marginLeft: '1%',
  }

})

const LogoStyle = styled('img')({

  height: '1.5rem',

  // marginRight: '14px',
})


const NavBox = styled('div')({

  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '50%',

  [theme.breakpoints.down('sm')]: {

    width: '75%',

  }

})

const SpacerBox = styled('div')({

  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '25%',

  [theme.breakpoints.down('sm')]: {

    width: '0%',

  }

})

const LoginBox = styled('div')({

  height: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  width: '26%',
  marginRight: '9%',

  [theme.breakpoints.down('sm')]: {
    display: 'none'
  }

})

const DonateBox = styled('div')({

  height: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',

  marginLeft: '9%',
  marginBottom: '4px',

  [theme.breakpoints.down('sm')]: {
    display: 'none'
  }

})

const LoginBoxSmall = styled('div')({

  display: 'none',
  [theme.breakpoints.down('sm')]: {



    height: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '26%',
    marginRight: '1rem',
  }

})

const DonateBoxSmall = styled('div')({

  display: 'none',
  [theme.breakpoints.down('sm')]: {



    height: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',

    marginLeft: '1rem',
    marginBottom: '4px',
  }

})



const StyledDonateButton = styled(Button)({


  display: 'block',
  textTransform: 'none',

  border: '1px solid white',
  color: 'white',
  fontWeight: 'normal',
  fontSize: '.8rem',
  padding: '1px 1.4rem',

  '&:hover': {
    // backgroundColor: chitSkyBlue,
    textDecoration: 'none',
    border: '1px solid #A8BEED',
    color: '#A8BEED'
  },

  [theme.breakpoints.down('sm')]: {

    fontSize: '.65rem',
    padding: '1px 1.4rem',
  }


})

const StyledLogIn = styled(Button)({



  textTransform: 'none',

  // backgroundColor: shadowBlue,

  borderRadius: '0',
  color: 'white',
  fontWeight: '300',

  padding: '0 10px',

  '&:disabled ': {
    color: '#8293B8'
  },

  '& :hover': {
    backgroundColor: veryLightGrey,
  },

  [theme.breakpoints.down('sm')]: {



  },
  [theme.breakpoints.down('xs')]: {

  }

})

const StyledDonateButtonSmall = styled(Button)({

  position: 'absoute',
  top: '4px',
  left: '4px',
  display: 'block',
  textTransform: 'none',

  border: '1px solid white',
  color: 'white',
  fontWeight: 'normal',
  fontSize: '.8rem',
  padding: '1px 1.4rem',

  '&:hover': {
    // backgroundColor: chitSkyBlue,
    textDecoration: 'none',
    border: '1px solid #A8BEED',
    color: '#A8BEED'
  }


})

const StyledLogInSmall = styled(Button)({



  textTransform: 'none',

  // backgroundColor: shadowBlue,

  borderRadius: '0',
  color: 'white',
  fontWeight: '300',

  padding: '0 10px',

  '&:disabled ': {
    color: '#8293B8'
  },

  '& :hover': {
    backgroundColor: veryLightGrey,
  },

  [theme.breakpoints.down('sm')]: {



  },
  [theme.breakpoints.down('xs')]: {

  }

})


const JoinBox = styled('div')({
  height: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',


  marginBottom: '4px',


  [theme.breakpoints.down('sm')]: {

    marginLeft: 0,



  }

})

const StyledJoinButton = styled(Button)({


  display: 'block',
  textTransform: 'none',

  border: '1px solid white',
  color: 'white',
  fontWeight: 'normal',
  fontSize: '.8rem',
  padding: '1px 2rem',

  '&:hover': {
    // backgroundColor: chitSkyBlue,
    textDecoration: 'none',
    border: '1px solid #A8BEED',
    color: '#A8BEED'
  },

  [theme.breakpoints.down('sm')]: {

    fontSize: '.65rem',
    padding: '1px 1.4rem',
  }


})