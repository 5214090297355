/* Delete_icon

  Displays the garbage icon used by all ChitGit apps.
  A given app's delete function can trigger multiple actions.
  For example deleting a task removes the task from the tasks collection, 
  and delete's the task from the task's parent spotlight taskArray.

  @props - id - from item
         - source - task, spotlight, logSection etc.




*/




import React from 'react';

import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { mediumLightGrey, chitOrange, lightGrey, chitBurgandyDull, } from '../../../styles/colors'

// --- redux imports

import { deleteLogSection } from '../../../app/redux/logRedux/logsSlice';
import { deleteFirstSection } from '../../../app/redux/firstRedux/firstSlice';
import { deleteTopicalSection } from '../../../app/redux/topicalRedux/topicalSectionsSlice';

import { deleteTwoPartyChit } from '../../../app/redux/twoPartyChitRedux/twoPartyChitsSlice';
 

import { deleteReceivedChit } from '../../../app/redux/receivedRedux/receivedChitsSlice';

import { deletePersonalChit } from '../../../app/redux/personalChitRedux/personalChitSlice';

import { deleteDocumentFromFirestore } from '../../../app/firebase/fireStore.utils';

import { deleteNote } from '../../../app/redux/noteRedux/notesSlice';

import { selectStatus } from '../../../app/redux/statusRedux/statusSlice';

// --- helper imports



// Material UI --------------------

import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button'



import { styled, createTheme } from "@mui/material/styles"
import { withStyles } from '@mui/styles'
const theme = createTheme(); // allows use of mui theme in styled component


export default function Delete_icon(props) {
// console.log('[ Delete_icon ] props ', props);
  const { source, id, parentSpotlight } = props
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const status = useSelector(selectStatus)
 
  const uid = status.auth.loginId

  let sourceMessage
  if (source === 'topic') {
    sourceMessage = `note - If you delete this topic, all descendent sections will also be permanently deleted.`
  } else {
    sourceMessage = `Are you sure you want to delete ${source} ?`
  }

  // --- Popup Dialog functions
  const [open, setOpen] = React.useState(false)

  const handleClick = (id, source) => {
    setOpen(true);

  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleDelete = async (id, source) => {

    // ########################################################################
    // console.log('[ Delete_icon - handleDelete ] id ', id);
    let documentToBeDeleted = {
      id: id,
      source: source,

    }
    await deleteDocumentFromFirestore(uid, source, id)

    if (source === 'topicalSections') {
      dispatch(deleteTopicalSection(documentToBeDeleted))
    }

    if (source === 'twoPartyChits') {
      dispatch(deleteTwoPartyChit(documentToBeDeleted))
    }

    if (source === 'personalChits') {
      dispatch(deletePersonalChit(documentToBeDeleted))
    }

    if (source === 'receivedChits') {
      dispatch(deleteReceivedChit(documentToBeDeleted))
    }

    if (source === 'notes') {
      dispatch(deleteNote(documentToBeDeleted))
    }

    
    if (source === 'logs') {
      dispatch(deleteLogSection(documentToBeDeleted))
    }

        
    if (source === 'firstContacts') {
      dispatch(deleteFirstSection(documentToBeDeleted))
    }

// ########################################################################


  }// --- end handleDelete


// --- main return Delete_icon -----------------------------

  return (
    <>


      <LightTooltip title='Delete' arrow>
        <Icon


          onClick={() => handleClick(id, source)}

        />
      </LightTooltip  >


      <Dialog
        open={open}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">

        </DialogTitle>
        <DialogContent>
          <div>
            {sourceMessage}
          </div>
        </DialogContent>
        <DialogActions>
          <StyledButton

            variant="contained"
            color="primary"
            onClick={() => handleDelete(id, source)}
          >
            Yes - delete
          </StyledButton>

          <StyledButton
            form="submit-form"
            variant="contained"
            color="primary"

            onClick={handleDialogClose}
          >
            Cancel
          </StyledButton>

        </DialogActions>
      </Dialog>

    </>
  )
}


// ----------------------------



const Icon= styled(DeleteIcon)({
  backgroundColor: 'white',
  borderRadius: '5px',
  fontSize: '.95rem',
  color: chitOrange,
  margin: '0 .5rem .3rem .5rem',
  cursor: 'pointer',
  


  '&:hover': {
    color: mediumLightGrey
    // backgroundColor: mediumLightGrey
  },


  [theme.breakpoints.down('sm')] : {
    // height: '1.25rem',
    // backgroundColor: 'red'
  },
})

const StyledButton= styled(Button)({
  backgroundColor: 'white',
  border: '1px solid #E6E7E8',
  color: chitBurgandyDull,
  margin: '0 1.5rem 0 6px',
 
  height: '1.5rem',
  fontSize: '.8rem',
  '&:hover' :{
    backgroundColor: lightGrey
  }

})


const LightTooltip = withStyles({
  tooltip: {
    color: "grey",
    backgroundColor: "white",
    boxShadow: '2px 3px 3px black',
    border: '1px solid grey',
  }
})(Tooltip);
