import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import PersonalChitHeader from './PersonalChitHeader';


import { selectStatus } from '../../../app/redux/statusRedux/statusSlice';

import New from '../components/New_Circle';
import PersonalLedger from './PersonalLedger';

import PersonalCalendar from './PersonalCalendar';

import Paper from '@mui/material/Paper'

import { styled, createTheme } from "@mui/material/styles"
import { withStyles } from '@mui/styles'
const theme = createTheme(); // allows use of mui theme in styled component


export default function PesonalMain(props) {
  let match = useParams()

  let chitView = useSelector(selectStatus).view.personal.display
  let categoryId = match.id
  // let [newCategoryView, setNewCategoryView] = useState(categoryView)


  // useEffect(() => {
  //   // console.log('[PersonalMain_s]  ', newCategoryView)
  //   setNewCategoryView(categoryView)
  // }, [categoryView])



  return (
    <Wrapper>

      {!categoryId &&
        <NoneMessage>
          <div>Choose a category to be displayed</div>
          <div>or</div>
          <div>Create a new chit<br/> 
              <New/></div>
        </NoneMessage>
      }

      {categoryId && <>
        <PersonalChitHeader />
        {chitView === 'ledger' && <PersonalLedger />}
        {chitView === 'calendar' && <PersonalCalendar />}
      </>
      }
    </Wrapper>
  );

}


// ---------------------------


const Wrapper = styled('div')({


  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  //   backgroundColor: veryLightGrey,
  width: '100%',
  height: '100%',
  overflowY: 'auto',

  //   [theme.breakpoints.down('sm')] : {
  //     // width: '100%'
  //   },

})

const NoneMessage = styled(Paper)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '1.5rem 0',
  width: '80%',
  height: '8rem',
  backgroundColor: 'white',
  marginTop: '3rem',
  borderRadius: '10px',



  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',
    width: '90%',
  },

})
