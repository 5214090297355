/* function FirstMain(props) -------------------
       parent: sampleSite/Plans_s

  Holds Firsts, Section Form and sections ... includes  
  (a) info icon - to get help
  (a) Header - to get help
   
  (sec c) Section Form()
  (sec d) Sections
 

------------------------------------*/


import React , {useState, useEffect} from 'react'
import {useParams} from 'react-router-dom'
import {useSelector} from 'react-redux'
import { Scrollbars } from 'react-custom-scrollbars';
import { backgroundBlue, chitBurgandy } from '../../../styles/colors';

import { selectStatus } from '../../../app/redux/statusRedux/statusSlice';

import First from './First'

import NewFirstSectionForm from '../../../forms/NewFirstSectionForm';
import FirstHeader from './FirstHeader'

import LogViewNav from './LogView_nav';

//  ---- Material Ui ------------------

import AddCircleIcon from '@mui/icons-material/AddCircle'
import Paper from '@mui/material/Paper'

import { styled, createTheme  } from "@mui/material/styles"
const theme = createTheme(); // allows use of mui theme in styled component


// ==========================================================

export default function FirstMain(props) {

  let match = useParams()
  let urlId = match.id

  let status = useSelector(selectStatus)

  let firstFormDisplay = status.view.firstContact.sectionId
  // let statusId = status.view.firstContact.id
 

// const [firstId, setFirstId] = useState(statusId)
// useEffect(()=>{
// setFirstId(statusId)

// },[statusId])


return (
  <OuterContainer> 
<LogViewNav initialValue = 'firstContact' />

 
 
      <>
       

        <MainWrapper>

        <FirstHeader />


          <Container>
          {firstFormDisplay === 'new' &&
              <NewFormSectionContainer> 
                
                 <NewFirstSectionForm /> 
              
               </NewFormSectionContainer>
            }
            <SectionsContainer>
              <SectionWrapper>

                <First />
              </SectionWrapper>

            </SectionsContainer>



          </Container>

        </MainWrapper>
      </>
  </OuterContainer>
  
)// --- end main reutrn
}// --end main function FirstMain

// -----------------------------------------------


const OuterContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  position: 'relative',
  
  // backgroundColor: 'red',
  
  
  // minHeight: '10rem',
  // height: '90%',
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  
  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',
  
  },
  
  
  })
  const MainWrapper= styled('div')({
  
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
   
  width: '100%',
  height: '100%',
  
  overflow: 'auto',
  [theme.breakpoints.down('sm')] : {
    // width: '100%'
  },
  
  
  })
  
  const HeaderWrapper = styled(Paper)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  margin: '.2rem  0 2rem 0',
  padding: '0 0 .5rem 2rem',
  height: '4rem',
  color: chitBurgandy,
  fontSize: '1.3rem',
  
  
  width: 'calc(98% - 2rem)',
  
  
  
  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',
  
  },
  })
  
  
  
  const LeftTopWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  paddingLeft: '1%',
  color: backgroundBlue,
  fontSize: '1.2rem',
  // backgroundColor: 'yellow',
  width: '33%',
  height: '3rem',
  
  // minHeight: '10rem',
  // height: '90%',
  
  
  // overflowY: 'hidden',
  
  [theme.breakpoints.down('sm')] : {
    // height: '1.25rem',
  
  },
  
  })
  
  const RightTopWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  
  // backgroundColor: 'yellow',
  width: '33%',
  height: '3rem',
  
  // minHeight: '10rem',
  // height: '90%',
  
  
  // overflowY: 'hidden',
  
  [theme.breakpoints.down('sm')] : {
    // height: '1.25rem',
  
  },
  
  })
  
  const Container= styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  
  
  color: 'charcoal',
  width: '95%',
  
  // minHeight: '10rem',
  // height: '90%',
  margin: '0 0 5% 0',
  
  // overflowY: 'hidden',
  
  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',
  
  },
  
  backgroundColor: 'white',
  
  
  })
  
  
  const FormContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    
  
    width: '94%',
    height: '65%',
    maxHeight: '23rem',
    marginLeft: '3%',
    paddingBottom: '8px',
   
    border: '2px solid orange',
    borderRadius: '5px',
    // minHeight: '10rem',
    // height: '90%',
  
    
    // overflowY: 'hidden',
  
    [theme.breakpoints.down('sm')] : {
      // height: '1.25rem',
  
    },
  
  
  
  })
  
  const SectionsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  
  
  width: '100%',
  minHeight: '13rem',
  // backgroundColor: 'yellow',
  
  // minHeight: '10rem',
  // height: '90%',
  
  
  // overflowY: 'hidden',
  
  [theme.breakpoints.down('sm')] : {
    // height: '1.25rem',
  
  },
  
  
  
  })
  
  const NewWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  
  // backgroundColor: 'yellow',
  width: '99%',
  height: '3rem',
  paddingLeft: '2px',
  
  // minHeight: '10rem',
  // height: '90%',
  
  
  // overflowY: 'hidden',
  
  [theme.breakpoints.down('sm')] : {
    // height: '1.25rem',
  
  },
  
  
  
  })
  
  const SectionWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  
  
  width: '100%',
  minHeight: '13rem',
  // backgroundColor: 'orange',
  
  // minHeight: '10rem',
  // height: '90%',
  
  
  // overflowY: 'hidden',
  
  [theme.breakpoints.down('sm')] : {
    // height: '1.25rem',
  
  },
  
  
  
  })
  
  
  const AddCircleIconWrapper= styled(AddCircleIcon)({
  
  color: 'grey',
  fontSize : '1.7rem',
  
  '&:hover' : {
    backgroundColor: 'lightGrey',
    borderRadius: '50px',
  },
  
  })
  
  
  
  const Boy= styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  
  
    color: 'charcoal',
    width: '95%',
  
    // minHeight: '10rem',
    // height: '90%',
    margin: '0 0 5% 0',
  
    // overflowY: 'hidden',
  
    [theme.breakpoints.down('sm')]: {
      // height: '1.25rem',
  
    },
  
    backgroundColor: 'white',
  
  
  })
  
  const NewFormSectionContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    
    border: '2px solid orange',
    borderRadius: '5px',
    width: '100%',
    padding: '4px 0 8px 0',
   
    
    // minHeight: '10rem',
    // height: '90%',
    
    
    // overflowY: 'hidden',
    
    [theme.breakpoints.down('sm')] : {
      // height: '1.25rem',
    
    },
    
    
    
    })

