import React from 'react';
 



import { styled, createTheme } from "@mui/material/styles"
import { withStyles } from '@mui/styles'
const theme = createTheme(); // allows use of mui theme in styled component

// ===============================================

export default function SharedChit(props) {
  return (
    <React.Fragment>
      Shared Chit Display here
    </React.Fragment>
  );
}

 