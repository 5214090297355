import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch, } from 'react-redux'


import { chitDarkLavendar, chitOrangeMedium, veryLightGrey, chitLightGreen, chitBright, chitLightPink, chitDullYellow, mediumGrey, lightGrey } from '../../../styles/colors'

import { capitalizeFirstLetter } from '../../../app/helpers/commonHelpers'
import { openModal, selectStatus } from '../../../app/redux/statusRedux/sam_statusSlice'

import HeaderMain from './samComponents/Header_main'

import PersonalChits from './samPersonalChits/PersonalChits_s'
import TwoPartyChits from './samTwoPartyChits/TwoPartyChits_s'
import Logs from './samLogs/Logs_s'
import Topicals from './samTopicals/Topicals_s'


import TwoPartyNav from '../../navComponents/publicNav/sampleNav/sideBarNav/TwoPartyNav_s'
import PersonalNav from '../../navComponents/publicNav/sampleNav/sideBarNav/PersonalNav_s'

import LogNav from '../../navComponents/publicNav/sampleNav/sideBarNav/LogNav_s'
import TopicalNav from '../../navComponents/publicNav/sampleNav/sideBarNav/TopicalNav_s'

// -------Material UI 

import ClickAwayListener from '@mui/material/ClickAwayListener';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'

import { styled, createTheme } from "@mui/material/styles"
const theme = createTheme(); // allows use of mui theme in styled component

// ===============================================

export default function Main_s(props) {
  let match = useParams()
  let dispatch = useDispatch

  const status = useSelector(selectStatus)
  const modalShow = status.modal.modalDisplayed
  let page, formattedPage
  page = match.pageView



  switch (page) {

    case 'personalChits':
      formattedPage = 'Personal Chits'
      break

    case 'twoPartyChits':
      formattedPage = 'Two Party Chits'
      break

    case 'workChits':
      formattedPage = 'Work Chits'
      break


    default: formattedPage = capitalizeFirstLetter(page)
  }



  /*
     --- each page (chits, logs etc) has a different header color
     --- headerColor logic determines which color is displayed
     --- based on page variable in URL
  */
  const headerColor = (page) => {
    let background

    if (page === 'twoPartyChits') {
      background = { backgroundColor: chitDarkLavendar, fontColor: 'white' }
    }

    if (page === 'personalChits') {
      background = { backgroundColor: chitLightGreen, fontColor: 'black' }
    }

    if (page === 'logs') {
      background =
        { backgroundColor: chitLightPink, fontColor: 'black' }
    }


    if (page === 'topicals') {
      background =
        { backgroundColor: chitOrangeMedium, fontColor: 'black' }
    }


    return background
  }

  // ----side Panel logic ----- 


  const [viewSidePanel, setViewSidePanel] = useState('hide');
  const handleSidePanelChange = (evt) => {
    evt.persist()
    const newSidePanelView = (viewSidePanel === 'show') ? 'hide' : 'show'
    setViewSidePanel(newSidePanelView)
  }

  const [open, setOpen] = React.useState(false);

  const handleClickAway = () => {
    setViewSidePanel('hide')
  };

  return (
    <BodyWrapper>


      <HeaderMain />
      {/* <TopSpacer /> */}


      <MainWrapper>

        <HeaderWrapper style={{
          backgroundColor: headerColor(page).backgroundColor,
          color: headerColor(page).fontColor
        }}>
          <Header> {formattedPage}   </Header>

        </HeaderWrapper>
        <MainContentWrapper>


          {/* #########   SIDE PANEL  ############################### */}
          <ClickAwayListener
            onClickAway={handleClickAway}
            mouseEvent="onMouseDown"
            touchEvent="onTouchStart"
          >
            <SidePanelWrapper className={viewSidePanel}>


              <Handle onClick={handleSidePanelChange}>

                {viewSidePanel === 'hide' &&
                  <KeyboardArrowRightIcon style={{ color: 'white' }} />
                }

                {viewSidePanel === 'show' &&
                  <KeyboardArrowLeftIcon style={{ color: 'white' }} />
                }
              </Handle>




              {/* {page === 'twoParty' &&  <TwoPartyNav />  }
              {page === 'personal' &&  <PersonalNav />  }
              {page === 'work' &&  <WorkNav  />  }
              {page === 'notes' &&  <NoteNav/>  }
              {page === 'logs' &&  <LogNav/>  }

              */}




              {page === 'logs' && <LogNav />}

              {page === 'topicals' && <TopicalNav />}


              {page === 'personalChits' && <PersonalNav />}
              {page === 'twoPartyChits' && <TwoPartyNav />}

            </SidePanelWrapper>
          </ClickAwayListener>

          <ContentArea>

            {page === 'twoPartyChits' &&
              <TwoPartyChits />
            }



            {page === 'personalChits' &&
              <PersonalChits />
            }


            {page === 'logs' &&
              <Logs />
            }
            {page === 'topicals' &&
              <Topicals />
            }



          </ContentArea>

        </MainContentWrapper>

      </MainWrapper>

    </BodyWrapper>


  ) // end return
}

// ---Styles ---------------------------------------
const BodyWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',

  // backgroundColor: bodyBlue,
  height: '100vh',
  width: '100vw',
  backgroundColor: veryLightGrey,
  // backgroundImage: 'linear-gradient(to  bottom, #3B30CC, #1B1625, #040952)',
  overflow: 'hidden',


})




const MainWrapper = styled('div')({

  display: 'block',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  margin: '0 auto',
  width: '100%',
  overflow: 'hidden',

  height: 'calc(100% - 3.4rem)',
  backgroundColor: veryLightGrey,


  [theme.breakpoints.down('md')]: {
    width: '100%'
  },

})
// ----- Content Area here -------------



const MainContentWrapper = styled('div')({

  display: 'block',
  height: 'calc(100% - 2rem)',
  position: 'relative',

  // paddingTop: '3rem',





  [theme.breakpoints.down('sm')]: {
    // paddingTop: '1.5rem',
    paddingLeft: '0',
    // height: '90%',

  },

  [theme.breakpoints.down('xs')]: {

    // paddingLeft: '3rem',

  }

})



const ContentArea = styled('div')({

  display: 'flex',
  marginLeft: '15rem',
  height: '100%',
  overflow: 'auto',
  position: 'relative',

  // paddingTop: '.5rem',



  [theme.breakpoints.down('md')]: {
    // display: 'block'
    marginLeft: '2rem',
  },

  [theme.breakpoints.down('xs')]: {
    marginLeft: '0',

  }

})


// ---- Side Panel CSS

const SidePanelWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  position: 'absolute',

  backgroundColor: 'white',
  width: '15rem',
  height: '100%',
  paddingTop: '3px',

  paddingBottom: '2rem',
  zIndex: '31',


  [theme.breakpoints.down('md')]: {

    left: '3rem',
    '&.hide': {
      left: '-15rem',
      borderRight: '4px solid #CFD0D1'
    },

    '&.show': {
      left: '0rem',
      borderRight: '2px solid #CFD0D1'
    },

  },



  [theme.breakpoints.down('md')]: {
    marginLeft: '0',
    // display: 'none'

    '&.hide': {
      left: '-15rem'
    },

    '&.show': {
      left: '0rem'
    },
  },

})

const Handle = styled('div')({

  display: 'none',
  position: 'absolute',
  width: '1.5rem',
  height: '3rem',
  borderRadius: '0 5px 5px 0',


  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    right: '-1.5rem',
    top: '0',



    background: 'grey'
  },


})

// --- Side Panel Header



const HeaderWrapper = styled('div')({

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '28px',
  width: '100%',
  backgroundColor: 'white',
  borderRadius: '0 0 15px 15px',
  margin: '0 auto 3px auto',

  [theme.breakpoints.down('sm')]: {
    borderRadius: 0
  }


})

const Header = styled('div')({

  display: 'block',
  fontSize: '1.1rem',

  // color: chitRedDark




})


const InfoIconWrapper = styled('div')({



  position: 'absolute',
  top: '2px',
  right: '24px',

  '&:hover': {
    color: lightGrey,
    cursor: 'pointer'
  },

  [theme.breakpoints.down('sm')]: {
    // height: '1.25rem',
    // backgroundColor: 'red'
  },
})
