
import React from 'react';
import LoginForm from '../../forms/LoginForm';
import JoinForm from '../../forms/JoinForm';

import { chitBurgandy, veryLightGrey } from '../../styles/colors'

import Paper from '@mui/material/Paper'
import { styled, createTheme } from "@mui/material/styles"
import { withStyles } from '@mui/styles'
const theme = createTheme(); // allows use of mui theme in styled component

// -----------------------------------------------------------------


export default function Login(props) {
  return (
    <Wrapper>
      <FormContent>
        <Left>
          <TopHeader> Login </TopHeader>
          <LoginForm />

        </Left>

        <Right>
          <TopHeader> Join</TopHeader>
          <JoinForm />
        </Right>
      </FormContent>
    </Wrapper>
  );
}

const Wrapper = styled('div')({

  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  justifyContent: 'space-around',
  alignItems: 'center',
  backgroundColor: 'white',
  width: '80%',
  height: '100%',
  padding: '3rem',
  borderRadius: '10px',
  marginTop: '3rem',

  [theme.breakpoints.down('sm')]: {
    // width: '100%'
  },

})

const FormContent = styled('div')({


  position: 'relative',
  display: 'flex',

  justifyContent: 'center',
  alignItems: 'flex-start',
  margin: '0 auto 0 auto',
  width: '100%',
  height: '28rem',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    height: 'auto',
    maxWidth: '100%',
    minWidth: '100%',
  }

})

const Left = styled(Paper)({

  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  margin: '0 ',
  width: '48%',
  height: '100%',

  paddingTop: '1rem',
  [theme.breakpoints.down('sm')]: {

    width: '100%',
    paddingLeft: '2%',

  },

})

const Right = styled(Paper)({

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  margin: '0 auto 0 auto',
  width: '48%',
  // backgroundColor: 'green',
  height: '100%',

  paddingTop: '1rem',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  }

})

const Middle = styled('div')({

  position: 'relative',
  backgroundColor: 'black',
  display: 'block',


  width: '1px',
  height: '100%',

  [theme.breakpoints.down('xs')]: {
    width: '100%',
  }

})

const TopHeader = styled('div')({

  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',

  width: '100%',
  color: chitBurgandy,
  height: '1rem',
  marginBottom: '2rem',
  fontSize: '1.4rem',
  [theme.breakpoints.down('xs')]: {
    width: '100%',
  }

})




